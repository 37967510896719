import { useMutation } from "@tanstack/react-query";
import { lodImgCreateApi, lodImgListApi } from "components/api/lod";
import { lodImgs } from "interface/lod";
import { MutableRefObject, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setMessage } from "store/commonSlice";
import InImgView from "./inImgCreate";
import OutImgView from "./outImgCreate";
import { useSelector } from "react-redux";
import { isAbleSelector } from "store/lodSlice";

type imgUrlProps = {
  name: string;
  url: string | ArrayBuffer | null;
};

type IProps = {
  lodNo: MutableRefObject<string>;
};

const LodImgView = ({ lodNo }: IProps) => {
  const dispatch = useDispatch();
  const [imgs, setImgs] = useState<lodImgs[]>([]);
  const [isRegi, setIsRegi] = useState(false);
  const isAdAble = useSelector(isAbleSelector);
  const [isIn, setIsIn] = useState(false);

  const listMutation = useMutation(lodImgListApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          setImgs(body?.lodImgList);
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const imgMutation = useMutation(lodImgCreateApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(
            setMessage({
              message: "이미지를 등록하였습니다.",
              isConfirm: true,
              handleClick() {
                setIsRegi(true);
                dispatch(setMessage({ message }));
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleCreate = () => {
    const params = {
      lodNo: lodNo.current ?? "",
      lodImgList: imgs.map((img, index) => {
        return {
          ...img,
          rgSeq: index + 1,
          expsOrdV: index + 1,
        };
      }),
    };

    dispatch(
      setMessage({
        message: "이미지를 등록하겠습니까?",
        isCancel: true,
        isConfirm: true,
        handleClick() {
          dispatch(setMessage({ message: null }));
          imgMutation.mutate(params);
        },
      })
    );
  };

  useEffect(() => {
    // if () {
    // listMutation.mutate({ lodNo: lodNo.current });
    // }
  }, []);

  const isAble = isAdAble ? isRegi : !isAdAble;

  return (
    <>
      <div className="table-form" data-dir-col>
        <div className="sb-title relative">
          <h4
            data-inf="최대 10개까지 등록가능"
            onClick={() => {
              setIsIn(!isIn);
            }}
          >
            이미지
          </h4>
          <div className="flex items-center gap-4">
            <button
              className="btn-mdfy"
              disabled={isAble}
              onClick={handleCreate}
            >
              등록
            </button>
          </div>
        </div>
        {isIn && (
          <>
            <OutImgView
              imgs={imgs}
              setImgs={setImgs}
              isRegi={isRegi}
              isAble={isAble}
            />
            <InImgView
              imgs={imgs}
              setImgs={setImgs}
              isRegi={isRegi}
              isAble={isAble}
            />
          </>
        )}
      </div>
    </>
  );
};

export default LodImgView;
