import { useMutation } from "@tanstack/react-query";
import { rmListApi } from "components/api/comn";
import Select from "components/select";
import { optionProps } from "interface";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setRmList } from "store/roomSlice";

type IProps = {
  option: string;
  disabled?: boolean;
  params: string;
  onSelect(target: EventTarget & HTMLLIElement): void;
};

const RmNoSelect = ({ params, disabled, option, onSelect }: IProps) => {
  const dispatch = useDispatch();
  const [options, setOptions] = useState<optionProps[]>([
    { value: "A", kr: "선택하세요", us: "select" },
  ]);

  const listMutation = useMutation({
    mutationKey: ["rmList", params],
    mutationFn: rmListApi,
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;

        if (code === 0) {
          const options = body.rmList.map((li: any) => {
            const option = {
              value: li.rmNo,
              kr: li.rmNo,
              us: li.rmNo,
            };

            return option;
          });

          setOptions([...options]);
          dispatch(setRmList(body?.rmList));
        }
        if (code === -1) {
          setOptions([{ value: "A", kr: "선택하세요", us: "select" }]);
        }
      }
    },
  });

  useEffect(() => {
    if (!!params) {
      listMutation.mutate(params);
    }
  }, [params]);

  return (
    <Select
      wrapStyle="flex-1 w-full h-[2.75rem]"
      id="rmNo"
      name="rmNo"
      option={option}
      disabled={disabled}
      options={[{ value: "A", kr: "선택하세요", us: "select" }, ...options]}
      onSelect={onSelect}
    />
  );
};

export default RmNoSelect;
