import LodDftListView from "components/view/lod/default/list";
import { lodDftSearchProps } from "interface/lod";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setIsLoading, setIsSearch } from "store/commonSlice";

const LodDefaultListPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname, state } = useLocation();
  const memType = pathname.includes("admin") ? "admin" : "manager";
  const lodGrpInfo = localStorage.getItem("lodGrpInfo")
    ? JSON.parse(localStorage.getItem("lodGrpInfo") ?? "")
    : "";
  const [searchInfo, setSearchInfo] = useState<lodDftSearchProps>({
    lodGrpNo: state?.lodGrpNo || lodGrpInfo?.lodGrpNo,
    lodGrpNm: state?.lodGrpNm || "",
    lodNo: state?.lodNo || "",
    lodGrdCd: state?.lodGrdCd || "",
    lodNm: state?.lodNm || "",
    uyn: state?.uyn || "",
  });
  const handleChange = (target: EventTarget & HTMLInputElement) => {
    const { name, value } = target;
    setSearchInfo({
      ...searchInfo,
      [name]: value,
    });
  };
  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { name, value, lng } = target.dataset;
    if (name && value) {
      setSearchInfo({
        ...searchInfo,
        [name]: value === "A" ? "" : value,
      });
    }
  };

  const handleSearch = () => {
    dispatch(setIsLoading(true));
    dispatch(setIsSearch(true));
    navigate(`?page=1`);
  };

  const handleReset = () => {
    setSearchInfo({
      lodGrpNo: lodGrpInfo?.lodGrpNo || "",
      lodGrpNm: "",
      lodNo: "",
      lodGrdCd: "",
      lodNm: "",
      uyn: "",
    });
    dispatch(setIsLoading(false));
    dispatch(setIsSearch(false));
  };

  return (
    <LodDftListView
      isLoading={false}
      memType={memType}
      searchInfo={searchInfo}
      handleChange={handleChange}
      handleSelect={handleSelect}
      handleReset={handleReset}
      handleSearch={handleSearch}
    />
  );
};

export default LodDefaultListPage;
