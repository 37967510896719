import {
  IlodRefdDtl,
  IlodRfd,
  IlodRfdAd,
  dftAdInf,
  lodAdProps,
  lodDftSearchProps,
  lodGrpAdInfProps,
  lodGrpInfProps,
  lodGrpSearchProps,
  lodImgProsp,
  lodImgs,
  lodInfProps,
  lodManProps,
  lodPayProps,
  lodProps,
  lodRefProps,
  lodRfdSearchInfoProps,
  lodSearchProps,
  opMdfyProps,
  opProps,
} from "interface/lod";
import api from "./api";

// 숙소그룹
export const lodGrpListApi = async (params: Partial<lodGrpSearchProps>) => {
  const res = await api({
    method: "post",
    url: "admin/lodman/lodGrpList",
    data: params,
  });

  return res;
};

export const lodGrpDtlApi = async (params: {
  lodGrpNo: string;
  langCd: string;
}) => {
  const res = await api({
    method: "post",
    url: "admin/lodman/lodGrpDtl",
    data: params,
  });

  return res;
};

export const lodGrpStatusApi = async (params: {
  lodGrpNo: string;
  uyn: string;
}) => {
  const res = await api({
    method: "post",
    url: "admin/lodman/lodGrpYn",
    data: params,
  });

  return res;
};

export const lodGrpInfCreate = async (params: lodGrpInfProps) => {
  const res = await api({
    method: "post",
    url: `admin/lodman/lodGrpInf/create`,
    data: params,
  });

  return res;
};

export const lodGrpAdInfCreate = async (params: lodGrpAdInfProps) => {
  const res = await api({
    method: "post",
    url: `admin/lodman/lodGrpAdInf/create`,
    data: params,
  });

  return res;
};

export const lodGrpAdList = async (params: {
  lodGrpNo: string;
  langCd: string;
}) => {
  const res = await api({
    method: "post",
    url: `admin/lodman/lodGrpAdList`,
    data: params,
  });

  return res;
};

export const lodGrpModifyApi = async (
  params: Partial<lodGrpInfProps> & { lodGrpNo: string }
) => {
  const res = await api({
    method: "post",
    url: "admin/lodman/lodGrpInf/modify",
    data: params,
  });

  return res;
};

export const lodGrpAdModifyApi = async (params: Partial<lodGrpAdInfProps>) => {
  const res = await api({
    method: "post",
    url: `admin/lodman/lodGrpAdInf/modify`,
    data: params,
  });

  return res;
};

// 숙소
export const lodListApi = async (params: lodSearchProps) => {
  const res = await api({
    method: "post",
    url: "admin/lodman/lodList",
    data: params,
  });

  return res;
};

export const lodStatusApi = async (params: { lodNo: string; uyn: string }) => {
  const res = await api({
    method: "post",
    url: "admin/lodman/lodYn",
    data: params,
  });
  return res;
};

export const lodCreateApi = async (params: lodProps) => {
  const res = await api({
    method: "post",
    url: `admin/lodman/lod/create`,
    data: params,
  });

  return res;
};

export const lodInfCreateApi = async (params: lodInfProps) => {
  const res = await api({
    method: "post",
    url: `admin/lodman/lodInf/create`,
    data: params,
  });

  return res;
};

export const lodManCreateApi = async (params: lodManProps) => {
  const res = await api({
    method: "post",
    url: `admin/lodman/lodMan/create`,
    data: params,
  });

  return res;
};

export const lodPayCreateApi = async (params: lodPayProps) => {
  const res = await api({
    method: "post",
    url: `admin/lodman/lodPay/create`,
    data: params,
  });

  return res;
};

export const lodAdCreateApi = async (params: lodAdProps) => {
  const res = await api({
    method: "post",
    url: `admin/lodman/lodAdInf/create`,
    data: params,
  });

  return res;
};

export const lodImgCreateApi = async (params: lodImgProsp) => {
  const res = await api({
    method: "post",
    url: `admin/lodman/lodImg/create`,
    data: params,
  });

  return res;
};

export const lodDftDtlApi = async (params: {
  lodNo: string;
  langCd?: string;
}) => {
  const res = await api({
    method: "post",
    url: `admin/lodman/lodDftDtl`,
    // url: "",
    data: params,
  });
  return res;
};

export const lodInfDtlApi = async (params: { lodNo: string }) => {
  const res = await api({
    method: "post",
    url: `admin/lodman/lodInfDtl`,
    data: params,
  });

  return res;
};

export const lodAdDftListApi = async (params: { lodNo: string }) => {
  const res = await api({
    method: "post",
    url: `admin/lodman/lodAdDftList`,
    data: params,
  });

  return res;
};

export const lodManDtlApi = async (params: { lodNo: string }) => {
  const res = await api({
    method: "post",
    url: `admin/lodman/lodManDtl`,
    data: params,
  });

  return res;
};

export const lodPayDtlApi = async (params: { lodNo: string }) => {
  const res = await api({
    method: "post",
    url: `admin/lodman/lodPayDtl`,
    data: params,
  });

  return res;
};

export const lodImgListApi = async (params: {
  lodNo: string;
  imgTypCd: string;
}) => {
  const res = await api({
    method: "post",
    url: `admin/lodman/lodImgList`,
    data: params,
  });

  return res;
};

export const lodAdListApi = async (params: { lodNo: string }) => {
  const res = await api({
    method: "post",
    url: `admin/lodman/lodAdList`,
    data: params,
  });

  return res;
};

export const lodDtlApi = async (params: { lodNo: string; langCd: string }) => {
  const res = await api({
    method: "post",
    url: `admin/lodman/lodDtl`,
    data: params,
  });

  return res;
};

export const lodDftListApi = async (params: lodDftSearchProps) => {
  const res = await api({
    method: "post",
    url: "admin/lodman/lodDftList",
    data: params,
  });

  return res;
};

export const lodModify = async (params: lodProps) => {
  const res = await api({
    method: "post",
    url: `admin/lodman/lod/modify`,
    data: params,
  });

  return res;
};

export const lodInfModify = async (params: lodInfProps) => {
  const res = await api({
    method: "post",
    url: `admin/lodman/lodInf/modify`,
    data: params,
  });

  return res;
};

export const lodManModify = async (params: lodManProps) => {
  const res = await api({
    method: "post",
    url: `admin/lodman/lodMan/modify`,
    data: params,
  });

  return res;
};

export const lodPayModify = async (params: lodPayProps) => {
  const res = await api({
    method: "post",
    url: `admin/lodman/lodPay/modify`,
    data: params,
  });

  return res;
};

export const lodAdModify = async (params: lodAdProps) => {
  const res = await api({
    method: "post",
    url: `admin/lodman/lodAd/modify`,
    data: params,
  });

  return res;
};

export const lodImgModifyApi = async (
  params: Partial<lodImgs> & {
    lodNo: string;
    uyn: string;
  }
) => {
  const res = await api({
    method: "post",
    url: `admin/lodman/lodImg/modify`,
    data: params,
  });

  return res;
};

// 숙소기본정보
export const lodAdDftDtlApi = async (params: {
  lodNo: string;
  langCd: string;
}) => {
  const res = await api({
    method: "post",
    url: `admin/lodman/lodAdDftDtl`,
    data: params,
  });

  return res;
};

export const lodAdDftModify = async (params: Partial<dftAdInf>) => {
  const res = await api({
    method: "post",
    url: `admin/lodman/lodDtlAdInf/modify`,
    data: params,
  });

  return res;
};

//숙소 제공정보
export const opDtl = async (params: opProps) => {
  const res = await api({
    method: "post",
    url: `admin/lodman/opDtl`,
    data: params,
  });

  return res;
};

export const opListApi = async (params: opProps) => {
  const res = await api({
    method: "post",
    url: `admin/lodman/opList`,
    data: params,
  });
  return res;
};

export const opModifyApi = async (params: opMdfyProps) => {
  const res = await api({
    method: "post",
    url: `admin/lodman/opModify`,
    data: params,
  });
  return res;
};

// 숙소환불수수료
export const lodRefdCreateApi = async (params: lodRefProps) => {
  const res = await api({
    method: "post",
    url: `admin/lodman/lodRefd/dftCreate`,
    data: params,
  });

  return res;
};

export const lodRefdAdCreateApi = async (params: IlodRfdAd) => {
  const res = await api({
    method: "post",
    url: `admin/lodman/lodRefd/dftAdCreate`,
    data: params,
  });

  return res;
};

export const lodRefdListApi = async (params: lodRfdSearchInfoProps) => {
  const res = await api({
    method: "post",
    url: `admin/lodman/lodRefd/dftList`,
    data: params,
  });

  return res;
};

export const lodRefdDtl = async (params: { lodNo: string; langCd: string }) => {
  const res = await api({
    method: "post",
    url: `admin/lodman/lodRefd/dftDtl`,
    data: params,
  });

  return res;
};

export const lodRefAdList = async (params: { lodNo: string }) => {
  const res = await api({
    method: "post",
    url: `admin/lodman/lodRefd/dftAdList`,
    data: params,
  });
  return res;
};

export const lodRefdInfModify = async (params: IlodRfd) => {
  const res = await api({
    method: "post",
    url: `admin/lodman/lodRefd/dftInfModify`,
    data: params,
  });

  return res;
};

export const lodRefdAdModifyApi = async (params: any) => {
  const res = await api({
    method: "post",
    url: `admin/lodman/lodRefd/dftAdInfModify`,
    data: params,
  });
  return res;
};

// export const lodDftCreateApi = async (params: lodDftInfoProps) => {
//   const res = await api({
//     method: "post",
//     url: "",
//     data: params,
//   });

//   return res;
// };

export const lodGrpNoListApi = async () => {
  const res = await api({
    method: "get",
    url: "",
  });

  return res;
};

export const lodGrpNmListApi = async () => {
  const res = await api({
    method: "get",
    url: "",
  });

  return res;
};

export const logdmentStatus = async () => {
  const res = await api({
    method: "post",
    url: "",
  });

  return res;
};

export const createLodmentApi = async () => {
  const res = await api({
    method: "post",
    url: "",
    data: {},
  });

  return res;
};

export const lodmentInfoApi = async () => {
  const res = await api({
    method: "get",
    url: "",
  });

  return res;
};

export const lodmentModifyApi = async () => {
  const res = await api({
    method: "post",
    url: "",
    data: {},
  });

  return res;
};
