import { useQuery } from "@tanstack/react-query";
import { lodAdDftDtlApi } from "components/api/lod";
import LodDfltModifyView from "components/view/lod/default/modify";
import i18n from "locales/i18n";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

const LodDefaultModifyPage = () => {
  const dispatch = useDispatch();

  const { search, pathname } = useLocation();

  const lodNo = search?.replace("?lodNo=", "");

  const query = useQuery({
    queryKey: ["lodAdDftDtl", lodNo],
    queryFn: () => {
      return lodAdDftDtlApi({
        lodNo,
        langCd: i18n.language,
      });
    },
  });

  const dtl = query?.data?.body?.dtl;

  return <LodDfltModifyView dtl={dtl} />;
};

export default LodDefaultModifyPage;
