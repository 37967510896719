import { admRmPdListHead, manRmPdListHead } from "util/option";
import { useLocation, useNavigate } from "react-router-dom";
import Pagenation from "components/pagenation";
import Modal from "components/modal/modal";
import { Dispatch, SetStateAction, Suspense, useEffect, useState } from "react";
import { lodStatusApi } from "components/api/lod";
import { useMutation } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import {
  isLoadingSelector,
  isSearchSelector,
  setIsLoading,
  setIsSearch,
  setMessage,
} from "store/commonSlice";
import NotData from "../notData";
import { rmPdSearchProps } from "interface/room";
import { pageNavProps } from "interface";
import { useSelector } from "react-redux";
import { rmPdListApi } from "components/api/room";
import { dateFormat, getDay, inCom } from "util/common";
import i18n from "locales/i18n";

type IProps = {
  memType: "admin" | "manager";
  searchInfo: rmPdSearchProps;
};

type IListProps = {
  memType: "admin" | "manager";
  searchInfo: rmPdSearchProps;
  setPageNav: Dispatch<SetStateAction<pageNavProps | null>>;
};
const List = ({ memType, searchInfo, setPageNav }: IListProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname, search, state } = useLocation();
  const isSearch = useSelector(isSearchSelector);
  const [list, setList] = useState([]);

  const rmPdMutation = useMutation(rmPdListApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          setPageNav(body?.pageNav);
          setList(body?.rmPdList);
          dispatch(setIsLoading(false));
          dispatch(setIsSearch(false));
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
          dispatch(setIsLoading(false));
          dispatch(setIsSearch(false));
        }
      }
    },
  });

  let page = search.replace("?page=", "") || null;
  useEffect(() => {
    if (!!page) {
      dispatch(setIsSearch(true));
      dispatch(setIsLoading(true));
    }
  }, [page]);

  useEffect(() => {
    if (isSearch) {
      const params = {
        langCd: searchInfo?.langCd || state?.langCd,
        rmNo: searchInfo?.rmNo || state?.rmNo,
        rmNm: searchInfo?.rmNm || state?.rmNm,
        rmTypCd: searchInfo?.rmTypCd || state?.rmTypCd,
        rmOlkCd: searchInfo?.rmOlkCd || state?.rmOlkCd,
        maxPerCtMax: searchInfo?.maxPerCtMax || state?.maxPerCtMax || -1,
        maxPerCtMin: searchInfo?.maxPerCtMin || state?.maxPerCtMin || -1,
        rmPdNo: searchInfo?.rmPdNo || state?.rmPdNo,
        lodChlCd: searchInfo?.lodChlCd || state?.lodChlCd,
        rmOpNm: searchInfo?.rmOpNm || state?.rmOpNm,
        sellSDt: (searchInfo?.sellSDt || state?.sellSDt)?.replaceAll("-", ""),
        sellEDt: (searchInfo?.sellEDt || state?.sellEDt)?.replaceAll("-", ""),
        sellYn: searchInfo?.sellYn || state?.sellYn,
        pageNo: Number(page) || 1,
      };

      rmPdMutation.mutate(params);
    }
  }, [isSearch, searchInfo, search]);

  const tdy = new Date().toISOString().split("T")[0].replaceAll("-", "");

  const pageRows = rmPdMutation.data?.body?.pageNav?.pageRows;
  return (
    <>
      {list.map((li: any, index: number) => {
        const isSts =
          Number(tdy) > Number(li.sellDt)
            ? "done"
            : li.sellYn === "N"
            ? "n-use"
            : "sll";

        return (
          <tr
            key={index}
            onClick={() => {
              navigate(`info?rmPdNo=${li.rmPdNo}`, {
                state: { searchInfo, url: pathname + search },
              });
            }}
          >
            <td>{(Number(page) - 1) * (pageRows || 10) + index + 1}</td>
            {memType === "admin" && (
              <>
                <td>{li.lodGrpNo}</td>
                <td>{li.lodGrpNm}</td>
                <td>{li.lodNo}</td>
                <td>
                  <span className="w-[12.5rem]">{li.lodNm}</span>
                </td>
                <td>{li.rmNo}</td>
                <td>{li.rmNm}</td>
                <td>{li.rmPdNo}</td>
                <td>{li.rmTypNm}</td>
                <td>{li.rmOlkNm}</td>
                <td>{li.lodChlCd}</td>
                <td>
                  <span className="w-[7.5rem]">{li.rmOpNm}</span>
                </td>
                <td>{li.brkfInYn === "N" ? "미포함" : "포함"}</td>
                <td>{li.latChkOutTi.replace(/\B(?=(\d{2})+(?!\d))/g, ":")}</td>
                <td></td>
                <td>{dateFormat(li.sellDt)}</td>
                <td>{getDay(dateFormat(li.sellDt), i18n.language)}</td>
                <td>{li.rmPdCt}</td>
                <td></td>
                <td>{inCom(li.sellPri ?? "")}</td>
              </>
            )}
            {memType === "manager" && (
              <>
                <td>{li.rmNo}</td>
                <td>{li.rmNm}</td>
                <td>{li.rmPdNo}</td>
                <td>{li.lodChlCd}</td>
                <td>
                  <span className="w-[7.5rem]">{li.rmOpNm}</span>
                </td>
                <td>{dateFormat(li.sellDt)}</td>
                <td>{getDay(dateFormat(li.sellDt), i18n.language)}</td>
                <td>{li.rmPdCt}</td>
                <td>{inCom(li.sellPri ?? "")}</td>
              </>
            )}

            <td>
              <button className={isSts} disabled={true} onClick={() => {}}>
                {isSts === "done"
                  ? "마감"
                  : isSts === "n-use"
                  ? "중지"
                  : "판매"}
              </button>
            </td>
          </tr>
        );
      })}
    </>
  );
};

const RoomProductList = ({ memType, searchInfo }: IProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoading = useSelector(isLoadingSelector);

  const [isOpen, setIsOpen] = useState(false);
  const [pageNav, setPageNav] = useState<pageNavProps | null>(null);

  return (
    <div className="data-list-wrap">
      <div className="data-cont">
        <div className="table-title">
          <h3>
            검색결과
            <span>
              총<b>{pageNav?.totalRows || 0}</b>개
            </span>
          </h3>
        </div>
        <div>
          <table>
            <thead>
              {memType === "admin" && (
                <tr>
                  {admRmPdListHead.map((li) => (
                    <th key={li}>{li}</th>
                  ))}
                </tr>
              )}
              {memType === "manager" && (
                <tr>
                  {manRmPdListHead.map((li) => (
                    <th key={li}>{li}</th>
                  ))}
                </tr>
              )}
            </thead>

            <tbody>
              <Suspense>
                <List
                  searchInfo={searchInfo}
                  memType={memType}
                  setPageNav={setPageNav}
                />
              </Suspense>
            </tbody>
          </table>
        </div>
        <NotData
          isLoading={isLoading}
          isNotData={!pageNav?.totalRows}
          isSuccess={!!pageNav}
          styles=""
          txt="Loading ..."
        />
      </div>
      {pageNav && pageNav?.totalRows > 0 && (
        <Pagenation total={pageNav?.totalPage} />
      )}
      {/* <AgGrid rowData={rowData} columnDefs={columnDefs} pagination={true} /> */}
      {/* <Modal
        title="상태 변경"
        isOpen={isOpen}
        isLoading={statusMutation.isLoading}
        disabled={statusMutation.isLoading}
        handleCloseModal={() => setIsOpen(!isOpen)}
        onClick={handleStatus}
      >
        <p>
          해당 숙소 그룹을 <b>사용</b> 하시겠습니까?
        </p>
      </Modal> */}
    </div>
  );
};

export default RoomProductList;
