import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import commonSlice from "./commonSlice";
import memberSlice from "./memberSlice";
import roomSlice from "./roomSlice";
import lodSlice from "./lodSlice";

const store = configureStore({
  reducer: {
    common: commonSlice,
    member: memberSlice,
    room: roomSlice,
    lod: lodSlice,
  },
  middleware: getDefaultMiddleware({ serializableCheck: false }),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;
// export const useAppDispatch: () => AppDispatch = useDispatch;

export default store;
