import { useMutation } from "@tanstack/react-query";
import { admInfoApi, managerInfoApi, userInfoApi } from "components/api/member";
import MemInfoView from "components/view/member/info";
import { ImemInf } from "interface/member";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setMessage } from "store/commonSlice";
import { dateFormat } from "util/common";

const MemInfoPage = () => {
  const dispatch = useDispatch();
  const { pathname, search, state } = useLocation();
  const memType = search.includes("admin") ? "admin" : "manager";
  const [memInfo, setMemInfo] = useState<ImemInf>({
    memNo: "",
    fstnm: "",
    surnm: "",
    name: "",
    emlId: "",
    mobCntyCd: "",
    mobNo: "",
    langCd: "",
    currCd: "",
    typCd: "",
    joiDtti: "",
    lckDtti: "",
    soYn: "",
    lckYn: "",
    joiReqDtti: "",
    joiAprYn: "",
    authGrpCod: "",
    authGrpNm: "",
  });

  const admMutation = useMutation(admInfoApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          const {
            admFstnm,
            admSurnm,
            admNo,
            authGrpCod,
            authGrpNm,
            currCd,
            emlId,
            joiAprDtti,
            joiReqDtti,
            langCd,
            mobCntyCd,
            mobNo,
            soYn,
            joiAprYn,
          } = body;

          setMemInfo({
            memNo: admNo,
            fstnm: admFstnm,
            surnm: admSurnm,
            name: admSurnm + admFstnm,
            emlId,
            mobCntyCd,
            mobNo,
            langCd,
            currCd,
            typCd: "",
            joiDtti: dateFormat(joiReqDtti?.slice(0, 8), "-") ?? "",
            lckDtti: "",
            soYn,
            lckYn: "",
            joiAprYn,
            authGrpCod,
            authGrpNm,
          });
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const manMutation = useMutation(managerInfoApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          const {
            currCd,
            emlId,
            joiReqDtti,
            langCd,
            lodGrpNo,
            manFstnm,
            manNo,
            manSurnm,
            manTypCod,
            mobNo,
            soYn,
            mobCntyCd,
          } = body.manInfo;
          setMemInfo({
            memNo: manNo,
            fstnm: manFstnm,
            surnm: manSurnm,
            name: manSurnm + manFstnm,
            emlId,
            mobCntyCd,
            mobNo,
            langCd,
            currCd,
            typCd: manTypCod,
            joiDtti: dateFormat(joiReqDtti?.slice(0, 8), "-") ?? "",
            lckDtti: "",
            soYn,
            lckYn: "",
          });
        }

        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const userMutation = useMutation(userInfoApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          const {
            emlId,
            joiDtti,
            langCd,
            lckYn,
            memFstnm,
            memSurnm,
            menNm,
            memNo,
            mobCntyCd,
            mobNo,
            soDtti,
            soYn,
            currCd,
            telCntyCd,
            telNo,
          } = body?.userDtl;
          setMemInfo({
            memNo: memNo,
            fstnm: memFstnm,
            surnm: memSurnm,
            name: memSurnm + memFstnm,
            emlId,
            mobCntyCd: mobCntyCd || telCntyCd,
            mobNo: mobNo || telNo,
            langCd,
            currCd,
            typCd: "",
            joiDtti,
            soDtti,
            soYn,
            lckYn,
          });
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleSttPwd = () => {
    alert("준비중입니다.");
  };

  const handleWthdw = () => {
    alert("준비중입니다.");
  };

  useEffect(() => {
    if (!!search.includes("manNo")) {
      manMutation.mutate(search.replace("?manNo=", ""));
      return;
    }
    if (!!search.includes("admNo")) {
      admMutation.mutate(search.replace("?admNo=", ""));
      return;
    }
    if (!!search.includes("memNo")) {
      userMutation.mutate(search.replace("?memNo=", ""));
    }
  }, [pathname, search]);

  return (
    <>
      <MemInfoView
        title={"회원정보 상세"}
        memType={memType}
        memInfo={memInfo}
        handleSttPwd={handleSttPwd}
        handleWthdw={handleWthdw}
      />
    </>
  );
};

export default MemInfoPage;
