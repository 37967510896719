import { useMutation, useQuery } from "@tanstack/react-query";
import { lodRefdCreateApi, lodRefdDtl } from "components/api/lod";
import CheckButton from "components/checkbox/checkbtn";
import Select from "components/select";
import LodGrpNmSelect from "components/select/room/lodGrpNmSelect ";
import LodNmSelect from "components/select/room/lodNmSelect";
import { lodRefProps } from "interface/lod";
import i18n from "locales/i18n";
import { MutableRefObject, useState } from "react";
import { useDispatch } from "react-redux";
import { setMessage } from "store/commonSlice";
import { feeTypeOptions, lastTimeOptions, lodGrdCdOptions } from "util/option";

type IProps = {
  lodNoRef: MutableRefObject<string>;
  isAbleRef: MutableRefObject<boolean>;
};

const InfCreateView = ({ lodNoRef, isAbleRef }: IProps) => {
  const dispatch = useDispatch();

  const query = useQuery({
    queryKey: ["lodRefdDtl", lodNoRef.current],
    queryFn: () => {
      return lodRefdDtl({
        lodNo: lodNoRef.current,
        langCd: i18n.language,
      });
    },
  });

  const refdDtl = query?.data?.body?.lodRefdDtl;

  const [inf, setInf] = useState<lodRefProps>({
    lodGrpNo: "",
    lodNo: "",
    langCd: "",
    feeTypCd: "B01",
    feeRti: "",
    lodGrdCd: "",
    refdFeeTypCd: "",
    rgSeq: 0,
    monAplyYn: "N",
    tuesAplyYn: "N",
    wenAplyYn: "N",
    thurAplyYn: "N",
    friAplyYn: "N",
    satAplyYn: "N",
    sunAplyYn: "N",
    aplySDt: "",
    aplyEDt: "",
    aplyChkDay: 0,
    aplyTi: "",
  });

  const handleChange = (name: string, value: string) => {
    setInf({
      ...inf,
      [name]: value,
    });
  };

  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { name, value } = target.dataset;
    if (name && value) {
      setInf({
        ...inf,
        [name]: value,
      });
    }
  };

  const infMutation = useMutation(lodRefdCreateApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(
            setMessage({
              message: "환불수수료기본정보를 등록하였습니다.",
              isConfirm: true,
              handleClick() {
                isAbleRef.current = true;
                lodNoRef.current = body?.lodNo;
                dispatch(setMessage({ message: null }));
              },
            })
          );
        }
      }
    },
  });

  const handleCreate = () => {
    if (!inf.lodGrpNo) {
      return alert("숙소그룹명을 선택하여 주세요");
    }
    if (!inf.lodNo) {
      return alert("숙소번호를 선택하여 주세요.");
    }
    const params = {
      ...inf,
      aplySDt: inf.aplySDt.replaceAll("-", ""),
      aplyEDt: inf.aplyEDt.replaceAll("-", ""),
      aplyTi: inf.aplyTi.replaceAll(":", ""),
      aplyChkDay: Number(inf.aplyChkDay),
    };
    dispatch(
      setMessage({
        message: "환불수수료기본율기본정보를 등록하시겠습니까?",
        isConfirm: true,
        isCancel: true,
        handleClick() {
          dispatch(setMessage({ message: null }));
          infMutation.mutate(params);
        },
      })
    );
  };

  return (
    <div className="table-form" data-dir-col>
      <div className="sb-title">
        <h4>기본정보</h4>
        <button className="btn-mdfy" onClick={handleCreate}>
          등록
        </button>
      </div>
      <fieldset disabled={isAbleRef.current}>
        <table>
          <tbody>
            <tr>
              <th>숙소그룹명</th>
              <td>
                <LodGrpNmSelect
                  option={inf.lodGrpNo ?? ""}
                  onSelect={handleSelect}
                />
              </td>
              <th>숙소등급</th>
              <td>
                <Select
                  id="lodGrdCd"
                  name="lodGrdCd"
                  option={inf.lodGrdCd}
                  options={lodGrdCdOptions}
                  onSelect={handleSelect}
                />
              </td>
            </tr>
            <tr>
              <th>숙소명</th>
              <td>
                <LodNmSelect
                  option={inf.lodNo}
                  params={inf.lodGrpNo ?? ""}
                  onSelect={handleSelect}
                />
              </td>
              <th>기본수수료종류</th>
              <td>
                <Select
                  id="feeTypCd"
                  name="feeTypCd"
                  option={inf.feeTypCd}
                  options={feeTypeOptions}
                  onSelect={handleSelect}
                />
              </td>
            </tr>
            <tr>
              <th>적용 시작일자</th>
              <td>
                <input
                  type="date"
                  value={inf.aplySDt}
                  onChange={(e) =>
                    handleChange("aplySDt", e.currentTarget.value)
                  }
                />
              </td>
              <th>적용 종료일자</th>
              <td>
                <input
                  type="date"
                  value={inf.aplyEDt}
                  onChange={(e) =>
                    handleChange("aplyEDt", e.currentTarget.value)
                  }
                />
              </td>
            </tr>
            <tr>
              <th>적용요일</th>
              <td colSpan={3}>
                <div className="w-full flex">
                  <CheckButton
                    id="MON"
                    name="day"
                    label="MON"
                    checked={inf.monAplyYn === "Y"}
                    onChange={(e) =>
                      handleChange(
                        "monAplyYn",
                        e.currentTarget.checked ? "Y" : "N"
                      )
                    }
                  />
                  <CheckButton
                    id="TUE"
                    name="day"
                    label="TUE"
                    checked={inf.tuesAplyYn === "Y"}
                    onChange={(e) =>
                      handleChange(
                        "tuesAplyYn",
                        e.currentTarget.checked ? "Y" : "N"
                      )
                    }
                  />
                  <CheckButton
                    id="WED"
                    name="day"
                    label="WED"
                    checked={inf.wenAplyYn === "Y"}
                    onChange={(e) =>
                      handleChange(
                        "wenAplyYn",
                        e.currentTarget.checked ? "Y" : "N"
                      )
                    }
                  />
                  <CheckButton
                    id="THU"
                    name="day"
                    label="THU"
                    checked={inf.thurAplyYn === "Y"}
                    onChange={(e) =>
                      handleChange(
                        "thurAplyYn",
                        e.currentTarget.checked ? "Y" : "N"
                      )
                    }
                  />
                  <CheckButton
                    id="FRI"
                    name="day"
                    label="FRI"
                    checked={inf.friAplyYn === "Y"}
                    onChange={(e) =>
                      handleChange(
                        "friAplyYn",
                        e.currentTarget.checked ? "Y" : "N"
                      )
                    }
                  />
                  <CheckButton
                    id="SAT"
                    name="day"
                    label="SAT"
                    checked={inf.satAplyYn === "Y"}
                    onChange={(e) =>
                      handleChange(
                        "satAplyYn",
                        e.currentTarget.checked ? "Y" : "N"
                      )
                    }
                  />
                  <CheckButton
                    id="SUN"
                    name="day"
                    label="SUN "
                    checked={inf.sunAplyYn === "Y"}
                    onChange={(e) =>
                      handleChange(
                        "sunAplyYn",
                        e.currentTarget.checked ? "Y" : "N"
                      )
                    }
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th>적용 숙박일기준</th>
              <td>
                <input
                  type="text"
                  value={inf.aplyChkDay}
                  onChange={(e) =>
                    handleChange("aplyChkDay", e.currentTarget.value)
                  }
                />
              </td>
              <th>적용 숙박일기준시간</th>
              <td>
                <Select
                  id="aplyTi"
                  name="aplyTi"
                  option={inf.aplyTi}
                  options={lastTimeOptions}
                  onSelect={handleSelect}
                />
              </td>
            </tr>
            <tr>
              <th>적용 수수료율</th>
              <td>
                <input
                  type="text"
                  value={inf.feeRti}
                  onChange={(e) =>
                    handleChange("feeRti", e.currentTarget.value)
                  }
                />
              </td>
            </tr>
          </tbody>
        </table>
      </fieldset>
    </div>
  );
};

export default InfCreateView;
