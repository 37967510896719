import FormSearchBtn from "components/button/formSearch";
import SubHeader from "components/layout/header/subHeader";
import LodChlSelect from "components/select/room/lodChlSelect";
import LodGrpSelect from "components/select/room/lodGrpNoSelect";
import LodNoSelect from "components/select/room/lodNoSelect";
import RmNoSelect from "components/select/room/rmNoSlect";
import RoomHsrList from "components/tableList/room/hstr";
import { rmHstSearhInfo } from "interface/room";

type IProps = {
  memType: "admin" | "manager";
  searchInfo: Omit<rmHstSearhInfo, "pageNo">;
  handleChange(name: string, value: string): void;
  handleSelect(target: EventTarget & HTMLLIElement): void;
  handleSearch(): void;
  handleReset(): void;
};

const RmHstView = ({
  memType,
  searchInfo,
  handleChange,
  handleSearch,
  handleSelect,
  handleReset,
}: IProps) => {
  return (
    <>
      <div className="search-form">
        <SubHeader title="객실상품이력" dot />
        <div className="table-form">
          <table>
            <tbody>
              {memType === "admin" && (
                <tr>
                  <th>숙소그룹번호</th>
                  <td>
                    <LodGrpSelect
                      option={searchInfo?.lodGrpNo}
                      onSelect={(target) => handleSelect(target)}
                    />
                  </td>
                  <th>숙소그룹명</th>
                  <td>
                    <input
                      type="text"
                      value={searchInfo?.lodGrpNm}
                      onChange={(e) =>
                        handleChange("lodGrpNm", e.currentTarget.value)
                      }
                    />
                  </td>
                </tr>
              )}
              {memType === "admin" && (
                <tr>
                  <th>숙소번호</th>
                  <td>
                    <LodNoSelect
                      disabled={!searchInfo?.lodGrpNo}
                      option={searchInfo?.lodNo}
                      params={searchInfo?.lodGrpNo}
                      onSelect={(target) => handleSelect(target)}
                    />
                  </td>
                  <th>숙소명</th>
                  <td>
                    <input
                      type="text"
                      value={searchInfo?.lodNm}
                      onChange={(e) =>
                        handleChange("lodNm", e.currentTarget.value)
                      }
                    />
                  </td>
                </tr>
              )}
              {/* {memType === "admin" && (
                <tr>
                  <th>숙소등급</th>
                  <td>
                    <LodGrdSelect
                      option={searchInfo?.lodGrdCd}
                      onSelect={(target) => handleSelect(target)}
                    />
                  </td>
                </tr>
              )} */}
              <tr>
                <th>객실번호</th>
                <td>
                  <RmNoSelect
                    disabled={!searchInfo?.lodNo}
                    option={searchInfo.rmNo}
                    params={searchInfo.lodNo}
                    onSelect={(target) => handleSelect(target)}
                  />
                </td>
                <th>객실옵션명</th>
                <td>
                  <input
                    type="text"
                    value={searchInfo.rmOpNm}
                    onChange={(e) =>
                      handleChange("rmOpNm", e.currentTarget.value)
                    }
                  />
                </td>
              </tr>
              {/* <tr>
                <th>객실타입</th>
                <td>
                  <RmTypeSelect
                    option={searchInfo.rmTypCd}
                    onSelect={(target) => handleSelect(target)}
                  />
                </td>
              </tr> */}
              <tr>
                {/* <th>객실상품번호</th>
                <td>
                  <input type="text" />
                </td> */}
                <th>객실상품채널 </th>
                <td>
                  <LodChlSelect
                    option={searchInfo.lodChlCd}
                    onSelect={(target) => handleSelect(target)}
                  />
                </td>
              </tr>
              <tr>
                <th data-dot>조회 시작일자</th>
                <td>
                  <input
                    type="date"
                    max={"2100-12-31"}
                    value={searchInfo.sellSDt}
                    onChange={(e) =>
                      handleChange("sellSDt", e.currentTarget.value)
                    }
                  />
                </td>
                <th data-dot>조회 종료일자</th>
                <td>
                  <input
                    type="date"
                    max="2100-12-31"
                    value={searchInfo.sellEDt}
                    onChange={(e) =>
                      handleChange("sellEDt", e.currentTarget.value)
                    }
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <FormSearchBtn
            handleSearch={handleSearch}
            handleReset={handleReset}
          />
        </div>
      </div>
      <RoomHsrList memType={memType} searchInfo={searchInfo} />
    </>
  );
};

export default RmHstView;
