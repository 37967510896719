import "../list.scss";
import { authOptions, adminListHead } from "util/option";
import { useMutation } from "@tanstack/react-query";
import { IadmList, searchProps } from "interface/member";
import { setMemNo, setIsSearchMember } from "store/memberSlice";
import {
  isLoadingSelector,
  isSearchSelector,
  setIsLoading,
  setIsSearch,
  setMessage,
} from "store/commonSlice";
import Pagenation from "components/pagenation";
import { admListApi, modifyUserStatusApi } from "components/api/member";
import { Dispatch, SetStateAction, Suspense, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import i18n from "locales/i18n";
import { optionProps, pageNavProps } from "interface";
import NotData from "../notData";

type IListProps = {
  searchInfo: Partial<searchProps>;
  setPageNav: Dispatch<SetStateAction<pageNavProps | null>>;
  memType: "admin" | "manager";
};

const List = ({ searchInfo, memType, setPageNav }: IListProps) => {
  const lng = i18n.language;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname, search, state } = useLocation();
  const isSearch = useSelector(isSearchSelector);

  const [list, setList] = useState([]);

  const auth = (value: string) => {
    const authOb = authOptions.find((auth) => {
      return auth.value === value;
    });

    return authOb ? authOb[lng as keyof optionProps] : "";
  };

  const admMutation = useMutation(admListApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          setPageNav(body?.pageNav);
          dispatch(setIsLoading(false));
          dispatch(setIsSearch(false));
          setList(body?.admList);
        }
        if (code === -1) {
          dispatch(setIsLoading(false));
          dispatch(setIsSearch(false));
          dispatch(
            setMessage({
              message,
              isConfirm: true,
              handleClick() {
                dispatch(setMessage({ message: null }));
              },
            })
          );
        }
      }
    },
  });

  const statusMutation = useMutation(modifyUserStatusApi, {
    onSuccess(data, context) {
      if (data) {
        const { code, message } = data.header;
        if (code === 0) {
          // setIsOpen(false);
          dispatch(
            setMessage({
              message,
              isConfirm: true,
              handleClick() {
                dispatch(setIsSearchMember(true));
                dispatch(setMessage({ message: null }));
              },
            })
          );

          dispatch(setMemNo(""));
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });
  let page = search.replace("?page=", "") || null;
  useEffect(() => {
    if (!!page) {
      dispatch(setIsSearch(true));
      dispatch(setIsLoading(true));
    }
  }, [page]);

  useEffect(() => {
    if (isSearch && searchInfo?.type === "admin") {
      const params = {
        authGrpCod: searchInfo?.authGrpCod,
        emlId: searchInfo?.emlId,
        mobCntyCd: searchInfo?.mobCntyCd,
        mobNo: searchInfo?.mobNo,
        admStatus: "",
        pageNo: 1,
      };
      admMutation.mutate(params);
    }
  }, [isSearch, searchInfo, search]);

  return (
    <>
      {list &&
        list?.map((li: IadmList, index: number) => {
          return (
            <tr
              key={li.admNo}
              className={li.soYn === "Y" ? "drop" : ""}
              onClick={(e) => {
                e.stopPropagation();
                navigate(`info?admNo=${li.admNo}`, {
                  state: {
                    searchInfo,
                    url: pathname + search,
                    admNo: li.admNo,
                  },
                });
              }}
            >
              <td>{index + 1}</td>
              <td>
                <button
                  onClick={() => {
                    dispatch(setMemNo(li.admNo));
                    navigate(`?admNo=${li.admNo}`);
                  }}
                  disabled={li.soYn === "Y"}
                >
                  {li.admNo}
                </button>
              </td>
              <td>{auth(li.authGrpCod)}</td>
              <td>{li.admFstnm}</td>
              <td>{li.admSurnm}</td>
              <td>{li.emlId}</td>
              <td>{li.mobNo}</td>
              <td>
                <span
                  className={
                    li.soYn === "Y"
                      ? "soYn"
                      : li.joiAprYn === "N"
                      ? "joiAprYn"
                      : ""
                  }
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  // onClick={handleJoinApply({
                  //   admNo: li.admNo,
                  //   apply: li.joiAprYn === "N",
                  // })}
                >
                  {li.soYn === "Y"
                    ? "탈퇴"
                    : li.joiAprYn === "N"
                    ? "신청"
                    : "정상"}
                </span>
              </td>
            </tr>
          );
        })}
    </>
  );
};

type Iprops = {
  searchInfo: Partial<searchProps>;
};

const AdminList = ({ searchInfo }: Iprops) => {
  const isLoading = useSelector(isLoadingSelector);

  const dispatch = useDispatch();
  const [pageNav, setPageNav] = useState<pageNavProps | null>(null);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [memAdmNo, setmemAdmNo] = useState("");

  const headList = adminListHead.map((head) => {
    return head;
  });

  return (
    <div className="data-list-wrap">
      <div className="data-cont">
        <div className="table-title">
          <h3>
            검색결과
            <span>
              총<b>{pageNav?.totalRows || 0}</b>개
            </span>
          </h3>
        </div>

        <table>
          <thead>
            <tr>
              {headList.map((li) => (
                <th key={li}>{li}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            <Suspense>
              <List
                searchInfo={searchInfo}
                setPageNav={setPageNav}
                memType={"manager"}
              />
            </Suspense>
          </tbody>
        </table>
      </div>
      <NotData
        isLoading={isLoading}
        isNotData={!pageNav?.totalRows}
        isSuccess={!!pageNav}
        styles=""
        txt="Loading ..."
      />
      {pageNav && pageNav?.totalRows > 0 && (
        <Pagenation total={pageNav.totalPage} />
      )}
    </div>
  );
};

export default AdminList;

{
  /* <AgGrid
          rowData={rowData}
          columnDefs={columnDefs}
          pagination={true}
          isLoading={isLoading}
        /> */
}
