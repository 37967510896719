import SubHeader from "components/layout/header/subHeader";
import Modal from "components/modal/modal";
import RadioEditor from "components/radio/radioEditor";
import RadioContent from "components/radio/radiocontent";
import Select from "components/select";
import LodGrpSelect from "components/select/room/lodGrpNoSelect";
import LodNoSelect from "components/select/room/lodNoSelect";
import Grid from "components/tableList";
import { rmOpProps } from "interface/room";
import i18n from "locales/i18n";
import { Dispatch, SetStateAction, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { setMessage } from "store/commonSlice";
import {
  brkfInYnOptions,
  langOptions,
  lngLabel,
  lastTimeOptions,
  uYnOptions,
} from "util/option";
type IProps = {
  info: rmOpProps;
  memType: "admin" | "manager";
  isLoading: boolean;
  addLoading: boolean;
  rmOpDtl: any;
  addInfList: any[];
  opAddInfo: any;
  opAddLngInf: any;
  isOpen: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  handleAddInfoChange(name: string, value: string): void;
  handleSelect(target: EventTarget & HTMLLIElement): void;
  handleModify(type: string): void;
  handleAddLngSelect(target: EventTarget & HTMLLIElement): void;
  handleAddLngInfChange(name: string, value: string): void;
  handleAddInfo(): void;
  handleAddInfClose(): void;
};

const RoomOptModifyInfoView = ({
  info,
  memType,
  rmOpDtl,
  addInfList,
  opAddLngInf,
  addLoading,
  isOpen,
  setIsOpen,
  handleAddInfoChange,
  handleSelect,
  handleModify,
  handleAddInfo,
  handleAddLngSelect,
  handleAddLngInfChange,
  handleAddInfClose,
}: IProps) => {
  const dispatch = useDispatch();
  const { state } = useLocation();

  let rmOpNmInfo = {};
  let rmOpDescInfo = {};
  let labels = [] as any[];
  let newLngOptions = [] as any[];

  addInfList?.forEach((inf: any) => {
    let langCd = inf.langCd === "en" ? "us" : inf.langCd;
    rmOpNmInfo = {
      ...rmOpNmInfo,
      langCd: i18n.language,
      [`rmOpNm_${langCd}`]: inf.rmOpNm,
    };
    rmOpDescInfo = {
      ...rmOpDescInfo,
      langCd: i18n.language,
      [`rmOpDesc_${langCd}`]: inf.rmOpDesc,
    };

    newLngOptions = langOptions.map((lang) => {
      return lang.value === inf.langCd
        ? {
            ...lang,
            events: "none",
          }
        : lang;
    });
  });

  lngLabel.forEach((lang) => {
    addInfList.forEach((inf) => {
      let langCd = lang.id === inf.langCd ? lang : "";
      if (!!langCd) {
        labels.push(langCd);
      }
    });
  });

  const isAdd = !(
    opAddLngInf.langCd !== "all" &&
    opAddLngInf.langCd &&
    opAddLngInf.rmOpNm &&
    opAddLngInf.rmOpDesc &&
    opAddLngInf.rmOpDesc !== "<p><br></p>"
  );

  return (
    <div className="main-cnt">
      <SubHeader
        title="객실옵션 수정"
        url={`/manager/rmManage/rmOp`}
        state={state}
        back
      />
      <div className="table-form" data-dir-col>
        <div className="sb-title relative">
          <h4>객실옵션 기본 정보</h4>
          <button className="btn-mdfy" onClick={() => handleModify("default")}>
            수정
          </button>
        </div>
        <table>
          <tbody>
            {memType === "admin" && (
              <tr>
                <th>숙소그룹명</th>
                <td>
                  <LodGrpSelect
                    option={rmOpDtl?.lodGrpNo}
                    onSelect={(target) => handleSelect(target)}
                  />
                </td>
                <th>숙소명</th>
                <td>
                  <LodNoSelect
                    disabled={true}
                    params={rmOpDtl?.lodGrpNo}
                    option={rmOpDtl?.lodNo}
                    onSelect={(target) => handleSelect(target)}
                  />
                </td>
              </tr>
            )}
            {memType === "manager" && (
              <tr>
                <th>숙소그룹명</th>
                <td>
                  <input type="text" value={rmOpDtl?.lodGrpNm} readOnly />
                </td>
                <th>숙소명</th>
                <td>
                  <input type="text" value={rmOpDtl?.lodNm} readOnly />
                </td>
              </tr>
            )}

            {memType === "manager" && (
              <tr>
                <th>숙소체크인시간</th>
                <td>
                  <input
                    type="text"
                    value={rmOpDtl?.chkInTi?.replace(
                      /\B(?=(\d{2})+(?!\d))/g,
                      ":"
                    )}
                    readOnly
                  />
                </td>
                <th>숙소체크아웃시간</th>
                <td>
                  <input
                    type="text"
                    value={rmOpDtl?.chkOutTi?.replace(
                      /\B(?=(\d{2})+(?!\d))/g,
                      ":"
                    )}
                    readOnly
                  />
                </td>
              </tr>
            )}
            <tr>
              <th>객실옵션 조식포함</th>
              <td>
                <Select
                  id="brkfInYn"
                  name="brkfInYn"
                  option={info?.brkfInYn || rmOpDtl?.brkfInYn}
                  options={brkfInYnOptions.slice(1, 3)}
                  onSelect={(target) => handleSelect(target)}
                />
              </td>
              <th>객실옵션 레이트 체크아웃시간</th>
              <td>
                <Select
                  id="latChkOutTi"
                  name="latChkOutTi"
                  option={
                    info?.latChkOutTi?.replace(/\B(?=(\d{2})+(?!\d))/g, ":") ||
                    rmOpDtl?.latChkOutTi?.replace(/\B(?=(\d{2})+(?!\d))/g, ":")
                  }
                  // rmOpDtl?.latChkOutTi
                  options={lastTimeOptions}
                  onSelect={(target) => handleSelect(target)}
                />
              </td>
            </tr>
            <tr>
              <th>상태</th>
              <td>
                <Select
                  id={"uyn"}
                  name={"uyn"}
                  option={info?.uyn || rmOpDtl?.uyn}
                  options={uYnOptions}
                  onSelect={(target) => handleSelect(target)}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="table-form" data-dir-col>
        <div className="sb-title relative">
          <h4 data-inf="* 언어별 수정 가능">객실옵션 추가 정보</h4>
          {langOptions.length > labels.length && (
            <button
              className="btn-mdfy"
              onClick={() => {
                dispatch(
                  setMessage({
                    message: "객실옵션추가정보를 추가 하시겠습니까?",
                    isConfirm: true,
                    isCancel: true,
                    handleClick() {
                      setIsOpen(true);
                      dispatch(setMessage({ message: null }));
                    },
                  })
                );
              }}
            >
              언어추가
            </button>
          )}
        </div>

        {Object.values(rmOpNmInfo).length === 0 &&
          Object.values(rmOpDescInfo).length === 0 && (
            <div className="text-sm text-center font-bold">
              추가 정보가 없습니다. <br />
              언어 추가 버튼을 클륵하여 추가해주시기 바랍니다.
            </div>
          )}
        <table>
          <tbody>
            {Object.values(rmOpNmInfo).length > 0 && (
              <tr>
                <th>객실옵션명</th>
                <td colSpan={3} className="relative full">
                  <RadioContent
                    options={labels}
                    name={"rmOpNm"}
                    info={rmOpNmInfo}
                    onChange={(target) => {
                      handleAddInfoChange(target.name, target.value);
                    }}
                  />
                  <button
                    className="btn-mdfy absolute top-2 right-0"
                    // data-inf="* 언어별로 수정 가능"
                    onClick={() => handleModify("rmOpNm")}
                  >
                    수정
                  </button>
                </td>
              </tr>
            )}
            {Object.values(rmOpDescInfo).length > 0 && (
              <tr>
                <th>객실옵션설명</th>
                <td colSpan={3} className="relative full">
                  <RadioEditor
                    options={labels}
                    name={"rmOpDesc"}
                    contStyle=""
                    info={rmOpDescInfo}
                    onChange={(name, value) => {
                      handleAddInfoChange(name, value);
                    }}
                  />
                  <button
                    className="btn-mdfy absolute top-2 right-0"
                    // data-inf="* 언어별로 수정 가능"
                    onClick={() => handleModify("rmOpDesc")}
                  >
                    수정
                  </button>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
      <Modal
        title={"객실옵션 추가"}
        styles="w-5/6"
        btnText="추가"
        isOpen={isOpen}
        isLoading={addLoading}
        disabled={isAdd}
        handleCloseModal={handleAddInfClose}
        onClick={handleAddInfo}
      >
        <div className="table-form" data-dir-col>
          <table>
            <tbody>
              <tr data-t-brd>
                <th>언어 선택</th>
                <td>
                  <Select
                    id={"langCd"}
                    name={"langCd"}
                    option={opAddLngInf?.langCd}
                    options={
                      newLngOptions.length !== 0
                        ? [
                            { kr: "선택하세요", value: "all", en: "select" },
                            ...newLngOptions,
                          ]
                        : [
                            { kr: "선택하세요", value: "all", en: "select" },
                            ...langOptions,
                          ]
                    }
                    onSelect={(target) => handleAddLngSelect(target)}
                  />
                </td>
                <th></th>
                <td></td>
              </tr>
              <tr>
                <th>객실옵션명</th>
                <td colSpan={3} className="relative full">
                  <RadioContent
                    options={[]}
                    name={"rmOpNm"}
                    info={{}}
                    onChange={(target) => {
                      handleAddLngInfChange(target.name, target.value);
                    }}
                  />
                </td>
              </tr>
              <tr>
                <th>객실옵션설명</th>
                <td colSpan={3} className="relative">
                  <RadioEditor
                    options={[]}
                    name={"rmOpDesc"}
                    contStyle=""
                    info={{}}
                    onChange={(name, value) => {
                      handleAddLngInfChange(name, value);
                    }}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Modal>
    </div>
  );
};

export default RoomOptModifyInfoView;
