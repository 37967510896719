import { useMutation } from "@tanstack/react-query";
import { lodAdCreateApi, lodAdListApi } from "components/api/lod";
import Modal from "components/modal/modal";
import RadioEditor from "components/radio/comRadioEditor";
import RadioContent from "components/radio/comRadiocontent";
import Select from "components/select";
import { lodAdProps } from "interface/lod";
import i18n from "locales/i18n";
import { MutableRefObject, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { isSuccessSelector, setIsSuccess, setMessage } from "store/commonSlice";
import { isAbleSelector } from "store/lodSlice";
import { langOptions } from "util/option";

type IProps = {
  lodNo: MutableRefObject<string>;
};

const AdInfCreateView = ({ lodNo }: IProps) => {
  const dispatch = useDispatch();
  const isAdAble = useSelector(isAbleSelector);
  const isSuccess = useSelector(isSuccessSelector);

  const adListMutation = useMutation(lodAdListApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          // setList(body?.lodAdList);
          dispatch(setIsSuccess(false));
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  let adList =
    (adListMutation.data?.body?.lodAdList as Array<lodAdProps>) || [];

  let newLngOption = [] as any[];

  let nmInf = {};
  let descInf = {};
  let addr1 = {};
  let addr2 = {};
  let addr3 = {};
  let addr4 = {};

  adList.forEach((inf) => {
    nmInf = {
      ...nmInf,
      langCd: i18n.language,
      [`lodNm_${inf.langCd}`]: inf.lodNm,
    };
    descInf = {
      ...descInf,
      langCd: i18n.language,
      [`lodDesc_${inf.langCd}`]: inf.lodDesc,
    };
    addr1 = {
      ...addr1,
      langCd: i18n.language,
      [`lodAddr1_${inf.langCd}`]: inf.lodAddr1,
    };
    addr2 = {
      ...addr2,
      langCd: i18n.language,
      [`lodAddr2_${inf.langCd}`]: inf.lodAddr2,
    };
    addr3 = {
      ...addr3,
      langCd: i18n.language,
      [`lodAddr3_${inf.langCd}`]: inf.lodAddr3,
    };
    addr4 = {
      ...addr4,
      langCd: i18n.language,
      [`lodAddr4_${inf.langCd}`]: inf.lodAddr4,
    };

    newLngOption = langOptions.filter((lang) => {
      return !adList.some((li) => li.langCd === lang.value);
    });
  });

  const [adInf, setAdInf] = useState<lodAdProps>({
    langCd: "",
    lodNm: "",
    lodDesc: "",
    lodZipc: "",
    lodCntyCd: "KOR",
    lodAddr: "",
    lodAddr1: "",
    lodAddr2: "",
    lodAddr3: "",
    lodAddr4: "",
    lodDtlAddr: "",
  });

  const [isOpen, setIsOpen] = useState(false);
  const [isIn, setIsIn] = useState(false);
  const [isRegi, setIsRegi] = useState(false);

  const handleChange = (name: string, value: string) => {
    if (name.includes("_")) {
      setAdInf({
        ...adInf,
        [name.split("_")[0]]: value,
      });
      return;
    }
    setAdInf({
      ...adInf,
      [name]: value,
    });
  };
  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { name, value, lng, code } = target.dataset;
    if (name && value) {
      if (name === "country") {
        setAdInf({
          ...adInf,
          lodCntyCd: code ?? "",
        });
        return;
      }
      setAdInf({
        ...adInf,
        [name]: value,
      });
    }
  };

  const adMutation = useMutation(lodAdCreateApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(
            setMessage({
              message: "숙소추가정보를 등록하였습니다.",
              isConfirm: true,
              handleClick() {
                dispatch(setMessage({ message: null }));
                setIsOpen(false);
                dispatch(setIsSuccess(true));
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleCreate = () => {
    if (!adInf.langCd) {
      return alert("언어를 선택하여 주세요.");
    }
    if (!adInf.lodNm) {
      return alert("숙소명을 입력하여 주세요.");
    }

    const params = {
      ...adInf,
      lodNo: lodNo.current,
    };

    dispatch(
      setMessage({
        message: "숙소추가정보를 등록하시겠습니까?",
        isCancel: true,
        isConfirm: true,
        handleClick() {
          adMutation.mutate(params);
        },
      })
    );
  };

  useEffect(() => {
    if (isSuccess) {
      adListMutation.mutate({
        lodNo: lodNo.current ?? "",
      });
    }
    // return () => {
    //   dispatch(setIsSuccess(false));
    // };
  }, [isSuccess]);

  const langOp = adList.length === 0 ? langOptions : newLngOption;
  const isAble = isAdAble ? isRegi : !isAdAble;

  return (
    <>
      <div className="table-form" data-dir-col>
        <div className="sb-title">
          <h4
            data-inf="기본정보 생성후 추가 정보 등록이 가능합니다."
            onClick={() => setIsIn(!isIn)}
          >
            추가정보
          </h4>
          <button
            disabled={isAble}
            className="btn-mdfy"
            onClick={() => {
              setIsOpen(true);
              setAdInf({
                ...adInf,
                lodZipc: adList[0]?.lodZipc ?? "",
              });
            }}
          >
            언어추가
          </button>
        </div>
        <fieldset disabled={false} className={isIn ? "" : "hidden"}>
          <table>
            <tbody>
              <tr>
                <th>숙소명</th>
                <td colSpan={3}>
                  <RadioContent
                    name={"lodNm"}
                    info={nmInf}
                    readOnly
                    onChange={() => {}}
                  />
                </td>
              </tr>
              <tr>
                <th>숙소 설명</th>
                <td colSpan={3}>
                  <RadioEditor
                    name={"lodDesc"}
                    info={descInf}
                    readOnly
                    onChange={() => {}}
                  />
                </td>
              </tr>
              <tr>
                <th>숙소 우편번호</th>
                <td>
                  <input
                    type="text"
                    name="lodZipc"
                    value={adList[0]?.lodZipc ?? ""}
                    readOnly
                    onChange={() => {}}
                  />
                </td>
              </tr>
              <tr>
                <th>숙소주소1</th>
                <td>
                  <div className="max-w-[25rem]">
                    <RadioContent
                      name={"lodAddr1"}
                      info={addr1}
                      readOnly
                      onChange={() => {}}
                    />
                  </div>
                </td>
                <th>숙소주소2</th>
                <td>
                  <div className="max-w-[25rem]">
                    <RadioContent
                      name={"lodAddr2"}
                      info={addr2}
                      readOnly
                      onChange={() => {}}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>숙소주소3</th>
                <td>
                  <div className="max-w-[25rem]">
                    <RadioContent
                      name={"lodAddr3"}
                      info={addr3}
                      readOnly
                      onChange={() => {}}
                    />
                  </div>
                </td>
                <th>숙소주소4</th>
                <td>
                  <div className="max-w-[25rem]">
                    <RadioContent
                      name={"lodAddr4"}
                      info={addr4}
                      readOnly
                      onChange={() => {}}
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </fieldset>
      </div>
      <Modal
        title={"추가정보 등록"}
        isOpen={isOpen}
        btnText="등록"
        styles="w-[90%]"
        handleCloseModal={() => {
          setIsOpen(false);
        }}
        onClick={handleCreate}
      >
        <div className="table-form">
          <table>
            <tbody>
              <tr data-t-brd>
                <th>언어 선택</th>
                <td>
                  <Select
                    id={"langCd"}
                    name={"langCd"}
                    option={adInf.langCd}
                    options={[
                      { kr: "선택하세요", value: "A", us: "select" },
                      ...langOp,
                    ]}
                    onSelect={(target) => handleSelect(target)}
                  />
                </td>
                <th></th>
              </tr>
              <tr>
                <th>숙소명</th>
                <td colSpan={3}>
                  <RadioContent
                    name={"lodNm"}
                    onChange={(target) => handleChange("lodNm", target.value)}
                  />
                </td>
              </tr>
              <tr>
                <th>숙소 설명</th>
                <td colSpan={3}>
                  <RadioEditor
                    name={"lodDesc"}
                    info={{}}
                    onChange={(name, value) => handleChange(name, value)}
                  />
                </td>
              </tr>
              <tr>
                {/* <th>숙소주소국가</th>
                <td>
                  <CountrySelect
                    option={adInf.lodCntyCd}
                    handleSelect={(target) => handleSelect(target)}
                  />
                </td> */}
                <th>숙소 우편번호</th>
                <td>
                  <input
                    type="text"
                    name="lodZipc"
                    value={adInf.lodZipc}
                    onChange={(e) =>
                      handleChange("lodZipc", e.currentTarget.value)
                    }
                  />
                </td>
              </tr>
              <tr>
                <th>숙소주소1</th>
                <td>
                  <div className="max-w-[25rem]">
                    <RadioContent
                      name={"lodAddr1"}
                      onChange={(target) =>
                        handleChange(target.name, target.value)
                      }
                    />
                  </div>
                </td>
                <th>숙소주소2</th>
                <td>
                  <div className="max-w-[25rem]">
                    <RadioContent
                      name={"lodAddr2"}
                      onChange={(target) =>
                        handleChange(target.name, target.value)
                      }
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>숙소주소3</th>
                <td>
                  <div className="max-w-[25rem]">
                    <RadioContent
                      name={"lodAddr3"}
                      onChange={(target) =>
                        handleChange(target.name, target.value)
                      }
                    />
                  </div>
                </td>
                <th>숙소주소4</th>
                <td>
                  <div className="max-w-[25rem]">
                    <RadioContent
                      name={"lodAddr4"}
                      onChange={(target) =>
                        handleChange(target.name, target.value)
                      }
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Modal>
    </>
  );
};

export default AdInfCreateView;
