import LoadingButton from "components/button/loadingButton";
import CheckButton from "components/checkbox/checkbtn";
import SubHeader from "components/layout/header/subHeader";
import RadioEditor from "components/radio/comRadioEditor";
import RadioContent from "components/radio/comRadiocontent";
import { IlodRefdDtl, IlodRfdAd } from "interface/lod";
import i18n from "locales/i18n";
import { useNavigate } from "react-router";
import { useLocation } from "react-router-dom";
import { dateFormat } from "util/common";

type IProps = {
  refdDtl: IlodRefdDtl;
  refdAdList: Array<IlodRfdAd>;
};

// 숙소환불수수료생성 - 숙소환불수수료율기본
const LodRfdInfoView = ({ refdDtl, refdAdList }: IProps) => {
  const navigate = useNavigate();
  const { search, pathname, state } = useLocation();

  let nmInf = {};
  let descInf = {};

  refdAdList.forEach((inf) => {
    nmInf = {
      ...nmInf,
      langCd: i18n.language,
      [`refdInfNm_${inf.langCd}`]: inf.refdInfNm,
    };
    descInf = {
      ...descInf,
      langCd: i18n.language,
      [`refdDesc_${inf.langCd}`]: inf.refdDesc,
    };
  });

  return (
    <div className="main-cnt">
      <SubHeader
        title="숙소환불수수료생성 - 숙소환불수수료율기본"
        close
        url={state?.url}
        state={state?.searchInfo}
      />
      <div className="table-form">
        <table>
          <tbody>
            <tr>
              <th>숙소그룹명</th>
              <td>
                <input type="text" defaultValue={refdDtl?.lodGrpNm} readOnly />
              </td>
              <th>숙소등급</th>
              <td>
                <input type="text" defaultValue={refdDtl?.lodGrdCd} readOnly />
              </td>
            </tr>
            <tr>
              <th>숙소명</th>
              <td>
                <input type="text" defaultValue={refdDtl?.lodNm} readOnly />
              </td>
              <th>기본수수료종류</th>
              <td>
                <input
                  type="text"
                  defaultValue={
                    refdDtl?.feeTypCd === "B01"
                      ? "기본수수료율"
                      : refdDtl?.feeTypCd === "S01"
                      ? "성수기수수료"
                      : ""
                  }
                  readOnly
                />
              </td>
            </tr>
            <tr>
              <th>적용 시작일자</th>
              <td>
                <input
                  type="text"
                  defaultValue={dateFormat(refdDtl?.aplySDt)}
                  readOnly
                />
              </td>
              <th>적용 종료일자</th>
              <td>
                <input
                  type="text"
                  defaultValue={dateFormat(refdDtl?.aplyEDt)}
                  readOnly
                />
              </td>
            </tr>
            <tr>
              <th>적용요일</th>
              <td colSpan={3}>
                <div className="w-full flex">
                  <CheckButton
                    id="MON"
                    name="day"
                    label="MON"
                    checked={refdDtl?.monAplyYn === "Y"}
                    disabled
                    onChange={() => {}}
                  />
                  <CheckButton
                    id="TUE"
                    name="day"
                    label="TUE"
                    checked={refdDtl?.tuesAplyYn === "Y"}
                    disabled
                    onChange={() => {}}
                  />
                  <CheckButton
                    id="WED"
                    name="day"
                    label="WED"
                    checked={refdDtl?.wenAplyYn === "Y"}
                    disabled
                    onChange={() => {}}
                  />
                  <CheckButton
                    id="THU"
                    name="day"
                    label="THU"
                    checked={refdDtl?.thurAplyYn === "Y"}
                    disabled
                    onChange={() => {}}
                  />
                  <CheckButton
                    id="FRI"
                    name="day"
                    label="FRI"
                    checked={refdDtl?.friAplyYn === "Y"}
                    disabled
                    onChange={() => {}}
                  />
                  <CheckButton
                    id="SAT"
                    name="day"
                    label="SAT"
                    checked={refdDtl?.satAplyYn === "Y"}
                    disabled
                    onChange={() => {}}
                  />
                  <CheckButton
                    id="SUN"
                    name="day"
                    label="SUN "
                    checked={refdDtl?.sunAplyYn === "Y"}
                    disabled
                    onChange={() => {}}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th>적용 숙박일기준</th>
              <td>
                <input
                  type="text"
                  defaultValue={refdDtl?.aplyChkDay}
                  readOnly
                />
              </td>
              <th>적용 숙박일기준시간</th>
              <td>
                <input
                  type="text"
                  defaultValue={
                    refdDtl?.aplyTi.replace(/\B(?=(\d{2})+(?!\d))/g, ":") ?? ""
                  }
                  readOnly
                />
              </td>
            </tr>
            <tr>
              <th>적용 수수료율</th>
              <td>
                <input type="text" defaultValue={refdDtl?.feeRti} readOnly />
              </td>
              <th>상태</th>
              <td>
                <input
                  type="text"
                  defaultValue={refdDtl?.uyn === "Y" ? "사용" : "미사용"}
                  readOnly
                />
              </td>
            </tr>
            <tr>
              <th>등록일시</th>
              <td>
                <input
                  type="text"
                  defaultValue={dateFormat(refdDtl?.dtti)}
                  readOnly
                />
              </td>
              <th>등록회원명</th>
              <td>
                <input type="text" defaultValue={refdDtl?.usid} readOnly />
              </td>
            </tr>
            <tr>
              <th>환불명</th>
              <td colSpan={3}>
                <RadioContent
                  name={"refdInfNm"}
                  info={nmInf}
                  readOnly
                  onChange={() => {}}
                />
              </td>
            </tr>
            <tr>
              <th>환불설명</th>
              <td colSpan={3}>
                <RadioEditor
                  name={"refdDesc"}
                  info={descInf}
                  readOnly
                  onChange={() => {}}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="btn-form-wrap">
        <LoadingButton
          isLoading={false}
          txt={"수정"}
          styles={"btn-search"}
          onClick={() => {
            navigate(pathname.replace("info", "modify") + search, {
              state: {
                searchInfo: state?.searchInfo,
                url: state?.url,
              },
            });
          }}
        />
      </div>
    </div>
  );
};

export default LodRfdInfoView;
