import FormSearchBtn from "components/button/formSearch";
import SubHeader from "components/layout/header/subHeader";
import Select from "components/select";
import LodGrdSelect from "components/select/room/lodGrdSelect";
import LodGrpNoSelect from "components/select/room/lodGrpNoSelect";
import LodNoSelect from "components/select/room/lodNoSelect";
import LodDefaultList from "components/tableList/lod/default";
import LodGrpList from "components/tableList/lod/grp";
import { lodDftSearchProps } from "interface/lod";
import { useLocation } from "react-router-dom";
import { lodGrpStatus } from "util/option";

type IProps = {
  isLoading: boolean;
  searchInfo: lodDftSearchProps;
  memType: "admin" | "manager";
  handleChange(target: EventTarget & HTMLInputElement): void;
  handleReset(): void;
  handleSearch(): void;
  handleSelect(target: EventTarget & HTMLLIElement): void;
};

const LodDftListView = ({
  memType,
  searchInfo,
  handleChange,
  handleReset,
  handleSelect,
  handleSearch,
}: IProps) => {
  const { pathname } = useLocation();
  return (
    <>
      <div className="search-form">
        <SubHeader title="숙소기본정보" />

        <div className="table-form">
          <table>
            <tbody>
              {pathname.includes("admin") && (
                <tr>
                  <th>숙소그룹번호</th>
                  <td>
                    <LodGrpNoSelect
                      option={searchInfo.lodGrpNo}
                      onSelect={(target) => handleSelect(target)}
                    />
                  </td>
                  <th>숙소그룹명</th>
                  <td>
                    <input
                      type="text"
                      name="lodGrpNm"
                      value={searchInfo.lodGrpNm}
                      onChange={(e) => handleChange(e.currentTarget)}
                    />
                  </td>
                </tr>
              )}
              <tr>
                <th>숙소번호</th>
                <td>
                  <LodNoSelect
                    option={searchInfo.lodNo}
                    params={searchInfo.lodGrpNo}
                    onSelect={(target) => handleSelect(target)}
                  />
                </td>
                <th>숙소명</th>
                <td>
                  <input
                    type="text"
                    name="lodNm"
                    value={searchInfo.lodNm}
                    onChange={(e) => handleChange(e.currentTarget)}
                  />
                </td>
              </tr>
              <tr>
                <th>숙소등급</th>
                <td>
                  <LodGrdSelect
                    option={searchInfo?.lodGrdCd}
                    onSelect={(target) => handleSelect(target)}
                  />
                </td>
                <th>숙소상태</th>
                <td>
                  <Select
                    id="uyn"
                    name="uyn"
                    option={searchInfo.uyn}
                    options={lodGrpStatus}
                    onSelect={(target) => handleSelect(target)}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <FormSearchBtn
            disabled={false}
            handleReset={handleReset}
            handleSearch={handleSearch}
          />
        </div>
      </div>
      <LodDefaultList memType={memType} searchInfo={searchInfo} />
    </>
  );
};

export default LodDftListView;
