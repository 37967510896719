import { lodChlHead } from "util/option";
import { lodChlSearchProps } from "interface/lod";
import { useLocation, useNavigate } from "react-router-dom";
import Pagenation from "components/pagenation";
import { Dispatch, SetStateAction, Suspense, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  isLoadingSelector,
  isSearchSelector,
  setIsLoading,
  setIsSearch,
  setMessage,
} from "store/commonSlice";
import NotData from "../notData";
import { useSelector } from "react-redux";
import { pageNavProps } from "interface";
import i18n from "locales/i18n";
type IListProps = {
  searchInfo: lodChlSearchProps;
  memType: "admin" | "manager";
  setPageNav: Dispatch<SetStateAction<pageNavProps | null>>;
};

const List = ({ searchInfo, setPageNav }: IListProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { pathname, search, state } = useLocation();
  const isSearch = useSelector(isSearchSelector);

  const [list, setList] = useState([]);

  // const statusMutation = useMutation(lodStatusApi, {
  //   onSuccess(data) {
  //     if (data) {
  //       const {
  //         header: { code, message },
  //         body,
  //       } = data;
  //       if (code === 0) {
  //         dispatch(
  //           setMessage({
  //             message: "숙소상태를 변경하였습니다.",
  //             isConfirm: true,
  //             handleClick() {
  //               dispatch(setMessage({ message: null }));
  //               dispatch(setIsSearch(true));
  //             },
  //           })
  //         );
  //       }
  //       if (code === -1) {
  //         dispatch(setMessage({ message }));
  //       }
  //     }
  //   },
  // });

  // const handleStatus = (lodNo: string, uyn: string) => {
  //   dispatch(
  //     setMessage({
  //       message: "숙소상태를 변경하시겠습니까?",
  //       isCancel: true,
  //       isConfirm: true,
  //       handleClick() {
  //         statusMutation.mutate({
  //           lodNo,
  //           uyn: uyn === "Y" ? "N" : "Y",
  //         });
  //       },
  //     })
  //   );
  // };

  let page = search.replace("?page=", "") || null;
  useEffect(() => {
    if (!!page) {
      dispatch(setIsSearch(true));
      dispatch(setIsLoading(true));
    }
  }, [page]);

  useEffect(() => {
    if (isSearch) {
      const params = {
        lodGrpNo: searchInfo.lodGrpNo || state?.lodGrpNo,
        lodGrpNm: searchInfo.lodGrpNm || state?.lodGrpNm,
        langCd: i18n.language,
        lodNo: searchInfo.lodNo || state?.lodNo,
        lodNm: searchInfo.lodNm || state?.lodNm,
        uyn: searchInfo.uyn || state?.uyn,
        pageNo: page || state?.pageNo,
      };
    }
  }, [searchInfo, isSearch]);
  return (
    <>
      <tr></tr>
    </>
  );
};

type IProps = {
  memType: "admin" | "manager";
  searchInfo: lodChlSearchProps;
};

const LodChlList = ({ memType, searchInfo }: IProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isLoading = useSelector(isLoadingSelector);

  const [pageNav, setPageNav] = useState<pageNavProps | null>(null);

  // const statusMutation = useMutation(lodStatusApi, {
  //   onSuccess(data) {
  //     if (data) {
  //       const {
  //         header: { code, message },
  //         body,
  //       } = data;

  //       if (code === 0) {
  //         setIsOpen(false);
  //       }
  //       if (code === -1) {
  //         dispatch(setMessage({ message }));
  //       }
  //     }
  //   },
  // });

  // const handleStatus = () => {
  //   const params = {
  //     uyn: "",
  //     lodNo: "",
  //   };
  //   statusMutation.mutate(params);
  // };

  const headList = lodChlHead.map((head) => {
    return head;
  });

  const [No, lodGrpNm, lodGrpCd, lodNm, ...res] = headList;

  return (
    <div className="data-list-wrap">
      <div className="data-cont">
        <div className="table-title">
          <h3>
            검색결과
            <span>
              총<b>{pageNav?.totalRows || 0}</b>개
            </span>
          </h3>
        </div>
        <div>
          <table>
            <thead>
              <tr>
                {headList.map((li) => (
                  <th key={li}>{li}</th>
                ))}
              </tr>
            </thead>

            <tbody>
              <Suspense>
                <List
                  searchInfo={searchInfo}
                  setPageNav={setPageNav}
                  memType={memType}
                />
              </Suspense>
            </tbody>
          </table>
        </div>
        <NotData
          isLoading={isLoading}
          isNotData={!pageNav?.totalRows}
          isSuccess={!!pageNav}
          styles=""
          txt="Loading ..."
        />
      </div>
      {pageNav && pageNav?.totalRows > 0 && (
        <Pagenation total={pageNav?.totalPage} />
      )}
    </div>
  );
};

export default LodChlList;
