import SubHeader from "components/layout/header/subHeader";
import { dftAdInf } from "interface/lod";
import { useLocation } from "react-router-dom";
import AdInfModifyView from "./adInfModify";

type IProps = {
  dtl: {
    lodNo: string;
    lodNm: string;
    lodGrpNm: string;
    lodGrdCd: string;
  };
};

const LodDefaultModifyView = ({ dtl }: IProps) => {
  const { pathname, search, state } = useLocation();

  return (
    <div className="main-cnt">
      <SubHeader
        title="숙소기본정보 수정"
        back
        url={state?.url}
        state={state?.searchInfo}
      />
      <div className="table-form" data-dir-col>
        <div className="sb-title">
          <h4>기본정보</h4>
        </div>
        <table>
          <tbody>
            <tr>
              <th>숙소그룹명</th>
              <td>
                <input type="text" defaultValue={dtl?.lodGrpNm} readOnly />
              </td>
              <th>숙소등급</th>
              <td>
                <input type="text" defaultValue={dtl?.lodGrdCd} readOnly />
              </td>
            </tr>
            <tr>
              <th>숙소명</th>
              <td colSpan={1}>
                <input type="text" defaultValue={dtl?.lodNm} readOnly />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <AdInfModifyView />
    </div>
  );
};

export default LodDefaultModifyView;
