import { useQuery } from "@tanstack/react-query";
import { opDtl } from "components/api/lod";
import LodPrdInfoView from "components/view/lod/prd/info";
import i18n from "locales/i18n";
import { useLocation } from "react-router-dom";

const LodPrdInfoPage = () => {
  const { search } = useLocation();

  const lodNo = search.replace("?lodNo=", "");
  const { data } = useQuery({
    queryKey: ["opDtl", lodNo],
    queryFn: () => {
      return opDtl({
        lodNo,
        langCd: i18n.language,
        opCtgCd: "",
      });
    },
  });
  const dtl = data?.body?.lodDtl;
  const lopList = data?.body?.lopList;
  const etcList = data?.body?.etcList;
  const ropList = data?.body?.ropList;
  return (
    <LodPrdInfoView
      dtl={dtl}
      lopList={lopList}
      etcList={etcList}
      ropList={ropList}
    />
  );
};

export default LodPrdInfoPage;
