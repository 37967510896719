import { lodHead } from "util/option";
import { IlodList, lodSearchProps } from "interface/lod";
import { useLocation, useNavigate } from "react-router-dom";
import Pagenation from "components/pagenation";
import { Dispatch, SetStateAction, Suspense, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  isLoadingSelector,
  isSearchSelector,
  setIsLoading,
  setIsSearch,
  setMessage,
} from "store/commonSlice";
import NotData from "../notData";
import { useSelector } from "react-redux";
import { pageNavProps } from "interface";
import { useMutation } from "@tanstack/react-query";
import { lodListApi, lodStatusApi } from "components/api/lod";
import i18n from "locales/i18n";

type IProps = {
  memType: "admin" | "manager";
  searchInfo: lodSearchProps;
};
type IListProps = {
  searchInfo: lodSearchProps;
  memType: "admin" | "manager";
  setPageNav: Dispatch<SetStateAction<pageNavProps | null>>;
};
const List = ({ memType, searchInfo, setPageNav }: IListProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { pathname, search, state } = useLocation();
  const isSearch = useSelector(isSearchSelector);

  const [list, setList] = useState([]);

  const lodListMutation = useMutation(lodListApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        dispatch(setIsLoading(false));
        dispatch(setIsSearch(false));
        if (code === 0) {
          setList(body.lodList);
          setPageNav(body.pageNav);
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const statusMutation = useMutation(lodStatusApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(
            setMessage({
              message: "숙소상태를 변경하였습니다.",
              isConfirm: true,
              handleClick() {
                dispatch(setMessage({ message: null }));
                dispatch(setIsSearch(true));
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleStatus = (lodNo: string, uyn: string) => {
    dispatch(
      setMessage({
        message: "숙소상태를 변경하시겠습니까?",
        isCancel: true,
        isConfirm: true,
        handleClick() {
          statusMutation.mutate({
            lodNo,
            uyn: uyn === "Y" ? "N" : "Y",
          });
        },
      })
    );
  };

  let page = search.replace("?page=", "") || null;
  useEffect(() => {
    if (!!page) {
      dispatch(setIsSearch(true));
      dispatch(setIsLoading(true));
    }
  }, [page]);

  useEffect(() => {
    if (isSearch) {
      const params = {
        lodGrpNo: searchInfo.lodGrpNo || state?.lodGrpNo,
        lodGrpNm: searchInfo.lodGrpNm || state?.lodGrpNm,
        langCd: i18n.language,
        lodNo: searchInfo.lodNo || state?.lodNo,
        lodNm: searchInfo.lodNm || state?.lodNm,
        uyn: searchInfo.uyn || state?.uyn,
        lodGrdCd: searchInfo.lodGrdCd || state?.lodGrpCd,
        lodAddr1: searchInfo.lodAddr1 || state?.lodAddr1,
        lodAddr2: searchInfo.lodAddr2 || state?.lodAddr2,
        contSDt: searchInfo.contSDt || state?.contSDt,
        contEDt: searchInfo.contEDt || state?.contEDt,
        sellAmDvc: searchInfo.sellAmDvc || state?.sellAmDvc,
        pageNo: page || state?.pageNo,
      };

      lodListMutation.mutate(params);
    }
  }, [searchInfo, isSearch]);

  const pageRows = lodListMutation.data?.body?.pageNav?.pageRows;

  return (
    <>
      {list &&
        list?.map((li: IlodList, index: number) => {
          return (
            <tr
              key={index}
              onClick={(e) => {
                e.stopPropagation();
                navigate(`info?lodNo=${li.lodNo}`, {
                  state: {
                    searchInfo,
                    url: pathname + search,
                  },
                });
              }}
            >
              <td>{(Number(page) - 1) * (pageRows || 10) + index + 1}</td>
              <td>
                <button
                  onClick={() => {
                    navigate(`?lodGrpNo=${li.lodGrpNo}`);
                  }}
                >
                  {li.lodGrpNo}
                </button>
              </td>
              <td>{li.lodGrpNm}</td>
              <td>{li.lodGrdCd}</td>
              <td>{li.lodNo}</td>
              <td>
                <span className="block min-w-[8.75rem]">{li.lodNm}</span>
              </td>
              <td>{li.lodCntyCd}</td>
              <td>{li.lodAddr1 ?? "-"}</td>
              <td>{li.lodAddr2 ?? "-"}</td>
              <td>{li.sellAmDvc === "01" ? "판매가" : "입금가"}</td>
              <td>{li.contSDt}</td>
              <td>{li.contEDt}</td>
              <td>
                <span
                  className={li.uyn === "Y" ? "uyn" : ""}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleStatus(li.lodNo, li.uyn);
                  }}
                >
                  {li.uyn === "Y" ? "정상" : "미 정상"}
                </span>
              </td>
            </tr>
          );
        })}
    </>
  );
};

const LodList = ({ memType, searchInfo }: IProps) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(isLoadingSelector);

  const [pageNav, setPageNav] = useState<pageNavProps | null>(null);

  const headList = lodHead.map((head) => {
    return head;
  });

  const [No, lodGrpNm, lodGrpCd, lodNm, ...res] = headList;

  return (
    <div className="data-list-wrap">
      <div className="data-cont">
        <div className="table-title">
          <h3>
            검색결과
            <span>
              총<b>{pageNav?.totalRows || 0}</b>개
            </span>
          </h3>
        </div>
        <div>
          <table>
            <thead>
              <tr>
                {headList.map((li) => (
                  <th key={li}>{li}</th>
                ))}
              </tr>
            </thead>

            <tbody>
              <Suspense>
                <List
                  searchInfo={searchInfo}
                  setPageNav={setPageNav}
                  memType={memType}
                />
              </Suspense>
            </tbody>
          </table>
        </div>
        <NotData
          isLoading={isLoading}
          isNotData={!pageNav?.totalRows}
          isSuccess={!!pageNav}
          styles=""
          txt="Loading ..."
        />
      </div>
      {pageNav && pageNav?.totalRows > 0 && (
        <Pagenation total={pageNav?.totalPage} />
      )}
    </div>
  );
};

export default LodList;
