import { useLocation, useNavigate } from "react-router-dom";
import "./gbn.scss";
import { useState, useRef, useEffect, MouseEvent } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import {
  isSelectSelector,
  lodGrpNoSelector,
  setMessage,
} from "store/commonSlice";
import { useSelector } from "react-redux";

const GBN = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const memType = pathname.includes("admin")
    ? "admin"
    : pathname.includes("manager")
    ? "manager"
    : localStorage.getItem("memType");

  const isSelect = useSelector(isSelectSelector);
  const [isOpen, setIsOpen] = useState<string>(
    pathname.replace(`/${memType}/`, "")
  );

  const [lodGrpNo, setLodNo] = useState("");

  const ulRef = useRef<HTMLUListElement>(null);

  const handleOpen = (type: string) => {
    const sltLodNo = localStorage.getItem("sltLodNo");

    if (memType === "manager" && !sltLodNo) {
      dispatch(
        setMessage({
          message: "[메뉴-홈]에서 숙소를 먼저 선택해 주세요.",
          isConfirm: true,
          handleClick() {
            dispatch(setMessage({ message: null }));
          },
        })
      );
      return;
    }

    setIsOpen(type);
    navigate(`/${memType}/${type}`);
  };

  const handleClickMenu = (
    e: MouseEvent<HTMLAnchorElement, globalThis.MouseEvent>
  ) => {
    if (e.currentTarget.className === "active") {
      e.preventDefault();
    }
    const sltLodNo = localStorage.getItem("sltLodNo");

    if (memType === "manager" && !sltLodNo) {
      e.preventDefault();
      dispatch(
        setMessage({
          message: "[메뉴-홈]에서 숙소를 먼저 선택해 주세요.",
          isConfirm: true,
          handleClick() {
            dispatch(setMessage({ message: null }));
          },
        })
      );
      return;
    }
  };

  useEffect(() => {
    setIsOpen(pathname);
  }, [pathname]);

  useEffect(() => {
    const lodGrpInf = localStorage.getItem("lodGrpInfo")
      ? JSON.parse(localStorage.getItem("lodGrpInfo") ?? "")
      : "";
    setLodNo(lodGrpInf?.lodGrpNo);
  }, [isSelect]);

  return (
    <section className="gbn-wrap">
      <ul className="menu-wrap">
        <li>
          <button
            className={
              pathname === `/${memType}` || pathname === "/"
                ? "no-arrow rotate"
                : "no-arrow"
            }
            onClick={() => {
              setIsOpen("home");
              navigate(`/${memType}`);
            }}
          >
            <span>{"홈"}</span>
          </button>
        </li>
        {pathname.includes("admin") && (
          <div>
            <button
              onClick={() => {
                handleOpen("mem");
              }}
              className={
                isOpen === "mem" || pathname.includes("mem") ? "rotate" : ""
              }
            >
              회원
            </button>
            <ul className={isOpen === "/admin/mem" ? "sub-menu" : "hidden"}>
              <li>
                <NavLink
                  to={`${memType}/mem`}
                  className={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "active" : ""
                  }
                  onClick={(e) => {
                    if (e.currentTarget.className === "active") {
                      e.preventDefault();
                    }
                  }}
                >
                  회원
                </NavLink>
              </li>
            </ul>
          </div>
        )}
        {memType === "admin" && (
          <li>
            <button
              onClick={() => handleOpen("lodManage/grp")}
              className={pathname.includes("lodManage") ? "rotate" : ""}
            >
              숙소관리
            </button>
            <ul
              className={isOpen.includes("lodManage") ? "sub-menu" : "hidden"}
              ref={ulRef}
            >
              <li className={pathname === "/lodManage/grp" ? "on" : ""}>
                <NavLink
                  to={`${memType}/lodManage/grp`}
                  className={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "active" : ""
                  }
                >
                  숙소그룹
                </NavLink>
              </li>
              <li className={pathname === "/lodManage/lod" ? "on" : ""}>
                <NavLink
                  to={`${memType}/lodManage/lod`}
                  className={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "active" : ""
                  }
                >
                  숙소
                </NavLink>
              </li>
              <li className={pathname === "/lodManage/dflt" ? "on" : ""}>
                <NavLink
                  to={`${memType}/lodManage/dflt`}
                  className={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "active" : ""
                  }
                >
                  숙소기본정보
                </NavLink>
              </li>
              <li className={pathname === "/lodManage/prd" ? "on" : ""}>
                <NavLink
                  to={`${memType}/lodManage/prd`}
                  className={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "active" : ""
                  }
                >
                  숙소제공정보
                </NavLink>
              </li>
              <li className={pathname === "/lodManage/rfd" ? "on" : ""}>
                <NavLink
                  to={`${memType}/lodManage/rfd`}
                  className={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "active" : ""
                  }
                >
                  숙소환불수수료
                </NavLink>
              </li>
              <li className={pathname === "/lodManage/chl" ? "on" : ""}>
                <NavLink
                  to={`${memType}/lodManage/chl`}
                  className={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "active" : ""
                  }
                >
                  숙소채널
                </NavLink>
              </li>
              {/* <li className={pathname === "/lodManage/" ? "on" : ""}>
                <NavLink
                  to={`${memType}/room/room/create`}
                  className={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "active" : ""
                  }
                >
                  숙소요청
                </NavLink>
              </li> */}
            </ul>
          </li>
        )}
        {memType === "manager" && (
          <li>
            <button
              // onClick={() =>
              //   handleOpen(`lodManage/grp/info?lodGrpNo=${lodGrpInf.lodGrpNo}`)
              // }
              className={pathname.includes("lodManage") ? "rotate" : ""}
              data-no-after
            >
              숙소관리
            </button>
            <ul className={"sub-menu"} ref={ulRef}>
              <li className={pathname === "/lodManage/grp" ? "on" : ""}>
                <NavLink
                  to={`${memType}/lodManage/grp/info?lodGrpNo=${lodGrpNo}`}
                  className={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "active" : ""
                  }
                  onClick={handleClickMenu}
                >
                  숙소그룹상세
                </NavLink>
              </li>
              <li className={pathname === "/lodManage/lod" ? "on" : ""}>
                <NavLink
                  to={`${memType}/lodManage/lod`}
                  className={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "active" : ""
                  }
                  onClick={handleClickMenu}
                >
                  숙소
                </NavLink>
              </li>

              <li className={pathname === "/lodManage/dflt" ? "on" : ""}>
                <NavLink
                  to={`${memType}/lodManage/dflt`}
                  className={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "active" : ""
                  }
                  onClick={handleClickMenu}
                >
                  숙소기본정보
                </NavLink>
              </li>
              {/* <li className={pathname === "/lod/prd" ? "on" : ""}>
                <NavLink
                  to={`${memType}/lod/prd`}
                  className={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "active" : ""
                  }
                >
                  숙소제공정보
                </NavLink>
              </li> */}
              <li className={pathname === "/lodManage/rfd" ? "on" : ""}>
                <NavLink
                  to={`${memType}/lodManage/rfd`}
                  className={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "active" : ""
                  }
                  onClick={handleClickMenu}
                >
                  숙소환불수수료
                </NavLink>
              </li>
              {/* <li className={pathname === "/lodManage/chl" ? "on" : ""}>
                <NavLink
                  to={`${memType}/lodManage/chl`}
                  className={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "active" : ""
                  }
                  onClick={handleClickMenu}
                >
                  숙소채널
                </NavLink>
              </li> */}
            </ul>
          </li>
        )}
        <div>
          {pathname.includes("admin") && (
            <button
              onClick={() => {
                handleOpen("rmManage/rm");
              }}
              className={pathname.includes("rmManage") ? "rotate" : ""}
            >
              객실관리
            </button>
          )}
          {pathname.includes("manager") && (
            <button
              // onClick={() => {
              //   handleOpen("rmManage/rm");
              // }}
              className={pathname.includes("rmManage") ? "rotate" : ""}
              data-no-after
            >
              객실관리
            </button>
          )}
          <ul
            className={
              pathname.includes("admin")
                ? isOpen.includes("rmManage")
                  ? "sub-menu"
                  : "hidden"
                : "sub-menu"
            }
            ref={ulRef}
          >
            <li>
              <NavLink
                to={`${memType}/rmManage/rm`}
                className={({ isActive, isPending }) =>
                  isPending ? "pending" : isActive ? "active" : ""
                }
                onClick={handleClickMenu}
              >
                객실
              </NavLink>
            </li>

            <li className={pathname.includes("/rmManage/rmOp") ? "on" : ""}>
              <NavLink
                to={`${memType}/rmManage/rmOp`}
                className={({ isActive, isPending }) =>
                  isPending ? "pending" : isActive ? "active" : ""
                }
                onClick={(e) => {
                  if (e.currentTarget.className === "active") {
                    e.preventDefault();
                  }
                  const sltLodNo = localStorage.getItem("sltLodNo");

                  if (memType === "manager" && !sltLodNo) {
                    e.preventDefault();
                    dispatch(
                      setMessage({
                        message: "[메뉴-홈]에서 숙소를 먼저 선택해 주세요.",
                        isConfirm: true,
                        handleClick() {
                          dispatch(setMessage({ message: null }));
                        },
                      })
                    );
                    return;
                  }
                }}
              >
                객실옵션
              </NavLink>
            </li>
            {/* <li>
              <Link to="/room/special ">객실특가</Link>
            </li> */}
            <li>
              <NavLink
                to={`${memType}/rmManage/rmPd`}
                className={({ isActive, isPending }) =>
                  isPending ? "pending" : isActive ? "active" : ""
                }
                onClick={(e) => {
                  if (e.currentTarget.className === "active") {
                    e.preventDefault();
                  }
                  const sltLodNo = localStorage.getItem("sltLodNo");

                  if (memType === "manager" && !sltLodNo) {
                    e.preventDefault();
                    dispatch(
                      setMessage({
                        message: "[메뉴-홈]에서 숙소를 먼저 선택해 주세요.",
                        isConfirm: true,
                        handleClick() {
                          dispatch(setMessage({ message: null }));
                        },
                      })
                    );
                    return;
                  }
                }}
              >
                객실상품
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`${memType}/rmManage/rmHst`}
                className={({ isActive, isPending }) =>
                  isPending ? "pending" : isActive ? "active" : ""
                }
                onClick={(e) => {
                  if (e.currentTarget.className === "active") {
                    e.preventDefault();
                  }

                  const sltLodNo = localStorage.getItem("sltLodNo");

                  if (memType === "manager" && !sltLodNo) {
                    e.preventDefault();
                    dispatch(
                      setMessage({
                        message: "[메뉴-홈]에서 숙소를 먼저 선택해 주세요.",
                        isConfirm: true,
                        handleClick() {
                          dispatch(setMessage({ message: null }));
                        },
                      })
                    );
                    return;
                  }
                }}
              >
                객실상품이력
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`${memType}/rmManage/current`}
                className={({ isActive, isPending }) =>
                  isPending ? "pending" : isActive ? "active" : ""
                }
                onClick={(e) => {
                  if (e.currentTarget.className === "active") {
                    e.preventDefault();
                  }

                  const sltLodNo = localStorage.getItem("sltLodNo");

                  if (memType === "manager" && !sltLodNo) {
                    e.preventDefault();
                    dispatch(
                      setMessage({
                        message: "[메뉴-홈]에서 숙소를 먼저 선택해 주세요.",
                        isConfirm: true,
                        handleClick() {
                          dispatch(setMessage({ message: null }));
                        },
                      })
                    );
                    return;
                  }
                }}
              >
                객실현황
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`${memType}/rmManage/resv`}
                className={({ isActive, isPending }) =>
                  isPending ? "pending" : isActive ? "active" : ""
                }
                onClick={(e) => {
                  if (e.currentTarget.className === "active") {
                    e.preventDefault();
                  }

                  const sltLodNo = localStorage.getItem("sltLodNo");

                  if (memType === "manager" && !sltLodNo) {
                    e.preventDefault();
                    dispatch(
                      setMessage({
                        message: "[메뉴-홈]에서 숙소를 먼저 선택해 주세요.",
                        isConfirm: true,
                        handleClick() {
                          dispatch(setMessage({ message: null }));
                        },
                      })
                    );
                    return;
                  }
                }}
              >
                객실예약
              </NavLink>
            </li>
            <li>
              <NavLink
                to={`${memType}/rmManage/cancel`}
                className={({ isActive, isPending }) =>
                  isPending ? "pending" : isActive ? "active" : ""
                }
                onClick={(e) => {
                  if (e.currentTarget.className === "active") {
                    e.preventDefault();
                  }

                  const sltLodNo = localStorage.getItem("sltLodNo");

                  if (memType === "manager" && !sltLodNo) {
                    e.preventDefault();
                    dispatch(
                      setMessage({
                        message: "[메뉴-홈]에서 숙소를 먼저 선택해 주세요.",
                        isConfirm: true,
                        handleClick() {
                          dispatch(setMessage({ message: null }));
                        },
                      })
                    );
                    return;
                  }
                }}
              >
                객실예약취소
              </NavLink>
            </li>
          </ul>
        </div>
        {memType === "admin" && (
          <>
            <div>
              <button
                onClick={() => handleOpen("promManage/prm")}
                className={pathname.includes("promManage/") ? "rotate" : ""}
              >
                프로모션관리
              </button>
              <ul
                className={
                  isOpen.includes("promManage") ? "sub-menu" : "hidden"
                }
                ref={ulRef}
              >
                <li>
                  <NavLink
                    to={`admin/promManage/prm`}
                    className={({ isActive, isPending }) =>
                      isPending ? "pending" : isActive ? "active" : ""
                    }
                  >
                    프로모션
                  </NavLink>
                </li>
              </ul>
            </div>
            <div>
              <button
                onClick={() => handleOpen("clcltManage/clclt")}
                className={pathname.includes("clcltManage") ? "rotate" : ""}
              >
                정산관리
              </button>
              <ul
                className={
                  isOpen.includes("clcltManage") ? "sub-menu" : "hidden"
                }
                ref={ulRef}
              >
                <li>
                  <NavLink
                    to={`admin/clcltManage/clclt`}
                    className={({ isActive, isPending }) =>
                      isPending ? "pending" : isActive ? "active" : ""
                    }
                  >
                    프로모션 참여
                  </NavLink>
                </li>
              </ul>
            </div>
          </>
        )}
        {memType === "admin" && (
          <div>
            <button
              onClick={() => {
                handleOpen("trmsManage/trm");
              }}
              className={
                isOpen === "trmsManage" || pathname.includes("trmsManage")
                  ? "rotate"
                  : ""
              }
            >
              약관관리
            </button>
            <ul
              className={isOpen.includes("trmsManage") ? "sub-menu" : "hidden"}
              ref={ulRef}
            >
              <li>
                <NavLink
                  to={`admin/trmsManage/trm`}
                  className={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "active" : ""
                  }
                >
                  약관
                </NavLink>
              </li>
              {/* <li>
                <NavLink
                  to={`admin/trmsManage/trm/create`}
                  className={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "active" : ""
                  }
                >
                  약관등록
                </NavLink>
              </li> */}
              {/* <li>
                <NavLink
                  to={`${memType}/term/create`}
                  className={({ isActive, isPending }) =>
                    isPending ? "pending" : isActive ? "active" : ""
                  }
                >
                  약관수정
                </NavLink>
              </li> */}
            </ul>
          </div>
        )}

        {/* <li>
          <button
            onClick={() => handleOpen("board")}
            className={
              isOpen === "board" && pathname.includes("board") ? "rotate" : ""
            }
          >
            게시판 알림관리
          </button>
          <ul
            className={`sub-menu ${
              isOpen === "board" || pathname.includes("board")
                ? "h-[15rem] "
                : "h-0"
            }`}
          >
            <li>
              <Link to="/board/alarm">공지사항</Link>
            </li>
            <li>
              <Link to="/board/fqa">FQA</Link>
            </li>
            <li>
              <Link to="/board/mail">이메일</Link>
            </li>
            <li>
              <Link to="/board/fax">팩스</Link>
            </li>
            <li>
              <Link to="/board/push">푸시</Link>
            </li>
          </ul>
        </li> */}
        {/* <li>
          <button
            onClick={() => handleOpen("setting")}
            className={
              isOpen === "setting" && pathname.includes("setting")
                ? "rotate"
                : ""
            }
          >
            설정
          </button>
          <ul
            className={`sub-menu ${
              isOpen === "setting" || pathname.includes("setting")
                ? "h-[3rem]"
                : "h-0"
            }`}
          >
            <li className={pathname === "/setting" ? "on" : ""}>
              <Link to="/setting">언어</Link>
            </li>
          </ul>
        </li> */}
      </ul>
    </section>
  );
};

export default GBN;
