import { Dispatch, SetStateAction, Suspense, useEffect, useState } from "react";
import "../list.scss";
import { managerListHead } from "util/option";
import { useMutation } from "@tanstack/react-query";
import { ImanagerList, searchProps } from "interface/member";
import { setMemNo, setIsSearchMember } from "store/memberSlice";
import Pagenation from "components/pagenation";
import { managerListApi, modifyManagerApi } from "components/api/member";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import NotData from "../notData";
import { useSelector } from "react-redux";
import {
  setIsSearch,
  isSearchSelector,
  setIsLoading,
  setMessage,
} from "store/commonSlice";
import { pageNavProps } from "interface";

type Iprops = {
  searchInfo: Partial<searchProps>;
};

type IListProps = {
  searchInfo: Partial<searchProps>;
  setPageNav: Dispatch<SetStateAction<pageNavProps | null>>;
};
const List = ({ searchInfo, setPageNav }: IListProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname, search, state } = useLocation();
  const isSearch = useSelector(isSearchSelector);
  const [list, setList] = useState([]);
  const [isOpen, setIsOpen] = useState(false);

  const manMutation = useMutation(managerListApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          setPageNav(body?.pageNav);
          dispatch(setIsLoading(false));
          dispatch(setIsSearch(false));
          setList(body?.manList);
        }
        if (code === -1) {
          dispatch(setIsLoading(false));
          dispatch(setIsSearch(false));
          dispatch(
            setMessage({
              message,
              isConfirm: true,
              handleClick() {
                dispatch(setMessage({ message: null }));
              },
            })
          );
        }
      }
    },
  });
  const statusMutation = useMutation(modifyManagerApi, {
    onSuccess(data) {
      if (data) {
        const { code, message } = data.header;
        if (code === 0) {
          dispatch(
            setMessage({
              message: "신청을 수락하였습니다.",
              isConfirm: true,
              handleClick() {
                dispatch(setMessage({ message: null }));
                dispatch(setIsSearchMember(false));
                dispatch(setMessage({ message: null }));
                dispatch(setIsSearch(true));
                setIsOpen(false);
                dispatch(setMemNo(""));
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleModifyState = ({
    manFstnm,
    manSurnm,
    manNo,
    joiAprYn,
  }: {
    manFstnm: string;
    manSurnm: string;
    manNo: string;
    joiAprYn: "N" | "Y";
  }) => {
    dispatch(
      setMessage({
        message: `${manSurnm}${manFstnm}님의 신청을 수락하시겠습니까?`,
        isCancel: true,
        isConfirm: true,
        handleClick() {
          statusMutation.mutate({
            memNo: manNo,
            joiAprYn,
          });
        },
      })
    );
  };

  let page = search.replace("?page=", "") || null;
  useEffect(() => {
    if (!!page) {
      dispatch(setIsSearch(true));
      dispatch(setIsLoading(true));
    }
  }, [page]);

  useEffect(() => {
    if (isSearch) {
      const params = {
        lodNo: searchInfo?.lodNo || undefined,
        mobCntyCd: searchInfo.mobCntyCd || undefined,
        mobNo: searchInfo.mobNo || undefined,
        emlId: searchInfo.emlId || undefined,
      };

      manMutation.mutate(params);
    }
  }, [isSearch, searchInfo, search]);

  return (
    <>
      {list &&
        list?.map((li: ImanagerList, index: number) => {
          return (
            <tr
              key={li.manNo}
              className={li.soYn === "Y" ? "drop" : ""}
              onClick={(e) => {
                e.stopPropagation();
                navigate(`info?manNo=${li.manNo}`, {
                  state: {
                    searchInfo,
                    url: pathname + search,
                    manNo: li.manNo,
                  },
                });
              }}
            >
              <td>{index + 1}</td>
              <td>{li.manNo}</td>
              <td>{li.lodNm}</td>
              <td>{li.manSurnm}</td>
              <td>{li.manFstnm}</td>
              <td>{li.emlId}</td>
              <td>{li.mobNo}</td>
              <td>
                <span
                  className={
                    li.soYn === "Y"
                      ? "soYn"
                      : li.joiAprYn === "N"
                      ? "joiAprYn"
                      : ""
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    handleModifyState({
                      manFstnm: li.manFstnm,
                      manSurnm: li.manSurnm,
                      manNo: li.manNo,
                      joiAprYn: "Y",
                    });
                  }}
                >
                  {li.soYn === "Y"
                    ? "탈퇴"
                    : li.joiAprYn === "N"
                    ? "신청"
                    : "정상"}
                </span>
              </td>
            </tr>
          );
        })}
    </>
  );
};

const ManagerList = ({ searchInfo }: Iprops) => {
  const dispatch = useDispatch();

  const isLoading = useSelector(isSearchSelector);

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [info, setInfo] = useState({
    memNo: "",
    joiAprYn: "",
  });
  const [pageNav, setPageNav] = useState<pageNavProps | null>(null);

  const headList = managerListHead.map((head) => {
    return head;
  });

  return (
    <div className="data-list-wrap">
      <div className="data-cont">
        <div className="table-title">
          <h3>
            검색결과
            <span>
              총<b>{pageNav?.totalRows || 0}</b>개
            </span>
          </h3>
        </div>

        <table>
          <thead>
            <tr>
              {headList.map((li) => (
                <th key={li}>{li}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            <Suspense>
              <List
                searchInfo={searchInfo}
                setPageNav={setPageNav}
                // handleModifyState={handleModifyState}
              />
            </Suspense>
          </tbody>
        </table>
        <NotData
          isLoading={isLoading}
          isNotData={!pageNav?.totalRows}
          isSuccess={!!pageNav}
          styles=""
          txt="Loading ..."
        />
      </div>
      {pageNav && pageNav?.totalRows > 0 && (
        <Pagenation total={pageNav?.totalPage} />
      )}
    </div>
  );
};

export default ManagerList;

{
  /* <AgGrid
          rowData={rowData}
          columnDefs={columnDefs}
          pagination={true}
          isLoading={isLoading}
        /> */
}
