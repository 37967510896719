import { useMutation } from "@tanstack/react-query";
import imgUploadApi from "components/api/imgUpload";
import { lodImgs } from "interface/lod";
import { ChangeEvent, Dispatch, SetStateAction, useState } from "react";
import { BsPencilSquare } from "react-icons/bs";
import { CgCloseR } from "react-icons/cg";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setMessage } from "store/commonSlice";
import { lodNoSelector } from "store/lodSlice";

type imgUrlProps = {
  name: string;
  url: string | ArrayBuffer | null;
};

type IProps = {
  imgs: lodImgs[];
  setImgs: Dispatch<SetStateAction<lodImgs[]>>;
  isRegi: boolean;
  isAble: boolean;
};

const OutImgView = ({ imgs, setImgs, isRegi, isAble }: IProps) => {
  const dispatch = useDispatch();
  const lodNo = useSelector(lodNoSelector) || "L240700024";
  const [outImgs, setOutImgs] = useState<any[]>([]);
  const [imgIdx, setImgIdx] = useState(-1);

  const imgUploadMutation = useMutation(imgUploadApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          setImgs((prev: any) => [...prev, ...body.imgList]);
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  // 이미지 업로드
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    alert("숙소외관 이미지 업로드");
    const files = e.target.files;

    if (files) {
      if (outImgs?.length > 10 || outImgs?.length + files?.length > 10) {
        dispatch(setMessage({ message: "최대 10까지 등록이 가능합니다." }));
        return;
      }

      let formData = new FormData();
      formData.append("imgTypCd", "I01");

      Array.from(files).forEach((file: File) => {
        if (file.size >= 1024000) {
          dispatch(setMessage({ message: "최대 업로드 사이즈는 1MB 입니다." }));
          return;
        }

        formData.append("uploadFiles", file);
        let reader = new FileReader();

        reader.onload = () => {
          if (reader.result) {
            const img = new Image();
            img.src = reader.result as string;

            img.onload = () => {
              // let width = img.width;
              // let height = img.height;

              // if (width <= 600 || height <= 390) {
              //   dispatch(
              //     setMessage({
              //       message: "이미지 업로드 가능 사이즈 650 * 400 ",
              //     })
              //   );

              //   return;
              // }

              setOutImgs((prevList) => [
                ...prevList,
                {
                  name: file.name,
                  url: reader.result,
                },
              ]);
            };
          }
        };
        reader.readAsDataURL(file);
      });

      imgUploadMutation.mutate({
        formData,
        lodNo: lodNo ?? "",
      });

      e.currentTarget.value = "";
    }
  };

  // 이미지 수정
  const handleModifyImg = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      if (outImgs?.length > 10 || outImgs?.length + files?.length > 10) {
        dispatch(setMessage({ message: "최대 10까지 등록이 가능합니다." }));
        return;
      }

      let formData = new FormData();
      formData.append("imgTypCd", "I01");

      const file = files[0];

      if (file.size >= 1024000) {
        dispatch(setMessage({ message: "최대 업로드 사이즈는 5MB 입니다." }));
        return;
      }

      formData.append("uploadFiles", file);
      let reader = new FileReader();

      reader.onload = () => {
        if (reader.result) {
          const img = new Image();
          img.src = reader.result as string;

          img.onload = () => {
            let width = img.width;
            let height = img.height;

            // if (width <= 600 || height <= 390) {
            //   dispatch(
            //     setMessage({
            //       message: "이미지 업로드 가능 사이즈 650 * 400 ",
            //     })
            //   );

            //   return;
            // }

            const urls = outImgs.map((img: imgUrlProps, idx: number) => {
              return imgIdx === idx
                ? { name: file.name, url: reader.result }
                : img;
            });

            setOutImgs(urls);
          };
        }
      };
      reader.readAsDataURL(file);

      imgUploadMutation.mutate({
        formData,
        lodNo,
      });
    }

    e.currentTarget.value = "";
  };

  // 이미지 삭제
  const handleImgRemove = (index: number) => {
    const newList = outImgs.filter((img, idx) => {
      return index !== idx;
    });
    setOutImgs(newList);
  };

  return (
    <>
      <div className="w-full px-4 min-h-[2.5rem]">
        <span className={isAble ? "hidden" : ""}>
          <input
            type="file"
            id="file-out"
            multiple
            onChange={handleChange}
            disabled={isAble}
          />

          <label
            htmlFor="file-out"
            className="img-add"
            data-txt="숙소외관 이미지 추가"
          />
        </span>
        <div
          className={
            outImgs.length !== 0 || imgs.length !== 0 ? "img-wrap" : "hidden "
          }
        >
          {outImgs.map((img, index) => {
            return (
              <div className="img-item" key={index}>
                <img src={img?.url || img?.resImgPhUrl} alt="" />
                <div></div>
                <div className={!isAble ? "img-btn-wrap" : "hidden"}>
                  <button
                    data-txt="삭제"
                    onClick={() => {
                      handleImgRemove(index);
                    }}
                  >
                    <CgCloseR
                      size={20}
                      style={{
                        padding: 1,
                        color: "red",
                        cursor: "pointer",
                        backgroundColor: "#fff",
                      }}
                    />
                  </button>
                  <div className="relative" data-idx={index}>
                    <BsPencilSquare
                      size={20}
                      style={{
                        padding: 1,
                        color: "#39405a",
                        cursor: "pointer",
                        backgroundColor: "#fff",
                      }}
                    />
                    <input
                      type="file"
                      accept="images/*"
                      required
                      id="file-img"
                      className=""
                      data-idx={index}
                      onChange={handleModifyImg}
                    />
                    <label
                      htmlFor="file-img"
                      onMouseDown={() => {
                        setImgIdx(index);
                      }}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default OutImgView;
