import LodDftInfoCreateView from "components/view/lod/default/create";
import { lodDftInfoProps } from "interface/lod";
import { useState } from "react";
import { useDispatch } from "react-redux";

const LodDfltCreatePage = () => {
  const dispatch = useDispatch();

  const [info, setInfo] = useState<lodDftInfoProps>({
    lodGrpNm: "",
    lodGrpNo: "",
    lodNo: "",
    lodGrpCd: "",
    lodNm: "",
    lodNotiCn: "",
    lodOprCn: "",
    lodCstAdCn: "",
    lodCstFvrCn: "",
    lodAddFacCn: "",
    lodBrkfCn: "",
    lodCokCn: "",
  });

  const handleChange = (name: string, value: string) => {
    setInfo({
      ...info,
      [name]: value,
    });
  };

  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { name, value, lng } = target.dataset;
  };

  // const defaultInfoMutation = useMutation(lodDefaultCreateApi, {
  //   onSuccess(data) {
  //     if (data) {
  //       const {
  //         header: { code, message },
  //         body,
  //       } = data;
  //       if (code === 0) {
  //       }
  //       if (code === -1) {
  //         dispatch(setMessage({ message }));
  //       }
  //     }
  //   },
  // });

  const handleCreate = () => {
    // defaultInfoMutation.mutate(info);
  };

  return (
    <>
      <LodDftInfoCreateView
        info={info}
        handleChange={handleChange}
        handleSelect={handleSelect}
        handleCreate={handleCreate}
      />
    </>
  );
};

export default LodDfltCreatePage;
