import Button from "components/button/createButtonWrap";
import Select from "components/select";
import PhoneSelect from "components/select/phone";
import { manageSignupInfoProps } from "interface/member";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { memTypeSelector } from "store/commonSlice";
import { regNum } from "util/common";
import { authOptions } from "util/option";

type IProps = {
  info: Omit<manageSignupInfoProps, "lodGrpNo">;
  handleChange(name: string, value: string): void;
  handleSelect(target: EventTarget & HTMLLIElement): void;
  handleCheck(): void;
};

const Step1View = ({
  info,
  handleChange,
  handleSelect,
  handleCheck,
}: IProps) => {
  const { pathname } = useLocation();

  const memType = pathname.includes("admin") ? "admin" : "manager";
  const memTypKr = memType === "admin" ? "관리자" : "매니저";

  const isAble = pathname.includes("manager")
    ? !(
        info?.manFstnm &&
        info?.manSurnm &&
        info?.manEmlId &&
        info?.mobNo &&
        info?.mobCntyCd
      )
    : !(
        info?.manFstnm &&
        info?.manSurnm &&
        info?.manEmlId &&
        info?.mobNo &&
        info?.mobCntyCd &&
        info?.authGrpCod
      );

  return (
    <div className="no-logged-wrap">
      <h2>{memTypKr} 회원 비밀번호 찾기</h2>
      <p className="no-logged-disc">
        이메일 주소로 비밀번호 설정 링크가 발송됩니다.
        <br />
        링크를 통하여 새로운 비밀번호를 설정하세요.
      </p>
      <div className="signup-form">
        <div>
          <label htmlFor="">영문이름</label>
          <input
            type="text"
            name=""
            value={info?.manFstnm}
            onChange={(e) => handleChange("manFstnm", e.currentTarget.value)}
          />
        </div>
        <div>
          <label htmlFor="">영문성</label>
          <input
            type="text"
            name=""
            value={info?.manSurnm}
            onChange={(e) => handleChange("manSurnm", e.currentTarget.value)}
          />
        </div>
        <div>
          <label htmlFor="">이메일</label>
          <input
            type="text"
            name=""
            value={info?.manEmlId}
            onChange={(e) => handleChange("manEmlId", e.currentTarget.value)}
          />
        </div>
        <div className="relative">
          <label htmlFor="">전화번호</label>
          <PhoneSelect
            wrapStyle="me-[0.25rem]"
            optionStyle="ms-[12.5rem] "
            mobCd={info.mobCntyCd}
            handleSelect={(target) => handleSelect(target)}
          />

          <input
            type="text"
            name="telNo"
            value={info?.mobNo}
            maxLength={11}
            onChange={(e) => {
              const value = regNum.test(e.currentTarget.value)
                ? e.currentTarget.value
                : "";

              handleChange("mobNo", value);
            }}
          />
        </div>
        {memType === "admin" && (
          <div className="">
            <label htmlFor="">권한 선택</label>
            <Select
              id="authGrpCod"
              name="authGrpCod"
              wrapStyle="w-[26.25rem] h-[2.75rem]"
              option={info?.authGrpCod}
              options={authOptions}
              onSelect={handleSelect}
            />
          </div>
        )}
        <Button
          txt="확인"
          isLoading={false}
          disabled={isAble}
          onClick={handleCheck}
        />
      </div>
    </div>
  );
};

export default Step1View;
