import { useMutation } from "@tanstack/react-query";
import LoadingButton from "components/button/loadingButton";
import SubHeader from "components/layout/header/subHeader";
import RadioEditor from "components/radio/comRadioEditor";
import RadioContent from "components/radio/comRadiocontent";
import { IlodGrpAddInf, IlodGrpDtl } from "interface/lod";
import i18n from "locales/i18n";
import { useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

type IProps = {
  lodGrpDtl: IlodGrpDtl;
  lodGrpAdList: Array<IlodGrpAddInf>;
};

const LodGroupInfoView = ({ lodGrpDtl, lodGrpAdList }: IProps) => {
  const navigate = useNavigate();
  const { pathname, search, state } = useLocation();
  let nmInf = {};
  let descInf = {};
  let addr1 = {};
  let addr2 = {};
  let addr3 = {};
  let addr4 = {};
  let labels = [] as any[];

  // const handleAdInf = useMemo(() => {
  lodGrpAdList.forEach((inf) => {
    nmInf = {
      ...nmInf,
      langCd: i18n.language,
      [`lodGrpNm_${inf.langCd}`]: inf.lodGrpNm,
    };
    descInf = {
      ...descInf,
      langCd: i18n.language,
      [`lodGrpDesc_${inf.langCd}`]: inf.lodGrpDesc,
    };

    addr1 = {
      ...addr1,
      langCd: i18n.language,
      [`addr1_${inf.langCd}`]: inf.lodGrpAddr1,
    };
    addr2 = {
      ...addr2,
      langCd: i18n.language,
      [`addr2_${inf.langCd}`]: inf.lodGrpAddr2,
    };
    addr3 = {
      ...addr3,
      langCd: i18n.language,
      [`addr3_${inf.langCd}`]: inf.lodGrpAddr3,
    };
    addr4 = {
      ...addr4,
      langCd: i18n.language,
      [`addr4_${inf.langCd}`]: inf.lodGrpAddr4,
    };
  });
  // }, [lodGrpAdList]);

  return (
    <div className="main-cnt">
      <SubHeader
        title="숙소그룹 상세"
        close={pathname.includes("admin")}
        url={state?.url}
        state={state?.searchInfo}
      />

      <div className="table-form">
        <table>
          <tbody>
            <tr>
              <th>숙소그룹번호</th>
              <td>
                <input type="text" value={lodGrpDtl?.lodGrpNo ?? ""} readOnly />
              </td>
              <th>숙소그룹 이메일</th>
              <td>
                <input
                  type="text"
                  defaultValue={lodGrpDtl.lodGrpEml}
                  readOnly
                />
              </td>
            </tr>
            <tr>
              <th>숙소그룹 전화번호</th>
              <td>
                <input type="text" defaultValue={lodGrpDtl.telNo} readOnly />
              </td>
              <th>상태</th>
              <td>
                <input
                  type="text"
                  value={lodGrpDtl.uyn === "Y" ? "사용" : "미사용"}
                  readOnly
                />
              </td>
            </tr>
            <tr>
              <th>숙소그룹 명</th>
              <td colSpan={3}>
                <RadioContent
                  name={"lodGrpNm"}
                  info={nmInf}
                  readOnly={true}
                  inpDisabled={true}
                  onChange={(target) => {}}
                />
              </td>
            </tr>
            <tr>
              <th>숙소그룹 설명</th>
              <td colSpan={3}>
                <RadioEditor
                  name={"lodGrpDesc"}
                  info={descInf}
                  readOnly={true}
                  onChange={(name, value) => {}}
                />
              </td>
            </tr>

            <tr>
              <th>숙소그룹 주소국가</th>
              <td>
                <input
                  type="text"
                  defaultValue={lodGrpDtl?.lodGrpCntyCd}
                  readOnly
                />
              </td>
              <th>숙소그룹 우편번호</th>
              <td>
                <input
                  type="text"
                  defaultValue={lodGrpAdList[0]?.lodGrpZipc ?? ""}
                  readOnly
                />
              </td>
            </tr>

            <tr>
              <th>숙소그룹 주소1</th>
              <td>
                <div className="max-w-[25rem]">
                  <RadioContent
                    info={addr1}
                    name={"addr1"}
                    readOnly={true}
                    inpDisabled={true}
                    onChange={(target) => {}}
                  />
                </div>
              </td>
              <th>숙소그룹 주소2</th>
              <td>
                <div className="max-w-[25rem]">
                  <RadioContent
                    name={"addr2"}
                    info={addr2}
                    readOnly={true}
                    inpDisabled={true}
                    onChange={(target) => {}}
                  />
                </div>
              </td>
            </tr>

            <tr>
              <th>숙소그룹 주소3</th>
              <td>
                <div className="max-w-[25rem]">
                  <RadioContent
                    name={"addr3"}
                    info={addr3}
                    readOnly={true}
                    inpDisabled={true}
                    onChange={(target) => {}}
                  />
                </div>
              </td>
              <th>숙소그룹 주소4</th>
              <td>
                <div className="max-w-[25rem]">
                  <RadioContent
                    name={"addr4"}
                    info={addr4}
                    readOnly={true}
                    inpDisabled={true}
                    onChange={(target) => {}}
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {pathname.includes("admin") && (
        <div className="btn-form-wrap">
          <LoadingButton
            isLoading={false}
            txt={"수정"}
            styles={"btn-search"}
            onClick={() => {
              navigate(pathname.replace("info", "modify") + search, {
                state: {
                  searchInfo: state?.searchInfo,
                  url: state?.url,
                },
              });
            }}
          />
        </div>
      )}
    </div>
  );
};

export default LodGroupInfoView;
