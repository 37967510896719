import SubHeader from "components/layout/header/subHeader";
import { useNavigate } from "react-router";
import InfCreateView from "./infCreate";
import InfAdCreateView from "./infAdCreate";
import { IoIosArrowBack } from "react-icons/io";
import { useRef } from "react";
import { useDispatch } from "react-redux";
import { setMessage } from "store/commonSlice";

type IProps = {};

const LodRefundCreateView = ({}: IProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const lodNoRef = useRef("");
  const isAbleRef = useRef(false);

  return (
    <div className="main-cnt">
      <SubHeader title="숙소환불수수료생성 - 숙소환불수수료율기본">
        <button
          className="flex items-center text-sm p-2"
          onClick={() => {
            dispatch(
              setMessage({
                message: "페이이지에서 나가시겠습니까?",
                isCancel: true,
                isConfirm: true,
                handleClick() {
                  navigate(-1);
                  dispatch(setMessage({ message: null }));
                },
              })
            );
          }}
        >
          <IoIosArrowBack size={18} />
          <span>리스트로 가기</span>
        </button>
      </SubHeader>
      <InfCreateView lodNoRef={lodNoRef} isAbleRef={isAbleRef} />
      <InfAdCreateView lodNoRef={lodNoRef} isAbleRef={isAbleRef} />
    </div>
  );
};

export default LodRefundCreateView;
