import LodProvideView from "components/view/lod/prd/list";
import { lodPrdSearchProps } from "interface/lod";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setIsLoading, setIsSearch } from "store/commonSlice";

const LodPrdPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const memType = pathname.includes("admin") ? "admin" : "manager";
  const [searchInfo, setSearchInfo] = useState<lodPrdSearchProps>({
    lodGrpNo: "",
    lodGrpNm: "",
    lodGrdCd: "",
    lodNo: "",
    lodNm: "",
    uyn: "",
  });
  const handleChange = (name: string, value: string) => {
    setSearchInfo({
      ...searchInfo,
      [name]: value,
    });
  };
  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { name, value } = target.dataset;
    if (name && value) {
      setSearchInfo({
        ...searchInfo,
        [name]: value,
      });
    }
  };
  const handleSearch = () => {
    dispatch(setIsLoading(true));
    dispatch(setIsSearch(true));
    navigate("?page=1");
  };
  const handleReset = () => {
    dispatch(setIsLoading(false));
    dispatch(setIsSearch(false));
    navigate(pathname);
    setSearchInfo({
      lodGrpNo: "",
      lodGrpNm: "",
      lodGrdCd: "",
      lodNo: "",
      lodNm: "",
      uyn: "",
    });
  };
  return (
    <LodProvideView
      memType={memType}
      searchInfo={searchInfo}
      handleChange={handleChange}
      handleSelect={handleSelect}
      handleReset={handleReset}
      handleSearch={handleSearch}
    />
  );
};

export default LodPrdPage;
