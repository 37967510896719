import { IoIosCloseCircleOutline } from "react-icons/io";
import { IoAddCircleOutline } from "react-icons/io5";
import { IoIosArrowBack } from "react-icons/io";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { setIsSearch } from "store/commonSlice";
import { Children, ReactNode } from "react";

type IProps = {
  title: string;
  sbTitle?: string;
  add?: boolean;
  close?: boolean;
  dot?: boolean;
  url?: string;
  state?: any;
  back?: boolean;
  children?: ReactNode;
};

const SubHeader = ({
  title,
  sbTitle = "",
  add,
  close,
  dot,
  url = "",
  state,
  back,
  children,
}: IProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleClose = () => {
    navigate(url, { state });
    dispatch(setIsSearch(true));
  };
  return (
    <>
      <div className="main-title">
        <h3>{title}</h3>
        <div className="main-btn-wrap">
          {dot && (
            <span className="text-xs text-red-300 font-bold">* 필수 입력</span>
          )}
          {add && (
            <Link to="create">
              <IoAddCircleOutline
                size={50}
                style={{ cursor: "pointer", padding: 10 }}
              />
            </Link>
          )}
          {close && (
            <button onClick={handleClose}>
              <IoIosCloseCircleOutline
                size={50}
                style={{ cursor: "pointer", padding: 10 }}
              />
            </button>
          )}
          {back && (
            <button
              onClick={handleClose}
              className="flex items-center text-sm p-2"
            >
              <IoIosArrowBack size={18} />
              <span>리스트로 가기</span>
              {/* <IoIosCloseCircleOutline
                size={50}
                style={{ cursor: "pointer", padding: 10 }}
              /> */}
            </button>
          )}
          {children}
        </div>
      </div>
    </>
  );
};

export default SubHeader;
