import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { lodAdListApi, lodAdModify } from "components/api/lod";
import Modal from "components/modal/modal";
import RadioEditor, { langProps } from "components/radio/comRadioEditor";
import RadioContent from "components/radio/comRadiocontent";
import Select from "components/select";
import { lodAdProps } from "interface/lod";
import i18n from "locales/i18n";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setMessage } from "store/commonSlice";
import { langOptions } from "util/option";

const AdInfModifyView = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();

  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey: ["lodAdList"],
    queryFn: () => {
      return lodAdListApi({
        lodNo: search.replace("?lodNo=", ""),
      });
    },
  });

  const adList = query?.data?.body?.lodAdList || [];

  let newLngOption = [] as langProps[];
  let listLngOption = [] as langProps[];

  let nmInf = {};
  let descInf = {};
  let addr1 = {};
  let addr2 = {};
  let addr3 = {};
  let addr4 = {};

  adList.forEach((inf: lodAdProps) => {
    nmInf = {
      ...nmInf,
      langCd: i18n.language,
      [`lodNm_${inf.langCd}`]: inf.lodNm,
    };
    descInf = {
      ...descInf,
      langCd: i18n.language,
      [`lodDesc_${inf.langCd}`]: inf.lodDesc,
    };
    addr1 = {
      ...addr1,
      langCd: i18n.language,
      [`lodAddr1_${inf.langCd}`]: inf.lodAddr1,
    };
    addr2 = {
      ...addr2,
      langCd: i18n.language,
      [`lodAddr2_${inf.langCd}`]: inf.lodAddr2,
    };
    addr3 = {
      ...addr3,
      langCd: i18n.language,
      [`lodAddr3_${inf.langCd}`]: inf.lodAddr3,
    };
    addr4 = {
      ...addr4,
      langCd: i18n.language,
      [`lodAddr4_${inf.langCd}`]: inf.lodAddr4,
    };
    newLngOption = langOptions.map((lang) => {
      return lang.value === inf.langCd
        ? {
            ...lang,
            events: "none",
          }
        : lang;
    });

    langOptions.forEach((lang) => {
      if (lang.value === inf.langCd) {
        listLngOption.push(lang);
      }
    });
  });

  const [adInf, setAdInf] = useState<lodAdProps>({
    lodNo: search.replace("?lodNo=", ""),
    langCd: "",
    lodNm: "",
    lodDesc: "",
    lodZipc: "",
    lodAddr: "",
    lodAddr1: "",
    lodAddr2: "",
    lodAddr3: "",
    lodAddr4: "",
    lodDtlAddr: "",
  });

  const [isOpen, setIsOpen] = useState("");

  const handleChange = (name: string, value: string) => {
    if (name.includes("_")) {
      setAdInf({
        ...adInf,
        [name.split("_")[0]]: value,
      });
      return;
    }
    setAdInf({
      ...adInf,
      [name]: value,
    });
  };
  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { name, value, lng, code } = target.dataset;
    if (name && value) {
      setAdInf({
        ...adInf,
        [name]: value,
      });
    }
  };

  const adMutation = useMutation(lodAdModify, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(
            setMessage({
              message: `숙소추가정보를 ${
                isOpen === "add" ? "등록" : "수정"
              }하였습니다`,
              isConfirm: true,
              handleClick() {
                queryClient.invalidateQueries(["lodAdList"]);
                dispatch(setMessage({ message: null }));
                setIsOpen("");
                setAdInf({
                  lodNo: search.replace("?lodNo=", ""),
                  langCd: "",
                  lodNm: "",
                  lodDesc: "",
                  lodZipc: "",
                  lodAddr: "",
                  lodAddr1: "",
                  lodAddr2: "",
                  lodAddr3: "",
                  lodAddr4: "",
                  lodDtlAddr: "",
                });
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleModify = () => {
    if (!adInf.langCd) {
      return alert("언어를 선택하여 주세요.");
    }
    if (!adInf.lodNo) {
      return alert("숙소번호가 없습니다.");
    }
    // if (!adInf.lodNm) {
    //   return alert("숙소명을 입력하여 주세요");
    // }

    const params = {
      ...adInf,
    };
    dispatch(
      setMessage({
        message: `숙소추가정보를 ${
          isOpen === "add" ? "등록" : "수정"
        }하시겠습니까?`,
        isCancel: true,
        isConfirm: true,
        handleClick() {
          adMutation.mutate(params);
        },
      })
    );
  };

  let langOp =
    isOpen === "add"
      ? [{ kr: "선택하세요", value: "A", us: "select" }, ...newLngOption]
      : [{ kr: "선택하세요", value: "A", us: "select" }, ...listLngOption];

  // const langOp =
  //   adList.length === 0
  //     ? [{ kr: "선택하세요", value: "A", us: "select" }, ...langOptions]
  //     : newLngOption;

  return (
    <>
      <div className="table-form" data-dir-col>
        <div className="sb-title">
          <h4>추가정보</h4>
          <div className="flex items-center gap-4">
            <button
              className={
                adList.length !== langOptions.length ? "btn-mdfy" : "hidden"
              }
              onClick={() => {
                setIsOpen("add");
                setAdInf({
                  ...adInf,
                  lodZipc: adList[0]?.lodZipc ?? "",
                });
              }}
            >
              언어추가
            </button>
            <button
              className={adList.length !== 0 ? "btn-mdfy" : "hidden"}
              onClick={() => {
                setIsOpen("mdfy");
                setAdInf({
                  ...adInf,
                  lodZipc: adList[0]?.lodZipc ?? "",
                });
              }}
            >
              수정
            </button>
          </div>
        </div>
        <fieldset disabled={false}>
          <table>
            <tbody>
              <tr>
                <th>숙소명</th>
                <td colSpan={3}>
                  <RadioContent
                    name={"lodNm"}
                    info={nmInf}
                    readOnly
                    onChange={() => {}}
                  />
                </td>
              </tr>
              <tr>
                <th>숙소 설명</th>
                <td colSpan={3}>
                  <RadioEditor
                    name={"lodDesc"}
                    info={descInf}
                    readOnly
                    onChange={() => {}}
                  />
                </td>
              </tr>
              <tr>
                <th>숙소 우편번호</th>
                <td>
                  <input
                    type="text"
                    name="lodZipc"
                    value={adList[0]?.lodZipc ?? ""}
                    readOnly
                    onChange={() => {}}
                  />
                </td>
              </tr>
              <tr>
                <th>숙소주소1</th>
                <td>
                  <div className="max-w-[25rem]">
                    <RadioContent
                      name={"lodAddr1"}
                      info={addr1}
                      readOnly
                      onChange={() => {}}
                    />
                  </div>
                </td>
                <th>숙소주소2</th>
                <td>
                  <div className="max-w-[25rem]">
                    <RadioContent
                      name={"lodAddr2"}
                      info={addr2}
                      readOnly
                      onChange={() => {}}
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>숙소주소3</th>
                <td>
                  <div className="max-w-[25rem]">
                    <RadioContent
                      name={"lodAddr3"}
                      info={addr3}
                      readOnly
                      onChange={() => {}}
                    />
                  </div>
                </td>
                <th>숙소주소4</th>
                <td>
                  <div className="max-w-[25rem]">
                    <RadioContent
                      name={"lodAddr4"}
                      info={addr4}
                      readOnly
                      onChange={() => {}}
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </fieldset>
      </div>
      <Modal
        title={`추가정보 ${isOpen === "add" ? "등록" : "수정"}`}
        isOpen={isOpen !== ""}
        btnText={isOpen === "add" ? "등록" : "수정"}
        styles="w-[90%]"
        handleCloseModal={() => {
          setIsOpen("");
        }}
        onClick={handleModify}
      >
        <div className="table-form">
          <table>
            <tbody>
              <tr data-t-brd>
                <th>언어 선택</th>
                <td>
                  <Select
                    id={"langCd"}
                    name={"langCd"}
                    option={adInf.langCd}
                    options={langOp}
                    onSelect={(target) => handleSelect(target)}
                  />
                </td>
                <th></th>
              </tr>
              <tr>
                <th>숙소명</th>
                <td colSpan={3}>
                  <RadioContent
                    name={"lodNm"}
                    onChange={(target) => handleChange("lodNm", target.value)}
                  />
                </td>
              </tr>
              <tr>
                <th>숙소 설명</th>
                <td colSpan={3}>
                  <RadioEditor
                    name={"lodDesc"}
                    info={{}}
                    onChange={(name, value) => handleChange(name, value)}
                  />
                </td>
              </tr>
              <tr>
                <th>숙소 우편번호</th>
                <td>
                  <input
                    type="text"
                    name="lodZipc"
                    value={adInf.lodZipc}
                    onChange={(e) =>
                      handleChange("lodZipc", e.currentTarget.value)
                    }
                  />
                </td>
              </tr>
              <tr>
                <th>숙소주소1</th>
                <td>
                  <div className="max-w-[25rem]">
                    <RadioContent
                      name={"lodAddr1"}
                      onChange={(target) =>
                        handleChange(target.name, target.value)
                      }
                    />
                  </div>
                </td>
                <th>숙소주소2</th>
                <td>
                  <div className="max-w-[25rem]">
                    <RadioContent
                      name={"lodAddr2"}
                      onChange={(target) =>
                        handleChange(target.name, target.value)
                      }
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>숙소주소3</th>
                <td>
                  <div className="max-w-[25rem]">
                    <RadioContent
                      name={"lodAddr3"}
                      onChange={(target) =>
                        handleChange(target.name, target.value)
                      }
                    />
                  </div>
                </td>
                <th>숙소주소4</th>
                <td>
                  <div className="max-w-[25rem]">
                    <RadioContent
                      name={"lodAddr4"}
                      onChange={(target) =>
                        handleChange(target.name, target.value)
                      }
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Modal>
    </>
  );
};

export default AdInfModifyView;
