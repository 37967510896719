import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  lodRefAdList,
  lodRefdAdCreateApi,
  lodRefdAdModifyApi,
} from "components/api/lod";
import Modal from "components/modal/modal";
import RadioEditor, { langProps } from "components/radio/comRadioEditor";
import RadioContent from "components/radio/comRadiocontent";
import Select from "components/select";
import { IlodRfdAd } from "interface/lod";
import i18n from "locales/i18n";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setMessage } from "store/commonSlice";
import { langOptions } from "util/option";

const InfAdModifyView = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const queryClient = useQueryClient();
  const lodNo = search.replace("?lodNo=", "");

  const query = useQuery({
    queryKey: ["lodRdfAdList", lodNo],
    queryFn: () => {
      return lodRefAdList({
        lodNo,
      });
    },
  });

  const adList = query?.data?.body?.lodRefdAdList || [];

  let nmInf = {};
  let descInf = {};
  let newLngOption = [] as langProps[];
  let listLngOption = [] as langProps[];

  adList?.forEach((inf: IlodRfdAd) => {
    nmInf = {
      ...nmInf,
      langCd: i18n.language,
      [`refdInfNm_${inf.langCd}`]: inf.refdInfNm,
    };
    descInf = {
      ...descInf,
      langCd: i18n.language,
      [`refdDesc_${inf.langCd}`]: inf.refdDesc,
    };

    newLngOption = langOptions.map((lang) => {
      return lang.value === inf.langCd
        ? {
            ...lang,
            events: "none",
          }
        : lang;
    });

    langOptions.forEach((lang) => {
      if (lang.value === inf.langCd) {
        listLngOption.push(lang);
      }
    });
  });

  const [adInf, setAdInf] = useState<IlodRfdAd>({
    lodNo,
    rgSeq: adList.length,
    langCd: "",
    refdInfNm: "",
    refdDesc: "",
  });
  const [isOpen, setIsOpen] = useState("");

  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { name, value } = target.dataset;
    if (name && value) {
      setAdInf({
        ...adInf,
        [name]: value,
      });
    }
  };

  const handleChange = (name: string, value: string) => {
    const adNm = name.split("_")[0];
    setAdInf({
      ...adInf,
      [adNm]: value,
    });
  };

  const handleCloseModal = () => {
    setIsOpen("");
    setAdInf({
      lodNo,
      rgSeq: adList.length,
      langCd: "",
      refdInfNm: "",
      refdDesc: "",
    });
  };

  const createMutation = useMutation(lodRefdAdCreateApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code == 0) {
          dispatch(
            setMessage({
              message: "숙소환불수수료 추가정보 추가하였습니다.",
              isConfirm: true,
              handleClick() {
                queryClient.invalidateQueries(["lodRdfAdList", lodNo]);
                handleCloseModal();
                dispatch(setMessage({ message: null }));
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const mdfyMutation = useMutation(lodRefdAdModifyApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(
            setMessage({
              message: "숙소환불수수료 추가정보를 수정하시였습니다.",
              isConfirm: true,
              handleClick() {
                queryClient.invalidateQueries(["lodRdfAdList", lodNo]);
                handleCloseModal();
                dispatch(setMessage({ message: null }));
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleModify = () => {
    if (!adInf.langCd) {
      return alert("언어를 선택하여주세요.");
    }
    if (!adInf.lodNo) {
      return alert("숙소번호가 없습니다.");
    }
    if (!adInf.refdInfNm && !adInf.refdDesc) {
      return alert("수정할 항목을 입력을 해주세요.");
    }
    if (isOpen === "add") {
      dispatch(
        setMessage({
          message: "숙소환불수수료추가정보를 추가하시겠습니까?",
          isConfirm: true,
          isCancel: true,
          handleClick() {
            createMutation.mutate(adInf);
            dispatch(setMessage({ message: null }));
          },
        })
      );
      return;
    }
    if (isOpen === "mdfy") {
      dispatch(
        setMessage({
          message: "숙소환불수수료추가정보를 수정하시겠습니까?",
          isCancel: true,
          isConfirm: true,
          handleClick() {
            dispatch(setMessage({ message: null }));
            mdfyMutation.mutate(adInf);
          },
        })
      );
      return;
    }
  };

  let langOp =
    isOpen === "add"
      ? [{ kr: "선택하세요", value: "A", us: "select" }, ...newLngOption]
      : [{ kr: "선택하세요", value: "A", us: "select" }, ...listLngOption];

  return (
    <>
      <div className="table-form" data-dir-col>
        <div className="sb-title">
          <h4>추가기본정보</h4>
          <div className="flex gap-4">
            <button
              className={
                langOptions.length !== listLngOption.length
                  ? "btn-mdfy"
                  : "hidden"
              }
              onClick={() => setIsOpen("add")}
            >
              언어추가
            </button>
            <button
              className={listLngOption.length > 0 ? "btn-mdfy" : "hidden"}
              onClick={() => setIsOpen("mdfy")}
            >
              수정
            </button>
          </div>
        </div>
        <table>
          <tbody>
            <tr>
              <th>환불명</th>
              <td colSpan={3} className="full">
                <RadioContent
                  name={"refdInfNm"}
                  info={nmInf}
                  readOnly
                  onChange={() => {}}
                />
              </td>
            </tr>
            <tr>
              <th>환불수수료설명</th>
              <td colSpan={3}>
                <RadioEditor
                  name={"refdDesc"}
                  info={descInf}
                  readOnly
                  onChange={() => {}}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <Modal
        title={`추가정보 ${isOpen === "add" ? "추가" : "수정"}`}
        isOpen={isOpen !== ""}
        styles="w-9/10"
        btnText={isOpen === "add" ? "추가" : "수정"}
        handleCloseModal={handleCloseModal}
        onClick={handleModify}
      >
        <div className="table-form" data-dir-col>
          <table>
            <tbody>
              <tr>
                <th>언어</th>
                <td>
                  <Select
                    id={"langCd"}
                    name={"langCd"}
                    option={adInf.langCd}
                    options={langOp}
                    onSelect={handleSelect}
                  />
                </td>
                <th></th>
                <td></td>
              </tr>
              <tr>
                <th>환불명</th>
                <td colSpan={3} className="full">
                  <RadioContent
                    name={"refdInfNm"}
                    onChange={(target) =>
                      handleChange(target.name, target.value)
                    }
                  />
                </td>
              </tr>
              <tr>
                <th>환불수수료설명</th>
                <td colSpan={3}>
                  <RadioEditor
                    name={"refdDesc"}
                    info={{}}
                    onChange={(name, value) => handleChange(name, value)}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Modal>
    </>
  );
};

export default InfAdModifyView;
