import SubHeader from "components/layout/header/subHeader";
import RadioEditor from "components/radio/comRadioEditor";
import { dftAdInf } from "interface/lod";
import i18n from "locales/i18n";
import { useLocation, useNavigate } from "react-router-dom";

type IProps = {
  dtl: {
    lodNo: string;
    lodGrpNm: string;
    lodGrdCd: string;
    lodNm: string;
  };
  adList: Array<dftAdInf>;
};
const LodDftInfoView = ({ adList, dtl }: IProps) => {
  const navigate = useNavigate();
  const { pathname, search, state } = useLocation();

  let facCn = {};
  let brkfCn = {};
  let cokCn = {};
  let cstAdCn = {};
  let cstFvrCn = {};
  let nmInf = {};
  let notiCn = {};
  let oprCn = {};

  adList?.forEach((inf) => {
    nmInf = {
      ...nmInf,
      langCd: i18n.language,
      [`lodNm_${inf.langCd}`]: inf.lodNm,
    };
    notiCn = {
      ...notiCn,
      langCd: i18n.language,
      [`lodNotiCn_${inf.langCd}`]: inf.lodNotiCn,
    };
    facCn = {
      ...facCn,
      langCd: i18n.language,
      [`lodAddFacCn_${inf.langCd}`]: inf.lodAddFacCn,
    };
    brkfCn = {
      ...brkfCn,
      langCd: i18n.language,
      [`lodBrkfCn_${inf.langCd}`]: inf.lodBrkfCn,
    };
    cokCn = {
      ...cokCn,
      langCd: i18n.language,
      [`lodCokCn_${inf.langCd}`]: inf.lodCokCn,
    };
    cstAdCn = {
      ...cstAdCn,
      langCd: i18n.language,
      [`lodCstAdCn_${inf.langCd}`]: inf.lodCstAdCn,
    };
    cstFvrCn = {
      ...cstFvrCn,
      langCd: i18n.language,
      [`lodCstFvrCn_${inf.langCd}`]: inf.lodCstFvrCn,
    };
    oprCn = {
      ...oprCn,
      langCd: i18n.language,
      [`lodOprCn_${inf.langCd}`]: inf.lodOprCn,
    };
  });

  return (
    <div className="main-cnt">
      <SubHeader
        title="숙소기본정보 상세"
        close
        url={state?.url}
        state={state?.searchInfo}
      />

      <div className="table-form">
        <table>
          <tbody>
            <tr>
              <th>숙소그룹 명</th>
              <td>
                <input
                  type="text"
                  defaultValue={dtl?.lodGrpNm ?? ""}
                  readOnly
                />
              </td>
              <th>숙소 등급</th>
              <td>
                <input
                  type="text"
                  defaultValue={dtl?.lodGrdCd ?? ""}
                  readOnly
                />
              </td>
            </tr>
            <tr>
              <th>숙소명</th>
              <td colSpan={1}>
                <input type="text" defaultValue={dtl?.lodNm} readOnly />
                {/* <RadioContent
                  name={"lodNm"}
                  info={nmInf}
                  readOnly
                  onChange={(target) => {}}
                /> */}
              </td>
            </tr>
            <tr>
              <th>공지사항</th>
              <td colSpan={3}>
                <RadioEditor
                  name={"lodNotiCn"}
                  info={notiCn}
                  readOnly
                  onChange={(target) => {}}
                />
              </td>
            </tr>
            <tr>
              <th>운영정보</th>
              <td colSpan={3}>
                <RadioEditor
                  name={"lodOprCn"}
                  info={oprCn}
                  readOnly
                  onChange={(name, value) => {}}
                />
              </td>
            </tr>
            <tr>
              <th>인원추가정보</th>
              <td colSpan={3}>
                <RadioEditor
                  name={"lodCstAdCn"}
                  info={cstAdCn}
                  readOnly
                  onChange={(name, value) => {}}
                />
              </td>
            </tr>
            <tr>
              <th>투숙고객혜택정보</th>
              <td colSpan={3}>
                <RadioEditor
                  name={"lodCstFvrCn"}
                  info={cstFvrCn}
                  readOnly
                  onChange={(name, value) => {}}
                />
              </td>
            </tr>
            <tr>
              <th>부대시설정보</th>
              <td colSpan={3}>
                <RadioEditor
                  name={"lodAddFacCn"}
                  info={facCn}
                  readOnly
                  onChange={(name, value) => {}}
                />
              </td>
            </tr>
            <tr>
              <th>조식정보</th>
              <td colSpan={3}>
                <RadioEditor
                  name={"lodBrkfCn"}
                  info={brkfCn}
                  readOnly
                  onChange={(name, value) => {}}
                />
              </td>
            </tr>
            <tr>
              <th>취사시설정보</th>
              <td colSpan={3}>
                <RadioEditor
                  name={"lodCokCn"}
                  info={cokCn}
                  readOnly
                  onChange={(name, value) => {}}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      {pathname.includes("admin") && (
        <div className="flex justify-center">
          <button
            className="btn-search"
            onClick={() => {
              navigate(pathname.replace("info", "modify") + search, {
                state: {
                  searchInfo: state?.searchInfo,
                  url: state?.url,
                },
              });
            }}
          >
            수정
          </button>
        </div>
      )}
    </div>
  );
};

export default LodDftInfoView;
