import { useMutation, useQueryClient } from "@tanstack/react-query";
import { lodGrpModifyApi } from "components/api/lod";
import CountrySelect from "components/select/country";
import PhoneSelect from "components/select/phone";
import { IlodGrpDtl, lodGrpInfProps } from "interface/lod";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setMessage } from "store/commonSlice";
import { isAbleSelector, setLodNo } from "store/lodSlice";
import { regEmail } from "util/common";

type IProps = {
  lodGrpDtl: IlodGrpDtl;
};

const InfModifyView = ({ lodGrpDtl }: IProps) => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const queClient = useQueryClient();

  const isAdAble = useSelector(isAbleSelector);

  const [info, setInfo] = useState<lodGrpInfProps>({
    telCntyCd: "+82",
    telNo: "",
    lodGrpEml: "",
    lodGrpCntyCd: "KR",
  });

  const handleChange = (name: string, value: string) => {
    setInfo({
      ...info,
      [name]: value,
    });
  };
  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { name, value, lng, code } = target.dataset;
    if (name === "country") {
      setInfo({
        ...info,
        lodGrpCntyCd: code ?? "",
      });
      return;
    }
    setInfo({
      ...info,
      telCntyCd: value ?? "+82",
    });
  };

  // 기본정보 수정
  const infMutation = useMutation(lodGrpModifyApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(
            setMessage({
              message: "숙소그룹기본정보가 수정되었습니다.",
              isConfirm: true,
              handleClick() {
                dispatch(setMessage({ message: null }));
                // dispatch(setIsAdAble(true));
                dispatch(setLodNo(body?.lodGrpNo));
                queClient.invalidateQueries(["lodGrpDtl"]);
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleModify = () => {
    if (!!info.lodGrpEml) {
      if (!regEmail.test(info.lodGrpEml)) {
        alert("정확한 이메일형식을 입력하여 주세요");
        return;
      }
    }

    dispatch(
      setMessage({
        message: "숙소그룹기본 정보를 수정 하시겠습니까?",
        isCancel: true,
        isConfirm: true,
        handleClick() {
          infMutation.mutate({
            ...info,
            lodGrpNo: search.replace("?lodGrpNo=", ""),
          });
          dispatch(setMessage({ message: null }));
        },
      })
    );
  };

  return (
    <>
      <div className="table-form" data-dir-col>
        <div className="sb-title">
          <h4>기본정보</h4>
          <button className="btn-mdfy" onClick={handleModify}>
            수정
          </button>
        </div>
        <table>
          <tbody>
            <tr>
              <th>숙소그룹번호</th>
              <td>
                <input
                  type="text"
                  defaultValue={search.replace("?lodGrpNo=", "")}
                  readOnly
                />
              </td>
              <th>숙소그룹국가코드</th>
              <td>
                <CountrySelect
                  option={info.lodGrpCntyCd || lodGrpDtl?.lodGrpCntyCd}
                  handleSelect={(target) => handleSelect(target)}
                />
              </td>
            </tr>
            <tr>
              <th data-dot>숙소그룹 전화번호</th>
              <td>
                <div className="flex gap-x-2 max-w-[25rem]">
                  <PhoneSelect
                    mobCd={info.telCntyCd || lodGrpDtl?.telCntyCd}
                    handleSelect={(target) => handleSelect(target)}
                  />
                  <input
                    type="text"
                    defaultValue={info.telNo || lodGrpDtl?.telNo}
                    onChange={(e) =>
                      handleChange("telNo", e.currentTarget.value)
                    }
                  />
                </div>
              </td>
              <th data-dot>숙소그룹 이메일</th>
              <td>
                <input
                  type="text"
                  value={info.lodGrpEml || lodGrpDtl?.lodGrpEml}
                  onChange={(e) =>
                    handleChange("lodGrpEml", e.currentTarget.value)
                  }
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default InfModifyView;
