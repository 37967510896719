import LodgmentListView from "components/view/lod/lod/list";
import { lodSearchProps } from "interface/lod";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setIsLoading, setIsSearch } from "store/commonSlice";

const LodgmentListPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { state } = useLocation();

  const lodGrpInfo = localStorage.getItem("lodGrpInfo")
    ? JSON.parse(localStorage.getItem("lodGrpInfo") ?? "")
    : "";

  const [searchInfo, setsearchInfo] = useState<lodSearchProps>({
    lodGrpNo: state?.lodGrpNo || lodGrpInfo?.lodGrpNo,
    lodGrpNm: state?.lodGrpNm || "",
    lodNo: state?.lodNo || "",
    lodNm: state?.lodNm || "",
    lodGrdCd: state?.lodGrdCd || "",
    lodAddr1: state?.lodAddr1 || "",
    lodAddr2: state?.lodAddr2 || "",
    sellAmDvc: state?.sellAmDvc || "",
    contSDt: state?.contSDt || "",
    contEDt: state?.contEDt || "",
    uyn: state?.uyn || "",
  });

  const handleChange = (target: EventTarget & HTMLInputElement) => {
    const { name, value } = target;
    setsearchInfo({
      ...searchInfo,
      [name]: value,
    });
  };

  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { name, lng, value } = target.dataset;

    if (value && lng && name) {
      setsearchInfo({
        ...searchInfo,
        [name]: value === "A" ? "" : value,
      });
    }
  };

  const handleSearch = () => {
    dispatch(setIsSearch(true));
    dispatch(setIsLoading(true));
    navigate(`?page=1`);
  };

  const handleReset = () => {
    dispatch(setIsSearch(false));
    dispatch(setIsLoading(false));
    setsearchInfo({
      lodGrpNo: lodGrpInfo?.lodGrpNo ?? "",
      lodGrpNm: "",
      lodNo: "",
      lodNm: "",
      lodGrdCd: "",
      lodAddr1: "",
      lodAddr2: "",
      sellAmDvc: "",
      contSDt: "",
      contEDt: "",
      uyn: "",
    });
  };

  return (
    <LodgmentListView
      searchInfo={searchInfo}
      handleChange={handleChange}
      handleSelect={handleSelect}
      handleSearch={handleSearch}
      handleReset={handleReset}
    />
  );
};

export default LodgmentListPage;
