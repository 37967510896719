import { useMutation } from "@tanstack/react-query";
import { lodCreateApi, lodDftDtlApi } from "components/api/lod";
import Select from "components/select";
import CountrySelect from "components/select/country";
import PhoneSelect from "components/select/phone";
import LodGrpNmSelect from "components/select/room/lodGrpNmSelect ";
import { lodProps } from "interface/lod";
import { MutableRefObject, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setMessage } from "store/commonSlice";
import { isAbleSelector, setIsAdAble } from "store/lodSlice";
import { regNum } from "util/common";
import { lodGrdCdOptions, lodTypOptions, uYnOptions } from "util/option";

type IProps = {
  lodNo: MutableRefObject<string>;
};

const LodCreateView = ({ lodNo }: IProps) => {
  const dispatch = useDispatch();
  const isAdAble = useSelector(isAbleSelector);

  const dtlMutaion = useMutation(lodDftDtlApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
        }
        if (code === -1) {
        }
      }
    },
  });

  const dtl = dtlMutaion?.data?.body?.lodDftDtl;

  const [inf, setInf] = useState<lodProps>({
    lodGrpNo: "",
    lodGrpNm: "",
    lodGrdCd: "",
    lodCntyCd: "KOR",
    lodTypCd: "",
    telCntyCd: "+82",
    telNo: "",
    lodEml: "",
    faxCntyCd: "+82",
    faxNo: "",
    hpUrl: "",
    lodPosUtc: 9,
    rmCnt: "",
    resvPsbYn: "Y",
    resvRsvEml: "",
    bizNo: "",
    mailOrdrBizNo: "",
    ceoNm: "",
    bizNm: "",
  });

  const handleChange = (name: string, value: string) => {
    setInf({
      ...inf,
      [name]: value,
    });
  };

  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { name, id, value, lng } = target.dataset;
    if (name && value) {
      setInf({
        ...inf,
        [name]: value,
      });
    }
  };

  const lodMutation = useMutation(lodCreateApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(
            setMessage({
              message: "숙소를 성공적으로 생성하였습니다.",
              isConfirm: true,
              handleClick() {
                lodNo.current = body?.lodNo;
                dispatch(setIsAdAble(true));
                dispatch(setMessage({ message: null }));
                setInf({
                  lodGrpNo: "",
                  lodGrpNm: "",
                  lodGrdCd: "",
                  lodCntyCd: "KOR",
                  lodTypCd: "",
                  telCntyCd: "+82",
                  telNo: "",
                  lodEml: "",
                  faxCntyCd: "+82",
                  faxNo: "",
                  hpUrl: "",
                  lodPosUtc: 9,
                  rmCnt: "",
                  resvPsbYn: "Y",
                  resvRsvEml: "",
                  bizNo: "",
                  mailOrdrBizNo: "",
                  ceoNm: "",
                  bizNm: "",
                });
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleCreate = () => {
    if (!inf.lodGrpNo) {
      return alert("숙소그룹명을 선택하여 주세요");
    }
    if (!inf.lodCntyCd) {
      return alert("숙소국가를 선택하여 주세요");
    }
    if (!inf.lodGrdCd) {
      return alert("숙소등급을 선택하여 주세요");
    }
    if (!(inf.telCntyCd && inf.telNo)) {
      return alert("숙소전화번호를 입력하여 주세요");
    }
    if (!inf.lodEml) {
      return alert("숙소이메일을 입력하여 주세요");
    }
    if (!inf.hpUrl) {
      return alert("숙소홈페이지를 입력하에 주세요");
    }
    const params = {
      ...inf,
      rmCnt: Number(inf.rmCnt),
    };

    dispatch(
      setMessage({
        message: "숙소를 생성하시겠습니까?",
        isCancel: true,
        isConfirm: true,
        handleClick() {
          lodMutation.mutate(params);
          dispatch(setMessage({ message: null }));
        },
      })
    );
  };

  useEffect(() => {
    if (isAdAble) {
      dtlMutaion.mutate({
        lodNo: lodNo.current ?? "",
      });
    }
  }, [isAdAble]);

  const isAble = !!(
    inf.lodGrpNo &&
    inf.lodCntyCd &&
    inf.lodGrdCd &&
    inf.telCntyCd &&
    inf.telNo &&
    inf.lodEml &&
    inf.hpUrl &&
    inf.rmCnt
  );

  return (
    <>
      <div className="table-form" data-dir-col>
        <div className="sb-title">
          <h4>기본정보</h4>
          <button
            className={isAdAble ? "hidden" : "btn-mdfy"}
            disabled={isAdAble ? isAdAble : !isAble}
            onClick={handleCreate}
          >
            생성
          </button>
        </div>
        <fieldset>
          <table>
            <tbody>
              <tr>
                {!isAdAble && (
                  <>
                    <th data-dot>숙소그룹명</th>
                    <td>
                      <LodGrpNmSelect
                        option={inf.lodGrpNo ?? ""}
                        onSelect={handleSelect}
                      />
                    </td>
                  </>
                )}
                {isAdAble && (
                  <>
                    <th>숙소번호</th>
                    <td>
                      <input
                        type="text"
                        value={lodNo.current ?? ""}
                        readOnly={isAdAble}
                      />
                    </td>
                  </>
                )}
              </tr>
              <tr>
                <th data-dot>숙소국가</th>
                <td>
                  <CountrySelect
                    option={inf.lodCntyCd || dtl?.lodCntyCd}
                    disabled={isAdAble}
                    handleSelect={handleCreate}
                  />
                </td>
                <th data-dot>숙소등급</th>
                <td>
                  <Select
                    id="lodGrdCd"
                    name="lodGrdCd"
                    option={inf.lodGrdCd || dtl?.lodGrdCd}
                    options={lodGrdCdOptions}
                    disabled={isAdAble}
                    onSelect={handleSelect}
                  />
                </td>
              </tr>
              <tr>
                <th data-dot>숙소전화번호</th>
                <td>
                  <div className="flex gap-x-2 max-w-[25rem] relative">
                    <PhoneSelect
                      wrapStyle=""
                      btnStyle="w-[6rem] h-[2.75rem]"
                      mobCd={inf.telCntyCd || dtl.telCntyCd}
                      disabled={isAdAble}
                      handleSelect={handleSelect}
                    />
                    <span className="flex gap-x-2 w-full">
                      <input
                        type="text"
                        className=""
                        value={(inf.telNo || dtl?.telNo) ?? ""}
                        disabled={isAdAble}
                        onChange={(e) => {
                          const value = e.currentTarget.value;
                          if (!regNum.test(value)) {
                            return;
                          }
                          handleChange("telNo", e.currentTarget.value);
                        }}
                      />
                    </span>
                  </div>
                </td>
                <th>숙소팩스번호</th>
                <td>
                  <div className="flex gap-x-2 max-w-[25rem] relative">
                    <PhoneSelect
                      btnStyle="w-[6rem] h-[2.75rem]"
                      wrapStyle=""
                      mobCd={inf.faxCntyCd || dtl?.faxCntyCd}
                      disabled={isAdAble}
                      handleSelect={handleSelect}
                    />
                    <span className="flex gap-x-2 w-full">
                      <input
                        type="text"
                        className=""
                        value={inf.faxNo || dtl?.faxNo}
                        readOnly={isAdAble}
                        onChange={(e) => {
                          const value = e.currentTarget.value;
                          if (!regNum.test(value)) {
                            return;
                          }
                          handleChange("faxNo", e.currentTarget.value);
                        }}
                      />
                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                <th data-dot>숙소이메일</th>
                <td>
                  <input
                    type="text"
                    value={inf.lodEml || dtl?.lodEml}
                    readOnly={isAdAble}
                    onChange={(e) =>
                      handleChange("lodEml", e.currentTarget.value)
                    }
                  />
                </td>
                <th data-dot>숙소홈페이지</th>
                <td>
                  <input
                    type="text"
                    value={inf.hpUrl || dtl?.hpUrl}
                    readOnly={isAdAble}
                    onChange={(e) =>
                      handleChange("hpUrl", e.currentTarget.value)
                    }
                  />
                </td>
              </tr>
              <tr>
                <th data-dot>숙소총객실수량</th>
                <td>
                  <input
                    type="text"
                    value={inf.rmCnt || dtl?.rmCnt}
                    readOnly={isAdAble}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (regNum.test(value)) {
                        handleChange("rmCnt", e.currentTarget.value);
                      } else {
                        return;
                      }
                    }}
                  />
                </td>
                <th>숙소UTC</th>
                <td>
                  <input
                    type="text"
                    name="utc"
                    value={inf.lodPosUtc || dtl?.lodPosUtc}
                    readOnly={isAdAble}
                    onChange={(e) => handleChange("utc", e.currentTarget.value)}
                  />
                </td>
              </tr>
              <tr>
                <th data-dot>숙소유형</th>
                <td>
                  <Select
                    id={"lodTypCd"}
                    name={"lodTypCd"}
                    option={inf.lodTypCd || dtl?.lodTypCd}
                    options={lodTypOptions}
                    disabled={isAdAble}
                    onSelect={handleSelect}
                  />
                </td>
                <th>예약가능여부</th>
                <td>
                  <Select
                    id={"resvPsbYn"}
                    name={"resvPsbYn"}
                    option={inf.resvPsbYn || dtl?.resvPsbYn}
                    options={uYnOptions}
                    disabled={isAdAble}
                    onSelect={handleSelect}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </fieldset>
      </div>
      {/* <div className={isAble ? "flex justify-center" : "hidden"}>
        <button className="btn-search" onClick={handleCreate}>
          생성
        </button>
      </div> */}
    </>
  );
};

export default LodCreateView;
