import { useEffect, useState } from "react";
import "./header.scss";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Modal from "components/modal/modal";
import { useDispatch } from "react-redux";
import {
  isSelectSelector,
  memTypeSelector,
  setMessage,
} from "store/commonSlice";
import { useMutation } from "@tanstack/react-query";
import { checkPwdApi, logOutApi } from "components/api/member";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";

const Header = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const { pathname } = useLocation();

  const manInf = localStorage.getItem("manInf")
    ? JSON.parse(localStorage.getItem("manInf") ?? "")
    : {};

  const memType = useSelector(memTypeSelector);

  const [info, setInfo] = useState({
    emlId: "",
    lgnPwd: "",
  });

  const [isOpen, setIsOpen] = useState(false);
  const [isOpenLang, setIsOpenLang] = useState(false);

  const lng = i18n.language === "en" ? "ENG" : "KOR";
  const otherLang = lng === "KOR" ? "ENG" : "KOR";
  const loginObject = sessionStorage.getItem("login");
  const isLogin = (loginObject && JSON.parse(loginObject).value) || null;
  const isSelect = useSelector(isSelectSelector);
  const [lodNm, setLodNm] = useState("");

  const checkpwMutation = useMutation(checkPwdApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          setIsOpen(false);
          const url =
            memType +
            "/mem/info" +
            `?${memType === "admin" ? "admNo=" : "manNo="}` +
            manInf?.manNo;
          navigate(url);
          setInfo({
            emlId: "",
            lgnPwd: "",
          });
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleCheckPw = () => {
    dispatch(
      setMessage({
        message: "회원정보를 조회 하시겠습니까?",
        isConfirm: true,
        isCancel: true,
        handleClick() {
          dispatch(setMessage({ message: null }));
          checkpwMutation.mutate(info);
        },
      })
    );
  };

  const logoutMutation = useMutation(logOutApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          navigate("/", { replace: true });
          sessionStorage.removeItem("login");
          localStorage.removeItem("lodGrpInfo");
          localStorage.removeItem("manInf");
          localStorage.removeItem("sltLodNo");
        }
        if (code === -1) {
          dispatch(
            setMessage({
              message,
              isConfirm: true,
              handleClick() {
                dispatch(setMessage({ message: null }));
              },
            })
          );
        }
      }
    },
  });

  const handleLogout = () => {
    dispatch(
      setMessage({
        title: "",
        message: "로그아웃 하시겠습니까?",
        isConfirm: true,
        isCancel: true,
        handleClick: () => {
          dispatch(
            setMessage({
              message: null,
              handleClick: () => {},
            })
          );

          logoutMutation.mutate(memType);
        },
      })
    );
  };

  // const lodGrpListMutation = useMutation({
  //   mutationKey: ["lodGrpList"],
  //   mutationFn: lodGrpListApi,
  //   onSuccess(data) {
  //     if (data) {
  //       const {
  //         header: { code, message },
  //         body: { lodGrpList },
  //       } = data;

  //       if (code === 0) {
  //         localStorage.setItem("lodGrpInfo", JSON.stringify(lodGrpList[0]));
  //       }
  //       if (code === -1) {
  //       }
  //     }
  //   },
  // });

  useEffect(() => {
    const lodGrpInfo = localStorage.getItem("lodGrpInfo");
    setLodNm(lodGrpInfo ? JSON.parse(lodGrpInfo)?.lodNm : "");
  }, [isSelect, localStorage.getItem("lodGrpInfo")]);

  return (
    <header>
      <div className="header-wrap">
        <div
          className="cursor-pointer"
          onClick={() => {
            if (!!isLogin) {
              navigate(`${memType}`);
              return;
            }
            if (!isLogin) {
              navigate("");
            }
          }}
        >
          <h1>UB</h1>
          <h3 className="ps-3">
            {isLogin ? (memType === "manager" ? "매니저" : "관리자") : ""}
            <span className="text-base px-4">{lodNm}</span>
          </h3>
        </div>
        {pathname === "/" && !isLogin && (
          <div className="left-menu">
            <Link to="/signup">회원가입</Link>
            <Link to={`${memType}/resetpw/step1`}>회원비밀번호 찾기</Link>
          </div>
        )}
        {pathname !== "/" && !isLogin && (
          <div>
            <Link to="/">로그인</Link>
          </div>
        )}
        {!!isLogin && (
          <div className="left-menu">
            {memType === "manager" && (
              <button onClick={() => setIsOpen(true)}>회원정보</button>
            )}
            <button onClick={handleLogout} className="text-red-600">
              로그아웃
            </button>
            <button
              className="btn-lng"
              data-icon={lng}
              onClick={() => {
                setIsOpenLang(!isOpenLang);
              }}
            >
              {lng}
            </button>
            {isOpenLang && (
              <ul className="languages" aria-disabled>
                <li
                  className="kor"
                  onClick={() => {
                    setIsOpenLang(false);
                    // if (lng === "KOR") {
                    // i18n.changeLanguage("us");
                    // localStorage.setItem("lng", "us");
                    // }
                    // else {
                    i18n.changeLanguage("kr");
                    localStorage.setItem("lng", "kr");
                    // }
                  }}
                >
                  KOR
                </li>
                <li
                  className="eng"
                  onClick={() => {
                    setIsOpenLang(false);
                    // i18n.changeLanguage("us");
                    // localStorage.setItem("lng", "use");

                    // if (lng === "KOR") {
                    //   i18n.changeLanguage("us");
                    //   localStorage.setItem("lng", "us");
                    // } else {
                    //   i18n.changeLanguage("kr");
                    //   localStorage.setItem("lng", "kr");
                    // }
                  }}
                >
                  ENG
                </li>
              </ul>
            )}
          </div>
        )}
      </div>
      <Modal
        title=""
        isOpen={isOpen}
        isLoading={false}
        disabled={!(info.emlId && info.lgnPwd)}
        isOutClose={false}
        handleCloseModal={() => {
          setIsOpen(false);
          setInfo({
            emlId: "",
            lgnPwd: "",
          });
        }}
        onClick={handleCheckPw}
      >
        <div className="">
          <p>
            개인 정보를 위하여 <br />
            비밀번호를 입력해 주세요
          </p>
          <label htmlFor="" className="w-full text-sm text-left pb-1">
            아이디
          </label>
          <input
            type="text"
            value={info.emlId}
            onChange={(e) => {
              setInfo({
                ...info,
                emlId: e.currentTarget.value,
              });
            }}
          />
          <label htmlFor="" className="w-full text-sm text-left pb-1 pt-5">
            비밀번호
          </label>
          <input
            type="password"
            value={info.lgnPwd}
            onChange={(e) => {
              setInfo({
                ...info,
                lgnPwd: e.currentTarget.value,
              });
            }}
          />
        </div>
      </Modal>
    </header>
  );
};

export default Header;
