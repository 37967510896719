import { useMutation, useQuery } from "@tanstack/react-query";
import imgUploadApi from "components/api/imgUpload";
import {
  lodImgCreateApi,
  lodImgListApi,
  lodImgModifyApi,
} from "components/api/lod";
import { lodImgs } from "interface/lod";
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useState,
  MouseEvent,
} from "react";
import { BsPencilSquare } from "react-icons/bs";
import { CgCloseR } from "react-icons/cg";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setMessage } from "store/commonSlice";

type imgUrlProps = {
  name: string;
  url: string | ArrayBuffer | null;
};

type IProps = {
  imgs: lodImgs[];
  setImgs: Dispatch<SetStateAction<lodImgs[]>>;
  isRegi: boolean;
  isAble: boolean;
};

const InImgModifyView = ({ imgs, setImgs, isRegi, isAble }: IProps) => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const lodNo = search.replace("?lodNo=", "");
  const [inImgs, setInImgs] = useState<any[]>([]);
  const [imgIdx, setImgIdx] = useState(-1);

  const query = useQuery({
    queryKey: ["inImgList", lodNo],
    queryFn: () => {
      return lodImgListApi({ lodNo, imgTypCd: "I02" });
    },
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });
  const imgList = query?.data?.body.lodImgList || [];

  // 이미지 등록 mutation
  const imgMutation = useMutation(lodImgCreateApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(
            setMessage({
              message: "이미지를 등록하였습니다.",
              isConfirm: true,
              handleClick() {
                // setIsRegi(true);
                dispatch(setMessage({ message }));
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  // 이미지 업로드 mutation
  const imgUploadMutation = useMutation(imgUploadApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          setImgs((prev: any) => [...prev, ...body.imgList]);
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  // 이미지 업로드
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    alert("숙소내관 이미지 업로드");

    const files = e.target.files;
    console.log("files", files);

    if (files) {
      if (inImgs?.length > 10 || inImgs?.length + files?.length > 10) {
        dispatch(setMessage({ message: "최대 10까지 등록이 가능합니다." }));
        return;
      }

      let formData = new FormData();
      formData.append("imgTypCd", "I02");

      Array.from(files).forEach((file: File) => {
        if (file.size >= 1024000) {
          dispatch(setMessage({ message: "최대 업로드 사이즈는 1MB 입니다." }));
          return;
        }

        formData.append("uploadFiles", file);
        let reader = new FileReader();

        reader.onload = () => {
          if (reader.result) {
            const img = new Image();
            img.src = reader.result as string;

            img.onload = () => {
              // let width = img.width;
              // let height = img.height;

              // if (width <= 600 || height <= 390) {
              //   dispatch(
              //     setMessage({
              //       message: "이미지 업로드 가능 사이즈 650 * 400 ",
              //     })
              //   );

              //   return;
              // }

              setInImgs((prevList) => [
                ...prevList,
                {
                  name: file.name,
                  url: reader.result,
                },
              ]);
            };
          }
        };
        reader.readAsDataURL(file);
      });

      imgUploadMutation.mutate({
        formData,
        lodNo: lodNo ?? "",
      });

      e.currentTarget.value = "";
    }
  };

  const imgModifyMutation = useMutation(lodImgModifyApi, {
    onSuccess(data, variables, context) {
      console.groupCollapsed(context);
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
        }
      }
    },
  });

  // 이미지 수정
  const handleModifyImg = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    const { imgtypecd, expsordv, rgseq } = e.currentTarget.dataset;
    if (files) {
      if (inImgs?.length > 10 || inImgs?.length + files?.length > 10) {
        dispatch(setMessage({ message: "최대 10까지 등록이 가능합니다." }));
        return;
      }

      let formData = new FormData();
      formData.append("imgTypCd", "I02");
      const file = files[0];
      formData.append("uploadFiles", file);

      if (file.size >= 1024000) {
        dispatch(setMessage({ message: "최대 업로드 사이즈는 5MB 입니다." }));
        return;
      }

      let reader = new FileReader();

      reader.onload = () => {
        if (reader.result) {
          const img = new Image();
          img.src = reader.result as string;

          img.onload = () => {
            let width = img.width;
            let height = img.height;

            // if (width <= 600 || height <= 390) {
            //   dispatch(
            //     setMessage({
            //       message: "이미지 업로드 가능 사이즈 650 * 400 ",
            //     })
            //   );

            //   return;
            // }

            const urls = inImgs.map((img: imgUrlProps, idx: number) => {
              return imgIdx === idx
                ? { name: file.name, url: reader.result }
                : img;
            });

            setInImgs(urls);
          };
        }
      };
      reader.readAsDataURL(file);

      // imgUploadMutation.mutate({
      //   formData,
      //   lodNo,
      // });

      imgUploadApi({
        formData,
        lodNo,
      }).then((data) => {
        if (data) {
          const {
            header: { code, message },
            body,
          } = data;
          if (code === 0) {
            setImgs((prev: any) => [...prev, ...body.imgList]);
            imgModifyMutation.mutate({
              ...body.imgList[0],
              lodNo,
              expsOrdV: expsordv,
              rgSeq: rgseq,
            });
          }
          if (code === -1) {
            dispatch(setMessage({ message }));
          }
        }
      });
    }

    e.currentTarget.value = "";
  };

  // 이미지 삭제
  const handleImgRemove = (
    e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>
  ) => {
    alert("이미지를 삭세 하시겠습니까?");
    const { index, expsordv, rgseq } = e.currentTarget.dataset;
    dispatch(
      setMessage({
        message: "해당 이미지를 삭세하시겠습니까?",
        isCancel: true,
        isConfirm: true,
        handleClick() {
          imgModifyMutation.mutate({
            lodNo,
            expsOrdV: Number(expsordv),
            rgSeq: Number(rgseq),
            uyn: "N",
          });
        },
      })
    );
  };

  console.log([...imgList, ...inImgs]);

  return (
    <>
      <div className="w-full px-4 min-h-[2.5rem]">
        <span>
          <input type="file" id="file-in" multiple onChange={handleChange} />

          <label
            htmlFor="file-in"
            className="img-add"
            data-txt="숙소내관 이미지 추가"
          />
        </span>
        <div className={"img-wrap"}>
          {[...imgList, ...inImgs].map((img, index) => {
            return (
              <div className="img-item" key={index}>
                <img src={img?.url ?? img?.resImgPhUrl} alt="숙소내관이미지" />
                <div></div>
                <div className={"img-btn-wrap"}>
                  <button
                    data-txt="삭제"
                    data-idx={index}
                    data-rgseq={img.rgSeq}
                    data-expsordv={img.expsOrdV}
                    data-imgtypecd={img.imgTypCd}
                    onClick={handleImgRemove}
                  >
                    <CgCloseR
                      size={20}
                      style={{
                        padding: 1,
                        color: "red",
                        cursor: "pointer",
                        backgroundColor: "#fff",
                      }}
                    />
                  </button>
                  <div className="relative" data-idx={index}>
                    <BsPencilSquare
                      size={20}
                      style={{
                        padding: 1,
                        color: "#39405a",
                        cursor: "pointer",
                        backgroundColor: "#fff",
                      }}
                    />
                    <input
                      type="file"
                      accept="images/*"
                      required
                      id="file-img"
                      className=""
                      data-idx={index}
                      data-rgseq={img.rgSeq}
                      data-expsordv={img.expsOrdV}
                      data-imgtypecd={img.imgTypCd}
                      onChange={handleModifyImg}
                    />
                    <label
                      htmlFor="file-img"
                      onMouseDown={() => {
                        setImgIdx(index);
                      }}
                    />
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default InImgModifyView;
