import { useMutation, useQuery } from "@tanstack/react-query";
import { lodGrpListApi } from "components/api/member";
import Select from "components/select";
import { optionProps } from "interface";
import { IlodGrpList } from "interface/member";
import { useEffect, useState } from "react";

type IProps = {
  option: string;
  onSelect(target: EventTarget & HTMLLIElement): void;
};

const LodGrpNmSelect = ({ option, onSelect }: IProps) => {
  const [options, setOptions] = useState<optionProps[]>([
    { value: "A", kr: "선택하세요", us: "select" },
  ]);

  const listMutation = useMutation({
    mutationKey: ["lodGrpList"],
    mutationFn: lodGrpListApi,
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;

        if (code === 0) {
          const options = body.lodGrpList.map((li: IlodGrpList) => {
            const option = {
              value: li.lodGrpNo,
              kr: li.lodGrpNm,
              us: li.lodGrpNm,
            };
            return option;
          });

          setOptions([
            { value: "A", kr: "선택하세요", us: "select" },
            ...options,
          ]);
        }
        if (code === -1) {
          setOptions([{ value: "A", kr: "선택하세요", us: "select" }]);
        }
      }
    },
  });

  useEffect(() => {
    listMutation.mutate();

    return () => {
      listMutation.mutate();
    };
  }, []);

  return (
    <Select
      wrapStyle="flex-1 w-full h-[2.75rem]"
      id="lodGrpNo"
      name="lodGrpNo"
      isSusess={listMutation.data?.header?.code === 0}
      option={option}
      options={options}
      onSelect={onSelect}
    />
  );
};

export default LodGrpNmSelect;
