import SubHeader from "components/layout/header/subHeader";
import { useNavigate } from "react-router";
import InfAdModifyView from "./infAdModify";
import InfModifyView from "./infModify";
import { useLocation } from "react-router-dom";

type IProps = {};

const LodRfdModifyView = ({}: IProps) => {
  const { state } = useLocation();
  return (
    <div className="main-cnt">
      <SubHeader
        title="숙소환불수수료생성 - 숙소환불수수료율기본 수정"
        back
        url={state?.url}
      />
      <InfModifyView />
      <InfAdModifyView />
    </div>
  );
};

export default LodRfdModifyView;
