import RadioButtonWrap from "./radioButtonWrap";
import {
  facilityOptions,
  otherFacilityOptions,
  roomFacilityOptions,
} from "util/option";
import { useLocation, useNavigate } from "react-router-dom";
import SubHeader from "components/layout/header/subHeader";
import Button from "components/button/createButtonWrap";
import { IopDft, IopList } from "interface/lod";

type IProps = {
  dtl: IopDft;
  lopList: Array<IopList>;
  etcList: Array<IopList>;
  ropList: Array<IProps>;
};

const LodPrdInfoView = ({ dtl, lopList, etcList, ropList }: IProps) => {
  const navigate = useNavigate();
  const { pathname, search, state } = useLocation();

  return (
    <div className="main-cnt">
      <SubHeader
        title="숙소제공정보 상세"
        close
        url={state?.url}
        state={state?.searchInfo}
      />
      <div className="table-form">
        <table>
          <tbody>
            <tr>
              <th>숙소그룹 명</th>
              <td>
                <input type="text" defaultValue={dtl?.lodGrpNm} readOnly />
              </td>
              <th>숙소 등급</th>
              <td>
                <input type="text" defaultValue={dtl?.lodGrdCd} readOnly />
              </td>
            </tr>
            <tr>
              <th>숙소명</th>
              <td colSpan={1}>
                <input type="text" defaultValue={dtl?.lodNm} readOnly />
              </td>
            </tr>
            <tr className={lopList.length !== 0 ? "" : "hidden"}>
              <th>공용시설</th>
              <td colSpan={3} className="py-2">
                {facilityOptions.map((radio, index) => (
                  <div className="radiobtn-wrap" key={index}>
                    <RadioButtonWrap
                      label={radio.label1}
                      radio1={false}
                      radio2
                      handleChange={() => {}}
                    />
                    {radio.label2 && (
                      <RadioButtonWrap
                        label={radio.label2}
                        radio1={false}
                        radio2
                        handleChange={() => {}}
                      />
                    )}
                  </div>
                ))}
              </td>
            </tr>
            <tr className={ropList.length !== 0 ? "" : "hidden"}>
              <th>객실시설</th>
              <td colSpan={3} className="py-2">
                {roomFacilityOptions.map((radio, index) => (
                  <div className="radiobtn-wrap" key={index}>
                    <RadioButtonWrap
                      label={radio.label1}
                      radio1={false}
                      radio2
                      handleChange={() => {}}
                    />
                    {radio.label2 && (
                      <RadioButtonWrap
                        label={radio.label2}
                        radio1={false}
                        radio2
                        handleChange={() => {}}
                      />
                    )}
                  </div>
                ))}
              </td>
            </tr>
            <tr className={etcList.length !== 0 ? "" : "hidden"}>
              <th>기타</th>
              <td colSpan={3} className="py-2">
                {otherFacilityOptions.map((radio, index) => (
                  <div className="radiobtn-wrap" key={index}>
                    <RadioButtonWrap
                      label={radio.label1}
                      radio1={false}
                      radio2
                      handleChange={() => {}}
                    />
                    {radio.label2 && (
                      <RadioButtonWrap
                        label={radio.label2}
                        radio1={false}
                        radio2
                        handleChange={() => {}}
                      />
                    )}
                  </div>
                ))}
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="text-center">
        <button
          className="btn-search"
          onClick={() => {
            navigate(pathname.replace("info", "modify") + search);
          }}
        >
          수정
        </button>
      </div>
    </div>
  );
};

export default LodPrdInfoView;
