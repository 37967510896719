import MemberListView from "components/view/member/list";
import { searchProps } from "interface/member";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setIsLoading, setIsSearch } from "store/commonSlice";
import { useLocation, useNavigate } from "react-router-dom";

const MemberListPage = () => {
  const dispatch = useDispatch();
  const navigage = useNavigate();
  // const admNo = useSelector(admNoSelector);
  const { pathname, search, state } = useLocation();

  const memType = pathname.includes("admin")
    ? "admin"
    : pathname.includes("manager")
    ? "manager"
    : "";

  const lodGrpInfo = localStorage.getItem("lodGrpInfo")
    ? JSON.parse(localStorage.getItem("lodGrpInfo") + "")
    : "";

  const [searchInfo, setSearchInfo] = useState<Partial<searchProps>>({
    type: state?.type || memType,
    authGrpCod: state?.authGrpCod || "",
    emlId: state?.emlId || "",
    mobCntyCd: state?.mobCntyCd || "+82",
    mobNo: state?.mobNo || "",
    memStatus: state?.memStatus || "",
    lodGrpNo: state?.lodGrpNo || lodGrpInfo?.lodGrpNo,
    lodGrpNm: state?.lodGrpNm || lodGrpInfo?.lodGrpNm,
    lodNo: state?.lodNo || lodGrpInfo?.lodNo,
    joiReqDtti: state?.joiReqDtti || "",
    soDtti: state?.soDtti || "",
    soYn: state?.soYn || "N",
    lckYn: state?.lckYn || "N",
    joiAprYn: state?.joiAprYn || "Y",
    // lodGrpNo: state.lodGrpNo || "",
  });

  const handleChange = (target: EventTarget & HTMLInputElement) => {
    const { name, value, id } = target;

    if (name === "memType") {
      setSearchInfo({
        ...searchInfo,
        type: id,
      });
      navigage("");
    } else {
      setSearchInfo({
        ...searchInfo,
        [name]: value,
      });
    }
  };

  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const name = target.dataset.name || "";
    const value = target.dataset.value || "";
    setSearchInfo({
      ...searchInfo,
      [name]: value,
    });
  };

  const handleSearch = () => {
    dispatch(setIsLoading(true));
    dispatch(setIsSearch(true));
    navigage("?page=1");
  };

  const handleReset = () => {
    setSearchInfo({
      type: memType || "manager",
      authGrpCod: "",
      emlId: "",
      mobCntyCd: "+82",
      mobNo: "",
      memStatus: "",
      lodGrpNo: lodGrpInfo?.lodGrpNo || "",
      lodGrpNm: lodGrpInfo?.lodGrpNm || "",
      lodNo: lodGrpInfo?.lodNo || "",
      joiReqDtti: "",
      soDtti: "",
      soYn: "N",
      lckYn: "N",
      joiAprYn: "Y",
    });
    dispatch(setIsLoading(false));
    dispatch(setIsSearch(false));
    navigage("");
  };

  return (
    <>
      <MemberListView
        searchInfo={searchInfo}
        handleChange={handleChange}
        handleSearch={handleSearch}
        handleSelect={handleSelect}
        handleReset={handleReset}
      />
    </>
  );
};

export default MemberListPage;
