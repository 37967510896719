import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  admInfoApi,
  managerInfoApi,
  modifyAdmInfoApi,
  modifyManagerApi,
  userInfoApi,
  userModifyApi,
} from "components/api/member";
import MemInfoModifyView from "components/view/member/modify";
import { ImemInf } from "interface/member";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setLoadingButton, setMessage } from "store/commonSlice";
import { setIsSearchMember } from "store/memberSlice";
import { dateFormat } from "util/common";

const MemInfoModifyPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { pathname, search } = useLocation();
  const memType = pathname.includes("admin") ? "admin" : "manager";

  const admNo = search.includes("admNo") ? search.replace("?admNo=", "") : "";
  const manNo = search.includes("manNo") ? search.replace("?manNo=", "") : "";
  const memNo = search.includes("memNo") ? search.replace("?memNo=", "") : "";

  const [memInfo, setMemInfo] = useState<ImemInf>({
    memNo: "",
    fstnm: "",
    surnm: "",
    name: "",
    emlId: "",
    mobCntyCd: "",
    mobNo: "",
    langCd: "",
    currCd: "",
    typCd: "",
    joiDtti: "",
    lckDtti: "",
    soYn: "",
    lckYn: "",
    joiReqDtti: "",
    joiAprYn: "",
    authGrpCod: "",
    authGrpNm: "",
  });

  const admMutation = useMutation(admInfoApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          const {
            admFstnm,
            admSurnm,
            admNo,
            authGrpCod,
            authGrpNm,
            currCd,
            emlId,
            joiAprDtti,
            joiReqDtti,
            langCd,
            mobCntyCd,
            mobNo,
            soYn,
            joiAprYn,
          } = body;

          setMemInfo({
            memNo: admNo,
            fstnm: admFstnm,
            surnm: admSurnm,
            name: admSurnm + admFstnm,
            emlId,
            mobCntyCd,
            mobNo,
            langCd,
            currCd,
            typCd: "",
            joiDtti: dateFormat(joiReqDtti?.slice(0, 8), "-") ?? "",
            lckDtti: "",
            soYn,
            lckYn: "",
            joiAprYn,
            authGrpCod,
            authGrpNm,
          });
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const manMutation = useMutation(managerInfoApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          const {
            currCd,
            emlId,
            joiReqDtti,
            langCd,
            lodGrpNo,
            manFstnm,
            manNo,
            manSurnm,
            manTypCod,
            mobNo,
            soYn,
            mobCntyCd,
          } = body.manInfo;
          setMemInfo({
            memNo: manNo,
            fstnm: manFstnm,
            surnm: manSurnm,
            name: manSurnm + manFstnm,
            emlId,
            mobCntyCd,
            mobNo,
            langCd,
            currCd,
            typCd: manTypCod,
            joiDtti: dateFormat(joiReqDtti?.slice(0, 8), "-") ?? "",
            lckDtti: "",
            soYn,
            lckYn: "",
          });
        }

        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const userMutation = useMutation(userInfoApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          const {
            emlId,
            joiDtti,
            langCd,
            lckYn,
            memFstnm,
            memSurnm,
            menNm,
            memNo,
            mobCntyCd,
            mobNo,
            soDtti,
            soYn,
            currCd,
            telCntyCd,
            telNo,
          } = body?.userDtl;
          setMemInfo({
            memNo: memNo,
            fstnm: memFstnm,
            surnm: memSurnm,
            name: memSurnm + memFstnm,
            emlId,
            mobCntyCd: mobCntyCd || telCntyCd,
            mobNo: mobNo || telNo,
            langCd,
            currCd,
            typCd: "",
            joiDtti,
            soDtti,
            soYn,
            lckYn,
          });
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const modifyAdmMutation = useMutation(modifyAdmInfoApi, {
    onSuccess(data) {
      if (data) {
        const { code, message } = data.header;
        if (code === 0) {
          dispatch(
            setMessage({
              message: `회원 정보 수정이 완료되였습니다.`,
              isConfirm: true,
              handleClick: () => {
                dispatch(setMessage({ message: null }));
              },
            })
          );
          dispatch(setLoadingButton({ isLoading: false, disabled: false }));

          queryClient.invalidateQueries(["memInfo", "admin"]);
          dispatch(setIsSearchMember(false));
          queryClient.invalidateQueries(["memList", "admin"]);
          dispatch(setIsSearchMember(true));
          setInfo({
            mobCntyCd: "",
            mobNo: "",
            joiAprYn: undefined,
          });
        }
        if (code === -1) {
          dispatch(
            setMessage({
              message,
              isConfirm: true,
              handleClick: () => {
                dispatch(setMessage({ message: null }));
              },
            })
          );
        }
      }
    },
  });

  const modifyMngMutation = useMutation(modifyManagerApi, {
    onSuccess(data) {
      if (data) {
        const { code, message } = data?.header;
        if (code === 0) {
          dispatch(
            setMessage({
              message: `매니저정보 수정이 완료되었습니다.`,
              isConfirm: true,
              handleClick: () => {
                dispatch(setMessage({ message: null }));
              },
            })
          );
          dispatch(setLoadingButton({ isLoading: false, disabled: false }));

          queryClient.invalidateQueries(["memInfo", "manager"]);
          dispatch(setIsSearchMember(false));
          queryClient.invalidateQueries(["memList", "manager"]);
          dispatch(setIsSearchMember(true));
          setInfo({
            mobCntyCd: "",
            mobNo: "",
            joiAprYn: undefined,
          });
        }
        if (code === -1) {
          dispatch(
            setMessage({
              message,
              isConfirm: true,
              handleClick() {
                dispatch(setMessage({ message: null }));
              },
            })
          );
        }
      }
    },
  });

  const modifyUsrMutation = useMutation(userModifyApi, {
    onSuccess(data) {
      if (data) {
        const { code, message } = data?.header;
        if (code === 0) {
          dispatch(
            setMessage({
              message: `회원정보 수정이 완료되었습니다.`,
              isConfirm: true,
              handleClick: () => {
                dispatch(setMessage({ message: null }));
              },
            })
          );
          dispatch(setLoadingButton({ isLoading: false, disabled: false }));

          queryClient.invalidateQueries(["memInfo", "user", memNo]);
          dispatch(setIsSearchMember(false));
          queryClient.invalidateQueries(["memList", "manager"]);
          dispatch(setIsSearchMember(true));
          setInfo({
            mobCntyCd: "",
            mobNo: "",
            joiAprYn: undefined,
          });
        }
        if (code === -1) {
          dispatch(
            setMessage({
              message,
              isConfirm: true,
              handleClick() {
                dispatch(setMessage({ message: null }));
              },
            })
          );
        }
      }
    },
  });

  const [info, setInfo] = useState({
    mobNo: "",
    mobCntyCd: "",
    joiAprYn: undefined,
  });

  const handleChange = (target: { name: string; value: string }) => {
    const { name, value } = target;
    setInfo({
      ...info,
      [name]: value,
    });
  };
  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { name, value } = target.dataset as any;

    setInfo({
      ...info,
      [name]: value ?? "",
    });
  };

  const handleModifyMemInfo = () => {
    if (search.includes("admNo") && !!admNo) {
      dispatch(
        setMessage({
          message: "회원정보를 수정하시겠습니까?",
          isConfirm: true,
          isCancel: true,
          handleClick: () => {
            modifyAdmMutation.mutate({
              memNo: admNo,
              mobNo: info.mobNo || undefined,
              mobCntyCd: info.mobCntyCd || undefined,
            });
            dispatch(setLoadingButton({ isLoading: true, disabled: true }));
          },
        })
      );
      return;
    }
    if (search.includes("manNo") && !!manNo) {
      dispatch(
        setMessage({
          message: "매니저정보를 수정하시겠습니까?",
          isConfirm: true,
          isCancel: true,
          handleClick: () => {
            modifyMngMutation.mutate({
              memNo: manNo,
              mobNo: info.mobNo || undefined,
              mobCntyCd: info.mobCntyCd || undefined,
              joiAprYn: info.joiAprYn || undefined,
            });
          },
        })
      );
      return;
    }
    if (search.includes("memNo") && !!memNo) {
      dispatch(
        setMessage({
          message: "회원정보를 수정하시겠습니까?",
          isConfirm: true,
          isCancel: true,
          handleClick: () => {
            modifyUsrMutation.mutate({
              memNo,
              mobNo: info.mobNo || undefined,
              mobCntyCd: info.mobCntyCd || undefined,
              // joiAprYn: info.joiAprYn || undefined,
            });
          },
        })
      );
    }
  };
  useEffect(() => {
    if (!!search.includes("manNo")) {
      manMutation.mutate(manNo);
      return;
    }
    if (!!search.includes("admNo")) {
      admMutation.mutate(admNo);
      return;
    }
    if (!!search.includes("memNo")) {
      userMutation.mutate(memNo);
    }
  }, [pathname, search]);

  return (
    <>
      <MemInfoModifyView
        title={"회원정보 수정"}
        info={info}
        memType={memType}
        memInfo={memInfo}
        isLoading={modifyAdmMutation.isLoading || modifyMngMutation.isLoading}
        handleChange={handleChange}
        handleSelect={handleSelect}
        handleModifyMemInfo={handleModifyMemInfo}
      />
    </>
  );
};

export default MemInfoModifyPage;
