import SubHeader from "components/layout/header/subHeader";

import InfView from "./infCreate";
import AdInfView from "./adInfCreate";
import { useDispatch } from "react-redux";
import { setMessage } from "store/commonSlice";
import { useNavigate } from "react-router-dom";
import { IoIosArrowBack } from "react-icons/io";
import { useRef } from "react";

type IProps = {};

const LodGroupCreateView = ({}: IProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const lodGrpNo = useRef("");

  return (
    <div className="main-cnt">
      <SubHeader title="숙소그룹 생성" dot>
        <button
          onClick={() => {
            dispatch(
              setMessage({
                message: "페이지에서 나가시겠습니까? ",
                isConfirm: true,
                isCancel: true,
                handleClick() {
                  navigate(-1);
                  dispatch(setMessage({ message: null }));
                },
              })
            );
          }}
          className="flex items-center text-sm p-2"
        >
          <IoIosArrowBack size={18} />
          <span>뒤로가기</span>
        </button>
      </SubHeader>
      <InfView lodGrpNo={lodGrpNo} />
      <AdInfView lodGrpNo={lodGrpNo} />
    </div>
  );
};
export default LodGroupCreateView;
