import { createSlice, createSelector } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "./store";
import { optionProps } from "interface";

type nofiType = {
  message: string | null;
  title?: string;
  cnfrmBtnText?: string;
  canclBntText?: string;
  isCancel?: boolean;
  isConfirm?: boolean;
  handleClick?: () => void;
  handleCancel?: () => void;
};

type optionType = {
  type?: string;
  name: string;
  value: string;
};

type lodGrpNoType = {
  lodGrpNo: string;
  lodGrpCntyCd: string;
  lodGrpNm: string;
};

type checkItem = {
  prvGrpCd: string;
  prvSeq: string;
  required: boolean;
  prvAgYn: string;
  essYn: string;
};

type loadingBtnType = {
  isLoading: boolean;
  disabled: boolean;
};

export type lngType = {};
export interface commonState {
  userInfo: object | null;
  notiInfo: nofiType;
  isLogin: boolean;
  isLoading: boolean;
  isOpenPhoneSelect: boolean;
  isOpenSelect: boolean;
  selectOption: optionType[];
  authGroupCod: optionType;
  lodGrpNo: lodGrpNoType;
  itemCheck: checkItem[];
  allCheck: boolean;
  // pageNo: Number;
  loadingBtn: loadingBtnType;
  selectLng: string;
  lodGrpList: optionProps[];
  isSearch: boolean;
  memType: "manager" | "admin" | string;
  isSelect: boolean;
  isSuccess: boolean;
}

const initialState: commonState = {
  userInfo: null,
  isLogin: false,
  isLoading: false,
  // pageNo: 1,
  selectLng: "",
  lodGrpList: [],
  isSearch: false,
  memType: "manager",
  isSelect: false,
  isSuccess: false,

  loadingBtn: {
    isLoading: false,
    disabled: false,
  },

  notiInfo: {
    message: null,
    title: "",
    cnfrmBtnText: "확인",
    canclBntText: "취소",
    isCancel: false,
    isConfirm: true,
  },
  isOpenPhoneSelect: false,
  isOpenSelect: false,
  selectOption: [],
  authGroupCod: {
    name: "선택하세요",
    value: "all",
  },
  lodGrpNo: {
    lodGrpNo: "",
    lodGrpCntyCd: "",
    lodGrpNm: "",
  },
  allCheck: false,
  itemCheck: [
    {
      prvGrpCd: "A01",
      prvSeq: "1",
      required: false,
      prvAgYn: "Y",
      essYn: "Y",
    },
    {
      prvGrpCd: "B01",
      prvSeq: "2",
      required: false,
      prvAgYn: "Y",
      essYn: "Y",
    },
    {
      prvGrpCd: "C01",
      prvSeq: "3",
      required: false,
      prvAgYn: "Y",
      essYn: "Y",
    },
    {
      prvGrpCd: "D01",
      prvSeq: "4",
      required: false,
      prvAgYn: "Y",
      essYn: "Y",
    },
  ],
};

export const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {
    setUserInfo: (state, { payload }: PayloadAction<object | null>) => {
      state.userInfo = payload;
    },
    // setPage: (state, { payload }: PayloadAction<Number>) => {
    //   state.pageNo = payload;
    // },

    setSelectLng: (state, { payload }: PayloadAction<string>) => {
      state.selectLng = payload;
    },

    setIsSearch: (state, { payload }: PayloadAction<boolean>) => {
      state.isSearch = payload;
    },

    setIsSelect: (state, { payload }: PayloadAction<boolean>) => {
      state.isSelect = payload;
    },

    setMessage: (state, { payload }: PayloadAction<nofiType>) => {
      state.notiInfo = {
        ...state.notiInfo,
        message: payload.message,
        title: payload.title,
        // btnText: payload.btnText,
        cnfrmBtnText: payload.cnfrmBtnText,
        canclBntText: payload.canclBntText,
        isConfirm: payload.isConfirm,
        isCancel: payload.isCancel,
        handleClick: payload.handleClick,
        handleCancel: payload.handleCancel,
      };
    },
    setLoadingButton: (
      state,
      { payload }: PayloadAction<commonState["loadingBtn"]>
    ) => {
      state.loadingBtn = {
        isLoading: payload.isLoading,
        disabled: payload.disabled,
      };
    },

    setIsSuccess: (state, { payload }: PayloadAction<boolean>) => {
      state.isSuccess = payload;
    },

    setMemType: (state, { payload }: PayloadAction<commonState["memType"]>) => {
      state.memType = payload;
    },

    setSession: (state, { payload }: PayloadAction<boolean>) => {
      state.isLogin = payload;
    },
    setIsLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.isLoading = payload;
    },
    setOpenPhoneSelect: (state, { payload }: PayloadAction<boolean>) => {
      state.isOpenPhoneSelect = payload;
    },
    setAuthGrpCod: (state, { payload }: PayloadAction<optionType>) => {
      state.authGroupCod = payload;
    },
    setLodNo: (state, { payload }: PayloadAction<lodGrpNoType>) => {
      state.lodGrpNo = payload;
    },
    setItemCheck: (state, { payload }: PayloadAction<checkItem>) => {
      const seq = Number(payload.prvSeq) - 1;

      state.itemCheck[seq] = {
        ...state.itemCheck[seq],
        required: payload.required,
      };

      const all =
        state.itemCheck[0].required &&
        state.itemCheck[1].required &&
        state.itemCheck[2].required &&
        state.itemCheck[3].required;

      state.allCheck = all;
    },
    setAllCheck: (state, { payload }: PayloadAction<boolean>) => {
      state.allCheck = payload;
      if (payload) {
        state.itemCheck = [
          {
            prvGrpCd: "A01",
            prvSeq: "1",
            required: true,
            prvAgYn: "Y",
            essYn: "Y",
          },
          {
            prvGrpCd: "B01",
            prvSeq: "2",
            required: true,
            prvAgYn: "Y",
            essYn: "Y",
          },
          {
            prvGrpCd: "C01",
            prvSeq: "3",
            required: true,
            prvAgYn: "Y",
            essYn: "Y",
          },
          {
            prvGrpCd: "D01",
            prvSeq: "4",
            required: true,
            prvAgYn: "Y",
            essYn: "Y",
          },
        ];
      } else {
        state.itemCheck = [
          {
            prvGrpCd: "A01",
            prvSeq: "1",
            required: false,
            prvAgYn: "Y",
            essYn: "Y",
          },
          {
            prvGrpCd: "B01",
            prvSeq: "2",
            required: false,
            prvAgYn: "Y",
            essYn: "Y",
          },
          {
            prvGrpCd: "C01",
            prvSeq: "3",
            required: false,
            prvAgYn: "Y",
            essYn: "Y",
          },
          {
            prvGrpCd: "D01",
            prvSeq: "4",
            required: false,
            prvAgYn: "Y",
            essYn: "Y",
          },
        ];
      }
    },

    setLodGrpList: (
      state,
      { payload }: PayloadAction<commonState["lodGrpList"]>
    ) => {
      state.lodGrpList = payload;
    },
  },
});

export const isLoginSelector = createSelector(
  [(state: RootState) => state.common.isLogin],
  (session) => session
);

export const isLoadingSelector = createSelector(
  [(state: RootState) => state.common.isLoading],
  (isLoading) => isLoading
);

export const isSearchSelector = createSelector(
  [(state: RootState) => state.common.isSearch],
  (isSearch) => isSearch
);

export const isSelectSelector = createSelector(
  [(state: RootState) => state.common.isSelect],
  (isSelect) => isSelect
);

export const isSuccessSelector = createSelector(
  [(state: RootState) => state.common.isSuccess],
  (isSuccess) => isSuccess
);

export const selectLngSelector = createSelector(
  [(state: RootState) => state.common.selectLng],
  (selectLng) => selectLng
);

export const loadingBtnSelector = createSelector(
  [(state: RootState) => state.common.loadingBtn],
  (loadingBtn) => loadingBtn
);

export const memTypeSelector = createSelector(
  [(state: RootState) => state.common.memType],
  (memType) => memType
);

// export const pageSelector = createSelector(
//   [(state: RootState) => state.common.pageNo],
//   (page) => page
// );

export const messageSelector = createSelector(
  [(state: RootState) => state.common.notiInfo],
  (notiInfo) => notiInfo
);

export const userInfoSelector = createSelector(
  [(state: RootState) => state.common.userInfo],
  (userInfo) => userInfo
);

export const isOpenPhoneSelectSelector = createSelector(
  [(state: RootState) => state.common.isOpenPhoneSelect],
  (isOpenPhoneSelect) => isOpenPhoneSelect
);

export const authselectSelector = createSelector(
  [(state: RootState) => state.common.authGroupCod],
  (authGroupCod) => authGroupCod
);

export const lodGrpNoSelector = createSelector(
  [(state: RootState) => state.common.lodGrpNo],
  (lodGrpNo) => lodGrpNo
);

export const optionSelector = createSelector(
  [(state: RootState) => state.common.selectOption],
  (selectOption) => selectOption
);

export const itemCheckSelector = createSelector(
  [(state: RootState) => state.common.itemCheck],
  (itemCheck) => itemCheck
);
export const allCheckSelector = createSelector(
  [(state: RootState) => state.common.allCheck],
  (allCheck) => allCheck
);

export const lodGrpListSelector = createSelector(
  [(state: RootState) => state.common.lodGrpList],
  (lodGrpList) => lodGrpList
);

export const {
  setUserInfo,
  // setPage,
  setSelectLng,
  setMessage,
  setMemType,
  setIsSearch,
  setIsSelect,
  setIsSuccess,
  setLoadingButton,
  setSession,
  setIsLoading,
  // setOptions,
  setOpenPhoneSelect,
  setAuthGrpCod,
  setLodNo,
  setItemCheck,
  setAllCheck,
  setLodGrpList,
} = commonSlice.actions;

export default commonSlice.reducer;
