import SubHeader from "components/layout/header/subHeader";
import { IoIosArrowBack } from "react-icons/io";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { setMessage } from "store/commonSlice";
import AdInfCreateView from "./adInfCreate";
import ManCreateView from "./manCreate";
import BkCreateView from "./bkCreate";
import InfCreateView from "./infCreate";
import LodInfCreateView from "./lodCreate";
import LodImgView from "./imgCreate";
import { useRef } from "react";
import { setIsAdAble } from "store/lodSlice";

const LodCreateView = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const lodNo = useRef("");

  return (
    <div className="main-cnt">
      <SubHeader title="숙소 생성" dot>
        <button
          onClick={() => {
            dispatch(
              setMessage({
                message: "페이지에서 나가시겠습니까? ",
                isConfirm: true,
                isCancel: true,
                handleClick() {
                  navigate(-1);
                  dispatch(setIsAdAble(false));
                  dispatch(setMessage({ message: null }));
                },
              })
            );
          }}
          className="flex items-center text-sm p-2"
        >
          <IoIosArrowBack size={18} />
          <span>뒤로가기</span>
        </button>
      </SubHeader>
      <LodInfCreateView lodNo={lodNo} />
      <InfCreateView lodNo={lodNo} />
      <ManCreateView lodNo={lodNo} />
      <BkCreateView lodNo={lodNo} />
      <AdInfCreateView lodNo={lodNo} />
      <LodImgView lodNo={lodNo} />
    </div>
  );
};

export default LodCreateView;
