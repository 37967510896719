import { rmBkListHead } from "util/option";
import { useLocation, useNavigate } from "react-router-dom";
import Pagenation from "components/pagenation";
import Modal from "components/modal/modal";
import { Dispatch, SetStateAction, Suspense, useEffect, useState } from "react";
import { lodStatusApi } from "components/api/lod";
import { useMutation } from "@tanstack/react-query";
import { useDispatch } from "react-redux";
import {
  isLoadingSelector,
  isSearchSelector,
  setIsLoading,
  setIsSearch,
  setMessage,
} from "store/commonSlice";
import NotData from "../notData";
import { rmResvSearchProps } from "interface/room";
import { useSelector } from "react-redux";
import { pageNavProps } from "interface";
import { rmResvListApi } from "components/api/room";
import { dateFormat, inCom } from "util/common";

type IListProps = {
  searchInfo: rmResvSearchProps;
  memType: "admin" | "manager";
  setPageNav: Dispatch<SetStateAction<pageNavProps | null>>;
};

const List = ({ searchInfo, memType, setPageNav }: IListProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname, search, state } = useLocation();
  const isSearch = useSelector(isSearchSelector);

  const [list, setList] = useState([]);

  const rmResvMutation = useMutation(rmResvListApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code == 0) {
          setPageNav(body?.pageNav);
          setList(body?.rmResvList);
          dispatch(setIsLoading(false));
          dispatch(setIsSearch(false));
        }
        if (code === -1) {
          dispatch(setIsLoading(false));
          dispatch(setIsSearch(false));
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  let page = search.replace("?page=", "") || null;
  useEffect(() => {
    if (!!page) {
      dispatch(setIsSearch(true));
      dispatch(setIsLoading(true));
    }
  }, [page]);

  useEffect(() => {
    if (isSearch) {
      const params = {
        rmNo: searchInfo?.rmNo,
        rmNm: searchInfo?.rmNm,
        rmTypCd: searchInfo?.rmTypCd,
        rmOlkCd: searchInfo?.rmOlkCd,
        maxPerCtMin: Number(searchInfo?.maxPerCtMin) ?? -1,
        maxPerCtMax: Number(searchInfo?.maxPerCtMax) ?? -1,
        rmPdNo: searchInfo?.rmPdNo,
        lodChlCd: searchInfo?.lodChlCd,
        rmOpNm: searchInfo?.rmOpNm,
        promCd: searchInfo?.promCd,
        promNm: searchInfo?.promNm,
        rmResvNo: searchInfo?.rmResvNo,
        resvMemFstnm: searchInfo?.resvMemFstnm,
        resvMemMobNo: searchInfo?.resvMemMobNo,
        resvCstFstnm: searchInfo?.resvCstFstnm,
        resvCstMblNo: searchInfo?.resvCstMblNo,
        sellSDt: searchInfo?.sellSDt.replaceAll("-", ""),
        sellEDt: searchInfo?.sellEDt.replaceAll("-", ""),
        resvSDt: searchInfo?.resvSDt.replaceAll("-", ""),
        resvEDt: searchInfo?.resvEDt.replaceAll("-", ""),
        pageNo: Number(page),
      };

      rmResvMutation.mutate(params);
    }
  }, [searchInfo, search, isSearch]);

  const pageRows = rmResvMutation.data?.body?.pageNav?.pageRows;

  return (
    <>
      {list &&
        list?.map((li: any, index: number) => {
          return (
            <tr
              key={index}
              onClick={() => {
                navigate(`info?rmResvNo=${li?.rmResvNo}`, {
                  state: {
                    searchInfo: searchInfo,
                    rmNo: li.rmNo,
                    rmPdNo: li.rmPdNo,
                    rmResvNo: li.rmResvNo,
                    url: pathname + search,
                  },
                });
              }}
            >
              <td>{(Number(page) - 1) * (pageRows || 10) + index + 1}</td>
              {memType === "admin" && <td>{li.lodGrpNo}</td>}
              {memType === "admin" && <td>{li.lodGrpNm}</td>}
              {memType === "admin" && <td>{li.lodNo}</td>}
              {memType === "admin" && (
                <td>
                  <span className="w-[12.5rem]">{li.lodNm}</span>
                </td>
              )}
              {/* {memType === "admin" && <td>{li.lodGrdNm}</td>} */}
              <td>{li.rmNo}</td>
              <td>
                <span className="w-[7.5rem]">{li.rmNm}</span>
              </td>
              <td>
                <span className="w-[7.5rem]">{li.rmTypNm}</span>
              </td>
              <td>{li.rmOlkNm}</td>
              <td>{li.maxPerCt}</td>
              <td>{li.rmPdNo}</td>
              <td>{li.lodChlNm}</td>
              <td>{li.rmOpNm}</td>
              <td>{dateFormat(li.sellDt)}</td>
              <td>{dateFormat(li.rmPdResvDt)}</td>
              <td>{li.rmResvNo}</td>
              <td>{li.resvMemSurnm + li.resvMemFstnm}</td>
              <td>{li.resvMemMobNo}</td>
              <td>{li.resvCstSurnm + li.resvCstFstnm}</td>
              <td>{li.resvCstMblNo}</td>
              <td>{inCom(li.sellPri)}</td>
            </tr>
          );
        })}
    </>
  );
};

type IProps = {
  memType: "admin" | "manager";
  searchInfo: rmResvSearchProps;
};

const RoomBookList = ({ memType, searchInfo }: IProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isLoading = useSelector(isLoadingSelector);
  const [isOpen, setIsOpen] = useState(false);
  const [pageNav, setPageNav] = useState<pageNavProps | null>(null);

  const statusMutation = useMutation(lodStatusApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;

        if (code === 0) {
          setIsOpen(false);
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleStatus = () => {
    const params = {
      lodNo: "",
      uyn: "",
    };
    statusMutation.mutate(params);
  };

  const headList = rmBkListHead.map((head) => {
    return head;
  });
  const [No, lodGrpNo, lodGrpNm, lodNo, lodNm, ...res] = headList;

  return (
    <div className="data-list-wrap">
      <div className="data-cont">
        <div className="table-title">
          <h3>
            검색결과
            <span>
              총<b>{pageNav?.totalRows || 0}</b>개
            </span>
          </h3>
        </div>
        <div>
          <table>
            <thead>
              {memType === "admin" && (
                <tr>
                  {rmBkListHead.map((li) => (
                    <th key={li}>{li}</th>
                  ))}
                </tr>
              )}
              {memType === "manager" && (
                <tr>
                  {[No, ...res].map((li) => (
                    <th key={li}>{li}</th>
                  ))}
                </tr>
              )}
            </thead>

            <tbody>
              <Suspense>
                <List
                  searchInfo={searchInfo}
                  memType={memType}
                  setPageNav={setPageNav}
                />
              </Suspense>
            </tbody>
          </table>
        </div>
        <NotData
          isLoading={isLoading}
          isNotData={!pageNav?.totalRows}
          isSuccess={!!pageNav}
          styles=""
          txt="Loading ..."
        />
      </div>
      {pageNav && pageNav?.totalRows > 0 && (
        <Pagenation total={pageNav?.totalPage} />
      )}
      <Modal
        title="상태 변경"
        isOpen={isOpen}
        isLoading={statusMutation.isLoading}
        disabled={statusMutation.isLoading}
        handleCloseModal={() => setIsOpen(!isOpen)}
        onClick={handleStatus}
      >
        <p>
          해당 숙소 그룹을 <b>사용</b> 하시겠습니까?
        </p>
      </Modal>
    </div>
  );
};

export default RoomBookList;
