import { useMutation } from "@tanstack/react-query";
import { rmProductCreateApi } from "components/api/room";
import RmPdCreateView from "components/view/room/prd/create";
import { rmPrdCreateProps } from "interface/room";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setMessage } from "store/commonSlice";
import { setIsSuccess, setRmList, setRmOpList } from "store/roomSlice";

const RmPdCreatePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const memType = pathname.includes("admin") ? "admin" : "manager";

  let lodGrpInfo =
    memType === "manager"
      ? JSON.parse(localStorage.getItem("lodGrpInfo") + "")
      : "";

  const [info, setInfo] = useState<rmPrdCreateProps>({
    lodGrpNo: lodGrpInfo?.lodGrpNo || "",
    lodGrpNm: lodGrpInfo?.lodGrpNm || "",
    lodNo: localStorage.getItem("sltLodNo") ?? "",
    lodNm: lodGrpInfo?.lodNm,
    lodChlCd: "",
    rmNo: "",
    rmOpSeq: null,
    rmPdSDt: "",
    rmPdEDt: "",
    rmPdAplyDow: "NNNNNNN",
    rmPdCt: "",
    rmPdSellPri: "",
  });

  // const [pdAddInfo, setPdAddInfo] = useState<any>({});

  const handleChange = (target: EventTarget & HTMLInputElement) => {
    const { name, value, id, checked } = target;

    if (id === "date") {
      setInfo({
        ...info,
        [name]: value,
      });
      return;
    }

    if (name === "rmPdSellPri") {
      setInfo({
        ...info,
        [name]: value.replaceAll(",", ""),
      });
      return;
    }
    if (name === "rmPdCt") {
      setInfo({
        ...info,
        rmPdCt: value.length > 1 ? value.replace(/^[0]/, "") : value,
      });
      return;
    }
    if (name === "rmPdAplyDow") {
      if (id === "all") {
        setInfo({
          ...info,
          [name]: checked ? "YYYYYYY" : "NNNNNNN",
        });
        return;
      }

      const day = Array.from(info.rmPdAplyDow).map(
        (day: string, index: number) => {
          return id === index + "" ? (checked ? "Y" : "N") : day;
        }
      );

      setInfo({
        ...info,
        [name]: day.join().replaceAll(",", ""),
      });
      return;
    }

    setInfo({
      ...info,
      [name]: value,
    });
  };

  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { name, lng, value } = target.dataset;

    if (name && value) {
      setInfo({
        ...info,
        [name]: value === "A" ? "" : value,
      });
    }
  };

  const rmPrdMutation = useMutation(rmProductCreateApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(
            setMessage({
              message: "객실상품 생성이 완료되었습니다.",
              isConfirm: true,
              isCancel: true,
              cnfrmBtnText: "뒤로가기",
              canclBntText: "확인",
              handleClick() {
                dispatch(setMessage({ message: null }));
                navigate(-1);
              },
            })
          );
          setInfo({
            lodGrpNo: lodGrpInfo?.lodGrpNo || "",
            lodGrpNm: lodGrpInfo?.lodGrpNm || "",
            lodNo: localStorage.getItem("sltLodNo") ?? "",
            lodNm: lodGrpInfo?.lodNm,
            lodChlCd: "",
            rmNo: "",
            rmOpSeq: null,
            rmPdSDt: "",
            rmPdEDt: "",
            rmPdAplyDow: "NNNNNNN",
            rmPdCt: "",
            rmPdSellPri: "",
          });
          dispatch(setRmList([]));
          dispatch(setRmOpList([]));

          dispatch(setIsSuccess(true));
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
          dispatch(setIsSuccess(false));
        }
      }
    },
  });

  const handleCreate = () => {
    const { lodGrpNo, rmOpSeq, rmPdCt, rmPdSDt, rmPdEDt, ...res } = info;

    const params = {
      ...res,
      rmPdCt: Number(rmPdCt),
      rmOpSeq: Number(rmOpSeq),
      rmPdSDt: rmPdSDt.replaceAll("-", ""),
      rmPdEDt: rmPdEDt.replaceAll("-", ""),
    };

    dispatch(
      setMessage({
        message: "객실상품을 생성하시겠습니까?",
        isConfirm: true,
        isCancel: true,
        handleClick() {
          dispatch(setMessage({ message: null }));
          rmPrdMutation.mutate(params);
          window.location.reload();
        },
      })
    );
  };

  return (
    <>
      <RmPdCreateView
        info={info}
        memType={memType}
        isLoading={rmPrdMutation.isLoading}
        handleChange={handleChange}
        handleSelect={handleSelect}
        handleCreate={handleCreate}
      />
    </>
  );
};

export default RmPdCreatePage;
