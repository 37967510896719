import FormSearchBtn from "components/button/formSearch";
import SubHeader from "components/layout/header/subHeader";
import Select from "components/select";
import LodChlSelect from "components/select/room/lodChlSelect";
import LodGrpSelect from "components/select/room/lodGrpNoSelect";
import LodNoSelect from "components/select/room/lodNoSelect";
import RmNoSelect from "components/select/room/rmNoSlect";
import RmOlkSelect from "components/select/room/rmOlkSelect";
import RmTypeSelect from "components/select/room/rmTypSelect";
import RoomProductList from "components/tableList/room/product";
import { rmPdSearchProps } from "interface/room";
import { regNum } from "util/common";
import { rmPrdStatus } from "util/option";

type IProps = {
  memType: "admin" | "manager";
  searchInfo: rmPdSearchProps;
  handleChange(target: EventTarget & HTMLInputElement): void;
  handleSelect(target: EventTarget & HTMLLIElement): void;
  handleSearch(): void;
  handleReset(): void;
};

const RmPdListView = ({
  memType,
  searchInfo,
  handleChange,
  handleSelect,
  handleReset,
  handleSearch,
}: IProps) => {
  return (
    <>
      <div className="search-form">
        <SubHeader title="객실상품" add dot />

        <div className="table-form">
          <table>
            <tbody>
              {memType === "admin" && (
                <tr>
                  <th>숙소그룹번호</th>
                  <td>
                    <LodGrpSelect
                      option={searchInfo.lodGrpNo}
                      onSelect={(target) => handleSelect(target)}
                    />
                  </td>
                  <th>숙소그룹명</th>
                  <td>
                    <input
                      type="text"
                      name="lodGrpNm"
                      value={searchInfo?.lodGrpNm}
                      onChange={(e) => handleChange(e.currentTarget)}
                    />
                  </td>
                </tr>
              )}
              {memType === "admin" && (
                <tr>
                  <th>숙소번호</th>
                  <td>
                    <LodNoSelect
                      disabled={!searchInfo.lodGrpNo}
                      params={searchInfo.lodGrpNo}
                      option={searchInfo.lodNo}
                      onSelect={(target) => handleSelect(target)}
                    />
                  </td>
                  <th>숙소명</th>
                  <td>
                    <input
                      type="text"
                      name="lodNm"
                      value={searchInfo?.lodNm}
                      onChange={(e) => handleChange(e.currentTarget)}
                    />
                  </td>
                </tr>
              )}
              {/* {memType === "admin" && (
                <tr>
                  <th>숙소등급</th>
                  <td>
                    <Select
                      id="lodGrpNo"
                      name="lodGrpNo"
                      options={lodGrdCdOptions}
                      option={searchInfo.lodGrpNo}
                      onSelect={(target) => handleSelect(target)}
                    />
                  </td>
                </tr>
              )} */}
              <tr>
                <th>객실번호</th>
                <td>
                  <RmNoSelect
                    disabled={!searchInfo.lodNo}
                    params={searchInfo.lodNo}
                    option={searchInfo.rmNo}
                    onSelect={(target) => handleSelect(target)}
                  />
                </td>
                <th>객실명</th>
                <td>
                  <input
                    type="text"
                    name="rmNm"
                    value={searchInfo.rmNm}
                    onChange={(e) => handleChange(e.currentTarget)}
                  />
                </td>
              </tr>
              <tr>
                {/* <th>객실상품옵션명</th>
                <td>
                  <RmOpSelect
                    option={searchInfo.opRgSeq}
                    params={searchInfo.rmPdNo}
                    onSelect={function (
                      target: EventTarget & HTMLLIElement
                    ): void {
                      throw new Error("Function not implemented.");
                    }}
                  />
                </td> */}
                <th>객실상품채널</th>
                <td>
                  <LodChlSelect
                    option={searchInfo?.lodChlCd}
                    onSelect={(target) => handleSelect(target)}
                  />
                </td>
              </tr>
              <tr>
                <th>객실타입</th>
                <td>
                  <RmTypeSelect
                    // disabled={!searchInfo.rmNo}
                    option={searchInfo.rmTypCd}
                    onSelect={(target) => handleSelect(target)}
                  />
                </td>
                <th>객실전망</th>
                <td>
                  <RmOlkSelect
                    // disabled={!searchInfo.rmNo}
                    option={searchInfo.rmOlkCd}
                    onSelect={(target) => handleSelect(target)}
                  />
                </td>
              </tr>
              <tr>
                <th>객실 최대인원수 조회최소값</th>
                <td>
                  <input
                    type="text"
                    name="maxPerCtMax"
                    value={searchInfo.maxPerCtMax}
                    onChange={(e) => {
                      if (regNum.test(e.currentTarget.value)) {
                        handleChange(e.currentTarget);
                      } else {
                        return;
                      }
                    }}
                  />
                </td>
                <th>객실 최대인원수 조회최대값</th>
                <td>
                  <input
                    type="text"
                    name="maxPerCtMin"
                    value={searchInfo.maxPerCtMin}
                    onChange={(e) => {
                      if (regNum.test(e.currentTarget.value)) {
                        handleChange(e.currentTarget);
                      } else {
                        return;
                      }
                    }}
                  />
                </td>
              </tr>
              <tr>
                <th data-dot>조회시작일자</th>
                <td>
                  <input
                    type="date"
                    id="date"
                    max="2100-12-31"
                    name="sellSDt"
                    value={searchInfo?.sellSDt}
                    onChange={(e) => handleChange(e.currentTarget)}
                  />
                </td>
                <th data-dot>조회종료일자</th>
                <td>
                  <input
                    type="date"
                    id="date"
                    name="sellEDt"
                    value={searchInfo?.sellEDt}
                    onChange={(e) => handleChange(e.currentTarget)}
                  />
                </td>
              </tr>
              <tr>
                <th>상태</th>
                <td>
                  <Select
                    id={"sellYn"}
                    name={"sellYn"}
                    options={rmPrdStatus}
                    option={searchInfo.sellYn}
                    onSelect={(target) => handleSelect(target)}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <FormSearchBtn
            disabled={false}
            handleReset={handleReset}
            handleSearch={handleSearch}
          />
        </div>
      </div>
      <RoomProductList memType={memType} searchInfo={searchInfo} />
    </>
  );
};

export default RmPdListView;
