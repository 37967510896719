import { useMutation } from "@tanstack/react-query";
import { modifyImgApi } from "components/api/comn";
import imgUploadApi from "components/api/imgUpload";
import {
  rmAddCreateApi,
  rmCreateApi,
  rmImgUploadApi,
} from "components/api/room";
import RoomeCreateView from "components/view/room/rm/create";
import { use } from "i18next";
import { rmImgList, rmAdInfoProps, rmCreateProps } from "interface/room";
import { ChangeEvent, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setMessage } from "store/commonSlice";
import { setIsSuccess } from "store/roomSlice";

type imgUrlProps = {
  name: string;
  url: string | ArrayBuffer | null;
};

const RoomeCreatePage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const imgWrapRef = useRef<HTMLDivElement>(null);
  const memType = pathname.includes("admin") ? "admin" : "manager";
  let lodGrpInfo =
    memType === "manager"
      ? JSON.parse(localStorage.getItem("lodGrpInfo") + "")
      : "";

  const [rmInfo, setRmInfo] = useState<rmCreateProps>({
    lodGrpNo: lodGrpInfo?.lodGrpNo,
    lodGrpNm: lodGrpInfo?.lodGrpNm,
    lodNo: localStorage.getItem("sltLodNo") ?? "",
    lodNm: lodGrpInfo?.lodNm,
    rmTypCd: "",
    rmOlkCd: "",
    rmAreaDesc: "",
    bedTypCd: "",
    rmMinPerCt: null,
    rmMaxPerCt: null,
    babyFreePerCt: null,
    chldFreePerCt: null,
  });

  const [rmAddInfo, setRmAddInfo] = useState<any>({});

  const [imgs, setImgs] = useState<any>([]);
  const [imgUrls, setImgUrls] = useState<imgUrlProps[]>([]);
  const [imgIdx, setImgIdx] = useState(-1);

  const handleRmInfoChange = (target: EventTarget & HTMLInputElement) => {
    const { name, value } = target;
    if (name.includes("PerCt") || name === "rmAreaDesc") {
      setRmInfo({
        ...rmInfo,
        [name]: value.length > 1 ? value.replace(/^[0]/, "") : value,
      });
      return;
    }

    setRmInfo({
      ...rmInfo,
      [name]: value,
    });
  };

  const handleAddInfoChange = (name: string, value: string) => {
    const names = name.split("_");
    const langCd = names[1];

    setRmAddInfo({
      ...rmAddInfo,
      [langCd]: {
        ...rmAddInfo[langCd],
        langCd,
        [names[0]]: value,
      },
    });
  };

  const imgUploadMutation = useMutation(imgUploadApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;

        if (code === 0) {
          setImgs((prev: any) => [...prev, ...body.imgList]);
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  // 이미지 업로드
  const handleRmImgChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;

    if (files) {
      if (imgUrls?.length > 10 || imgUrls?.length + files?.length > 10) {
        dispatch(setMessage({ message: "최대 10까지 등록이 가능합니다." }));
        return;
      }

      let formData = new FormData();
      formData.append("imgTypCd", "I11");

      Array.from(files).forEach((file: File) => {
        if (file.size >= 1024000) {
          dispatch(setMessage({ message: "최대 업로드 사이즈는 1MB 입니다." }));
          return;
        }

        formData.append("uploadFiles", file);
        let reader = new FileReader();

        reader.onload = () => {
          if (reader.result) {
            const img = new Image();
            img.src = reader.result as string;

            img.onload = () => {
              // let width = img.width;
              // let height = img.height;

              // if (width <= 600 || height <= 390) {
              //   dispatch(
              //     setMessage({
              //       message: "이미지 업로드 가능 사이즈 650 * 400 ",
              //     })
              //   );

              //   return;
              // }

              setImgUrls((prevList) => [
                ...prevList,
                {
                  name: file.name,
                  url: reader.result,
                },
              ]);
            };
          }
        };
        reader.readAsDataURL(file);
      });

      imgUploadMutation.mutate({
        formData,
        lodNo: rmInfo.lodNo,
      });

      e.currentTarget.value = "";
    }
  };

  // 객실 이밎 수정
  const rmImgMutation = useMutation(modifyImgApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(setMessage({ message }));
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  // 이미지 수정
  const handleModifyImg = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      if (imgUrls?.length > 10 || imgUrls?.length + files?.length > 10) {
        dispatch(setMessage({ message: "최대 10까지 등록이 가능합니다." }));
        return;
      }

      let formData = new FormData();
      formData.append("imgTypCd", "I11");

      const file = files[0];

      if (file.size >= 1024000) {
        dispatch(setMessage({ message: "최대 업로드 사이즈는 5MB 입니다." }));
        return;
      }

      formData.append("uploadFiles", file);
      let reader = new FileReader();

      reader.onload = () => {
        if (reader.result) {
          const img = new Image();
          img.src = reader.result as string;

          img.onload = () => {
            let width = img.width;
            let height = img.height;

            // if (width <= 600 || height <= 390) {
            //   dispatch(
            //     setMessage({
            //       message: "이미지 업로드 가능 사이즈 650 * 400 ",
            //     })
            //   );

            //   return;
            // }

            const urls = imgUrls.map((img: imgUrlProps, idx: number) => {
              return imgIdx === idx
                ? { name: file.name, url: reader.result }
                : img;
            });

            setImgUrls(urls);
          };
        }
      };
      reader.readAsDataURL(file);

      imgUploadMutation.mutate({
        formData,
        lodNo: rmInfo.lodNo,
      });
    }

    e.currentTarget.value = "";
  };

  // 이미지 삭제
  const handleImgRemove = (index: number) => {
    const newList = imgUrls.filter((img, idx) => {
      return index !== idx;
    });
    setImgUrls(newList);
  };

  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { lng, name, value } = target.dataset;
    if (name && value) {
      if (name === "lodGrpNo") {
        setRmInfo({
          ...rmInfo,
          lodNo: "",
          rmTypCd: "",
          rmOlkCd: "",
          [name]: value === "A" ? "" : value,
        });
      } else {
        setRmInfo({
          ...rmInfo,
          [name]: value === "A" ? "" : value,
        });
      }
    }
  };

  // 객실이미지 등록
  const rmImgUploadMutation = useMutation(rmImgUploadApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;

        if (code === 0) {
          setImgUrls([]);
          setImgs([]);
        }
      }
    },
  });

  const rmAddCreateMutaion = useMutation(rmAddCreateApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          setRmInfo({
            lodGrpNo: lodGrpInfo?.lodGrpNo + "",
            lodGrpNm: lodGrpInfo?.lodGrpNm + "",
            lodNo: localStorage.getItem("sltLodNo") ?? "",
            lodNm: lodGrpInfo?.lodNm,
            rmTypCd: "",
            rmOlkCd: "",
            rmAreaDesc: "",
            bedTypCd: "",
            rmMinPerCt: 1,
            rmMaxPerCt: null,
            babyFreePerCt: 0,
            chldFreePerCt: 0,
          });
          setRmAddInfo({});
          dispatch(setIsSuccess(true));
          dispatch(
            setMessage({
              message: "객실이 성공적으로 생성 되었습니다.",
              isCancel: true,
              isConfirm: true,
              canclBntText: "확인",
              cnfrmBtnText: "뒤로가기",
              handleClick() {
                navigate(-1);
                dispatch(setMessage({ message: null }));
                dispatch(setIsSuccess(true));
              },
              handleCancel() {
                dispatch(setMessage({ message: null }));
                dispatch(setIsSuccess(true));
                window.location.reload();
              },
            })
          );
        }
      }
    },
  });

  //객실 생성
  const rmCreateMutation = useMutation(rmCreateApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          const list = Object.values(rmAddInfo).filter((info: any) => {
            return info.rmNm !== "" && info.rmDesc !== "";
          });
          const rmAddInfoParams: rmAdInfoProps = {
            rmNo: body?.rmNo,
            rmInfList: list,
          };

          rmAddCreateMutaion.mutate(rmAddInfoParams);

          let rmImgList = [] as rmImgList[];
          imgs.forEach((img: any) => {
            imgUrls.forEach((imgUrl: any) => {
              if (img?.resImgNm === imgUrl?.name) {
                rmImgList.push(img);
              }
            });
          });

          rmImgUploadMutation.mutate({
            rmNo: body?.rmNo,
            rmImgList,
          });
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleCreate = () => {
    dispatch(
      setMessage({
        message: "객실 생성하시겠습니까?",
        isCancel: true,
        isConfirm: true,
        handleClick() {
          dispatch(setMessage({ message: null }));
          rmCreateMutation.mutate(rmInfo);
        },
      })
    );
  };

  return (
    <RoomeCreateView
      rmInfo={rmInfo}
      memType={memType}
      rmAddInfo={rmAddInfo}
      isLoading={rmCreateMutation.isLoading && rmAddCreateMutaion.isLoading}
      imgWrapRef={imgWrapRef}
      imgUrls={imgUrls}
      setImgIdx={setImgIdx}
      handleRmInfoChange={handleRmInfoChange}
      handleAddInfoChange={handleAddInfoChange}
      handleRmImgChange={handleRmImgChange}
      handleModifyImg={handleModifyImg}
      handleImgRemove={handleImgRemove}
      handleSelect={handleSelect}
      handleCreate={handleCreate}
    />
  );
};

export default RoomeCreatePage;
