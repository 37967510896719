import { lodGrpHead } from "util/option";
import { IlodGrpList, lodGrpSearchProps } from "interface/lod";
import { useLocation, useNavigate } from "react-router-dom";
import Pagenation from "components/pagenation";
import Modal from "components/modal/modal";
import { Dispatch, SetStateAction, Suspense, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  isLoadingSelector,
  setIsLoading,
  setIsSearch,
  setMessage,
  isSearchSelector,
} from "store/commonSlice";
import NotData from "../notData";
import { useSelector } from "react-redux";
import { pageNavProps } from "interface";
import { useMutation } from "@tanstack/react-query";
import { lodGrpListApi, lodGrpStatusApi } from "components/api/lod";
import i18n from "locales/i18n";

type IProps = {
  memType: "admin" | "manager";
  searchInfo: Omit<lodGrpSearchProps, "pageNo" | "langCd">;
};

type IListProps = {
  searchInfo: Omit<lodGrpSearchProps, "pageNo" | "langCd">;
  memType: "admin" | "manager";
  setPageNav: Dispatch<SetStateAction<pageNavProps | null>>;
};

const List = ({ searchInfo, memType, setPageNav }: IListProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { search, state, pathname } = useLocation();
  const isSearch = useSelector(isSearchSelector);

  const [list, setList] = useState([]);

  const lodGrpMutation = useMutation(lodGrpListApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;

        dispatch(setIsLoading(false));
        dispatch(setIsSearch(false));

        if (code === 0) {
          setPageNav(body?.pageNav);
          setList(body?.lodGrpList);
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const statusMutation = useMutation(lodGrpStatusApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(
            setMessage({
              message: "숙소그룹 상태를 변경하였습니다,",
              isConfirm: true,
              handleClick() {
                dispatch(setIsSearch(true));
                dispatch(setMessage({ message: null }));
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleStatus = (lodGrpNo: string, uyn: string) => {
    dispatch(
      setMessage({
        message: "숙소그룹 상태를 변경하시겠습니까?",
        isConfirm: true,
        isCancel: true,
        handleClick() {
          statusMutation.mutate({
            lodGrpNo,
            uyn: uyn === "Y" ? "N" : "Y",
          });
        },
      })
    );
  };

  let page = search.replace("?page=", "") || null;
  useEffect(() => {
    if (!!page) {
      dispatch(setIsSearch(true));
      dispatch(setIsLoading(true));
    }
  }, [page]);

  useEffect(() => {
    if (isSearch) {
      const params = {
        lodGrpNo: searchInfo?.lodGrpNo || state?.lodGrpNo,
        lodGrpNm: searchInfo?.lodGrpNm || state?.lodGrpNm,
        langCd: i18n.language ?? "kr",
        lodGrpAddr1: searchInfo?.lodGrpAddr1 || state?.lodGrpAddr1,
        lodGrpAddr2: searchInfo?.lodGrpAddr2 || state?.lodGrpAddr2,
        lodGrpCntyCd: searchInfo?.lodGrpCntyCd || state?.lodGrpCntyCd,
        uyn: searchInfo?.uyn || state?.uyn,
        pageNo: page || state?.pageNo,
      };

      lodGrpMutation.mutate(params);
    }
  }, [isSearch, searchInfo, search]);

  const pageRows = lodGrpMutation.data?.body?.pageNav?.pageRows;

  return (
    <>
      {list &&
        list?.map((li: IlodGrpList, index: number) => {
          return (
            <tr
              key={index}
              onClick={(e) => {
                e.preventDefault();
                navigate(`info?lodGrpNo=${li.lodGrpNo}`, {
                  state: {
                    searchInfo,
                    url: pathname + search,
                  },
                });
                dispatch(setIsSearch(false));
              }}
            >
              <td>{(Number(page) - 1) * (pageRows || 10) + index + 1}</td>
              <td>
                <button>{li.lodGrpNo}</button>
              </td>
              <td>{li.lodGrpNm}</td>
              <td>{li.lodGrpCntyCd}</td>
              <td>{li.lodGrpAddr1 ?? "-"}</td>
              <td>{li.lodGrpAddr2 ?? "-"}</td>
              <td>
                <button
                  className={li.uyn === "Y" ? "" : "n-use"}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleStatus(li.lodGrpNo, li.uyn);
                  }}
                >
                  {li.uyn === "Y" ? "사용" : "미 사용"}
                </button>
              </td>
            </tr>
          );
        })}
    </>
  );
};

const LodGrpList = ({ memType, searchInfo }: IProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isLoading = useSelector(isLoadingSelector);

  const [pageNav, setPageNav] = useState<pageNavProps | null>(null);

  const headList = lodGrpHead.map((head) => {
    return head;
  });

  const [No, lodGrpNm, lodGrpCd, lodNm, ...res] = headList;

  return (
    <div className="data-list-wrap">
      <div className="data-cont">
        <div className="table-title">
          <h3>
            검색결과
            <span>
              총<b>{pageNav?.totalRows || 0}</b>개
            </span>
          </h3>
        </div>
        <div>
          <table>
            <thead>
              <tr>
                {headList.map((li) => (
                  <th key={li}>{li}</th>
                ))}
              </tr>
            </thead>

            <tbody>
              {
                <Suspense>
                  <List
                    searchInfo={searchInfo}
                    memType={memType}
                    setPageNav={setPageNav}
                  />
                </Suspense>
              }
            </tbody>
          </table>
        </div>
        <NotData
          isLoading={isLoading}
          isNotData={!pageNav?.totalRows}
          isSuccess={!!pageNav}
          styles=""
          txt="Loading ..."
        />
      </div>
      {/* <AgGrid rowData={rowData} columnDefs={columnDefs} pagination={true} /> */}
      {pageNav && pageNav?.totalRows > 0 && (
        <Pagenation total={pageNav?.totalPage} />
      )}
    </div>
  );
};

export default LodGrpList;
