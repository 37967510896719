import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { lodPayDtlApi, lodPayModify } from "components/api/lod";
import Select from "components/select";
import { lodPayProps } from "interface/lod";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setMessage } from "store/commonSlice";
import { dateFormat } from "util/common";
import { bankOptions, moneyOptions } from "util/option";

type IProps = {
  bkDtl: lodPayProps;
};

const PayModifyView = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey: ["lodPayInf"],
    queryFn: () => {
      return lodPayDtlApi({
        lodNo: search.replace("?lodNo=", ""),
      });
    },
  });
  const payDtl = query?.data?.body?.lodPayDtl || {};

  const [inf, setInf] = useState<lodPayProps>({
    lodNo: search.replace("?lodNo=", ""),
    rgSeq: 0,
    uyn: "Y",
    calcInDt: "",
    calcInBnkNm: "",
    calcInBnkCd: "",
    calcAcntNo: "",
    calcCurrCd: "",
    aplySDt: "",
    aplyEDt: "",
  });
  const handleChange = (name: string, value: string) => {
    setInf({
      ...inf,
      [name]: value,
    });
  };
  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { name, value, lng } = target.dataset;
    if (name && value) {
      if (name === "calcInBnkCd") {
        setInf({
          ...inf,
          calcInBnkCd: value,
          calcInBnkNm: lng ?? "",
        });
        return;
      }

      setInf({
        ...inf,
        [name]: value === "A" ? "" : value,
      });
    }
  };

  const payMutation = useMutation(lodPayModify, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(
            setMessage({
              message: "결제정보를 수정하였습니다.",
              isConfirm: true,
              handleClick() {
                queryClient.invalidateQueries(["lodPayInf"]);
                dispatch(setMessage({ message: null }));
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });
  const handleModify = () => {
    const params = {
      ...inf,
      aplySDt: inf.aplySDt.replaceAll("-", ""),
      aplyEDt: inf.aplyEDt.replaceAll("-", ""),
    };
    dispatch(
      setMessage({
        message: "결제정보를 수정하시겠습니까?",
        isCancel: true,
        isConfirm: true,
        handleClick() {
          payMutation.mutate(params);
        },
      })
    );
  };

  return (
    <>
      <div className="table-form" data-dir-col>
        <div className="sb-title">
          <h4>결제정보</h4>
          <button className="btn-mdfy" onClick={handleModify}>
            수정
          </button>
        </div>
        <fieldset>
          <table>
            <tbody>
              <tr>
                <th>숙소정산입금일</th>
                <td>
                  <input
                    type="text"
                    name="calcInDt"
                    defaultValue={inf.calcInDt || payDtl?.calcInDt}
                    onChange={(e) =>
                      handleChange("calcInDt", e.currentTarget.value)
                    }
                  />
                </td>
                <th>숙소정산은행</th>
                <td>
                  <Select
                    id="bnk"
                    name="calcInBnkCd"
                    option={inf.calcInBnkCd || payDtl?.calcInBnkCd}
                    options={bankOptions}
                    onSelect={handleSelect}
                  />
                </td>
              </tr>
              <tr>
                <th>숙소정산은행계좌번호</th>
                <td>
                  <input
                    type="text"
                    name="calcAcntNo"
                    defaultValue={inf.calcAcntNo || payDtl?.calcAcntNo}
                    onChange={(e) =>
                      handleChange("calcAcntNo", e.currentTarget.value)
                    }
                  />
                </td>
                <th>숙소정산통화</th>
                <td>
                  <Select
                    id={""}
                    name={"calcCurrCd"}
                    option={inf.calcCurrCd || payDtl?.calcCurrCd}
                    options={moneyOptions}
                    onSelect={handleSelect}
                  />
                </td>
              </tr>
              <tr>
                <th>적용시작일</th>
                <td>
                  <input
                    type="date"
                    defaultValue={inf.aplySDt || dateFormat(payDtl?.aplySDt)}
                    onChange={(e) => {
                      handleChange("aplySDt", e.currentTarget.value);
                    }}
                  />
                </td>
                <th>적용종료일</th>
                <td>
                  <input
                    type="date"
                    defaultValue={inf.aplyEDt || dateFormat(payDtl?.aplyEDt)}
                    onChange={(e) =>
                      handleChange("aplyEDt", e.currentTarget.value)
                    }
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </fieldset>
      </div>
    </>
  );
};

export default PayModifyView;
