import { Fragment, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { setSelectLng } from "store/commonSlice";
import { useSelector } from "react-redux";
import { isSuccessSelector, setIsSuccess } from "store/roomSlice";
import "./radio.scss";
import i18n from "locales/i18n";
import { lngLabel } from "util/option";

export type langProps = {
  kr: string;
  us: string;
};

type IOptions = { id: string; label: string };

type IProps = {
  name: string;
  contStyle?: string;
  placeholder?: string;
  rows?: number;
  styles?: string;
  inpDisabled?: boolean;
  rdoDisabled?: boolean;
  readOnly?: boolean;
  isApi?: boolean;
  info?:
    | {
        langCd: string;
        kr: string;
        us: string;
      }
    | any;
  onChange(
    target: EventTarget & (HTMLInputElement | HTMLTextAreaElement)
  ): void;
};

const RadioContent = ({
  name,
  placeholder,
  styles = "",
  contStyle = "h-[2.75rem]",
  rows = 1,
  inpDisabled = false,
  rdoDisabled = false,
  readOnly = false,
  info = null,
  onChange,
}: IProps) => {
  const dispatch = useDispatch();
  const isSuccess = useSelector(isSuccessSelector);

  const txtRef = useRef<HTMLTextAreaElement>(null);
  const [lng, setLng] = useState(i18n.language);
  const [txt, setTxt] = useState<any>({});

  const options = [] as IOptions[];

  lngLabel.forEach((lng) => {
    if (info) {
      const labels = info[`${name}_${lng?.id}`];
      if (!!labels) {
        options.push(lng);
      }
    }
    return;
  });

  const handleChange = (target: EventTarget & HTMLTextAreaElement) => {
    const { name, value, id } = target;

    setTxt({
      ...txt,
      [name]: value,
    });
  };

  useEffect(() => {
    if (txtRef.current) {
      txtRef.current.style.height = txtRef.current.scrollHeight + "px";
    }
  }, [txtRef.current, txt]);

  useEffect(() => {
    if (isSuccess) {
      setTxt({});
      if (txtRef.current) {
        txtRef.current.innerText = "";
      }
    }

    return () => {
      dispatch(setIsSuccess(false));
    };
  }, [isSuccess]);

  // useEffect(() => {
  //   if (!!info.langCd) {
  //     setLng(info.langCd);
  //   }
  // }, []);

  useEffect(() => {
    if (info && info[`${name}_${lng}`]) {
      const { langCd, ...res } = info;
      setTxt({ ...res });
    }
  }, [info]);

  return (
    <div className={`${styles} radio-content`}>
      {options.map((op) => {
        return (
          <Fragment key={op.id}>
            <input
              type="radio"
              id={name + "_" + op.id}
              name={name}
              value={op.id}
              onChange={(e) => {
                const value = e.currentTarget.value;
                dispatch(setSelectLng(value ?? ""));
                setLng(value ?? "");
                if (txtRef.current) {
                  txtRef.current.value = txt[`${name}_${value}`] ?? "";
                }

                // if (txtRef.current) {
                //   txtRef.current.focus();
                //   if (info?.us) {
                //     setTxt({
                //       [`${name}_${lng}`]: info[lng as keyof langProps],
                //     });
                //   }
                // }
              }}
              disabled={rdoDisabled}
              defaultChecked={lng === op.id}
            />
            <label htmlFor={name + "_" + op.id} data-label={op.label}></label>
          </Fragment>
        );
      })}

      <textarea
        id={name}
        name={`${name}_${lng}`}
        ref={txtRef}
        className={`${contStyle}`}
        placeholder={placeholder}
        disabled={inpDisabled}
        readOnly={readOnly}
        value={txt[`${name}_${lng}`]}
        onChange={(e) => {
          onChange(e.currentTarget);
          handleChange(e.currentTarget);
        }}
      />
    </div>
  );
};

export default RadioContent;
