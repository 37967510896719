import { lodDefaultHead } from "util/option";
import { IlodList, lodPrdSearchProps } from "interface/lod";
import { useLocation, useNavigate } from "react-router-dom";
import Pagenation from "components/pagenation";
import { Dispatch, SetStateAction, Suspense, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  isLoadingSelector,
  setIsLoading,
  setIsSearch,
  setMessage,
} from "store/commonSlice";
import NotData from "../notData";
import { useSelector } from "react-redux";
import { isSearchSelector } from "store/commonSlice";
import { pageNavProps } from "interface";
import { useMutation } from "@tanstack/react-query";
import { lodDftListApi } from "components/api/lod";
import i18n from "locales/i18n";

type IProps = {
  searchInfo: lodPrdSearchProps;
  memType: "admin" | "manager";
};

type IListProps = {
  searchInfo: lodPrdSearchProps;
  memType: "admin" | "manager";
  setPageNav: Dispatch<SetStateAction<pageNavProps | null>>;
};

const List = ({ searchInfo, memType, setPageNav }: IListProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname, search, state } = useLocation();

  const isSearch = useSelector(isSearchSelector);

  const [list, setList] = useState([]);

  const prdListMutation = useMutation(lodDftListApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        dispatch(setIsLoading(false));
        dispatch(setIsSearch(false));
        if (code === 0) {
          setPageNav(body?.pageNav);
          setList(body?.lodDftList);
        }
        if (code === -1) {
          dispatch(
            setMessage({
              message,
              isConfirm: true,
              handleClick() {
                dispatch(setMessage({ message: null }));
              },
            })
          );
        }
      }
    },
  });

  let page = search.replace("?page=", "") || null;
  useEffect(() => {
    if (!!page) {
      dispatch(setIsSearch(true));
      dispatch(setIsLoading(true));
    }
  }, [page]);

  useEffect(() => {
    if (isSearch) {
      const params = {
        lodGrpNo: searchInfo.lodGrpNo || state?.lodGrpNo,
        lodGrpNm: searchInfo.lodGrpNm || state?.lodGrpNm,
        lodNo: searchInfo.lodNo || state?.lodNo,
        lodNm: searchInfo.lodNm || state?.lodNm,
        lodGrdCd: searchInfo.lodGrdCd || state?.lodGrdCd,
        uyn: searchInfo?.uyn || state?.uyn,
        pageNo: page || state?.pageNo,
        langCd: i18n.language,
      };

      prdListMutation.mutate(params);
    }
  }, [isSearch, searchInfo, search]);

  const pageRows = prdListMutation.data?.body?.pageNav?.pageRows;

  return (
    <>
      {list &&
        list?.map((li: IlodList, index: number) => {
          return (
            <tr
              key={li.lodNo}
              onClick={(e) => {
                e.stopPropagation();
                navigate(`info?lodNo=${li.lodNo}`, {
                  state: {
                    searchInfo,
                    url: pathname + search,
                  },
                });
              }}
            >
              <td>{(Number(page) - 1) * (pageRows || 10) + index + 1}</td>
              <td>{li.lodGrpNo}</td>
              <td>{li.lodGrpNm}</td>
              <td>{li.lodNo}</td>
              <td>{li.lodNm}</td>
              <td>{li.lodGrdCd}</td>
              <td>
                <span className={li.uyn === "Y" ? "uyn" : ""}>
                  {li.uyn === "Y" ? "사용" : "미사용"}
                </span>
              </td>
            </tr>
          );
        })}
    </>
  );
};

const LodPrdList = ({ memType, searchInfo }: IProps) => {
  const dispatch = useDispatch();
  const isLoading = useSelector(isLoadingSelector);

  const [pageNav, setPageNav] = useState<pageNavProps | null>(null);

  const headList = lodDefaultHead.map((head) => {
    return head;
  });

  return (
    <div className="data-list-wrap">
      <div className="data-cont">
        <div className="table-title">
          <h3>
            검색결과
            <span>
              총<b>{pageNav?.totalRows || 0}</b>개
            </span>
          </h3>
        </div>
        <div>
          <table>
            <thead>
              <tr>
                {headList.map((li) => (
                  <th key={li}>{li}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              <Suspense>
                <List
                  searchInfo={searchInfo}
                  setPageNav={setPageNav}
                  memType={memType}
                />
              </Suspense>
            </tbody>
          </table>
        </div>
        <NotData
          isLoading={isLoading}
          isNotData={!pageNav?.totalRows}
          isSuccess={!!pageNav}
          styles=""
          txt="Loading ..."
        />
      </div>
      {pageNav && pageNav?.totalRows > 0 && (
        <Pagenation total={pageNav?.totalPage} />
      )}
    </div>
  );
};

export default LodPrdList;
