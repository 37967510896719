import { useMutation } from "@tanstack/react-query";
import { lodPayCreateApi, lodPayDtlApi } from "components/api/lod";
import Select from "components/select";
import { lodPayProps } from "interface/lod";
import { MutableRefObject, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setMessage } from "store/commonSlice";
import { isAbleSelector } from "store/lodSlice";
import { bankOptions, moneyOptions } from "util/option";

type IProps = {
  lodNo: MutableRefObject<string>;
};

const BkCreateView = ({ lodNo }: IProps) => {
  const dispatch = useDispatch();
  const isAdAble = useSelector(isAbleSelector);

  const dtlMutation = useMutation(lodPayDtlApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
        }
        if (code === -1) {
        }
      }
    },
  });

  const dtl = dtlMutation?.data?.body?.lodPayDtl;

  const [inf, setInf] = useState<lodPayProps>({
    rgSeq: 1,
    uyn: "Y",
    calcInDt: "",
    calcInBnkNm: "",
    calcInBnkCd: "",
    calcAcntNo: "",
    calcCurrCd: "KRW",
    aplySDt: "",
    aplyEDt: "",
  });

  const [isIn, setIsIn] = useState(false);
  const [isRegi, setIsRegi] = useState(false);

  const handleChange = (name: string, value: string) => {
    setInf({
      ...inf,
      [name]: value,
    });
  };
  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { name, value, lng } = target.dataset;
    if (name && value) {
      if (name === "calcInBnkCd") {
        setInf({
          ...inf,
          calcInBnkCd: value,
          calcInBnkNm: lng ?? "",
        });
        return;
      }

      setInf({
        ...inf,
        [name]: value === "A" ? "" : value,
      });
    }
  };

  const bkMutation = useMutation(lodPayCreateApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(
            setMessage({
              message: "결제정보를 등록하였습니다.",
              isConfirm: true,
              handleClick() {
                dispatch(setMessage({ message: null }));
                setIsRegi(true);
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });
  const handleCreate = () => {
    if (!inf.calcCurrCd) {
      return alert("은행을 선택하여 주세요.");
    }

    if (!inf.calcInDt) {
      return alert("정산입금일을 입력하여 주세요.");
    }

    const params = {
      ...inf,
      lodNo: lodNo.current ?? "",
      aplySDt: inf.aplySDt.replaceAll("-", ""),
      aplyEDt: inf.aplyEDt.replaceAll("-", ""),
    };
    dispatch(
      setMessage({
        message: "결제정보를 등록하시겠습니까?",
        isCancel: true,
        isConfirm: true,
        handleClick() {
          bkMutation.mutate(params);
        },
      })
    );
  };

  useEffect(() => {
    if (isRegi) {
      dtlMutation.mutate({
        lodNo: lodNo.current,
      });
    }
  }, [isRegi]);

  const isAble = isAdAble ? isRegi : !isAdAble;

  return (
    <>
      <div className="table-form" data-dir-col>
        <div className="sb-title">
          <h4
            data-inf="기본정보 생성후 추가 정보 등록이 가능합니다."
            onClick={() => setIsIn(!isIn)}
          >
            결제정보
          </h4>
          <button
            disabled={isAble}
            className={"btn-mdfy"}
            onClick={handleCreate}
          >
            등록
          </button>
        </div>
        <fieldset className={isIn ? "" : "hidden"}>
          <table>
            <tbody>
              <tr>
                <th data-dot>숙소정산입금일</th>
                <td>
                  <input
                    type="text"
                    name="calcInDt"
                    value={inf.calcInDt}
                    readOnly={isAble}
                    onChange={(e) =>
                      handleChange("calcInDt", e.currentTarget.value)
                    }
                  />
                </td>
                <th data-dot>숙소정산은행</th>
                <td>
                  <Select
                    id="bnk"
                    name="calcInBnkCd"
                    option={inf.calcInBnkCd}
                    options={bankOptions}
                    disabled={isAble}
                    onSelect={handleSelect}
                  />
                </td>
              </tr>
              <tr>
                <th>숙소정산은행계좌번호</th>
                <td>
                  <input
                    type="text"
                    name="calcAcntNo"
                    value={inf.calcAcntNo}
                    readOnly={isAble}
                    onChange={(e) =>
                      handleChange("calcAcntNo", e.currentTarget.value)
                    }
                  />
                </td>
                <th>숙소정산통화</th>
                <td>
                  <Select
                    id={""}
                    name={"calcCurrCd"}
                    option={inf.calcCurrCd}
                    options={moneyOptions}
                    disabled={isAble}
                    onSelect={handleSelect}
                  />
                </td>
              </tr>
              <tr>
                <th>적용시작일</th>
                <td>
                  <input
                    type="date"
                    value={inf.aplySDt}
                    readOnly={isAble}
                    onChange={(e) => {
                      handleChange("aplySDt", e.currentTarget.value);
                    }}
                  />
                </td>
                <th>적용종료일</th>
                <td>
                  <input
                    type="date"
                    value={inf.aplyEDt}
                    readOnly={isAble}
                    onChange={(e) =>
                      handleChange("aplyEDt", e.currentTarget.value)
                    }
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </fieldset>
      </div>
    </>
  );
};

export default BkCreateView;
