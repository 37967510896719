import FormSearchBtn from "components/button/formSearch";
import SubHeader from "components/layout/header/subHeader";
import Select from "components/select";
import CalculateList from "components/tableList/calculate";
import { clcPromProps } from "interface/calcutate";
import { uYnOptions } from "util/option";

type IProps = {
  searchInfo: clcPromProps;
  handleChange(name: string, value: string): void;
  handleSelect(target: EventTarget & HTMLLIElement): void;
  handleReset(): void;
  handleSearch(): void;
};

const CalculatePromView = ({
  searchInfo,
  handleChange,
  handleReset,
  handleSearch,
  handleSelect,
}: IProps) => {
  return (
    <>
      <div className="search-form">
        <SubHeader title="정산관리" />
        <div className="table-form">
          <table>
            <tbody>
              <tr>
                <th>프로모션 코드</th>
                <td></td>
              </tr>
              <tr>
                <th>객실 예약번호</th>
                <td>
                  <input
                    type="text"
                    value={searchInfo?.rmResvNo}
                    onChange={(e) =>
                      handleChange("rmResvNo", e.currentTarget.value)
                    }
                  />
                </td>
                <th>예약취소 여부</th>
                <td>
                  <Select
                    id={"uyn"}
                    name={"uyn"}
                    options={uYnOptions}
                    onSelect={(target) => handleSelect(target)}
                  />
                </td>
              </tr>
              <tr>
                <th>참여검색시작일</th>
                <td>
                  <input
                    type="date"
                    value={searchInfo?.sDt}
                    onChange={(e) => handleChange("sDt", e.currentTarget.value)}
                  />
                </td>
                <th>참여검색종료일</th>
                <td>
                  <input
                    type="date"
                    value={searchInfo?.eDt}
                    onChange={(e) => handleChange("eDt", e.currentTarget.value)}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <FormSearchBtn
            handleReset={handleReset}
            handleSearch={handleSearch}
          />
        </div>
      </div>
      <CalculateList searchInfo={searchInfo} />
    </>
  );
};

export default CalculatePromView;
