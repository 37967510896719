import LodRfdModifyView from "components/view/lod/rfd/modify/default";

const LodRfdModifyPage = () => {
  return (
    <>
      <LodRfdModifyView />
    </>
  );
};

export default LodRfdModifyPage;
