import SubHeader from "components/layout/header/subHeader";
import RadioEditor from "components/radio/radioEditor";
import RadioContent from "components/radio/radiocontent";
import BedTypeSelect from "components/select/room/bedTypSelect";
import RmOlkSelect from "components/select/room/rmOlkSelect";
import RmTypeSelect from "components/select/room/rmTypSelect";
import { useLocation } from "react-router-dom";
import { langOptions, lngLabel, uYnOptions } from "util/option";
import { BsPencilSquare } from "react-icons/bs";
import { CgCloseR } from "react-icons/cg";
// import { IoIosCheckboxOutline } from "react-icons/io";
import i18n from "locales/i18n";
import { ChangeEvent, Dispatch, SetStateAction } from "react";
import { IRmInfo } from "interface/room";
import "./room.scss";
import Select from "components/select";
import { setMessage } from "store/commonSlice";
import Modal from "components/modal/modal";
import { useDispatch } from "react-redux";

type IProps = {
  memType: string;
  rmDtl: IRmInfo;
  rmAddInfo: any;
  imgUrls: any[];
  rmAddInfList: any[];
  info: any;
  isOpen: boolean;
  isLoading: boolean;
  rmAddLngInf: any;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  setImgIdx: Dispatch<SetStateAction<number>>;
  handleImgModify(e: ChangeEvent<HTMLInputElement>): void;
  handleImgUpload(e: ChangeEvent<HTMLInputElement>): void;
  handleSelect(target: EventTarget & HTMLLIElement): void;
  handleChange(target: EventTarget & HTMLInputElement): void;
  handleAddInfoChange(name: string, value: string): void;
  handleAddLngInfChange(name: string, value: string): void;
  handleAddLngSelect(target: EventTarget & HTMLLIElement): void;
  handleImgChange(e: Event & HTMLInputElement): void;
  handleModifyInfo(type: string): void;
  handleImgRemove({ rgSeq, uyn }: { rgSeq: number; uyn: "N" | "Y" }): void;
  handleAddInf(): void;
  handleAddInfClose(): void;
};

const RoomModifyView = ({
  memType,
  rmDtl,
  imgUrls,
  rmAddInfList,
  info,
  rmAddInfo,
  isOpen,
  isLoading,
  rmAddLngInf,
  setIsOpen,
  setImgIdx,
  handleImgModify,
  handleChange,
  handleSelect,
  handleAddInfoChange,
  handleModifyInfo,
  handleImgUpload,
  handleImgRemove,
  handleAddLngSelect,
  handleAddLngInfChange,
  handleAddInf,
  handleAddInfClose,
}: IProps) => {
  // const navigate = useNavigate();
  const dispatch = useDispatch();
  const { state } = useLocation();

  let rmNmInfo = {} as any;
  let rmDescInfo = {} as any;
  let labels = [] as any[];
  let newLngOptions = [] as any[];

  rmAddInfList.forEach((inf) => {
    rmNmInfo = {
      ...rmNmInfo,
      langCd: i18n.language,
      [`rmNm_${inf.langCd}`]: inf.rmNm,
    };

    rmDescInfo = {
      ...rmDescInfo,
      langCd: i18n.language,
      [`rmDesc_${inf.langCd}`]: inf.rmDesc,
    };

    newLngOptions = langOptions.map((lang) => {
      return lang.value === inf.langCd
        ? {
            ...lang,
            events: "none",
          }
        : lang;
    });
  });

  lngLabel.forEach((lang) => {
    rmAddInfList.forEach((inf) => {
      let langCd = lang.id === inf.langCd ? lang : "";
      if (!!langCd) {
        labels.push(langCd);
      }
    });
  });

  const isAbleDefault = Object.keys(info).some((inf) => {
    return (
      !!info[inf as string] &&
      info[inf as string] !== rmDtl[inf as keyof IRmInfo]
    );
  });

  const isAbleRmNm =
    !!rmAddInfo?.rmNm &&
    rmAddInfo?.rmNm !== rmNmInfo[`rmNm_${rmAddInfo?.langCd}` as string];

  const isAbleRmDesct =
    !!rmAddInfo?.rmDesc &&
    rmAddInfo?.rmDesc !== rmDescInfo[`rmDesc_${rmAddInfo?.langCd}` as string];

  const isAddAble = !(
    (rmAddLngInf.langCd !== "all" && rmAddLngInf.langCd && rmAddLngInf.rmNm) ||
    (rmAddLngInf.rmDesc && rmAddLngInf.rmDesc !== "<p><br></p>")
  );

  return (
    <div className="main-cnt">
      <SubHeader
        title={"객실 수정"}
        url={state.url}
        state={state.searchInfo}
        back
      />

      <div className="table-form" data-dir-col>
        <div className="sb-title relative">
          <h4>객실 기본 정보</h4>
          <button
            className="btn-mdfy"
            // disabled={!isAbleDefault}
            onClick={() => handleModifyInfo("default")}
          >
            수정
          </button>
        </div>
        <table>
          <tbody>
            <tr>
              <th>속소그룹명</th>
              <td>
                <input type="text" value={rmDtl?.lodGrpNm} readOnly />
              </td>
              <th>숙소명</th>
              <td>
                <input type="text" value={rmDtl?.lodNm} readOnly />
              </td>
            </tr>

            <tr>
              <th>객실타입</th>
              <td>
                <RmTypeSelect
                  // disabled={!rmInfo.lodNo}
                  option={info.rmTypCd || rmDtl?.rmTypCd}
                  onSelect={(target) => handleSelect(target)}
                />
              </td>
              <th>객실전망</th>
              <td>
                <RmOlkSelect
                  // disabled={!rmInfo.lodNo}
                  option={info.rmOlkCd || rmDtl?.rmOlkCd}
                  onSelect={(target) => handleSelect(target)}
                />
              </td>
            </tr>
            <tr>
              <th>객실크기</th>
              <td>
                <input
                  type="text"
                  name="rmAreaDesc"
                  defaultValue={info.rmAreaDesc ?? rmDtl?.rmAreaDesc}
                  onChange={(e) => {
                    const isNum = !/[^0-9~]/.test(e.currentTarget.value);
                    if (isNum) {
                      handleChange(e.currentTarget);
                    } else {
                      return;
                    }
                  }}
                />
              </td>
              <th>객실침대종류</th>
              <td>
                <BedTypeSelect
                  option={info.bedTypCd || rmDtl.bedTypCd}
                  onSelect={(target) => handleSelect(target)}
                />
              </td>
            </tr>
            <tr>
              <th>객실 최소인원수</th>
              <td>
                <input
                  type="text"
                  name="rmMinPerCt"
                  defaultValue={info.rmMinPerCt ?? rmDtl?.minPerCt}
                  onChange={(e) => {
                    const isNum = !/[^0-9]/.test(e.currentTarget.value);
                    if (isNum || !e.currentTarget.value) {
                      handleChange(e.currentTarget);
                    } else {
                      return;
                    }
                  }}
                />
              </td>
              <th>객실 최대인원수</th>
              <td>
                <input
                  type="text"
                  name="rmMaxPerCt"
                  defaultValue={info.rmMaxPerCt ?? rmDtl?.maxPerCt}
                  onChange={(e) => {
                    const isNum = !/[^0-9]/.test(e.currentTarget.value);
                    if (isNum || !e.currentTarget.value) {
                      handleChange(e.currentTarget);
                    } else {
                      return;
                    }
                  }}
                />
              </td>
            </tr>
            <tr>
              <th>객실유아 무료인원수</th>
              <td>
                <input
                  type="text"
                  name="babyFreePerCt"
                  defaultValue={info.babyFreePerCt ?? rmDtl?.babyFreePerCt}
                  onChange={(e) => {
                    const isNum = !/[^0-9]/.test(e.currentTarget.value);
                    if (isNum || !e.currentTarget.value) {
                      handleChange(e.currentTarget);
                    } else {
                      return;
                    }
                  }}
                />
              </td>
              <th>객실아동 무료인원수</th>
              <td>
                <input
                  type="text"
                  name="chldFreePerCt"
                  defaultValue={info.chldFreePerCt ?? rmDtl?.chldFreePerCt}
                  onChange={(e) => {
                    const isNum = !/[^0-9]/.test(e.currentTarget.value);
                    if (isNum || !e.currentTarget.value) {
                      handleChange(e.currentTarget);
                    } else {
                      return;
                    }
                  }}
                />
              </td>
            </tr>
            <tr>
              <th>상태</th>
              <td>
                <Select
                  id={"uyn"}
                  name={"uyn"}
                  option={info?.uyn || rmDtl?.uyn}
                  options={uYnOptions}
                  onSelect={(target) => handleSelect(target)}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className="table-form" data-dir-col>
        <div className="sb-title relative">
          <h4 data-inf="* 언어별 수정 가능">객실 추가 정보</h4>
          {langOptions.length > labels.length && (
            <button
              className="btn-mdfy "
              onClick={() => {
                dispatch(
                  setMessage({
                    message: "객실추가정보를 추가 하시겠습니까?",
                    isConfirm: true,
                    isCancel: true,
                    handleClick() {
                      setIsOpen(true);
                      dispatch(setMessage({ message: null }));
                    },
                  })
                );
              }}
            >
              언어추가
            </button>
          )}
        </div>
        <table>
          <tbody>
            <tr>
              <th>객실명</th>
              <td colSpan={3} className="relative full">
                <RadioContent
                  options={labels}
                  name={"rmNm"}
                  info={rmNmInfo}
                  onChange={(target) => {
                    handleAddInfoChange(target.name, target.value);
                  }}
                />
                <button
                  className="btn-mdfy absolute top-2 right-0"
                  // data-inf="* 언어별로 수정 가능"
                  // disabled={!isAbleRmNm}
                  onClick={() => handleModifyInfo("rmNm")}
                >
                  수정
                </button>
              </td>
            </tr>
            <tr>
              <th>객실설명</th>
              <td colSpan={1} className="relative full">
                {Object.values(rmDescInfo).length !== 0 && (
                  <RadioEditor
                    options={labels}
                    name={"rmDesc"}
                    contStyle=""
                    info={rmDescInfo}
                    onChange={(name, value) => handleAddInfoChange(name, value)}
                  />
                )}
                <button
                  className="btn-mdfy absolute top-2 right-0"
                  // data-inf="* 언어별로 수정 가능"
                  // disabled={!isAbleRmDesct}
                  onClick={() => handleModifyInfo("rmDesc")}
                >
                  수정
                </button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="table-form" data-dir-col>
        <div className="sb-title">
          <h4>객실이미지</h4>
          <div>
            <span className={imgUrls.length >= 10 ? "hidden" : ""}>
              <input
                type="file"
                id="file"
                multiple
                // disabled
                onChange={handleImgUpload}
              />

              <label
                htmlFor="file"
                className="img-add"
                data-txt="이미지 추가"
              />
            </span>

            <span className=" px-4 text-red-400">
              * 최대 10개까지 사용 가능
            </span>
          </div>
        </div>
        {imgUrls.length > 0 && (
          <table>
            <tbody>
              <tr>
                <th>객실이미지</th>
                <td colSpan={3} className="full">
                  <div
                    className={imgUrls.length !== 0 ? "img-wrap" : "hidden "}
                  >
                    {imgUrls.map((img, index) => (
                      <div className="img-item" key={index}>
                        <img
                          src={img.url || img.resImgPhUrl}
                          alt={`객실 이미지_${index}`}
                          draggable
                        />
                        <div></div>
                        <div className="img-btn-wrap">
                          {/* {img.uyn === "N" && (
                          <button
                            data-status={img.uyn}
                            data-txt="사용"
                            onClick={() => {
                              handleImgChangeStatus({
                                rgSeq: img.rgSeq,
                                uyn: img.uyn,
                              });
                            }}
                          >
                            <IoIosCheckboxOutline
                              size={22}
                              style={{
                                padding: 1,
                                color: "green",
                                cursor: "pointer",
                                backgroundColor: "#fff",
                              }}
                            />
                          </button>
                        )} */}
                          {img.uyn === "Y" && (
                            <button
                              data-txt="삭제"
                              onClick={() => {
                                handleImgRemove({
                                  rgSeq: img.rgSeq,
                                  uyn: img.uyn,
                                });
                              }}
                            >
                              <CgCloseR
                                size={20}
                                style={{
                                  padding: 1,
                                  color: "red",
                                  cursor: "pointer",
                                  backgroundColor: "#fff",
                                }}
                              />
                            </button>
                          )}
                          <div className="relative" data-idx={index}>
                            <BsPencilSquare
                              size={20}
                              style={{
                                padding: 1,
                                color: "#39405a",
                                cursor: "pointer",
                                backgroundColor: "#fff",
                              }}
                            />
                            <input
                              type="file"
                              accept="images/*"
                              required
                              id="file-img"
                              className=""
                              data-idx={index}
                              onChange={handleImgModify}
                            />
                            <label
                              htmlFor="file-img"
                              onMouseDown={() => {
                                setImgIdx(index);
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        )}
      </div>
      <Modal
        title={"객실옵션 추가"}
        styles="w-5/6"
        btnText="추가"
        isOpen={isOpen}
        isLoading={isLoading}
        disabled={isAddAble}
        handleCloseModal={handleAddInfClose}
        onClick={handleAddInf}
      >
        <div className="table-form" data-dir-col>
          <table>
            <tbody>
              <tr data-t-brd>
                <th>언어 선택</th>
                <td>
                  <Select
                    id={"langCd"}
                    name={"langCd"}
                    option={rmAddLngInf?.langCd}
                    options={[
                      { kr: "선택하세요", value: "all", en: "select" },
                      ...newLngOptions,
                    ]}
                    onSelect={(target) => handleAddLngSelect(target)}
                  />
                </td>
                <th></th>
              </tr>
              <tr>
                <th>객실명</th>
                <td colSpan={3} className="relative  full">
                  <RadioContent
                    options={[]}
                    name={"rmNm"}
                    info={{}}
                    onChange={(target) => {
                      handleAddLngInfChange(target.name, target.value);
                    }}
                  />
                </td>
              </tr>
              <tr>
                <th>객실설명</th>
                <td colSpan={3} className="relative full">
                  {Object.values(rmDescInfo).length !== 0 && (
                    <RadioEditor
                      options={[]}
                      name={"rmDesc"}
                      contStyle="w-full"
                      info={{}}
                      onChange={(name, value) =>
                        handleAddLngInfChange(name, value)
                      }
                    />
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Modal>
    </div>
  );
};

export default RoomModifyView;
