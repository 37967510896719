import SubHeader from "components/layout/header/subHeader";
import ManModifyView from "./manModify";
import InfModifyView from "./infModify";
import PayModifyView from "./payModify";
import AdInfModifyView from "./adInfModify";
import { useLocation } from "react-router-dom";
import LodInfModifyView from "./lodModify";
import LodImgModifyView from "./imgModify";

const LodModifyView = () => {
  const { state } = useLocation();

  return (
    <div className="main-cnt">
      <SubHeader title="숙소 수정" back url={state?.url} />
      <LodInfModifyView />
      <InfModifyView />
      <ManModifyView />
      <PayModifyView />
      <AdInfModifyView />
      <LodImgModifyView />
    </div>
  );
};

export default LodModifyView;
