import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { lodDftDtlApi, lodModify } from "components/api/lod";
import Select from "components/select";
import CountrySelect from "components/select/country";
import PhoneSelect from "components/select/phone";
import { lodProps } from "interface/lod";
import i18n from "locales/i18n";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setMessage } from "store/commonSlice";
import { regNum } from "util/common";
import { lodGrdCdOptions, lodTypOptions, uYnOptions } from "util/option";

type IProps = {
  lodDtl: lodProps & {
    lodNo: string;
  };
};

const LodInfModifyView = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey: ["lodDftDtl"],
    queryFn: () => {
      return lodDftDtlApi({
        lodNo: search.replace("?lodNo=", ""),
        langCd: i18n.language,
      });
    },
  });

  let lodDtl = query?.data?.body?.lodDftDtl || {};

  const [inf, setInf] = useState<lodProps>({
    lodGrdCd: "",
    lodCntyCd: "",
    lodTypCd: "",
    telCntyCd: "",
    telNo: "",
    lodEml: "",
    faxCntyCd: "",
    faxNo: "",
    hpUrl: "",
    lodPosUtc: 9,
    rmCnt: "",
    resvPsbYn: "Y",
    resvRsvEml: "",
    bizNo: "",
    mailOrdrBizNo: "",
    ceoNm: "",
    bizNm: "",
  });

  const handleChange = (name: string, value: string) => {
    setInf({
      ...inf,
      [name]: value,
    });
  };

  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { name, id, value, lng } = target.dataset;
    if (name && value) {
      setInf({
        ...inf,
        [name]: value,
      });
    }
  };

  const lodMutation = useMutation(lodModify, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(
            setMessage({
              message: "숙소기본정보를 수정하였습니다.",
              isConfirm: true,
              handleClick() {
                dispatch(setMessage({ message: null }));
                queryClient.invalidateQueries(["lodDftDtl"]);
                setInf({
                  lodGrpNo: "",
                  lodGrpNm: "",
                  lodGrdCd: "",
                  lodCntyCd: "",
                  lodTypCd: "",
                  telCntyCd: "",
                  telNo: "",
                  lodEml: "",
                  faxCntyCd: "",
                  faxNo: "",
                  hpUrl: "",
                  lodPosUtc: 9,
                  rmCnt: "",
                  resvPsbYn: "Y",
                  resvRsvEml: "",
                  bizNo: "",
                  mailOrdrBizNo: "",
                  ceoNm: "",
                  bizNm: "",
                });
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleModify = () => {
    // if (!inf.lodEml) {
    //   return alert("숙소이메일을 입력하여 주세요");
    // }

    const params = {
      ...inf,
      lodNo: search.replace("?lodNo=", ""),
      rmCnt: Number(inf.rmCnt),
    };
    dispatch(
      setMessage({
        message: "숙소기본정보를 수정하시겠습니까?",
        isCancel: true,
        isConfirm: true,
        handleClick() {
          lodMutation.mutate(params);
          dispatch(setMessage({ message: null }));
          // dispatch(setLod)
        },
      })
    );
  };

  const isAble = !!(
    inf.lodGrpNo &&
    inf.lodCntyCd &&
    inf.lodGrdCd &&
    inf.telCntyCd &&
    inf.telNo &&
    inf.lodEml &&
    inf.hpUrl &&
    inf.rmCnt
  );

  return (
    <>
      <div className="table-form" data-dir-col>
        <div className="sb-title">
          <h4>기본정보</h4>
          <button
            className="btn-mdfy"
            // disabled={!isAble}
            onClick={handleModify}
          >
            수정
          </button>
        </div>
        <fieldset>
          <table>
            <tbody>
              <tr>
                <th>숙소그룹명</th>
                <td>
                  <input type="text" defaultValue={lodDtl?.lodGrpNm} readOnly />
                </td>

                <th>숙소번호</th>
                <td>
                  <input type="text" defaultValue={lodDtl?.lodNo} readOnly />
                </td>
              </tr>
              <tr>
                <th>숙소국가</th>
                <td>
                  <CountrySelect
                    option={lodDtl?.lodCntyCd}
                    disabled
                    handleSelect={handleSelect}
                  />
                </td>
                <th>숙소등급</th>
                <td>
                  <Select
                    id="lodGrdCd"
                    name="lodGrdCd"
                    option={inf.lodGrdCd || lodDtl?.lodGrdCd}
                    options={lodGrdCdOptions}
                    onSelect={handleSelect}
                  />
                </td>
              </tr>
              <tr>
                <th>숙소전화번호</th>
                <td>
                  <div className="flex gap-x-2 max-w-[25rem] relative">
                    <PhoneSelect
                      wrapStyle=""
                      btnStyle="w-[6rem] h-[2.75rem]"
                      name="telCntyCd"
                      mobCd={inf.telCntyCd || lodDtl?.telCntyCd}
                      handleSelect={handleSelect}
                    />
                    <span className="flex gap-x-2 w-full">
                      <input
                        type="text"
                        className=""
                        defaultValue={inf.telNo || lodDtl?.telNo}
                        onChange={(e) => {
                          const value = e.currentTarget.value;
                          if (!regNum.test(value)) {
                            return;
                          }
                          handleChange("telNo", e.currentTarget.value);
                        }}
                      />
                    </span>
                  </div>
                </td>
                <th>숙소팩스번호</th>
                <td>
                  <div className="flex gap-x-2 max-w-[25rem] relative">
                    <PhoneSelect
                      btnStyle="w-[6rem] h-[2.75rem]"
                      wrapStyle=""
                      name="faxCntyCd"
                      mobCd={inf.faxCntyCd || lodDtl?.faxCntyCd}
                      handleSelect={handleSelect}
                    />
                    <span className="flex gap-x-2 w-full">
                      <input
                        type="text"
                        className=""
                        defaultValue={inf.faxNo || lodDtl?.faxNo}
                        onChange={(e) => {
                          const value = e.currentTarget.value;
                          if (!regNum.test(value)) {
                            return;
                          }
                          handleChange("faxNo", e.currentTarget.value);
                        }}
                      />
                    </span>
                  </div>
                </td>
              </tr>
              <tr>
                <th>숙소이메일</th>
                <td>
                  <input
                    type="text"
                    defaultValue={inf.lodEml || lodDtl?.lodEml}
                    onChange={(e) =>
                      handleChange("lodEml", e.currentTarget.value)
                    }
                  />
                </td>
                <th>숙소홈페이지</th>
                <td>
                  <input
                    type="text"
                    name="hpUrl"
                    defaultValue={inf.hpUrl || lodDtl?.hpUrl}
                    onChange={(e) =>
                      handleChange("hpUrl", e.currentTarget.value)
                    }
                  />
                </td>
              </tr>
              <tr>
                <th>숙소총객실수량</th>
                <td>
                  <input
                    type="text"
                    name="rmCnt"
                    defaultValue={inf.rmCnt || lodDtl?.rmCnt}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (regNum.test(value)) {
                        handleChange("rmCnt", e.currentTarget.value);
                      } else {
                        return;
                      }
                    }}
                  />
                </td>
                <th>숙소UTC</th>
                <td>
                  <input
                    type="text"
                    name="utc"
                    defaultValue={inf.lodPosUtc || lodDtl?.lodPosUtc}
                    onChange={(e) => handleChange("utc", e.currentTarget.value)}
                  />
                </td>
              </tr>
              <tr>
                <th>숙소유형</th>
                <td>
                  <Select
                    id={"lodTypCd"}
                    name={"lodTypCd"}
                    option={inf.lodTypCd || lodDtl?.lodTypCd}
                    options={lodTypOptions}
                    onSelect={handleSelect}
                  />
                </td>
                <th>예약가능여부</th>
                <td>
                  <Select
                    id={"resvPsbYn"}
                    name={"resvPsbYn"}
                    option={inf.resvPsbYn || lodDtl?.resvPsbYn}
                    options={uYnOptions}
                    onSelect={handleSelect}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </fieldset>
      </div>
    </>
  );
};

export default LodInfModifyView;
