import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  lodRefAdList,
  lodRefdAdCreateApi,
  lodRefdListApi,
} from "components/api/lod";
import Modal from "components/modal/modal";
import RadioEditor, { langProps } from "components/radio/comRadioEditor";
import RadioContent from "components/radio/comRadiocontent";
import Select from "components/select";
import { lodRefAdProps } from "interface/lod";
import i18n from "locales/i18n";
import { MutableRefObject, useState } from "react";
import { useDispatch } from "react-redux";
import { setMessage } from "store/commonSlice";
import { langOptions } from "util/option";

type IProps = {
  lodNoRef: MutableRefObject<string>;
  isAbleRef: MutableRefObject<boolean>;
};

const InfAdCreateView = ({ lodNoRef, isAbleRef }: IProps) => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const query = useQuery({
    queryKey: ["lodRefdAd", lodNoRef.current],
    queryFn: () => {
      return lodRefAdList({
        lodNo: lodNoRef.current || "L240700020",
      });
    },
  });

  const adList = query?.data?.body?.lodRefdAdList;

  let nmInf = {};
  let descInf = {};
  let newLngOption = [] as langProps[];

  adList?.forEach((inf: lodRefAdProps) => {
    nmInf = {
      ...nmInf,
      langCd: i18n.language,
      [`refdInfNm_${inf.langCd}`]: inf.refdInfNm,
    };
    descInf = {
      ...descInf,
      langCd: i18n.language,
      [`refdDesc_${inf.langCd}`]: inf.refdDesc,
    };

    langOptions.forEach((lang) => {
      if (lang.value !== inf.langCd) {
        newLngOption.push(lang);
      }
    });

    newLngOption = langOptions.map((lang) => {
      return lang.value === inf.langCd
        ? {
            ...lang,
            events: "none",
          }
        : lang;
    });
  });

  const [adInf, setAdInf] = useState<lodRefAdProps>({
    lodNo: lodNoRef.current || "L240700020",
    rgSeq: adList.length,
    langCd: "",
    refdInfNm: "",
    refdDesc: "",
  });

  const [isOpen, setIsOpen] = useState(false);

  const handleChagne = (name: string, value: string) => {
    const adNm = name.split("_")[0];
    setAdInf({
      ...adInf,
      [adNm]: value,
    });
  };

  const handleClose = () => {
    setIsOpen(false);
    setAdInf({
      lodNo: lodNoRef.current || "L240700020",
      rgSeq: 1,
      langCd: "",
      refdInfNm: "",
      refdDesc: "",
    });
  };

  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { name, value } = target.dataset;
    if (name && value) {
      setAdInf({
        ...adInf,
        langCd: value,
      });
    }
  };

  const adInfMutation = useMutation(lodRefdAdCreateApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(
            setMessage({
              message: "숛소환불수수료 추가정보를 등록하였습니다",
              isConfirm: true,
              handleClick() {
                dispatch(setMessage({ message: null }));
                setIsOpen(false);
                queryClient.invalidateQueries(["lodRefdAd", lodNoRef.current]);
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleCreate = () => {
    if (!adInf.langCd) {
      return alert("언어를 선택하여 주세요");
    }
    if (!adInf.lodNo) {
      return alert("숙소번호가 없습니다.");
    }

    dispatch(
      setMessage({
        message: "숙소환불수수료 추가정보를 등록하시겠습니까?",
        isCancel: true,
        isConfirm: true,
        handleClick() {
          adInfMutation.mutate(adInf);
          dispatch(setMessage({ message: null }));
        },
      })
    );
  };

  let langOp =
    adList.length === 0
      ? [{ kr: "선택하세요", value: "A", us: "select" }, ...langOptions]
      : [{ kr: "선택하세요", value: "A", us: "select" }, ...newLngOption];

  return (
    <>
      <div className="table-form" data-dir-col>
        <div className="sb-title">
          <h4>추가기본정보</h4>
          <div className="flex gap-4">
            <button
              className="btn-mdfy"
              disabled={!isAbleRef.current}
              onClick={() => setIsOpen(true)}
            >
              언어추가
            </button>
          </div>
        </div>
        <fieldset disabled={!isAbleRef.current}>
          <table>
            <tbody>
              <tr>
                <th>기본명</th>
                <td colSpan={3} className="full">
                  <RadioContent
                    name={"refdInfNm"}
                    info={nmInf}
                    readOnly
                    onChange={() => {}}
                  />
                </td>
              </tr>
              <tr>
                <th>환불수수료설명</th>
                <td colSpan={3}>
                  <RadioEditor
                    name={"refdDesc"}
                    info={descInf}
                    readOnly
                    onChange={() => {}}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </fieldset>
      </div>
      <Modal
        title={"추가정보 등록"}
        isOpen={isOpen}
        styles="w-9/10"
        btnText="등록"
        handleCloseModal={handleClose}
        onClick={handleCreate}
      >
        <div className="table-form" data-dir-col>
          <table>
            <tbody>
              <tr>
                <th>언어</th>
                <td colSpan={1}>
                  <Select
                    id={"langCd"}
                    name={"langCd"}
                    option={adInf.langCd}
                    options={langOp}
                    onSelect={handleSelect}
                  />
                </td>
                <th></th>
                <td></td>
              </tr>
              <tr>
                <th>기본명</th>
                <td colSpan={3} className="full">
                  <RadioContent
                    name={"refdInfNm"}
                    onChange={(target) =>
                      handleChagne(target.name, target.value)
                    }
                  />
                </td>
              </tr>
              <tr>
                <th>환불수수료설명</th>
                <td colSpan={3}>
                  <RadioEditor
                    name={"refdDesc"}
                    info={{}}
                    onChange={(name, value) => handleChagne(name, value)}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Modal>
    </>
  );
};

export default InfAdCreateView;
