import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { modifyImgApi, removeImgApi } from "components/api/comn";
import imgUploadApi from "components/api/imgUpload";
import {
  rmAddCreateApi,
  rmCreateApi,
  rmImgUploadApi,
  rmInfoApi,
  rmModifyApi,
} from "components/api/room";
import RoomModifyView from "components/view/room/rm/modify";
import { imgProps } from "interface";
import i18n from "locales/i18n";
import { ChangeEvent, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setMessage } from "store/commonSlice";

const RoomModifyPage = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { pathname, search, state } = useLocation();
  const memType = pathname.includes("admin") ? "admin" : "manager";

  const [info, setInfo] = useState({
    rmTypCd: "",
    rmOlkCd: "",
    bedTypCd: "",
    // langCd: i18n.language,
    // rmNm: "",
    // rmDesc: "",
    rmAreaDesc: null,
    rmMaxPerCt: null,
    rmMinPerCt: null,
    babyFreePerCt: null,
    chldFreePerCt: null,
    uyn: "",
  });

  const rmNo = search.replace("?rmNo=", "");
  const params = {
    rmNo: rmNo ?? "",
    langCd: i18n.language,
    imgTypCd: "I11",
  };

  const query = useQuery({
    queryKey: ["rmInfo", !!rmNo],
    queryFn: () => {
      return rmInfoApi(params);
    },
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body: { rmDtl, rmImgList },
        } = data;
        if (code === 0) {
          // setImgUrls(rmImgList);
          setInfo({
            rmTypCd: "",
            rmOlkCd: "",
            bedTypCd: "",
            // langCd: i18n.language,
            // rmNm: "",
            // rmDesc: "",
            rmAreaDesc: null,
            rmMaxPerCt: null,
            rmMinPerCt: null,
            babyFreePerCt: null,
            chldFreePerCt: null,
            uyn: "",
          });
        }

        if (code === -1) {
          dispatch(
            setMessage({
              message,
              isConfirm: true,
              handleClick() {
                dispatch(setMessage({ message: null }));
              },
            })
          );
        }
      }
    },
  });

  let rmDtl = query?.data?.body?.rmDtl || {};
  let rmImgList = query?.data?.body?.rmImgList || ([] as any[]);
  let rmAddInfList = query?.data?.body?.addInfList;
  let isAbleImgs = rmImgList?.filter((img: any) => {
    return img.uyn === "Y";
  });

  const [rmAddInfo, setRmAddInfo] = useState<any>({});
  const [imgUrls, setImgUrls] = useState(rmImgList);
  const [imgIdx, setImgIdx] = useState(-1);
  const [isOpen, setIsOpen] = useState(false);
  const [rmAddLngInf, setRmAddInf] = useState<any>({});

  const handleAddInfoChange = (name: string, value: string) => {
    const names = name.split("_");
    const langCd = names[1];

    setRmAddInfo({
      langCd,
      [names[0]]: value,
    });
  };

  const handleChange = (target: EventTarget & HTMLInputElement) => {
    const { name, value } = target;
    if (name.includes("PerCt") || name === "rmAreaDesc") {
      setInfo({
        ...info,
        [name]: value.replace(/^[0]/, ""),
      });
      return;
    }

    setInfo({
      ...info,
      [name]: value,
    });
  };

  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { name, value } = target.dataset;

    if (!!name) {
      setInfo({
        ...info,
        [name]: value,
      });
    }
  };

  // 이미지 변경
  const modifyImgMutation = useMutation(modifyImgApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(
            setMessage({
              message: "삭제 완료하였습니다.",
              isConfirm: true,
              handleClick() {
                dispatch(setMessage({ message: null }));
                queryClient.invalidateQueries({ queryKey: ["rmInfo", !!rmNo] });
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  // 이미지 업로드
  const imgUploadMutation = useMutation(imgUploadApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;

        if (code === 0) {
          //객실 이미지 등록
          const params = {
            rmNo,
            rmImgList: body?.imgList,
          };

          rmImgUploadApi(params).then((data) => {
            if (data) {
              const {
                header: { code, message },
                body,
              } = data;
              if (code === 0) {
                dispatch(
                  setMessage({
                    message: "객실이미지 성공적으로 등록하였습니다.",
                    isConfirm: true,
                    handleClick() {
                      dispatch(setMessage({ message: null }));
                      queryClient.invalidateQueries({ queryKey: ["rmInfo"] });
                    },
                  })
                );
              }
              if (code === -1) {
                dispatch(
                  setMessage({
                    message,
                    isConfirm: true,
                    handleClick() {
                      dispatch(setMessage({ message: null }));
                    },
                  })
                );
              }
            }
          });
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  // 이미지 업로드
  const handleImgUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.currentTarget?.files;

    const imgLH = imgUrls.filter((img: any) => {
      return img.uyn === "Y";
    });

    if (imgLH.length > 10) {
      alert(`현재 등록 가능한 이미지는 ${10 - isAbleImgs.length}개 입니다`);
      return;
    }

    let formData = new FormData();
    formData.append("imgTypCd", "I11");

    if (files) {
      Array.from(files).forEach((file: File) => {
        if (file.size >= 1024000) {
          dispatch(setMessage({ message: "최대 업로드 사이즈는 1MB 입니다." }));
          return;
        }

        formData.append("uploadFiles", file);
        let reader = new FileReader();

        reader.onload = () => {
          if (reader.result) {
            const img = new Image();
            img.src = reader.result as string;

            img.onload = () => {
              let width = img.width;
              let height = img.height;

              if (width <= 600 || height <= 390) {
                dispatch(
                  setMessage({
                    message: "이미지 업로드 가능 사이즈 650 * 400 ",
                  })
                );

                return;
              }

              setImgUrls((prevList: any) => [
                ...prevList,
                {
                  name: file.name,
                  url: reader.result,
                },
              ]);
            };
          }
        };
        reader.readAsDataURL(file);
      });
    }

    imgUploadMutation.mutate({
      formData,
      lodNo: rmDtl.lodNo,
    });

    e.currentTarget.value = "";
  };

  //이미지 삭제
  const handleImgRemove = ({
    rgSeq,
    uyn,
  }: {
    rgSeq: number;
    uyn: "N" | "Y";
  }) => {
    const params = {
      rmNo,
      rgSeq,
      imgTypCd: "I11",
      uyn: (uyn === "N" ? "Y" : "N") as "Y" | "N",
    };

    dispatch(
      setMessage({
        message: "이미지를 삭제 하시겠습니까?",
        isConfirm: true,
        isCancel: true,
        handleClick() {
          modifyImgMutation.mutate(params);
          dispatch(setMessage({ message: null }));
        },
      })
    );
  };

  // 이미지 수정
  const handleImgModify = async (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.currentTarget?.files;

    let formData = new FormData();
    formData.append("imgTypCd", "I11");

    if (files) {
      Array.from(files).forEach((file: File) => {
        if (file.size >= 1024000) {
          dispatch(setMessage({ message: "최대 업로드 사이즈는 1MB 입니다." }));
          return;
        }

        formData.append("uploadFiles", file);
        let reader = new FileReader();

        reader.onload = () => {
          if (reader.result) {
            const img = new Image();
            img.src = reader.result as string;

            img.onload = () => {
              // let width = img.width;
              // let height = img.height;

              // if (width <= 600 || height <= 390) {
              //   dispatch(
              //     setMessage({
              //       message: "이미지 업로드 가능 사이즈 650 * 400 ",
              //     })
              //   );

              //   return;
              // }

              const newImgs = imgUrls.map((img: any, index: number) => {
                return index === imgIdx
                  ? {
                      ...img,
                      name: file.name,
                      url: reader.result,
                    }
                  : img;
              });

              setImgUrls(newImgs);
            };
          }
        };
        reader.readAsDataURL(file);
      });
    }

    imgUploadApi({
      formData,
      lodNo: rmDtl.lodNo,
    }).then((res: any) => {
      if (res) {
        const {
          header: { code, message },
          body,
        } = res;
        if (code === 0) {
          const imgItem = imgUrls[imgIdx];
          const imgData = body?.imgList[0] as imgProps;
          const params = {
            rmNo: imgItem.rmNo,
            rgSeq: imgItem.rgSeq,
            imgTypCd: "I11",
            uyn: "Y",
            tmpResImgPhUrl: imgData?.tmpResImgPhUrl,
            resImgPhUrl: imgData?.resImgPhUrl,
            resImgFileNm: imgData?.resImgFileNm,
            resImgNm: imgData?.resImgNm,
            imgFileExtNm: imgData?.imgFileExtNm,
            imgHei: imgData?.imgHei,
            imgWid: imgData?.imgWid,
            fileSiz: imgData?.fileSiz,
            tmpThumImgPhUrl: imgData?.tmpThumImgPhUrl,
            thumImgPhUrl: imgData?.thumImgPhUrl,
            thumImgHei: imgData?.thumImgHei,
            thumImgWid: imgData?.thumImgWid,
            expsOrdV: imgItem?.expsOrdV,
          };

          // modifyImgMutation.mutate(params);
          modifyImgApi(params).then((data: any) => {
            if (data) {
              const {
                header: { code, message },
                body,
              } = data;
              if (code === 0) {
                dispatch(
                  setMessage({
                    message: "이미지 수정이 완료되었습니다.",
                    isConfirm: true,
                    handleClick() {
                      dispatch(setMessage({ message: null }));
                      queryClient.invalidateQueries({
                        queryKey: ["rmInfo", !!rmNo],
                      });
                    },
                  })
                );
              }
              if (code === -1) {
                dispatch(setMessage({ message }));
              }
            }
          });
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    });

    e.currentTarget.value = "";
  };

  const handleImgChange = (e: Event & HTMLInputElement) => {};

  //이미지 삭제
  const removeImgMutation = useMutation(removeImgApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(
            setMessage({
              message: `이미지가 성공적으로 삭제 되었습니다.`,
              isConfirm: true,
              handleClick() {
                dispatch(setMessage({ message: null }));
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  //객실 정보 수정
  const modifyMutation = useMutation(rmModifyApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(
            setMessage({
              message: "객실정보가 성정공적으로 수정 되었습니다.",
              isConfirm: true,
              handleClick() {
                queryClient.invalidateQueries({ queryKey: ["rmInfo", !!rmNo] });
                dispatch(setMessage({ message: null }));
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleModifyInfo = (type: string) => {
    if (type === "default") {
      const params = {
        rmNo: search.replace("?rmNo=", ""),
        langCd: i18n.language,
        rmTypCd: info?.rmTypCd,
        rmOlkCd: info?.rmOlkCd,
        bedTypCd: info?.bedTypCd,
        maxPerCt: info?.rmMaxPerCt ?? 0,
        minPerCt: info?.rmMinPerCt ?? 0,
        babyFreePerCt: info?.babyFreePerCt ?? -1,
        chldFreePerCt: info?.chldFreePerCt ?? -1,
        uyn: info.uyn,
        // ...rmAddInfo,
      };
      dispatch(
        setMessage({
          message: "객실정보를 수정하시겠습니까?",
          isConfirm: true,
          isCancel: true,
          handleClick() {
            // dispatch(setMessage({ message: null }));
            modifyMutation.mutate(params);
          },
        })
      );
      return;
    }
    if (type === "rmNm") {
      const params = {
        rmNo: search.replace("?rmNo=", ""),
        langCd: rmAddInfo?.langCd ?? i18n.language,
        ...rmAddInfo,
      };
      dispatch(
        setMessage({
          message: `객실${
            params?.langCd === "kr"
              ? "한글"
              : params?.langCd === "us"
              ? "영문"
              : ""
          }명을 수정하시겠습니까?`,
          isConfirm: true,
          isCancel: true,
          handleClick() {
            // dispatch(setMessage({ message: null }));
            modifyMutation.mutate(params);
          },
        })
      );
      return;
    }
    if (type === "rmDesc") {
      const params = {
        rmNo: search.replace("?rmNo=", ""),
        langCd: rmAddInfo?.langCd ?? i18n.language,
        ...rmAddInfo,
      };
      dispatch(
        setMessage({
          message: `객실${
            params?.langCd === "kr"
              ? "한글"
              : params?.langCd === "us"
              ? "영문"
              : ""
          }설명을 수정하시겠습니까?`,
          isConfirm: true,
          isCancel: true,
          handleClick() {
            // dispatch(setMessage({ message: null }));
            modifyMutation.mutate(params);
          },
        })
      );
      return;
    }
  };

  // 언어별 객실 정보 추가
  const handleAddInfClose = () => {
    setIsOpen(false);
    setRmAddInf({});
  };
  const handleAddLngSelect = (target: EventTarget & HTMLLIElement) => {
    const { name, value } = target.dataset;
    if (name && value) {
      setRmAddInf({
        ...rmAddLngInf,
        [name]: value,
      });
    }
  };

  const handleAddLngInfChange = (name: string, value: string) => {
    const names = name.split("-");
    const langCd = names[1];

    setRmAddInf({
      ...rmAddLngInf,
      // langCd,
      [names[0].split("_")[0]]: value,
    });
  };

  const addInfMutation = useMutation(rmAddCreateApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(
            setMessage({
              message: "객실추가정보를 추가 완료하였습니다.",
              isConfirm: true,
              handleClick() {
                queryClient.invalidateQueries({
                  queryKey: ["rmInfo", !!rmNo],
                });
                setIsOpen(false);
                dispatch(setMessage({ message: null }));
                window.location.reload();
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleAddInf = () => {
    const params = {
      rmNo,
      rmInfList: [
        {
          ...rmAddLngInf,
          langCd: rmAddLngInf.langCd === "en" ? "us" : rmAddLngInf.langCd,
        },
      ],
    };

    dispatch(
      setMessage({
        message: "객실추가정보를 추가 하시겠습니까?",
        isCancel: true,
        isConfirm: true,
        handleClick() {
          addInfMutation.mutate(params);
          dispatch(setMessage({ message: null }));
        },
      })
    );
  };

  useEffect(() => {
    setRmAddInfo({});
  }, []);

  useEffect(() => {
    setImgUrls(rmImgList);
  }, [query]);

  return (
    <RoomModifyView
      memType={memType}
      rmDtl={rmDtl}
      rmAddInfo={rmAddInfo}
      rmAddInfList={rmAddInfList}
      imgUrls={imgUrls}
      info={info}
      isOpen={isOpen}
      isLoading={false}
      rmAddLngInf={rmAddLngInf}
      setIsOpen={setIsOpen}
      setImgIdx={setImgIdx}
      handleAddLngInfChange={handleAddLngInfChange}
      handleAddLngSelect={handleAddLngSelect}
      handleImgChange={handleImgChange}
      handleImgModify={handleImgModify}
      handleChange={handleChange}
      handleAddInfoChange={handleAddInfoChange}
      handleSelect={handleSelect}
      handleImgRemove={handleImgRemove}
      handleImgUpload={handleImgUpload}
      handleModifyInfo={handleModifyInfo}
      handleAddInf={handleAddInf}
      handleAddInfClose={handleAddInfClose}
    />
  );
};

export default RoomModifyPage;
