import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import {
  lodGrpAdInfCreate,
  lodGrpAdList,
  lodGrpAdModifyApi,
} from "components/api/lod";
import Modal from "components/modal/modal";
import RadioEditor from "components/radio/comRadioEditor";
import RadioContent from "components/radio/comRadiocontent";
import { langProps } from "components/radio/radioEditor";

import Select from "components/select";
import CountrySelect from "components/select/country";
import { IlodGrpAddInf, lodGrpAdInfProps } from "interface/lod";
import i18n from "locales/i18n";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { isSuccessSelector, setIsSuccess, setMessage } from "store/commonSlice";
import { isAbleSelector } from "store/lodSlice";

import { langOptions, lngLabel } from "util/option";

type IProps = {
  lodGrpAddInf: Array<IlodGrpAddInf>;
};

const AdInfModifyView = ({ lodGrpAddInf }: IProps) => {
  const dispatch = useDispatch();
  const { search } = useLocation();

  const queClient = useQueryClient();
  const isAdAble = useSelector(isAbleSelector);
  const isSuccess = useSelector(isSuccessSelector);
  const lodGrpNo = search.replace("?lodGrpNo=", "");

  const adQuery = useQuery({
    queryKey: ["lodGrpAdList", search.replace("?lodGrpNo=", "")],
    queryFn: () => {
      return lodGrpAdList({
        lodGrpNo: search.replace("?lodGrpNo=", ""),
        langCd: "",
      });
    },
  });

  const [adTyp, setTyp] = useState("");

  let adList = adQuery?.data?.body?.lodGrpAdList || lodGrpAddInf;

  let nmInf = {};
  let descInf = {};
  let addr1 = {};
  let addr2 = {};
  let addr3 = {};
  let addr4 = {};
  let newLngOptions = [] as langProps[];
  let listLngOptions = [] as langProps[];

  adList.forEach((inf: IlodGrpAddInf) => {
    nmInf = {
      ...nmInf,
      langCd: i18n.language,
      [`lodGrpNm_${inf.langCd}`]: inf.lodGrpNm,
    };
    descInf = {
      ...descInf,
      langCd: i18n.language,
      [`lodGrpDesc_${inf.langCd}`]: inf.lodGrpDesc,
    };
    addr1 = {
      ...addr1,
      langCd: i18n.language,
      [`lodGrpAddr1_${inf.langCd}`]: inf.lodGrpAddr1,
    };
    addr2 = {
      ...addr2,
      langCd: i18n.language,
      [`lodGrpAddr2_${inf.langCd}`]: inf.lodGrpAddr2,
    };
    addr3 = {
      ...addr3,
      langCd: i18n.language,
      [`lodGrpAddr3_${inf.langCd}`]: inf.lodGrpAddr3,
    };
    addr4 = {
      ...addr4,
      langCd: i18n.language,
      [`lodGrpAddr4_${inf.langCd}`]: inf.lodGrpAddr4,
    };

    newLngOptions = langOptions.map((lang) => {
      return lang.value === inf.langCd
        ? {
            ...lang,
            events: "none",
          }
        : lang;
    });

    langOptions.forEach((lang: langProps) => {
      if (lang.value === inf.langCd) {
        listLngOptions.push(lang);
      }
    });
  });

  let labels = [] as any[];
  lngLabel.forEach((lang) => {
    adList.forEach((inf: IlodGrpAddInf) => {
      let langCd = lang.id === inf.langCd ? lang : "";
      if (!!langCd) {
        labels.push(langCd);
      }
    });
  });

  const [adInf, setAdInf] = useState<lodGrpAdInfProps>({
    lodGrpNo,
    langCd: "",
    lodGrpNm: "",
    lodGrpDesc: "",
    lodGrpCntyCd: "KR",
    lodGrpZipc: adList[0]?.lodGrpZipc ?? "",
    lodGrpAddr1: "",
    lodGrpAddr2: "",
    lodGrpAddr3: "",
    lodGrpAddr4: "",
  });

  const [isOpen, setIsOpen] = useState(false);

  const handleChange = (name: string, value: string) => {
    const adNm = name.includes("Desc") ? name.replace("_kr", "") : name;

    setAdInf({
      ...adInf,
      [adNm]: value,
    });
  };

  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { name, value, code } = target.dataset;

    if (name && value) {
      const adNm = name?.includes("country") ? "lodGrpCntyCd" : name;
      const adValue = name?.includes("country") ? code : value;
      if (name === "country") {
        setAdInf({
          ...adInf,
          [adNm]: adValue,
        });
        return;
      }
      setAdInf({
        ...adInf,
        [adNm]: adValue,
      });
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    setAdInf({
      lodGrpNo,
      langCd: "",
      lodGrpNm: "",
      lodGrpDesc: "",
      lodGrpCntyCd: "KR",
      lodGrpZipc: adList[0]?.lodGrpZipc ?? "",
      lodGrpAddr1: "",
      lodGrpAddr2: "",
      lodGrpAddr3: "",
      lodGrpAddr4: "",
    });
  };

  // 추가 정보 등록
  const adInfMutation = useMutation(lodGrpAdInfCreate, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(
            setMessage({
              message: "숙소그룹추가 정보가 추가 되었습니다.",
              isConfirm: true,
              handleClick() {
                handleClose();
                dispatch(setIsSuccess(true));
                setIsOpen(false);
                dispatch(setMessage({ message: null }));
                queClient.invalidateQueries(["lodGrpAdList"]);
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  // 추가정보 수정
  const adInfMdyMutaion = useMutation(lodGrpAdModifyApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(
            setMessage({
              message: "추가정보 수정이 완료되었습니다.",
              isConfirm: true,
              handleClick() {
                dispatch(setMessage({ message: null }));
                setIsOpen(false);
                queClient.invalidateQueries(["lodGrpAdList"]);
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleClick = (type: string) => {
    if (type === "add") {
      dispatch(
        setMessage({
          message: "숙소그룹추가 정보를 추가하시겠습니까?",
          isCancel: true,
          isConfirm: true,
          handleClick() {
            adInfMutation.mutate(adInf);
            dispatch(setMessage({ message: null }));
          },
        })
      );
      return;
    }
    if (type !== "add") {
      if (!adInf.langCd) {
        return alert("언어를 선택하여 주세요");
      }
      dispatch(
        setMessage({
          message: "추가정보를 수정하시겠습니까?",
          isCancel: true,
          isConfirm: true,
          handleClick() {
            setIsOpen(true);
            dispatch(setMessage({ message: null }));
            adInfMdyMutaion.mutate(adInf);
          },
        })
      );
    }
  };

  let langOp =
    adTyp === "add"
      ? [{ kr: "선택하세요", value: "A", us: "select" }, ...newLngOptions]
      : [{ kr: "선택하세요", value: "A", us: "select" }, ...listLngOptions];

  return (
    <>
      <div className={"table-form"} data-dir-col>
        <div className="sb-title">
          <h4 data-inf="언어별 수정이 가능합니다.">추가정보</h4>
          <div className="flex justify-between gap-4">
            <button
              className="btn-mdfy"
              disabled={adList.length === 0}
              onClick={() => {
                setIsOpen(true);
                setTyp("add");
              }}
            >
              언어 추가
            </button>
            <button
              className="btn-mdfy"
              disabled={adList.length === 0}
              onClick={() => {
                setIsOpen(true);
                setTyp("");
              }}
            >
              수정
            </button>
          </div>
        </div>
        <fieldset>
          <table>
            <tbody>
              <tr>
                <th>숙소그룸명</th>
                <td colSpan={3}>
                  <RadioContent
                    name={"lodGrpNm"}
                    readOnly
                    info={nmInf}
                    onChange={(target) => {}}
                  />
                </td>
              </tr>
              <tr>
                <th>숙소그룹설명</th>
                <td colSpan={3}>
                  <RadioEditor
                    name={"lodGrpDesc"}
                    info={{}}
                    readOnly
                    onChange={() => {}}
                  />
                </td>
              </tr>
              <tr>
                <th>숙소그룹 우편번호</th>
                <td>
                  <input
                    type="text"
                    defaultValue={adList[0]?.lodGrpZipc}
                    onChange={() => {}}
                    readOnly
                  />
                </td>
              </tr>
              <tr>
                <th>숙소그룹 주소1</th>
                <td>
                  <div className="max-w-[25rem]">
                    <RadioContent
                      name={"lodGrpAddr1"}
                      info={addr1}
                      readOnly
                      onChange={() => {}}
                    />
                  </div>
                </td>
                <th>숙소그룹 주소2</th>
                <td>
                  <div className="max-w-[25rem]">
                    <RadioContent
                      name={"lodGrpAddr2"}
                      info={addr2}
                      readOnly
                      onChange={() => {}}
                    />
                  </div>
                </td>
              </tr>
              <tr data-n-brd>
                <th>숙소그룹 주소3</th>
                <td>
                  <div className="max-w-[25rem]">
                    {Object.values(addr3).length !== 0 && (
                      <RadioContent
                        name={"lodGrpAddr3"}
                        info={addr3}
                        readOnly
                        onChange={() => {}}
                      />
                    )}
                  </div>
                </td>
                <th>숙소그룹주소4</th>
                <td>
                  <div className="max-w-[25rem]">
                    {Object.values(addr4).length !== 0 && (
                      <RadioContent
                        name={"lodGrpAddr4"}
                        readOnly
                        onChange={() => {}}
                      />
                    )}
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </fieldset>
      </div>
      <Modal
        title={`숙소그룹 추가정보 ${adTyp === "add" ? "추가" : "수정"} `}
        isOpen={isOpen}
        styles="w-[90%]"
        btnText={adTyp === "add" ? "추가" : "수정"}
        handleCloseModal={handleClose}
        onClick={() => {
          handleClick(adTyp);
        }}
      >
        <div className="table-form">
          <table>
            <tbody>
              <tr data-t-brd>
                <th>언어 선택</th>
                <td>
                  <Select
                    id={"langCd"}
                    name={"langCd"}
                    option={adInf.langCd}
                    options={langOp}
                    onSelect={(target) => handleSelect(target)}
                  />
                </td>
                <th></th>
              </tr>
              <tr>
                <th>숙소그룸명</th>
                <td colSpan={3}>
                  <RadioContent
                    name={"lodGrpNm"}
                    onChange={(target) =>
                      handleChange("lodGrpNm", target.value)
                    }
                  />
                </td>
              </tr>
              <tr>
                <th>숙소그룹설명</th>
                <td colSpan={3}>
                  <RadioEditor
                    name={"lodGrpDesc"}
                    info={{}}
                    onChange={handleChange}
                  />
                </td>
              </tr>
              <tr>
                <th>숙소그룹 우편번호</th>
                <td>
                  <input
                    type="text"
                    value={adInf.lodGrpZipc}
                    onChange={(e) =>
                      handleChange("lodGrpZipc", e.currentTarget.value)
                    }
                  />
                </td>
              </tr>
              <tr>
                <th>숙소그룹 주소1</th>
                <td>
                  <div className="max-w-[25rem]">
                    <input
                      type="text"
                      name="lodGrpAddr1"
                      value={adInf.lodGrpAddr1}
                      onChange={(e) =>
                        handleChange("lodGrpAddr1", e.currentTarget.value)
                      }
                    />
                  </div>
                </td>
                <th>숙소그룹 주소2</th>
                <td>
                  <div className="max-w-[25rem]">
                    <input
                      type="text"
                      value={adInf?.lodGrpAddr2}
                      onChange={(e) =>
                        handleChange("lodGrpAddr2", e.currentTarget.value)
                      }
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>숙소그룹 주소3</th>
                <td>
                  <div className="max-w-[25rem]">
                    <input
                      type="text"
                      value={adInf?.lodGrpAddr3}
                      onChange={(e) =>
                        handleChange("lodGrpAddr3", e.currentTarget.value)
                      }
                    />
                  </div>
                </td>
                <th>숙소그룹 주소4</th>
                <td>
                  <div className="max-w-[25rem]">
                    <input
                      type="text"
                      value={adInf?.lodGrpAddr4}
                      onChange={(e) =>
                        handleChange("lodGrpAddr4", e.currentTarget.value)
                      }
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Modal>
    </>
  );
};

export default AdInfModifyView;
