import LoadingButton from "components/button/loadingButton";
import SubHeader from "components/layout/header/subHeader";
import Radio from "components/radio/radio";
import RadioEditor from "components/radio/comRadioEditor";
import RadioContent from "components/radio/comRadiocontent";
import { IlodAdInf, IlodDtl, IlodImg } from "interface/lod";
import i18n from "locales/i18n";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

type IProps = {
  lodDtl: IlodDtl;
  lodAdList: Array<IlodAdInf>;
  lodImgList: Array<IlodImg>;
};

const LodInfoView = ({ lodDtl, lodAdList, lodImgList }: IProps) => {
  const navigate = useNavigate();
  const { pathname, search, state } = useLocation();

  let nmInf = {};
  let descInf = {};
  let addr1 = {};
  let addr2 = {};
  let addr3 = {};
  let addr4 = {};

  lodAdList?.forEach((inf) => {
    nmInf = {
      ...nmInf,
      langCd: i18n.language,
      [`lodNm_${inf.langCd}`]: inf.lodNm,
    };
    descInf = {
      ...descInf,
      langCd: i18n.language,
      [`lodDesc_${inf.langCd}`]: inf.lodDesc,
    };

    addr1 = {
      ...addr1,
      langCd: i18n.language,
      [`lodAddr1_${inf.langCd}`]: inf.lodAddr1,
    };
    addr2 = {
      ...addr2,
      langCd: i18n.language,
      [`lodAddr2_${inf.langCd}`]: inf.lodAddr2,
    };
    addr3 = {
      ...addr3,
      langCd: i18n.language,
      [`lodAddr3_${inf.langCd}`]: inf.lodAddr1,
    };
    addr4 = {
      ...addr4,
      langCd: i18n.language,
      [`lodAddr4_${inf.langCd}`]: inf.lodAddr2,
    };
  });

  return (
    <div className="main-cnt">
      <SubHeader
        title="숙소 상세"
        close
        url={state?.url}
        state={state?.searchInfo}
      />

      <div className="table-form">
        <table>
          <tbody>
            <tr>
              <th>숙소그룹 명</th>
              <td>
                <input type="text" defaultValue={lodDtl?.lodGrpNm} readOnly />
              </td>
              <th>숙소 등급</th>
              <td>
                <input type="text" defaultValue={lodDtl?.lodGrdCd} readOnly />
              </td>
            </tr>
            <tr>
              <th>숙소국가</th>
              <td>
                <input type="text" defaultValue={lodDtl?.lodCntyCd} readOnly />
              </td>
            </tr>
            <tr>
              <th>숙소 전화번호</th>
              <td>
                <input type="text" defaultValue={lodDtl?.telNo} readOnly />
              </td>
              <th>숙소 팩스번호</th>
              <td>
                <input type="text" defaultValue={lodDtl?.faxNo} readOnly />
              </td>
            </tr>
            <tr>
              <th>숙소 이메일</th>
              <td>
                <input type="text" defaultValue={lodDtl?.lodEml} readOnly />
              </td>
              <th>숙소 홈페이지</th>
              <td>
                <input type="text" defaultValue={lodDtl?.hpUrl} readOnly />
              </td>
            </tr>
            <tr>
              <th>숙소 총객실수량</th>
              <td>
                <input type="text" defaultValue={lodDtl?.rmCnt} readOnly />
              </td>
              <th>숙소 UTC</th>
              <td>
                <input type="text" defaultValue={lodDtl?.lodPosUtc} readOnly />
              </td>
            </tr>
            <tr>
              <th>숙소유형</th>
              <td>
                <input type="text" defaultValue={lodDtl?.lodTypCd} readOnly />
              </td>
              <th>예약가능여부</th>
              <td>
                <input
                  type="text"
                  defaultValue={
                    lodDtl?.resvPsbYn === "Y" ? "예약가능" : "예약불가"
                  }
                  readOnly
                />
              </td>
            </tr>
            <tr>
              <th>숙소 판매금액구분</th>
              <td>
                <input
                  type="text"
                  defaultValue={
                    lodDtl?.sellAmDvc === "01" ? "판매가" : "입금가"
                  }
                  readOnly
                />
              </td>
              <th>숙소 판매금액 VAT포함여부</th>
              <td>
                <input
                  type="text"
                  defaultValue={
                    lodDtl?.sellAmVatInYn === "Y" ? "포함" : "미포함"
                  }
                  readOnly
                />
              </td>
            </tr>
            <tr>
              <th>숙소 판매당일 예약마감시간</th>
              <td>
                <input
                  type="text"
                  defaultValue={
                    lodDtl?.resvEti?.replace(/\B(?=(\d{2})+(?!\d))/g, ":") ?? ""
                  }
                  readOnly
                />
              </td>
            </tr>
            <tr>
              <th>숙소 환불수수료 부과방식</th>
              <td colSpan={3} className="py-[0.375rem]">
                <div className="w-full flex flex-col gap-y-4 ">
                  <span className="text-xs mx-[13.25rem]">
                    취소 수수료율은 취소를 접수한 날짜를 기준으로 적용
                  </span>
                  <span className="w-full flex justify-start">
                    <Radio
                      id="PerNight"
                      name="refund"
                      label="PerNight"
                      lableStyle="w-[12.5rem]"
                      readonly
                      disabled
                      checked={lodDtl?.refdFeeTypCd === "01"}
                      onChange={() => {}}
                    />
                    <span className="text-xs">
                      예약 일자별 판매금액에 취소수수료 부과 <br /> 일별판매금액
                      x 일별취소수수료율 (연박 시 각 일별취소수수료 합산)
                    </span>
                  </span>

                  <span className="w-full flex justify-start">
                    <Radio
                      id="PerBooking"
                      name="refund"
                      label="PerBooking"
                      lableStyle="w-[12.5rem]"
                      readonly
                      disabled
                      checked={lodDtl?.refdFeeTypCd === "02"}
                      onChange={() => {}}
                    />
                    <span className="text-xs">
                      총판매금액에 취소수수료 부과
                      <br /> 총판매금엑 x 예약1일차취소수수료율 (연박 시 동일)
                    </span>
                  </span>

                  <span className="w-full flex justify-start">
                    <Radio
                      id="PerOneNight"
                      name="refund"
                      label="PerOneNight"
                      lableStyle="w-[12.5rem]"
                      readonly
                      disabled
                      checked={lodDtl?.refdFeeTypCd === "03"}
                      onChange={() => {}}
                    />
                    <span className="text-xs">
                      예약1일차판매금액에 취소수수료 부과
                      <br /> 예약1일차판매금액 x 예약1일차취소수수료율 (연박 시
                      동일)
                    </span>
                  </span>
                  <span className="w-full italic underline text-right">
                    취소 수수료 부과 방식 예시
                  </span>
                </div>
              </td>
            </tr>
            <tr>
              <th>
                숙소 유아나이기준 <br /> (설정 값 미만)
              </th>
              <td>
                <input
                  type="text"
                  defaultValue={lodDtl?.babyAgeStndV}
                  readOnly
                />
              </td>
              <th>
                숙소 아동나이기준 <br /> (설정 값 미만)
              </th>
              <td>
                <input
                  type="text"
                  defaultValue={lodDtl?.chldAgeStndV}
                  readOnly
                />
              </td>
            </tr>
            <tr>
              <th>숙소 체크인 시간</th>
              <td>
                <input
                  type="text"
                  defaultValue={
                    lodDtl?.chkInTi?.replace(/\B(?=(\d{2})+(?!\d))/g, ":") ?? ""
                  }
                  readOnly
                />
              </td>
              <th>숙소 체크아웃시간</th>
              <td>
                <input
                  type="text"
                  defaultValue={
                    lodDtl?.chkOutTi?.replace(/\B(?=(\d{2})+(?!\d))/g, ":") ??
                    ""
                  }
                  readOnly
                />
              </td>
            </tr>
            <tr>
              <th>담당1 영문 이름</th>
              <td>
                <input
                  type="text"
                  name="manSurnm"
                  defaultValue={lodDtl?.manFstnm}
                  readOnly
                />
              </td>
              <th>담당1 영문 성</th>
              <td>
                <input
                  type="text"
                  name="manFstnm"
                  defaultValue={lodDtl?.manSurnm}
                  readOnly
                />
              </td>
            </tr>
            <tr>
              <th>담당1 직급</th>
              <td>
                <input
                  type="text"
                  name="man"
                  defaultValue={lodDtl?.manGrd}
                  readOnly
                />
              </td>
              <th>담당1 전화번호</th>
              <td>
                <input type="text" defaultValue={""} readOnly />
              </td>
            </tr>
            <tr>
              <th>숙소 정산입금일</th>
              <td>
                <input type="text" defaultValue={lodDtl?.calcInDt} readOnly />
              </td>
              <th>숙소 정산은행</th>
              <td>
                <input
                  type="text"
                  defaultValue={lodDtl?.calcInBnkNm}
                  readOnly
                />
              </td>
            </tr>
            <tr>
              <th>숙소 정산은행계좌번호</th>
              <td>
                <input type="text" defaultValue={lodDtl?.calcAcntNo} readOnly />
              </td>
              <th>숙소 정산통화</th>
              <td>
                <input type="text" defaultValue={lodDtl?.calCurrCd} readOnly />
              </td>
            </tr>
            <tr>
              <th>숙소명</th>
              <td colSpan={3}>
                <RadioContent
                  name={"lodNm"}
                  info={nmInf}
                  readOnly
                  inpDisabled={true}
                  onChange={(target) => {}}
                />
              </td>
            </tr>
            <tr>
              <th>숙소설명</th>
              <td colSpan={3}>
                <RadioEditor
                  name={"lodDesc"}
                  readOnly
                  info={{}}
                  onChange={(name, value) => {}}
                />
              </td>
            </tr>

            <tr>
              <th>숙소주소1</th>
              <td>
                <div className="max-w-[25rem]">
                  <RadioContent
                    name={"lodAddr1"}
                    info={addr1}
                    readOnly
                    onChange={(target) => {}}
                  />
                </div>
              </td>
              <th>숙소주소2</th>
              <td>
                <div className="max-w-[25rem]">
                  <RadioContent
                    name={"lodAddr2"}
                    info={addr2}
                    readOnly
                    onChange={(target) => {}}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th>숙소주소3</th>
              <td>
                <div className="max-w-[25rem]">
                  <RadioContent
                    name={"lodAddr3"}
                    info={addr3}
                    readOnly
                    onChange={(target) => {}}
                  />
                </div>
              </td>
              <th>숙소주소4</th>
              <td>
                <div className="max-w-[25rem]">
                  <RadioContent
                    name={"lodAddr4"}
                    info={addr4}
                    readOnly
                    onChange={(target) => {}}
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th>숙소우편번호</th>
              <td>
                <input
                  type="text"
                  name="lodZipc"
                  defaultValue={lodDtl?.lodZipc}
                  readOnly
                />
              </td>
            </tr>

            <tr className="">
              <th>숙소 이미지</th>
              <td colSpan={3}>
                <div className="img-wrap">
                  <div className="img-wrap">
                    {lodImgList?.map((item) => (
                      <div className="img-item" key={item.expsOrdV}>
                        <img src={item?.resImgPhUrl} alt="숙소이미지" />
                      </div>
                    ))}
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      {pathname.includes("admin") && (
        <div className="btn-form-wrap">
          <LoadingButton
            isLoading={false}
            txt={"수정"}
            styles={"btn-search"}
            onClick={() => {
              navigate(pathname.replace("info", "modify") + search, {
                state: {
                  searchInfo: state?.searchInfo,
                  url: state?.url,
                },
              });
            }}
          />
        </div>
      )}
    </div>
  );
};

export default LodInfoView;
