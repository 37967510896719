import { useMutation } from "@tanstack/react-query";
import { lodGrpAdInfCreate, lodGrpAdList } from "components/api/lod";
import Modal from "components/modal/modal";
import RadioEditor from "components/radio/comRadioEditor";
import RadioContent from "components/radio/comRadiocontent";
import Select from "components/select";
import { lodGrpAdInfProps } from "interface/lod";
import i18n from "locales/i18n";
import { MutableRefObject, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { isSuccessSelector, setIsSuccess, setMessage } from "store/commonSlice";
import { isAbleSelector, setLodNo } from "store/lodSlice";
import { langOptions } from "util/option";

type IProps = {
  lodGrpNo: MutableRefObject<string>;
};

const AdInfView = ({ lodGrpNo }: IProps) => {
  const dispatch = useDispatch();

  const isSuccess = useSelector(isSuccessSelector);
  const isAdAble = useSelector(isAbleSelector);

  const [adList, setList] = useState<Array<lodGrpAdInfProps>>([]);

  const adListMutation = useMutation(lodGrpAdList, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          setList(body?.lodGrpAdList);
          dispatch(setIsSuccess(false));
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  let nmInf = {};
  let descInf = {};
  let addr1 = {};
  let addr2 = {};
  let addr3 = {};
  let addr4 = {};
  let newLngOption = [] as any[];

  adList.forEach((inf) => {
    nmInf = {
      ...nmInf,
      langCd: i18n.language,
      [`lodGrpNm_${inf.langCd}`]: inf.lodGrpNm,
    };
    descInf = {
      ...descInf,
      langCd: i18n.language,
      [`lodGrpDesc_${inf.langCd}`]: inf.lodGrpDesc,
    };
    addr1 = {
      ...addr1,
      langCd: i18n.language,
      [`lodGrpAddr1_${inf.langCd}`]: inf.lodGrpAddr1,
    };
    addr2 = {
      ...addr2,
      langCd: i18n.language,
      [`lodGrpAddr2_${inf.langCd}`]: inf.lodGrpAddr2,
    };
    addr3 = {
      ...addr3,
      langCd: i18n.language,
      [`lodGrpAddr3_${inf.langCd}`]: inf.lodGrpAddr3,
    };
    addr4 = {
      ...addr4,
      langCd: i18n.language,
      [`lodGrpAddr4_${inf.langCd}`]: inf.lodGrpAddr4,
    };

    newLngOption = langOptions.filter((lang) => {
      return !adList.some((li) => li.langCd === lang.value);
    });
  });

  const [adInf, setAdInf] = useState<lodGrpAdInfProps>({
    langCd: "",
    lodGrpNm: "",
    lodGrpDesc: "",
    lodGrpCntyCd: "KR",
    lodGrpZipc: "",
    lodGrpAddr1: "",
    lodGrpAddr2: "",
    lodGrpAddr3: "",
    lodGrpAddr4: "",
  });

  const [isOpen, setIsOpen] = useState(false);

  const handleChange = (name: string, value: string) => {
    const adNm = name.includes("Desc") ? name.replace("_kr", "") : name;
    setAdInf({
      ...adInf,
      [adNm]: value,
    });
  };

  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { name, value, code } = target.dataset;

    if (name && value) {
      const adNm = name?.includes("country") ? "lodGrpCntyCd" : name;
      const adValue = name?.includes("country") ? code : value;
      if (name === "country") {
        setAdInf({
          ...adInf,
          [adNm]: adValue,
        });
        return;
      }
      setAdInf({
        ...adInf,
        [adNm]: adValue === "A" ? "" : adValue,
      });
    }
  };

  const handleClose = () => {
    setIsOpen(false);
    setAdInf({
      langCd: "",
      lodGrpNm: "",
      lodGrpDesc: "",
      lodGrpCntyCd: "KR",
      lodGrpZipc: "",
      lodGrpAddr1: "",
      lodGrpAddr2: "",
      lodGrpAddr3: "",
      lodGrpAddr4: "",
    });
  };

  // 추가 정보 등록
  const adInfMutation = useMutation(lodGrpAdInfCreate, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(
            setMessage({
              message: "숙소그룹추가 정보가 등록되었습니다.",
              isConfirm: true,
              handleClick() {
                handleClose();
                dispatch(setIsSuccess(true));
                dispatch(setMessage({ message: null }));
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleCreate = () => {
    if (!adInf.langCd) {
      return alert("추가할 언어를 선택하여 주세요");
    }
    dispatch(
      setMessage({
        message: "숙소그룹추가정보를 등록하시겠습니까?",
        isCancel: true,
        isConfirm: true,
        handleClick() {
          adInfMutation.mutate({
            ...adInf,
            lodGrpNo: lodGrpNo.current,
          });
          dispatch(setMessage({ message: null }));
        },
      })
    );
  };

  useEffect(() => {
    if (isSuccess) {
      adListMutation.mutate({
        lodGrpNo: lodGrpNo.current,
        langCd: "",
      });
    }
    return () => {
      dispatch(setLodNo(""));
      dispatch(setIsSuccess(false));
    };
  }, [isSuccess]);

  const langOp = adList.length === 0 ? langOptions : newLngOption;

  return (
    <>
      <div className={"table-form"} data-dir-col>
        <div className="sb-title relative">
          <h4 data-inf="기본정보 생성후 추가 정보 등록이 가능합니다.">
            추가정보
          </h4>
          <button
            className="btn-mdfy"
            disabled={!isAdAble || langOp.length === 0}
            onClick={() => {
              setIsOpen(true);
            }}
          >
            언어 추가
          </button>
        </div>
        {/* <fieldset disabled={!isAdAble}> */}
        <table>
          <tbody>
            <tr>
              <th>숙소그룸명</th>
              <td colSpan={3}>
                <RadioContent
                  name={"lodGrpNm"}
                  readOnly
                  info={nmInf}
                  onChange={(target) => handleChange("lodGrpNm", target.value)}
                />
              </td>
            </tr>
            <tr>
              <th>숙소그룹설명</th>
              <td colSpan={3}>
                <RadioEditor
                  name={"lodDesc"}
                  info={{}}
                  readOnly
                  onChange={handleChange}
                />
              </td>
            </tr>
            <tr>
              {/* <th>숙소그룹국가</th>
                <td>
                  <CountrySelect
                    option={adList[0]?.lodGrpCntyCd}
                    disabled
                    handleSelect={(target) => handleSelect(target)}
                  />
                </td> */}
              <th>숙소그룹 우편번호</th>
              <td>
                <input
                  type="text"
                  defaultValue={adList[0]?.lodGrpZipc}
                  readOnly
                />
              </td>
            </tr>
            <tr>
              <th>숙소그룹 주소1</th>
              <td>
                <div className="max-w-[25rem]">
                  <RadioContent
                    name={"lodGrpAddr1"}
                    info={addr1}
                    readOnly
                    onChange={(target) =>
                      handleChange("lodAddr1", target.value)
                    }
                  />
                </div>
              </td>
              <th>숙소그룹 주소2</th>
              <td>
                <div className="max-w-[25rem]">
                  <RadioContent
                    name={"lodGrpAddr2"}
                    info={addr2}
                    readOnly
                    onChange={(target) =>
                      handleChange("lodAddr2", target.value)
                    }
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th>숙소그룹 주소3</th>
              <td>
                <div className="max-w-[25rem]">
                  <RadioContent
                    name={"lodGrpAddr3"}
                    info={addr3}
                    readOnly
                    onChange={(target) =>
                      handleChange("lodAddr3", target.value)
                    }
                  />
                </div>
              </td>
              <th>숙소그룹 주소4</th>
              <td>
                <div className="max-w-[25rem]">
                  <RadioContent
                    name={"lodGrpAddr4"}
                    readOnly
                    onChange={(target) =>
                      handleChange("lodAddr4", target.value)
                    }
                  />
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        {/* </fieldset> */}
      </div>
      <Modal
        title={"숙소그룹 추가정보 등록"}
        isOpen={isOpen}
        styles="w-[90%]"
        btnText="등록"
        handleCloseModal={handleClose}
        onClick={handleCreate}
      >
        <div className="table-form">
          <table>
            <tbody>
              <tr data-t-brd>
                <th>언어 선택</th>
                <td>
                  <Select
                    id={"langCd"}
                    name={"langCd"}
                    option={adInf.langCd}
                    options={[
                      { kr: "선택하세요", value: "A", us: "select" },
                      ...langOp,
                    ]}
                    onSelect={(target) => handleSelect(target)}
                  />
                </td>
                <th></th>
              </tr>
              <tr>
                <th>숙소그룹명</th>
                <td colSpan={3}>
                  <RadioContent
                    name={"lodGrpNm"}
                    onChange={(target) =>
                      handleChange("lodGrpNm", target.value)
                    }
                  />
                </td>
              </tr>
              <tr>
                <th>숙소그룹설명</th>
                <td colSpan={3}>
                  <RadioEditor
                    name={"lodGrpDesc"}
                    info={{}}
                    onChange={handleChange}
                  />
                </td>
              </tr>
              <tr>
                {/* <th>숙소그룹국가</th>
                <td>
                  <CountrySelect
                    wrapStyle=""
                    option={adList[0]?.lodGrpCntyCd}
                    handleSelect={(target) => handleSelect(target)}
                  />
                </td> */}
                <th>숙소그룹 우편번호</th>
                <td>
                  <input
                    type="text"
                    value={adList[0]?.lodGrpZipc}
                    onChange={(e) =>
                      handleChange("lodGrpZipc", e.currentTarget.value)
                    }
                  />
                </td>
              </tr>
              <tr>
                <th>숙소그룹 주소1</th>
                <td>
                  <div className="max-w-[25rem]">
                    <input
                      type="text"
                      value={adInf.lodGrpAddr1}
                      onChange={(e) =>
                        handleChange("lodGrpAddr1", e.currentTarget.value)
                      }
                    />
                  </div>
                </td>
                <th>숙소그룹 주소2</th>
                <td>
                  <div className="max-w-[25rem]">
                    <input
                      type="text"
                      value={adInf.lodGrpAddr2}
                      onChange={(e) =>
                        handleChange("lodGrpAddr2", e.currentTarget.value)
                      }
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th>숙소그룹 주소3</th>
                <td>
                  <div className="max-w-[25rem]">
                    <input
                      type="text"
                      value={adInf.lodGrpAddr3}
                      onChange={(e) =>
                        handleChange("lodGrpAddr3", e.currentTarget.value)
                      }
                    />
                  </div>
                </td>
                <th>숙소그룹 주소4</th>
                <td>
                  <div className="max-w-[25rem]">
                    <input
                      type="text"
                      value={adInf.lodGrpAddr4}
                      onChange={(e) =>
                        handleChange("lodGrpAddr4", e.currentTarget.value)
                      }
                    />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Modal>
    </>
  );
};

export default AdInfView;
