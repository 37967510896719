import { useMutation } from "@tanstack/react-query";
import { rmListApi, rmOpListApi } from "components/api/comn";
import Select from "components/select";
import { optionProps } from "interface";
import i18n from "locales/i18n";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setRmList, setRmOpList } from "store/roomSlice";

type IProps = {
  option: string;
  disabled?: boolean;
  params: string;
  onSelect(target: EventTarget & HTMLLIElement): void;
};

const RmOpSelect = ({ params, disabled, option, onSelect }: IProps) => {
  const dispatch = useDispatch();
  const lng = i18n.language;
  const [options, setOptions] = useState<optionProps[]>([
    { value: "A", kr: "선택하세요", us: "select" },
  ]);

  const listMutation = useMutation({
    mutationKey: ["rmOpList", params],
    mutationFn: rmOpListApi,
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;

        if (code === 0) {
          const options = body.rmOpList.map((li: any) => {
            const option = {
              value: li.rgSeq,
              kr: li.rmOpNm,
              us: li.rmOpNm,
            };

            return option;
          });

          setOptions([...options]);

          dispatch(setRmOpList(body.rmOpList));
        }
        if (code === -1) {
          setOptions([{ value: "A", kr: "선택하세요", us: "select" }]);
        }
      }
    },
  });

  useEffect(() => {
    if (!!params) {
      listMutation.mutate(params);
    }
  }, [params]);

  return (
    <Select
      wrapStyle="flex-1 w-full h-[2.75rem]"
      id="rmOpSeq"
      name="rmOpSeq"
      option={option}
      disabled={disabled}
      options={[{ value: "A", kr: "선택하세요", us: "select" }, ...options]}
      onSelect={onSelect}
    />
  );
};

export default RmOpSelect;
