import { useMutation } from "@tanstack/react-query";
import { rmInfoApi } from "components/api/room";
import RoomInfoView from "components/view/room/rm/info";
import { imgList } from "interface";
import { IRmInfo } from "interface/room";
import i18n from "locales/i18n";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setMessage } from "store/commonSlice";

const RoomInfoPage = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();

  const rmNo = search.replace("?rmNo=", "");

  const rmMutation = useMutation(rmInfoApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
        }

        if (code === -1) {
          dispatch(
            setMessage({
              message: message || "검색 데이터가 없습니다.",
              isConfirm: true,
              handleClick() {
                dispatch(setMessage({ message: null }));
              },
            })
          );
        }
      }
    },
  });

  useEffect(() => {
    const params = {
      rmNo: rmNo ?? "",
      langCd: i18n.language,
      imgTypCd: "I11",
    };
    rmMutation.mutate(params);
  }, [search]);

  let rmDtl = rmMutation?.data?.body?.rmDtl || ({} as IRmInfo);
  let rmImgList = rmMutation?.data?.body?.rmImgList || ([] as imgList[]);
  let rmAddInfList = rmMutation?.data?.body?.addInfList || [];

  return (
    <RoomInfoView
      rmNo={rmNo}
      rmDtl={rmDtl}
      rmImgList={rmImgList}
      rmAddInfList={rmAddInfList}
    />
  );
};

export default RoomInfoPage;
