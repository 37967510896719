import Button from "components/button/createButtonWrap";
import SubHeader from "components/layout/header/subHeader";
import BedTypeSelect from "components/select/room/bedTypSelect";
import LodGrpSelect from "components/select/room/lodGrpNoSelect";
import RmOlkSelect from "components/select/room/rmOlkSelect";
import RmTypeSelect from "components/select/room/rmTypSelect";
import { rmCreateProps } from "interface/room";
import { ChangeEvent, Dispatch, RefObject, SetStateAction } from "react";
import "./room.scss";
import RadioContent from "components/radio/radiocontent";
import { lngLabel } from "util/option";
import RadioEditor from "components/radio/radioEditor";
import { regNum } from "util/common";
import { BsPencilSquare } from "react-icons/bs";
import { CgCloseR } from "react-icons/cg";
import i18n from "locales/i18n";
import LodNmSelect from "components/select/room/lodNmSelect";

type IProps = {
  rmInfo: rmCreateProps;
  rmAddInfo: any;
  memType: "admin" | "manager";
  imgWrapRef: RefObject<HTMLDivElement>;
  isLoading: boolean;
  imgUrls: {
    name: string;
    url: any;
  }[];
  setImgIdx: Dispatch<SetStateAction<number>>;
  handleRmInfoChange(target: EventTarget & HTMLInputElement): void;
  handleAddInfoChange(name: string, value: string): void;
  handleRmImgChange(e: ChangeEvent<HTMLInputElement>): void;
  handleModifyImg(e: ChangeEvent<HTMLInputElement>): void;
  handleImgRemove(index: number): void;
  handleSelect(target: EventTarget & HTMLLIElement): void;
  handleCreate(): void;
};

const RoomCreateView = ({
  memType,
  rmInfo,
  isLoading,
  rmAddInfo,
  imgWrapRef,
  imgUrls,
  setImgIdx,
  handleRmInfoChange,
  handleAddInfoChange,
  handleRmImgChange,
  handleImgRemove,
  handleModifyImg,
  handleSelect,
  handleCreate,
}: IProps) => {
  const lng = i18n.language;

  const isAble = !(
    rmInfo?.lodNo &&
    rmInfo?.rmTypCd &&
    rmInfo?.rmOlkCd &&
    rmInfo.bedTypCd &&
    rmInfo.rmMaxPerCt &&
    (rmAddInfo?.kr?.rmNm || rmAddInfo?.us?.rmNm)
  );

  return (
    <div className="main-cnt">
      <SubHeader title="객실 생성" dot />

      <div className="table-form">
        <table>
          <tbody>
            {memType === "admin" && (
              <tr>
                <th data-dot>숙소그룹명</th>
                <td>
                  <LodGrpSelect
                    option={rmInfo.lodGrpNo}
                    onSelect={(target) => handleSelect(target)}
                  />
                </td>
                <th data-dot>숙소명</th>
                <td>
                  <LodNmSelect
                    disabled={!rmInfo.lodGrpNo}
                    params={rmInfo.lodGrpNo}
                    option={rmInfo.lodNo}
                    onSelect={(target) => handleSelect(target)}
                  />
                </td>
              </tr>
            )}
            {memType === "manager" && (
              <tr>
                <th>숙소그룹명</th>
                <td>
                  <input type="text" value={rmInfo?.lodGrpNm} readOnly />
                </td>
                <th>숙소명</th>
                <td>
                  <input type="text" value={rmInfo?.lodNm} readOnly />
                </td>
              </tr>
            )}

            <tr>
              <th data-dot>객실타입</th>
              <td>
                <RmTypeSelect
                  disabled={!rmInfo.lodNo}
                  option={rmInfo.rmTypCd}
                  onSelect={(target) => handleSelect(target)}
                />
              </td>
              <th data-dot>객실전망</th>
              <td>
                <RmOlkSelect
                  disabled={!rmInfo.lodNo}
                  option={rmInfo.rmOlkCd}
                  onSelect={(target) => handleSelect(target)}
                />
              </td>
            </tr>
            <tr>
              <th>객실크기</th>
              <td>
                <input
                  type="text"
                  name="rmAreaDesc"
                  value={rmInfo.rmAreaDesc}
                  onChange={(e) => {
                    const isNum = !/[^0-9~]/.test(e.currentTarget.value);
                    if (isNum) {
                      handleRmInfoChange(e.currentTarget);
                    } else {
                      return;
                    }
                  }}
                />
              </td>
              <th data-dot>객실침대종류</th>
              <td>
                <BedTypeSelect
                  option={rmInfo.bedTypCd}
                  onSelect={(target) => handleSelect(target)}
                />
              </td>
            </tr>
            <tr>
              <th>객실 최소인원수</th>
              <td>
                <input
                  type="text"
                  name="rmMinPerCt"
                  value={rmInfo.rmMinPerCt ?? ""}
                  onChange={(e) => {
                    const isNum = !/[^0-9]/.test(e.currentTarget.value);
                    if (isNum || !e.currentTarget.value) {
                      handleRmInfoChange(e.currentTarget);
                    } else {
                      return;
                    }
                  }}
                />
              </td>
              <th data-dot>객실 최대인원수</th>
              <td>
                <input
                  type="text"
                  name="rmMaxPerCt"
                  value={rmInfo?.rmMaxPerCt ?? ""}
                  onChange={(e) => {
                    const isNum = !/[^0-9]/.test(e.currentTarget.value);
                    if (isNum || !e.currentTarget.value) {
                      handleRmInfoChange(e.currentTarget);
                    } else {
                      return;
                    }
                  }}
                />
              </td>
            </tr>
            <tr>
              <th>객실유아 무료인원수</th>
              <td>
                <input
                  type="text"
                  name="babyFreePerCt"
                  value={rmInfo?.babyFreePerCt || ""}
                  onChange={(e) => {
                    if (regNum.test(e.currentTarget.value)) {
                      handleRmInfoChange(e.target);
                    } else {
                      return;
                    }
                  }}
                />
              </td>
              <th>객실아동 무료인원수</th>
              <td>
                <input
                  type="text"
                  name="chldFreePerCt"
                  value={rmInfo?.chldFreePerCt ?? ""}
                  onChange={(e) => {
                    const isNum = !/[^0-9]/.test(e.currentTarget.value);
                    if (isNum || !e.currentTarget.value) {
                      handleRmInfoChange(e.currentTarget);
                    } else {
                      return;
                    }
                  }}
                />
              </td>
            </tr>
            <tr>
              <th data-dot>객실명</th>
              <td colSpan={3}>
                <RadioContent
                  options={lngLabel}
                  name={"rmNm"}
                  onChange={(target) =>
                    handleAddInfoChange(target.name, target.value)
                  }
                />
              </td>
            </tr>
            <tr>
              <th>객실설명</th>
              <td colSpan={3}>
                <RadioEditor
                  options={lngLabel}
                  name={"rmDesc"}
                  contStyle=""
                  info={{} as any}
                  onChange={(name, value) => handleAddInfoChange(name, value)}
                />
              </td>
            </tr>

            <tr>
              <th>객실이미지</th>
              <td colSpan={3}>
                <div className="flex items-center gap-x-5 py-[0.25rem]">
                  <span className={imgUrls.length >= 10 ? "hidden" : ""}>
                    <input
                      type="file"
                      id="file"
                      multiple
                      onChange={handleRmImgChange}
                    />
                    <label
                      htmlFor="file"
                      className="img-add"
                      data-txt="이미지 추가"
                    />
                  </span>

                  <span className="text-red-400">
                    * 최대 10개까지 등록 가능
                  </span>
                </div>

                <div
                  className={imgUrls.length !== 0 ? "img-wrap" : "hidden "}
                  ref={imgWrapRef}
                >
                  {imgUrls.map((img, index) => (
                    <div className="img-item" key={index}>
                      <img src={img.url || ""} alt={`객실 이미지_${index}`} />
                      <div></div>
                      <div className="img-btn-wrap">
                        <button
                          data-txt="삭제"
                          onClick={() => {
                            handleImgRemove(index);
                          }}
                        >
                          <CgCloseR
                            size={20}
                            style={{
                              padding: 1,
                              color: "red",
                              cursor: "pointer",
                              backgroundColor: "#fff",
                            }}
                          />
                        </button>
                        <div className="relative" data-idx={index}>
                          <BsPencilSquare
                            size={20}
                            style={{
                              padding: 1,
                              color: "#39405a",
                              cursor: "pointer",
                              backgroundColor: "#fff",
                            }}
                          />
                          <input
                            type="file"
                            accept="images/*"
                            required
                            id="file-img"
                            className=""
                            data-idx={index}
                            onChange={handleModifyImg}
                          />
                          <label
                            htmlFor="file-img"
                            onMouseDown={() => {
                              setImgIdx(index);
                            }}
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <Button isLoading={isLoading} disabled={isAble} onClick={handleCreate} />
    </div>
  );
};

export default RoomCreateView;
