import FormSearchBtn from "components/button/formSearch";
import SubHeader from "components/layout/header/subHeader";
import Select from "components/select";
import LodGrdSelect from "components/select/room/lodGrdSelect";
import LodGrpSelect from "components/select/room/lodGrpNoSelect";
import LodNoSelect from "components/select/room/lodNoSelect";
import LodRfdList from "components/tableList/lod/rfd";
import { lodRfdSearchInfoProps } from "interface/lod";
import { useLocation } from "react-router-dom";
import { lodGrpStatus, lodRfdStatus } from "util/option";

type IProps = {
  memType: "admin" | "manager";
  searchInfo: lodRfdSearchInfoProps;
  handleChange(name: string, value: string): void;
  handleSelect(target: EventTarget & HTMLLIElement): void;
  handleSearch(): void;
  handleReset(): void;
};

const LodRfdView = ({
  memType,
  searchInfo,
  handleChange,
  handleSelect,
  handleReset,
  handleSearch,
}: IProps) => {
  const { pathname } = useLocation();
  return (
    <>
      <div className="search-form">
        <SubHeader title="숙소환불수수료" add />

        <div className="table-form">
          <table>
            <tbody>
              {pathname.includes("admin") && (
                <tr>
                  <th>숙소그룹번호</th>
                  <td>
                    <LodGrpSelect
                      option={searchInfo.lodGrpNo}
                      onSelect={(target) => handleSelect(target)}
                    />
                  </td>
                  <th>숙소그룹 명</th>
                  <td>
                    <input
                      type="text"
                      value={searchInfo.lodGrpNm}
                      onChange={(e) =>
                        handleChange("lodGrpNm", e.currentTarget.value)
                      }
                    />
                  </td>
                </tr>
              )}
              <tr>
                <th>숙소번호</th>
                <td>
                  <LodNoSelect
                    option={searchInfo.lodNo}
                    params={searchInfo.lodGrpNo}
                    onSelect={(target) => handleSelect(target)}
                  />
                </td>
                <th>숙소명</th>
                <td>
                  <input
                    type="text"
                    value={searchInfo.lodNm}
                    onChange={(e) => {
                      handleChange("lodNm", e.currentTarget.value);
                    }}
                  />
                </td>
              </tr>
              <tr>
                <th>숙소등급</th>
                <td>
                  <LodGrdSelect
                    option={searchInfo.lodGrdCd}
                    onSelect={(target) => handleSelect(target)}
                  />
                </td>
                <th>숙소 환불수수료종류</th>
                <td>
                  <Select
                    id="refdFeeTypCd"
                    name="refdFeeTypCd"
                    options={lodRfdStatus}
                    onSelect={(target) => handleSelect(target)}
                  />
                </td>
              </tr>
              <tr>
                <th>숙소상태</th>
                <td colSpan={1}>
                  <Select
                    id="uyn"
                    name="uyn"
                    option={searchInfo.uyn}
                    options={lodGrpStatus}
                    onSelect={(target) => handleSelect(target)}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <FormSearchBtn
            handleSearch={handleSearch}
            handleReset={handleReset}
          />
        </div>
      </div>
      <LodRfdList searchInfo={searchInfo} memType={memType} />
    </>
  );
};

export default LodRfdView;
