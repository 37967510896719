import { promnHead } from "util/option";
import { IlodGrpList } from "interface/lod";
import { useLocation, useNavigate } from "react-router-dom";
import Pagenation from "components/pagenation";
import Modal from "components/modal/modal";
import { Dispatch, SetStateAction, Suspense, useEffect, useState } from "react";
import { lodStatusApi } from "components/api/lod";
import { useMutation } from "@tanstack/react-query";
import { useDispatch, useSelector } from "react-redux";
import {
  isLoadingSelector,
  isSearchSelector,
  setIsLoading,
  setIsSearch,
  setMessage,
} from "store/commonSlice";
import NotData from "../notData";
import { pageNavProps } from "interface";

type IProps = {
  searchInfo: any;
};

type IListProps = {
  searchInfo: any;
  setPageNav: Dispatch<SetStateAction<pageNavProps | null>>;
};

const List = ({ searchInfo, setPageNav }: IListProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname, search, state } = useLocation();
  const isSearch = useSelector(isSearchSelector);
  const [list, setList] = useState([]);

  let page = search.replace("?page=", "") || null;
  useEffect(() => {
    if (!!page) {
      dispatch(setIsSearch(true));
      dispatch(setIsLoading(true));
    }
  }, [page]);

  useEffect(() => {
    if (isSearch) {
      const params = {
        lodGrpNo: searchInfo?.lodGrpNo || state?.lodGrpNo,
        lodNo: searchInfo?.lodNo || state?.lodNo,
        // lodGrpCd: "",
        lodNm: searchInfo?.lodNm || state?.lodNm,
        rmNo: searchInfo?.rmNo || state?.rmNo,
        rmNm: searchInfo?.rmNm || state?.rmNm,
        rmTypCd: searchInfo?.rmTypCd || state?.rmTypCd,
        rmOlkCd: searchInfo?.rmOlkCd || state?.rmOlkCd,
        maxPerCt: searchInfo?.maxPerCt || state?.maxPerCt,
        minPerCt: searchInfo?.minPerCt || state?.minPerCt,
        uyn: searchInfo?.uyn || state?.uyn,
        pageNo: page || state?.pageNo,
      };
    }
  }, [isSearch, searchInfo, search]);

  const pageRows = 10;
  return (
    <>
      {list &&
        list.map((li: any, index: number) => (
          <tr
            key={index}
            onClick={(e) => {
              e.preventDefault();
              navigate(`info?prmCd=${li.rmNo}`, {
                state: {
                  searchInfo,
                  url: pathname + search,
                },
              });
              dispatch(setIsSearch(false));
            }}
          >
            <td>{index + 1}</td>
            <td>{li.lodGrpNo}</td>
            <td>{li.lodGrpNm}</td>
            <td>{li.lodGrpCntyCd}</td>
            <td>{li.lodGrpAddr1}</td>
            <td>{li.lodGrpAddr2}</td>
            <td>
              <span className={li.uYn === "Y" ? "uyn" : ""} onClick={() => {}}>
                {li.uYn === "Y" ? "정상" : "미 정상"}
              </span>
            </td>
          </tr>
        ))}
    </>
  );
};

const PromotionList = ({ searchInfo }: IProps) => {
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const isLoading = useSelector(isLoadingSelector);

  const [pageNav, setPageNav] = useState<pageNavProps | null>(null);

  const headList = promnHead.map((head) => {
    return head;
  });

  return (
    <div className="data-list-wrap">
      <div className="data-cont">
        <div className="table-title">
          <h3>
            검색결과
            <span>
              총<b>{pageNav?.totalRows || 0}</b>개
            </span>
          </h3>
        </div>
        <table>
          <thead>
            <tr>
              {headList.map((li) => (
                <th key={li}>{li}</th>
              ))}
            </tr>
          </thead>

          <tbody>
            <Suspense>
              <List searchInfo={searchInfo} setPageNav={setPageNav} />
            </Suspense>
          </tbody>
        </table>
      </div>
      <NotData
        isLoading={isLoading}
        isNotData={!pageNav?.totalRows}
        isSuccess={!!pageNav}
        styles=""
        txt="Loading ..."
      />
      {/* {rmList && !isLoading && <Pagenation total={1} />} */}
      {/* <AgGrid rowData={rowData} columnDefs={columnDefs} pagination={true} /> */}
    </div>
  );
};

export default PromotionList;
