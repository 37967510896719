import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { lodRefdDtl, lodRefdInfModify } from "components/api/lod";
import CheckButton from "components/checkbox/checkbtn";
import Select from "components/select";
import { IlodRfd } from "interface/lod";
import i18n from "locales/i18n";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setMessage } from "store/commonSlice";
import { dateFormat } from "util/common";
import { feeTypeOptions } from "util/option";

const InfModifyView = () => {
  const dispatch = useDispatch();
  const queryClient = useQueryClient();
  const { search, state } = useLocation();

  const lodNo = search.replace("?lodNo=", "");
  const query = useQuery({
    queryKey: ["lodRfdDtl", lodNo],
    queryFn: () => {
      return lodRefdDtl({
        lodNo,
        langCd: i18n.language,
      });
    },
  });

  let refdDtl = query?.data?.body?.lodRefdDtl;

  const [inf, setInf] = useState<IlodRfd>({
    lodNo,
    langCd: "",
    lodNm: "",
    rgSeq: refdDtl?.rgSeq || 1,
    uyn: "",
    refdFeeTypCd: "",
    feeTypCd: "",
    feeRti: -1,
    thurAplyYn: "",
    friAplyYn: "",
    satAplyYn: "",
    sunAplyYn: "",
    fstCrtDtti: "",
    wenAplyYn: "",
    tuesAplyYn: "",
    monAplyYn: "",
    aplyChkDay: "",
    aplyTi: "",
  });

  const handleChagne = (name: string, value: string) => {
    setInf({
      ...inf,
      [name]: value,
    });
  };

  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { name, value } = target.dataset;
    if (name && value) {
      setInf({
        ...inf,
        [name]: value === "A" ? "" : value,
      });
    }
  };

  const mdfyMutation = useMutation(lodRefdInfModify, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(
            setMessage({
              message: "숙소환불수수료 기본정보를 수정하였습니다.",
              handleClick() {
                queryClient.invalidateQueries(["lodRfdDtl", lodNo]);
                dispatch(setMessage({ message: null }));
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleModify = () => {
    dispatch(
      setMessage({
        message: "숙소환불수수료기본정보를 수정하시겠습니까?",
        isCancel: true,
        isConfirm: true,
        handleClick() {
          mdfyMutation.mutate(inf);
          dispatch(setMessage({ message: null }));
        },
      })
    );
  };

  return (
    <>
      <div className="table-form" data-dir-col>
        <div className="sb-title">
          <h4>추가기본정보</h4>
          <div className="flex gap-4">
            <button className="btn-mdfy" onClick={handleModify}>
              수정
            </button>
          </div>
        </div>
        <table>
          <tbody>
            <tr>
              <th>숙소그룹명</th>
              <td>
                <input type="text" defaultValue={refdDtl?.lodGrpNm} readOnly />
              </td>
              <th>숙소등급</th>
              <td>
                <input type="text" defaultValue={refdDtl?.lodGrdCd} readOnly />
              </td>
            </tr>
            <tr>
              <th>숙소명</th>
              <td>
                <input type="text" defaultValue={refdDtl?.lodNm} readOnly />
              </td>
            </tr>

            <tr>
              <th>등록일시</th>
              <td>
                <input
                  type="text"
                  defaultValue={dateFormat(refdDtl?.fstCrtDtti)}
                  readOnly
                />
              </td>
              <th>등록회원</th>
              <td>
                <input
                  type="text"
                  defaultValue={refdDtl?.fstCrtUsid}
                  readOnly
                />
              </td>
            </tr>
            <tr>
              <th>수정일시</th>
              <td>
                <input
                  type="text"
                  defaultValue={dateFormat(refdDtl?.ltChDtti)}
                  readOnly
                />
              </td>
              <th>수정회원</th>
              <td>
                <input type="text" defaultValue={refdDtl?.ltChUsid} readOnly />
              </td>
            </tr>
            <tr>
              <th>기본수수료종류</th>
              <td>
                <Select
                  id={"feeTypCd"}
                  name={"feeTypCd"}
                  option={inf.feeTypCd || refdDtl?.feeTypCd}
                  options={feeTypeOptions}
                  onSelect={handleSelect}
                />
              </td>
            </tr>
            <tr>
              <th>적용 시작일자</th>
              <td>
                <input
                  type="date"
                  defaultValue={dateFormat(refdDtl?.aplySDt)}
                  onChange={(e) =>
                    handleChagne("aplySDt", e.currentTarget.value)
                  }
                />
              </td>
              <th>적용 종료일자</th>
              <td>
                <input
                  type="date"
                  defaultValue={dateFormat(refdDtl?.aplyEDt)}
                  onChange={(e) =>
                    handleChagne("aplyEDt", e.currentTarget.value)
                  }
                />
              </td>
            </tr>
            <tr>
              <th>적용요일</th>
              <td colSpan={3}>
                <div className="w-full flex">
                  <CheckButton
                    id="MON"
                    name="day"
                    label="MON"
                    checked={
                      !!inf.monAplyYn
                        ? inf.monAplyYn === "Y"
                        : refdDtl?.monAplyYn === "Y"
                    }
                    onChange={(e) =>
                      handleChagne(
                        "monAplyYn",
                        e.currentTarget.checked ? "Y" : "N"
                      )
                    }
                  />
                  <CheckButton
                    id="TUE"
                    name="day"
                    label="TUE"
                    checked={
                      !!inf.tuesAplyYn
                        ? inf.tuesAplyYn === "Y"
                        : refdDtl?.tuesAplyYn === "Y"
                    }
                    onChange={(e) =>
                      handleChagne(
                        "tuesAplyYn",
                        e.currentTarget.checked ? "Y" : "N"
                      )
                    }
                  />
                  <CheckButton
                    id="WED"
                    name="day"
                    label="WED"
                    checked={
                      !!inf.wenAplyYn
                        ? inf.wenAplyYn === "Y"
                        : refdDtl?.wenAplyYn === "Y"
                    }
                    onChange={(e) =>
                      handleChagne(
                        "wenAplyYn",
                        e.currentTarget.checked ? "Y" : "N"
                      )
                    }
                  />
                  <CheckButton
                    id="THU"
                    name="day"
                    label="THU"
                    checked={
                      !!inf.thurAplyYn
                        ? inf.thurAplyYn === "Y"
                        : refdDtl?.thurAplyYn === "Y"
                    }
                    onChange={(e) =>
                      handleChagne(
                        "thurAplyYn",
                        e.currentTarget.checked ? "Y" : "N"
                      )
                    }
                  />
                  <CheckButton
                    id="FRI"
                    name="day"
                    label="FRI"
                    checked={
                      !!inf.friAplyYn
                        ? inf.friAplyYn === "Y"
                        : refdDtl?.friAplyYn === "Y"
                    }
                    onChange={(e) =>
                      handleChagne(
                        "friAplyYn",
                        e.currentTarget.checked ? "Y" : "N"
                      )
                    }
                  />
                  <CheckButton
                    id="SAT"
                    name="day"
                    label="SAT"
                    checked={
                      !!inf.satAplyYn
                        ? inf.satAplyYn === "Y"
                        : refdDtl?.satAplyYn === "Y"
                    }
                    onChange={(e) =>
                      handleChagne(
                        "satAplyYn",
                        e.currentTarget.checked ? "Y" : "N"
                      )
                    }
                  />
                  <CheckButton
                    id="SUN"
                    name="day"
                    label="SUN "
                    checked={
                      !!inf.sunAplyYn
                        ? inf.sunAplyYn === "Y"
                        : refdDtl?.sunAplyYn === "Y"
                    }
                    onChange={(e) =>
                      handleChagne(
                        "sunAplyYn",
                        e.currentTarget.checked ? "Y" : "N"
                      )
                    }
                  />
                </div>
              </td>
            </tr>
            <tr>
              <th>적용 숙박일기준</th>
              <td>
                <input type="text" defaultValue={refdDtl?.aplyChkDay} />
              </td>
              <th>적용 숙박일기준시간</th>
              <td>
                <input
                  type="text"
                  defaultValue={
                    refdDtl?.aplyTi.replace(/\B(?=(\d{2})+(?!\d))/g, ":") ?? ""
                  }
                />
              </td>
            </tr>
            <tr>
              <th>적용 수수료율</th>
              <td>
                <input
                  type="text"
                  defaultValue={refdDtl?.feeRti}
                  onChange={(e) =>
                    handleChagne("feeRti", e.currentTarget.value)
                  }
                />
              </td>
              <th>상태</th>
              <td>
                <input
                  type="text"
                  defaultValue={refdDtl?.uyn === "Y" ? "사용" : "미사용"}
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default InfModifyView;
