import { PayloadAction, createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "./store";

export interface lodState {
  isAdAble: boolean;
  lodGrpNo: string;
  rgSeq: number;
}

const initialState: lodState = {
  isAdAble: false,
  lodGrpNo: "",
  rgSeq: 0,
};

export const lodSlice = createSlice({
  name: "lod",
  initialState,
  reducers: {
    setIsAdAble: (state, { payload }: PayloadAction<boolean>) => {
      state.isAdAble = payload;
    },
    setLodNo: (state, { payload }: PayloadAction<string>) => {
      state.lodGrpNo = payload;
    },
    setRgSeq: (state, { payload }: PayloadAction<number>) => {
      state.rgSeq = payload;
    },
  },
});

export const isAbleSelector = createSelector(
  [(state: RootState) => state.lod.isAdAble],
  (isAdAble) => isAdAble
);

export const lodNoSelector = createSelector(
  [(state: RootState) => state.lod.lodGrpNo],
  (lodGrpNo) => lodGrpNo
);

export const lodRgSeqSelector = createSelector(
  [(state: RootState) => state.lod.rgSeq],
  (rgSeq) => rgSeq
);

export const { setIsAdAble, setLodNo, setRgSeq } = lodSlice.actions;

export default lodSlice.reducer;
