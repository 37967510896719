import FormSearchBtn from "components/button/formSearch";
import { lodGrpStatus } from "util/option";
import Select from "components/select";
import SubHeader from "components/layout/header/subHeader";
import { lodGrpSearchProps } from "interface/lod";
import LodGrpSelect from "components/select/room/lodGrpNoSelect";
import CountrySelect from "components/select/country";
import LodGrpList from "components/tableList/lod/grp";

type IProps = {
  searchInfo: Omit<lodGrpSearchProps, "pageNo" | "langCd">;
  memType: "admin" | "manager";
  handleChange(target: EventTarget & HTMLInputElement): void;
  handleSearch(): void;
  handleReset(): void;
  handleSelect(target: EventTarget & HTMLLIElement): void;
};

const LodGroupListView = ({
  searchInfo,
  memType,
  handleChange,
  handleReset,
  handleSearch,
  handleSelect,
}: IProps) => {
  return (
    <>
      <div className="search-form">
        <SubHeader title="숙소 그룹" add />

        <div className="table-form">
          <table>
            <tbody>
              <tr>
                <th>숙소그룹번호</th>
                <td>
                  <LodGrpSelect
                    option={searchInfo.lodGrpNo}
                    onSelect={(target) => handleSelect(target)}
                  />
                </td>
                <th>숙소그룹 명</th>
                <td>
                  <input
                    type="text"
                    name="lodGrpNm"
                    value={searchInfo.lodGrpNm}
                    onChange={(e) => handleChange(e.currentTarget)}
                  />
                </td>
              </tr>

              <tr>
                <th>숙소그룹 주소국가</th>
                <td>
                  <CountrySelect
                    option={searchInfo.lodGrpCntyNm}
                    handleSelect={(target) => handleSelect(target)}
                  />
                </td>
              </tr>
              <tr>
                <th>숙소그룹 주소1</th>
                <td>
                  <input
                    type="text"
                    name="lodGrpAddr1"
                    value={searchInfo.lodGrpAddr1}
                    onChange={(e) => handleChange(e.currentTarget)}
                  />
                </td>
                <th>숙소그룹 주소2</th>
                <td>
                  <input
                    type="text"
                    name="lodGrpAddr2"
                    value={searchInfo.lodGrpAddr2}
                    onChange={(e) => handleChange(e.currentTarget)}
                  />
                </td>
              </tr>
              <tr>
                <th>숙소그룹 상태</th>
                <td>
                  <Select
                    options={lodGrpStatus}
                    option={searchInfo.uyn}
                    onSelect={(target) => handleSelect(target)}
                    id={"uyn"}
                    name={"uyn"}
                  />
                </td>
              </tr>
            </tbody>
          </table>
          <FormSearchBtn
            disabled={false}
            handleSearch={handleSearch}
            handleReset={handleReset}
          />
        </div>
      </div>
      <LodGrpList memType={memType} searchInfo={searchInfo} />
    </>
  );
};

export default LodGroupListView;
