import { useQueries } from "@tanstack/react-query";
import { lodRefAdList, lodRefdDtl } from "components/api/lod";
import LodRfdInfoView from "components/view/lod/rfd/info/default";
import i18n from "locales/i18n";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";

const LodRfdInfoPage = () => {
  const dispatch = useDispatch();
  const { search, pathname } = useLocation();
  const lodNo = search.replace("?lodNo=", "");

  const querys = useQueries({
    queries: [
      {
        queryKey: ["lodRfdDtl", lodNo],
        queryFn: () => {
          return lodRefdDtl({
            lodNo,
            langCd: i18n.language,
          });
        },
      },
      {
        queryKey: ["lodRdfAdList", lodNo],
        queryFn: () => {
          return lodRefAdList({
            lodNo,
          });
        },
      },
    ],
  });

  let refdDtl = querys[0]?.data?.body?.lodRefdDtl;
  let refdAdList = querys[1]?.data?.body?.lodRefdAdList;

  return <LodRfdInfoView refdDtl={refdDtl} refdAdList={refdAdList} />;
};

export default LodRfdInfoPage;
