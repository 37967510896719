import SubHeader from "components/layout/header/subHeader";
import { IlodGrpAddInf, IlodGrpDtl } from "interface/lod";
import i18n from "locales/i18n";
import { useLocation, useNavigate } from "react-router-dom";
import { langOptions, lngLabel } from "util/option";
import InfModifyView from "./infModify";
import AdInfModifyView from "./adInfModify";

type IProps = {
  lodGrpDtl: IlodGrpDtl;
  lodGrpAdList: Array<IlodGrpAddInf>;
  inf: any;
};

const LodGrpModifyView = ({ lodGrpDtl, lodGrpAdList }: IProps) => {
  const navigate = useNavigate();
  const { state } = useLocation();

  let nmInf = {};
  let descInf = {};
  let addr1 = {};
  let addr2 = {};
  let addr3 = {};
  let addr4 = {};
  let newLngOptions = [] as any[];
  let labels = [] as any[];

  lodGrpAdList.forEach((inf) => {
    nmInf = {
      ...nmInf,
      langCd: i18n.language,
      [`lodGrpNm_${inf.langCd}`]: inf.lodGrpNm,
    };
    descInf = {
      ...descInf,
      langCd: i18n.language,
      [`lodGrpDesc_${inf.langCd}`]: inf.lodGrpDesc,
    };

    addr1 = {
      ...addr1,
      langCd: i18n.language,
      [`lodGrpAddr1_${inf.langCd}`]: inf.lodGrpAddr1,
    };
    addr2 = {
      ...addr2,
      langCd: i18n.language,
      [`lodGrpAddr2_${inf.langCd}`]: inf.lodGrpAddr2,
    };
    addr3 = {
      ...addr3,
      langCd: i18n.language,
      [`lodGrpAddr3_${inf.langCd}`]: inf.lodGrpAddr3,
    };
    addr4 = {
      ...addr4,
      langCd: i18n.language,
      [`lodGrpAddr4_${inf.langCd}`]: inf.lodGrpAddr4,
    };

    newLngOptions = langOptions.map((lang) => {
      return lang.value === inf.langCd
        ? {
            ...lang,
            events: "none",
          }
        : lang;
    });
  });
  lngLabel.forEach((lang) => {
    lodGrpAdList.forEach((inf) => {
      let langCd = lang.id === inf.langCd ? lang : "";
      if (!!langCd) {
        labels.push(langCd);
      }
    });
  });

  return (
    <div className="main-cnt">
      <SubHeader
        title="숙소그룹 수정"
        back
        url={state?.url}
        state={state?.searchInfo}
      />
      <InfModifyView lodGrpDtl={lodGrpDtl} />
      <AdInfModifyView lodGrpAddInf={lodGrpAdList} />
    </div>
  );
};

export default LodGrpModifyView;
