import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { lodManDtlApi, lodManModify } from "components/api/lod";
import PhoneSelect from "components/select/phone";
import { lodManProps } from "interface/lod";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setMessage } from "store/commonSlice";

type IProps = {
  manDtl: lodManProps;
};

const ManModifyView = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();

  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey: ["lodManDtl"],
    queryFn: () => {
      return lodManDtlApi({
        lodNo: search.replace("?lodNo=", ""),
      });
    },
  });

  const manDtl = query?.data?.body?.lodManDtl || {};

  const [inf, setInf] = useState<lodManProps>({
    rgSeq: 0,
    lodNo: search?.replace("?lodNo=", ""),
    manMkV: 0,
    manGrd: "",
    manFstnm: "",
    manSurnm: "",
    telCntyCd: "",
    telNo: "",
    uyn: "Y",
  });
  const handleChange = (name: string, value: string) => {
    setInf({
      ...inf,
      [name]: value,
    });
  };
  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { name, value } = target.dataset;
    if (name && value) {
      setInf({
        ...inf,
        [name]: value,
      });
    }
  };

  const manMutation = useMutation(lodManModify, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(
            setMessage({
              message: "담당자정보가 수정되었습니다.",
              isConfirm: true,
              handleClick() {
                dispatch(setMessage({ message: null }));
                queryClient.invalidateQueries(["lodManDtl"]);
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleModify = () => {
    dispatch(
      setMessage({
        message: "담당자정보를 수정하시겠습니까?",
        isCancel: true,
        isConfirm: true,
        handleClick() {
          manMutation.mutate(inf);
          dispatch(setMessage({ message: null }));
        },
      })
    );
  };

  return (
    <>
      <div className="table-form" data-dir-col>
        <div className="sb-title">
          <h4>담당자 정보</h4>
          <button className="btn-mdfy" onClick={handleModify}>
            수정
          </button>
        </div>
        <fieldset>
          <table>
            <tbody>
              <tr>
                <th>담당1 영문 이름</th>
                <td>
                  <input
                    type="text"
                    name="manFstnm"
                    defaultValue={inf.manFstnm || manDtl?.manFstnm}
                    onChange={(e) =>
                      handleChange("manFstnm", e.currentTarget.value)
                    }
                  />
                </td>
                <th>담당1 영문 성</th>
                <td>
                  <input
                    type="text"
                    name="manSurnm"
                    defaultValue={inf.manSurnm || manDtl?.manSurnm}
                    onChange={(e) =>
                      handleChange("manSurnm", e.currentTarget.value)
                    }
                  />
                </td>
              </tr>
              <tr>
                <th>담당1 직급</th>
                <td>
                  <input
                    type="text"
                    name="manGrd"
                    defaultValue={inf.manGrd || manDtl?.manGrd}
                    onChange={(e) =>
                      handleChange("manGrd", e.currentTarget.value)
                    }
                  />
                </td>
                <th>담당1 전화번호</th>
                <td>
                  <div className="flex gap-x-2 max-w-[25rem] relative">
                    <PhoneSelect
                      wrapStyle=""
                      btnStyle="w-[6rem] h-[2.75rem]"
                      name="telCntyCd"
                      mobCd={inf.telCntyCd || manDtl?.telCntyCd}
                      handleSelect={(target) => handleSelect(target)}
                    />
                    <span className="flex gap-x-2 w-full">
                      <input
                        type="text"
                        name="telNo"
                        defaultValue={inf.telNo || manDtl?.telNo}
                        onChange={(e) =>
                          handleChange("telNo", e.currentTarget.value)
                        }
                      />
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </fieldset>
      </div>
    </>
  );
};

export default ManModifyView;
