import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { lodInfDtlApi, lodInfModify } from "components/api/lod";
import Modal from "components/modal/modal";
import Radio from "components/radio/radio";
import Select from "components/select";
import { lodInfProps } from "interface/lod";
import { useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setMessage } from "store/commonSlice";
import { setRgSeq } from "store/lodSlice";
import { dateFormat } from "util/common";
import { lastTimeOptions, sllPayOption, vatInYnOption } from "util/option";

type IProps = {
  infDtl: lodInfProps;
};

const InfModifyView = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const queryClient = useQueryClient();

  const query = useQuery({
    queryKey: ["lodInfDtl"],
    queryFn: () => {
      return lodInfDtlApi({
        lodNo: search.replace("?lodNo=", ""),
      });
    },
  });

  const infDtl = query?.data?.body?.lodInfDtl || {};

  const [inf, setInf] = useState<lodInfProps>({
    rgSeq: 1,
    uyn: "Y",
    sellAmDvc: "01",
    sellAmVatInYn: "Y",
    contSDt: "",
    contEDt: "",
    resvETi: "",
    refdFeeTypCd: "",
    babyAgeStndV: 0,
    chldAgeStndV: 7,
    chkInTi: "",
    chkOutTi: "",
  });

  const isRegi = useRef(false);

  const handleChange = (name: string, value: string) => {
    setInf({
      ...inf,
      [name]: value,
    });
  };

  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { name, value } = target.dataset;
    if (name && value) {
      setInf({
        ...inf,
        [name]: value,
      });
    }
  };

  const infMutation = useMutation(lodInfModify, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(
            setMessage({
              message: "숙소정보를 수정하였습니다.",
              isConfirm: true,
              handleClick() {
                isRegi.current = true;
                queryClient.invalidateQueries(["lodInfDtl"]);
                dispatch(setMessage({ message: null }));
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleModify = () => {
    const params = {
      ...inf,
      lodNo: search.replace("?lodNo=", ""),
      resvETi: inf.resvETi.replaceAll(":", ""),
      chkInTi: inf.chkInTi.replaceAll(":", ""),
      chkOutTi: inf.chkOutTi.replaceAll(":", ""),
      contSDt: inf.contSDt.replaceAll("-", ""),
      contEDt: inf.contEDt.replaceAll("-", ""),
      babyAgeStndV: Number(inf.babyAgeStndV),
      chldAgeStndV: Number(inf.chldAgeStndV),
    };

    dispatch(
      setMessage({
        message: "숙소정보를 수정하시겠습니까?",
        isCancel: true,
        isConfirm: true,
        handleClick() {
          infMutation.mutate(params);
        },
      })
    );
  };

  return (
    <>
      <div className="table-form" data-dir-col>
        <div className="sb-title">
          <h4>숙소 정보</h4>
          <button
            className="btn-mdfy"
            // disabled={isRegi.current}
            onClick={handleModify}
          >
            수정
          </button>
        </div>
        <fieldset disabled={false}>
          <table>
            <tbody>
              <tr>
                <th>숙소판매금액구분</th>
                <td>
                  <Select
                    id={"sellAmDvc"}
                    name={"sellAmDvc"}
                    option={inf.sellAmDvc || infDtl?.sellAmDvc}
                    options={sllPayOption}
                    onSelect={handleSelect}
                  />
                </td>
                <th>숙소판매금액VAT포함여부</th>
                <td>
                  <Select
                    id={"sellAmVatInYn"}
                    name={"sellAmVatInYn"}
                    option={inf.sellAmVatInYn || infDtl?.sellAmVatInYn}
                    options={vatInYnOption}
                    onSelect={(target) => handleSelect(target)}
                  />
                </td>
              </tr>
              <tr>
                <th>계약시작일자</th>
                <td>
                  <input
                    type="date"
                    defaultValue={inf.contSDt || dateFormat(infDtl?.contSDt)}
                    onChange={(e) =>
                      handleChange("contSDt", e.currentTarget.value)
                    }
                  />
                </td>
                <th>계약종료일자</th>
                <td>
                  <input
                    type="date"
                    defaultValue={inf.contEDt || dateFormat(infDtl?.contEDt)}
                    onChange={(e) =>
                      handleChange("contEDt", e.currentTarget.value)
                    }
                  />
                </td>
              </tr>

              <tr>
                <th>숙소판매당일예약마감시간</th>
                <td>
                  <Select
                    id="resvETi"
                    name="resvETi"
                    option={
                      inf.resvETi ||
                      infDtl?.resvETi?.replace(/\B(?=(\d{2})+(?!\d))/g, ":")
                    }
                    options={lastTimeOptions}
                    onSelect={handleSelect}
                  />
                </td>
              </tr>
              <tr>
                <th>
                  숙소 유아나이기준 <br /> (설정 값 미만)
                </th>
                <td>
                  <input
                    type="text"
                    name="babyAgeStndV"
                    defaultValue={inf.babyAgeStndV || infDtl?.babyAgeStndV}
                    onChange={(e) =>
                      handleChange("babyAgeStndV", e.currentTarget.value)
                    }
                  />
                </td>
                <th>
                  숙소 아동나이기준 <br /> (설정 값 미만)
                </th>
                <td>
                  <input
                    type="text"
                    name="chldAgeStndV"
                    defaultValue={inf.chldAgeStndV || infDtl?.chldAgeStndV}
                    onChange={(e) =>
                      handleChange("chldAgeStndV", e.currentTarget.value)
                    }
                  />
                </td>
              </tr>
              <tr>
                <th>숙소체크인시간</th>
                <td>
                  <Select
                    id={"chkInTi"}
                    name={"chkInTi"}
                    option={
                      inf.chkInTi ||
                      infDtl?.chkInTi?.replace(/\B(?=(\d{2})+(?!\d))/g, ":")
                    }
                    options={lastTimeOptions}
                    onSelect={handleSelect}
                  />
                </td>
                <th>숙소체크아웃시간</th>
                <td>
                  <Select
                    id={"chkOutTi"}
                    name={"chkOutTi"}
                    option={
                      inf.chkOutTi ||
                      infDtl?.chkOutTi?.replace(/\B(?=(\d{2})+(?!\d))/g, ":")
                    }
                    options={lastTimeOptions}
                    onSelect={handleSelect}
                  />
                </td>
              </tr>
              <tr>
                <th data-dot>숙소환불수수료부과방식</th>
                <td colSpan={3} className="py-[0.375rem]">
                  <div className="w-full flex flex-col gap-y-4 ">
                    <span className="text-xs mx-[13.25rem]">
                      취소 수수료율은 취소를 접수한 날짜를 기준으로 적용
                    </span>
                    <span className="w-full flex justify-start">
                      <Radio
                        id="01"
                        name="refdFeeTypCd"
                        label="PerNight"
                        lableStyle="w-[12.5rem]"
                        checked={
                          inf.refdFeeTypCd === "01" ||
                          infDtl?.refdFeeTypCd === "01"
                        }
                        onChange={(e) =>
                          handleChange("refdFeeTypCd", e.currentTarget.id)
                        }
                      />
                      <span className="text-xs">
                        예약 일자별 판매금액에 취소수수료 부과 <br />{" "}
                        일별판매금액 x 일별취소수수료율 (연박 시 각
                        일별취소수수료 합산)
                      </span>
                    </span>

                    <span className="w-full flex justify-start">
                      <Radio
                        id="02"
                        name="refdFeeTypCd"
                        label="PerBooking"
                        lableStyle="w-[12.5rem]"
                        checked={
                          inf.refdFeeTypCd === "02" ||
                          infDtl?.refdFeeTypCd === "02"
                        }
                        onChange={(e) =>
                          handleChange("refdFeeTypCd", e.currentTarget.id)
                        }
                      />
                      <span className="text-xs">
                        총판매금액에 취소수수료 부과
                        <br /> 총판매금엑 x 예약1일차취소수수료율 (연박 시 동일)
                      </span>
                    </span>

                    <span className="w-full flex justify-start">
                      <Radio
                        id="03"
                        name="refdFeeTypCd"
                        label="PerOneNight"
                        lableStyle="w-[12.5rem]"
                        checked={
                          inf.refdFeeTypCd === "03" ||
                          infDtl?.refdFeeTypCd === "03"
                        }
                        onChange={(e) =>
                          handleChange("refdFeeTypCd", e.currentTarget.id)
                        }
                      />
                      <span className="text-xs">
                        예약1일차판매금액에 취소수수료 부과
                        <br /> 예약1일차판매금액 x 예약1일차취소수수료율 (연박
                        시 동일)
                      </span>
                    </span>
                    <button
                      className="w-full italic underline text-right"
                      onClick={() => {
                        //   setIsOpen(true);
                      }}
                    >
                      취소 수수료 부과 방식 예시
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </fieldset>
      </div>
      <Modal
        title={"취소 수수료 부과 방식 예시"}
        isOpen={false}
        handleCloseModal={() => {
          //   setIsOpen(false);
        }}
        onClick={() => {}}
      >
        <div className="w-full m-4">
          <span className="text-left font-bold">
            기본 수수료 정책만 설정했을 경우
          </span>
          <span className="text-left text-sm">취소수수료 예시</span>
        </div>
      </Modal>
    </>
  );
};

export default InfModifyView;
