import { useMutation } from "@tanstack/react-query";
import { lodInfCreateApi, lodInfDtlApi } from "components/api/lod";
import Modal from "components/modal/modal";
import Radio from "components/radio/radio";
import Select from "components/select";
import { lodInfProps } from "interface/lod";
import { MutableRefObject, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setMessage } from "store/commonSlice";
import { isAbleSelector, setRgSeq } from "store/lodSlice";
import { lastTimeOptions, sllPayOption, vatInYnOption } from "util/option";

type IProps = {
  lodNo: MutableRefObject<string>;
};

const InfCreateView = ({ lodNo }: IProps) => {
  const dispatch = useDispatch();
  const isAdAble = useSelector(isAbleSelector);

  const dtlMutation = useMutation(lodInfDtlApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          // isRegi.current = false;
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const dtl = dtlMutation?.data?.body?.lodInfDtl;

  const [inf, setInf] = useState<lodInfProps>({
    rgSeq: 1,
    uyn: "Y",
    sellAmDvc: "01",
    sellAmVatInYn: "Y",
    contSDt: "",
    contEDt: "",
    resvETi: "",
    refdFeeTypCd: "",
    babyAgeStndV: 0,
    chldAgeStndV: 7,
    chkInTi: "",
    chkOutTi: "",
  });

  const [isIn, setIsIn] = useState(false);
  const [isRegi, setIsRegi] = useState(false);

  const handleChange = (name: string, value: string) => {
    setInf({
      ...inf,
      [name]: value,
    });
  };

  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { name, value } = target.dataset;
    if (name && value) {
      setInf({
        ...inf,
        [name]: value,
      });
    }
  };

  const infMutation = useMutation(lodInfCreateApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(
            setMessage({
              message: "숙소정보를 등록하였습니다.",
              isConfirm: true,
              handleClick() {
                setIsRegi(true);
                dispatch(setRgSeq(body?.data?.rgSeq));
                dispatch(setMessage({ message: null }));
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleCreate = () => {
    const params = {
      ...inf,
      lodNo: lodNo.current ?? "",
      resvETi: inf.resvETi.replaceAll(":", ""),
      chkInTi: inf.chkInTi.replaceAll(":", ""),
      chkOutTi: inf.chkOutTi.replaceAll(":", ""),
      contSDt: inf.contSDt.replaceAll("-", ""),
      contEDt: inf.contEDt.replaceAll("-", ""),
      babyAgeStndV: Number(inf.babyAgeStndV),
      chldAgeStndV: Number(inf.chldAgeStndV),
    };

    dispatch(
      setMessage({
        message: "숙소정보를 등록하시겠습니까?",
        isCancel: true,
        isConfirm: true,
        handleClick() {
          infMutation.mutate(params);
        },
      })
    );
  };

  useEffect(() => {
    if (isRegi) {
      dtlMutation.mutate({
        lodNo: lodNo.current ?? "",
      });
    }
  }, [isRegi]);

  const isAble = isAdAble ? isRegi : !isAdAble;

  return (
    <>
      <div className="table-form" data-dir-col>
        <div className="sb-title">
          <h4
            data-inf="기본정보 생성후 추가 정보 등록이 가능합니다."
            onClick={() => setIsIn(!isIn)}
          >
            숙소 정보
          </h4>
          <button
            className={"btn-mdfy"}
            disabled={isAble}
            onClick={handleCreate}
          >
            등록
          </button>
        </div>
        <fieldset className={isIn ? "" : "hidden"}>
          <table>
            <tbody>
              <tr>
                <th data-dot>숙소판매금액구분</th>
                <td>
                  <Select
                    id={"sellAmDvc"}
                    name={"sellAmDvc"}
                    option={inf.sellAmDvc || dtl?.sellAmDvc}
                    options={sllPayOption}
                    disabled={isAble}
                    onSelect={handleSelect}
                  />
                </td>
                <th data-dot>숙소판매금액VAT포함여부</th>
                <td>
                  <Select
                    id={"sellAmVatInYn"}
                    name={"sellAmVatInYn"}
                    option={inf.sellAmVatInYn || dtl?.sellAmVatInYn}
                    options={vatInYnOption}
                    disabled={isAble}
                    onSelect={(target) => handleSelect(target)}
                  />
                </td>
              </tr>
              <tr>
                <th data-dot>계약시작일자</th>
                <td>
                  <input
                    type="date"
                    value={inf.contSDt || dtl?.contSDt}
                    readOnly={isAble}
                    onChange={(e) =>
                      handleChange("contSDt", e.currentTarget.value)
                    }
                  />
                </td>
                <th data-dot>계약종료일자</th>
                <td>
                  <input
                    type="date"
                    value={inf.contEDt || dtl?.contEDt}
                    readOnly={isAble}
                    onChange={(e) =>
                      handleChange("contEDt", e.currentTarget.value)
                    }
                  />
                </td>
              </tr>

              <tr>
                <th>숙소판매당일예약마감시간</th>
                <td>
                  <Select
                    id="resvETi"
                    name="resvETi"
                    option={inf.resvETi || dtl?.resvETi}
                    disabled={isAble}
                    options={lastTimeOptions}
                    onSelect={handleSelect}
                  />
                </td>
              </tr>
              <tr>
                <th>
                  숙소 유아나이기준 <br /> (설정 값 미만)
                </th>
                <td>
                  <input
                    type="text"
                    value={inf.babyAgeStndV || dtl?.babyAgeStndV}
                    readOnly={isAble}
                    onChange={(e) =>
                      handleChange("babyAgeStndV", e.currentTarget.value)
                    }
                  />
                </td>
                <th>
                  숙소 아동나이기준 <br /> (설정 값 미만)
                </th>
                <td>
                  <input
                    type="text"
                    value={inf.chldAgeStndV || dtl?.chldAgeStndV}
                    readOnly={isAble}
                    onChange={(e) =>
                      handleChange("chldAgeStndV", e.currentTarget.value)
                    }
                  />
                </td>
              </tr>
              <tr>
                <th>숙소체크인시간</th>
                <td>
                  <Select
                    id={"chkInTi"}
                    name={"chkInTi"}
                    option={inf.chkInTi || dtl?.chkInTi}
                    disabled={isAble}
                    options={lastTimeOptions}
                    onSelect={handleSelect}
                  />
                </td>
                <th>숙소체크아웃시간</th>
                <td>
                  <Select
                    id={"chkOutTi"}
                    name={"chkOutTi"}
                    option={inf.chkOutTi}
                    disabled={isAble}
                    options={lastTimeOptions || dtl?.lastTimeOptions}
                    onSelect={handleSelect}
                  />
                </td>
              </tr>
              <tr>
                <th data-dot>숙소환불수수료부과방식</th>
                <td colSpan={3} className="py-[0.375rem]">
                  <div className="w-full flex flex-col gap-y-4 ">
                    <span className="text-xs mx-[13.25rem]">
                      취소 수수료율은 취소를 접수한 날짜를 기준으로 적용
                    </span>
                    <span className="w-full flex justify-start">
                      <Radio
                        id="01"
                        name="refdFeeTypCd"
                        label="PerNight"
                        lableStyle="w-[12.5rem]"
                        disabled={isAble}
                        onChange={(e) =>
                          handleChange("refdFeeTypCd", e.currentTarget.id)
                        }
                      />
                      <span className="text-xs">
                        예약 일자별 판매금액에 취소수수료 부과 <br />{" "}
                        일별판매금액 x 일별취소수수료율 (연박 시 각
                        일별취소수수료 합산)
                      </span>
                    </span>

                    <span className="w-full flex justify-start">
                      <Radio
                        id="02"
                        name="refdFeeTypCd"
                        label="PerBooking"
                        lableStyle="w-[12.5rem]"
                        disabled={isAble}
                        onChange={(e) =>
                          handleChange("refdFeeTypCd", e.currentTarget.id)
                        }
                      />
                      <span className="text-xs">
                        총판매금액에 취소수수료 부과
                        <br /> 총판매금엑 x 예약1일차취소수수료율 (연박 시 동일)
                      </span>
                    </span>

                    <span className="w-full flex justify-start">
                      <Radio
                        id="03"
                        name="refdFeeTypCd"
                        label="PerOneNight"
                        lableStyle="w-[12.5rem]"
                        disabled={isAble}
                        onChange={(e) =>
                          handleChange("refdFeeTypCd", e.currentTarget.id)
                        }
                      />
                      <span className="text-xs">
                        예약1일차판매금액에 취소수수료 부과
                        <br /> 예약1일차판매금액 x 예약1일차취소수수료율 (연박
                        시 동일)
                      </span>
                    </span>
                    <button
                      className="w-full italic underline text-right"
                      onClick={() => {}}
                    >
                      취소 수수료 부과 방식 예시
                    </button>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </fieldset>
      </div>
      <Modal
        title={"취소 수수료 부과 방식 예시"}
        isOpen={false}
        handleCloseModal={() => {
          //   setIsOpen(false);
        }}
        onClick={() => {}}
      >
        <div className="w-full m-4">
          <span className="text-left font-bold">
            기본 수수료 정책만 설정했을 경우
          </span>
          <span className="text-left text-sm">취소수수료 예시</span>
        </div>
      </Modal>
    </>
  );
};

export default InfCreateView;
