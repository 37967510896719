import { useMutation } from "@tanstack/react-query";
import { lodGrpInfCreate } from "components/api/lod";
import CountrySelect from "components/select/country";
import PhoneSelect from "components/select/phone";
import { lodGrpInfProps } from "interface/lod";
import { MutableRefObject, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { setMessage } from "store/commonSlice";
import { isAbleSelector, setIsAdAble, setLodNo } from "store/lodSlice";
import { regEmail } from "util/common";

type IProps = {
  lodGrpNo: MutableRefObject<string>;
};

const InfView = ({ lodGrpNo }: IProps) => {
  const dispatch = useDispatch();

  const isAdAble = useSelector(isAbleSelector);

  const [info, setInfo] = useState<lodGrpInfProps>({
    telCntyCd: "+82",
    telNo: "",
    lodGrpEml: "",
    lodGrpCntyCd: "KOR",
  });

  const handleChange = (name: string, value: string) => {
    setInfo({
      ...info,
      [name]: value,
    });
  };
  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { name, value, lng, code } = target.dataset;
    if (name === "country") {
      setInfo({
        ...info,
        lodGrpCntyCd: code ?? "",
      });
      return;
    }
    setInfo({
      ...info,
      telCntyCd: value ?? "+82",
    });
  };

  // 기본정보 생성
  const infMutation = useMutation(lodGrpInfCreate, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(
            setMessage({
              message: "숙소그룹이 생성되었습니다.",
              isConfirm: true,
              handleClick() {
                dispatch(setMessage({ message: null }));
                dispatch(setIsAdAble(true));
                dispatch(setLodNo(body?.lodGrpNo));
                lodGrpNo.current = body?.lodGrpNo;
                dispatch(setIsAdAble(true));
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleCreate = () => {
    if (!(info.lodGrpEml && regEmail.test(info.lodGrpEml))) {
      alert("정확한 이메일형식을 입력하여 주세요");
      return;
    }
    if (!info.telNo) {
      alert("숙소전화번호를 입력하여 주세요");
      return;
    }

    dispatch(
      setMessage({
        message: "숙소그룹을 생성하시겠습니까?",
        isCancel: true,
        isConfirm: true,
        handleClick() {
          infMutation.mutate(info);
          dispatch(setMessage({ message: null }));
        },
      })
    );
  };
  const isInfAble = !!info.lodGrpEml && !!info.telCntyCd && !!info.telNo;

  return (
    <>
      <div className="table-form" data-dir-col>
        <div className="sb-title">
          <h4>기본정보</h4>
          <button
            className="btn-mdfy"
            disabled={!isInfAble || isAdAble}
            onClick={handleCreate}
          >
            생성
          </button>
        </div>
        <table>
          <tbody>
            <tr>
              {isAdAble && (
                <>
                  <th>숙소그룹번호</th>
                  <td>
                    <input type="text" value={lodGrpNo.current} readOnly />
                  </td>
                </>
              )}
              <th>숙소그룹국가코드</th>
              <td>
                <CountrySelect
                  option={info.lodGrpCntyCd}
                  disabled={isAdAble}
                  handleSelect={(target) => handleSelect(target)}
                />
              </td>
            </tr>
            <tr>
              <th data-dot>숙소그룹 전화번호</th>
              <td>
                <div className="flex gap-x-2 max-w-[25rem]">
                  <PhoneSelect
                    mobCd={info.telCntyCd}
                    disabled={isAdAble}
                    handleSelect={(target) => handleSelect(target)}
                  />
                  <input
                    type="text"
                    value={info.telNo}
                    readOnly={isAdAble}
                    onChange={(e) =>
                      handleChange("telNo", e.currentTarget.value)
                    }
                  />
                </div>
              </td>
              <th data-dot>숙소그룹 이메일</th>
              <td>
                <input
                  type="text"
                  value={info.lodGrpEml}
                  readOnly={isAdAble}
                  onChange={(e) =>
                    handleChange("lodGrpEml", e.currentTarget.value)
                  }
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default InfView;
