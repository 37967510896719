import { useMutation } from "@tanstack/react-query";
import { lodManCreateApi, lodManDtlApi } from "components/api/lod";
import PhoneSelect from "components/select/phone";
import { lodManProps } from "interface/lod";
import { MutableRefObject, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { setMessage } from "store/commonSlice";
import { isAbleSelector } from "store/lodSlice";

type IProps = {
  lodNo: MutableRefObject<string>;
};

const ManCreateView = ({ lodNo }: IProps) => {
  const dispatch = useDispatch();
  const isAdAble = useSelector(isAbleSelector);

  const dtlMutation = useMutation(lodManDtlApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          // isRegi.current = false;
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const dtl = dtlMutation?.data?.body?.lodManDtl;

  const [inf, setInf] = useState<lodManProps>({
    rgSeq: 1,
    lodNo: lodNo.current ?? "",
    manMkV: 0,
    manGrd: "",
    manFstnm: "",
    manSurnm: "",
    telCntyCd: "+82",
    telNo: "",
    uyn: "Y",
  });

  const [isIn, setIsIn] = useState(false);
  const [isRegi, setIsRegi] = useState(false);

  const handleChange = (name: string, value: string) => {
    setInf({
      ...inf,
      [name]: value,
    });
  };
  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { name, value } = target.dataset;
    if (name && value) {
      setInf({
        ...inf,
        [name]: value,
      });
    }
  };

  const manMutation = useMutation(lodManCreateApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(
            setMessage({
              message: "담당자정보가 등록되었습니다.",
              isConfirm: true,
              handleClick() {
                setIsRegi(true);
                dispatch(setMessage({ message: null }));
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleCreate = () => {
    if (!inf.manFstnm) {
      return alert("담장자 영문이름을 입력하여 주세요.");
    }
    if (!inf.manSurnm) {
      return alert("담당자 영문성을 입력하여 주세요.");
    }

    dispatch(
      setMessage({
        message: "담당자정보를 등록 하시겠습니까?",
        isCancel: true,
        isConfirm: true,
        handleClick() {
          manMutation.mutate({
            ...inf,
            lodNo: lodNo.current ?? "",
          });
          dispatch(setMessage({ message: null }));
        },
      })
    );
  };

  useEffect(() => {
    if (isRegi) {
      dtlMutation.mutate({
        lodNo: lodNo.current,
      });
    }
  }, [isRegi]);

  const isAble = isAdAble ? isRegi : !isAdAble;

  return (
    <>
      <div className="table-form" data-dir-col>
        <div className="sb-title">
          <h4
            data-inf="기본정보 생성후 추가 정보 등록이 가능합니다."
            onClick={() => {
              setIsIn(!isIn);
            }}
          >
            담당자 정보
          </h4>
          <button
            disabled={isAble}
            className={"btn-mdfy"}
            onClick={handleCreate}
          >
            등록
          </button>
        </div>
        <fieldset className={isIn ? "" : "hidden"}>
          <table>
            <tbody>
              <tr>
                <th data-dot>담당1 영문 이름</th>
                <td>
                  <input
                    type="text"
                    value={inf.manFstnm || dtl?.manFstnm}
                    readOnly={isAble}
                    onChange={(e) =>
                      handleChange("manFstnm", e.currentTarget.value)
                    }
                  />
                </td>
                <th data-dot>담당1 영문 성</th>
                <td>
                  <input
                    type="text"
                    value={inf.manSurnm || dtl?.manSurnm}
                    readOnly={isAble}
                    onChange={(e) =>
                      handleChange("manSurnm", e.currentTarget.value)
                    }
                  />
                </td>
              </tr>
              <tr>
                <th>담당1 직급</th>
                <td>
                  <input
                    type="text"
                    value={inf.manGrd || dtl?.manGrd}
                    readOnly={isAble}
                    onChange={(e) =>
                      handleChange("manGrd", e.currentTarget.value)
                    }
                  />
                </td>
                <th>담당1 전화번호</th>
                <td>
                  <div className="flex gap-x-2 max-w-[25rem] relative">
                    <PhoneSelect
                      wrapStyle=""
                      btnStyle="w-[6rem] h-[2.75rem]"
                      mobCd={inf.telCntyCd || dtl?.telCntyCd}
                      disabled={isAble}
                      handleSelect={(target) => handleSelect(target)}
                    />
                    <span className="flex gap-x-2 w-full">
                      <input
                        type="text"
                        name="telNo"
                        value={inf.telNo || dtl?.telNo}
                        readOnly={isAble}
                        onChange={(e) =>
                          handleChange("telNo", e.currentTarget.value)
                        }
                      />
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </fieldset>
      </div>
    </>
  );
};

export default ManCreateView;
