import { lodRfdHead } from "util/option";
import { IlodRfd, lodRfdSearchInfoProps } from "interface/lod";
import { useLocation, useNavigate } from "react-router-dom";
import {
  Dispatch,
  SetStateAction,
  Suspense,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  isLoadingSelector,
  isSearchSelector,
  setIsLoading,
  setIsSearch,
  setMessage,
} from "store/commonSlice";
import NotData from "../notData";
import { useDispatch, useSelector } from "react-redux";
import { pageNavProps } from "interface";
import Pagenation from "components/pagenation";
import { useMutation } from "@tanstack/react-query";
import { lodRefdListApi } from "components/api/lod";
import i18n from "locales/i18n";

type IListProps = {
  searchInfo: lodRfdSearchInfoProps;
  memType: "admin" | "manager";
  setPageNav: Dispatch<SetStateAction<pageNavProps | null>>;
};

const List = ({ searchInfo, memType, setPageNav }: IListProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname, search, state } = useLocation();
  const [info, setInfo] = useState({
    isOpen: false,
    rmNo: "",
    uyn: "",
  });
  const isSearch = useSelector(isSearchSelector);

  const [list, setList] = useState([]);

  const lodRefdMutation = useMutation(lodRefdListApi, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        dispatch(setIsLoading(false));
        dispatch(setIsSearch(false));
        if (code === 0) {
          setPageNav(body?.pageNav);
          setList(body?.lodRefdList);
        }
        if (code === -1) {
          dispatch(
            setMessage({
              message,
              isConfirm: true,
              handleClick() {
                dispatch(setMessage({ message: null }));
              },
            })
          );
        }
      }
    },
  });

  let page = search.replace("?page=", "") || null;
  useEffect(() => {
    if (!!page) {
      dispatch(setIsSearch(true));
      dispatch(setIsLoading(true));
    }
  }, [page]);

  useEffect(() => {
    if (isSearch) {
      const params = {
        lodGrpNo: searchInfo.lodGrpNo || state?.lodGrpNo,
        lodGrpNm: searchInfo.lodGrpNm || state?.lodGrpNm,
        lodNo: searchInfo?.lodNo || state?.lodNo,
        lodNm: searchInfo.lodNm || state?.lodNm,
        lodGrdCd: searchInfo.lodGrdCd || state?.lodGrdCd,
        refdFeeTypCd: searchInfo.refdFeeTypCd || state?.refdFeeTypCd,
        uyn: searchInfo.uyn || state?.uyn,
        pageNo: page || state?.pageNo,
        langCd: i18n.language,
      };

      lodRefdMutation.mutate(params);
    }
  }, [isSearch, searchInfo, search]);

  const pageRows = lodRefdMutation.data?.body?.pageNav?.pageRows;

  return (
    <>
      {list &&
        list?.map((li: IlodRfd, index: number) => {
          return (
            <tr
              key={index}
              onClick={(e) => {
                e.preventDefault();
                navigate(`info?lodNo=${li.lodNo}`, {
                  state: {
                    searchInfo,
                    url: pathname + search,
                  },
                });
                dispatch(setIsSearch(false));
              }}
            >
              <td>{(Number(page) - 1) * (pageRows || 10) + index + 1}</td>
              <td>{li.lodGrpNo}</td>
              <td>{li.lodGrpNm}</td>
              <td>{li.lodNo}</td>
              <td>{li.lodNm}</td>
              <td>{li.lodGrdCd}</td>
              <td>{li.refdFeeTypCd === "01" ? "판매가" : "입금가"}</td>
              <td>
                {li.feeTypCd === "B01"
                  ? "PerNight"
                  : li.feeTypCd === "B02"
                  ? "PerBooking"
                  : "PerOneNight"}
              </td>
              <td>
                <div className="flex">
                  <span>{li.monAplyYn === "Y" ? "월" : ""}</span>
                  <span>{li.tuesAplyYn === "Y" ? "화" : ""}</span>
                  <span>{li.wenAplyYn === "Y" ? "수" : ""}</span>
                  <span>{li.thurAplyYn === "Y" ? "목" : ""}</span>
                  <span>{li.friAplyYn === "Y" ? "금" : ""}</span>
                  <span>{li.satAplyYn === "Y" ? "토" : ""}</span>
                  <span>{li.sunAplyYn === "Y" ? "일" : ""}</span>
                </div>
              </td>
              <td>{li.fstCrtDtti}</td>
              <td>{li.uyn === "Y" ? "사용" : "미사용"}</td>
            </tr>
          );
        })}
    </>
  );
};

type IProps = {
  searchInfo: lodRfdSearchInfoProps;
  memType: "admin" | "manager";
};

const LodRfdList = ({ memType, searchInfo }: IProps) => {
  const isLoading = useSelector(isLoadingSelector);

  const [pageNav, setPageNav] = useState<pageNavProps | null>(null);

  const headList = lodRfdHead.map((head) => {
    return head;
  });

  const tableRef = useRef<HTMLDivElement>(null);

  return (
    <div className="data-list-wrap" ref={tableRef}>
      <div className="data-cont">
        <div className="table-title">
          <h3>
            검색결과
            <span>
              총<b>{pageNav?.totalRows || 0}</b>개
            </span>
          </h3>
        </div>
        <div>
          <table>
            <thead>
              {memType === "admin" && (
                <tr>
                  {headList.map((li) => (
                    <th key={li}>{li}</th>
                  ))}
                </tr>
              )}

              {memType === "manager" && (
                <tr>
                  {headList.map((li) => (
                    <th key={li}>{li}</th>
                  ))}
                </tr>
              )}
            </thead>

            <tbody>
              <Suspense>
                <List
                  searchInfo={searchInfo}
                  setPageNav={setPageNav}
                  memType={memType}
                />
              </Suspense>
            </tbody>
          </table>
        </div>
        <NotData
          isLoading={isLoading}
          isNotData={!pageNav?.totalRows}
          isSuccess={!!pageNav}
          styles=""
          txt="Loading ..."
        />
      </div>
      {pageNav && pageNav?.totalRows > 0 && (
        <Pagenation total={pageNav?.totalPage} />
      )}
    </div>
  );
};

export default LodRfdList;
