import { lodGrpDtlApi } from "components/api/lod";
import { useLocation } from "react-router-dom";
import i18n from "locales/i18n";
import { useDispatch } from "react-redux";
import LodGrpModifyView from "components/view/lod/grp/modify/modify";
import { useQuery } from "@tanstack/react-query";

const LodGrpModifyPage = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();

  const dtlQuery = useQuery({
    queryKey: ["lodGrpDtl", search.replace("?lodGrpNo=", "")],
    queryFn: () => {
      return lodGrpDtlApi({
        lodGrpNo: search.replace("?lodGrpNo=", ""),
        langCd: i18n.language,
      });
    },
  });

  const lodGrpDtl = dtlQuery.data?.body?.lodGrpDtl || {};
  const lodGrpAdList = dtlQuery.data?.body?.lodGrpAdList || [];

  return (
    <LodGrpModifyView
      lodGrpDtl={lodGrpDtl}
      lodGrpAdList={lodGrpAdList}
      inf={undefined}
    />
  );
};

export default LodGrpModifyPage;
