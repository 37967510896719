import LodGroupListView from "components/view/lod/grp/list";
import { lodGrpSearchProps } from "interface/lod";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { setIsLoading, setIsSearch } from "store/commonSlice";

const LodGroupListPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname, state } = useLocation();
  const memType = pathname.includes("admin") ? "admin" : "manager";

  const [searchInfo, setSearchInfo] = useState<
    Omit<lodGrpSearchProps, "pageNo" | "langCd">
  >({
    lodGrpNo: state?.lodgrpNo ?? "",
    lodGrpCntyNm: state?.lodGrpCntyNm ?? "대한민국",
    lodGrpCntyCd: state?.lodGrpcntyCd ?? "",
    lodGrpNm: state?.lodGrpNm ?? "",
    lodGrpAddr1: state?.lodGrpAddr1 ?? "",
    lodGrpAddr2: state?.lodgrpAddr2 ?? "",
    uyn: state?.uyn ?? "",
  });

  const handleChange = (target: EventTarget & HTMLInputElement) => {
    const { name, value } = target;

    setSearchInfo({
      ...searchInfo,
      [name]: value,
    });
  };

  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { name, lng, value, code } = target.dataset;

    if (value && name) {
      if (name === "country") {
        setSearchInfo({
          ...searchInfo,
          lodGrpCntyCd: code ?? "",
          lodGrpCntyNm: value,
        });
        return;
      }
      if (name === "uyn") {
        setSearchInfo({
          ...searchInfo,
          uyn: value,
        });
        return;
      }

      setSearchInfo({
        ...searchInfo,
        [name]: value === "A" ? "" : value,
      });
    }
  };

  const handleSearch = () => {
    dispatch(setIsSearch(true));
    dispatch(setIsLoading(true));
    navigate(`?page=1`);
  };

  const handleReset = () => {
    setSearchInfo({
      lodGrpNo: "",
      lodGrpCntyCd: "",
      lodGrpNm: "",
      lodGrpAddr1: "",
      lodGrpAddr2: "",
      uyn: "",
    });
    dispatch(setIsLoading(false));
    dispatch(setIsSearch(false));
    navigate(pathname);
  };

  return (
    <LodGroupListView
      searchInfo={searchInfo}
      memType={memType}
      handleChange={handleChange}
      handleSearch={handleSearch}
      handleReset={handleReset}
      handleSelect={handleSelect}
    />
  );
};

export default LodGroupListPage;
