import { useQueries, useQuery } from "@tanstack/react-query";
import { lodAdDftDtlApi, lodAdDftListApi } from "components/api/lod";
import LodDftInfoView from "components/view/lod/default/info";
import { use } from "i18next";
import i18n from "locales/i18n";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

const LodDefaultInfoPage = () => {
  const { search, pathname } = useLocation();

  const lodNo = search?.replace("?lodNo=", "");

  const querys = useQueries({
    queries: [
      {
        queryKey: ["lodAdDftDlt", lodNo],
        queryFn: () => {
          return lodAdDftDtlApi({
            lodNo,
            langCd: i18n.language,
          });
        },
      },
      {
        queryKey: ["lodAdDtfList", lodNo],
        queryFn: () => {
          return lodAdDftListApi({
            lodNo,
          });
        },
      },
    ],
  });

  let dtl = querys[0]?.data?.body?.dtl;
  let adList = querys[1]?.data?.body?.lodAdDftList;

  return <LodDftInfoView dtl={dtl} adList={adList} />;
};

export default LodDefaultInfoPage;
