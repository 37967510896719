import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { lodAdDftListApi, lodAdDftModify } from "components/api/lod";
import Modal from "components/modal/modal";
import RadioEditor, { langProps } from "components/radio/comRadioEditor";
import Select from "components/select";
import { dftAdInf } from "interface/lod";
import i18n from "locales/i18n";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { setMessage } from "store/commonSlice";
import { langOptions } from "util/option";

const AdInfModifyView = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();
  const queryClient = useQueryClient();

  const lodNo = search.replace("?lodNo=", "");

  const query = useQuery({
    queryKey: ["lodAdDftList", lodNo],
    queryFn: () => {
      return lodAdDftListApi({
        lodNo: search.replace("?lodNo=", ""),
      });
    },
  });

  const adList = query?.data?.body?.lodAdDftList;

  let facCn = {};
  let brkfCn = {};
  let cokCn = {};
  let cstAdCn = {};
  let cstFvrCn = {};
  let notiCn = {};
  let oprCn = {};
  let newLngOption = [] as any[];
  let listLngOption = [] as langProps[];

  adList?.forEach((inf: dftAdInf) => {
    notiCn = {
      ...notiCn,
      langCd: i18n.language,
      [`lodNotiCn_${inf.langCd}`]: inf.lodNotiCn,
    };
    facCn = {
      ...facCn,
      langCd: i18n.language,
      [`lodAddFacCn_${inf.langCd}`]: inf.lodAddFacCn,
    };
    brkfCn = {
      ...brkfCn,
      langCd: i18n.language,
      [`lodBrkfCn_${inf.langCd}`]: inf.lodBrkfCn,
    };
    cokCn = {
      ...cokCn,
      langCd: i18n.language,
      [`lodCokCn_${inf.langCd}`]: inf.lodCokCn,
    };
    cstAdCn = {
      ...cstAdCn,
      langCd: i18n.language,
      [`lodCstAdCn_${inf.langCd}`]: inf.lodCstAdCn,
    };
    cstFvrCn = {
      ...cstFvrCn,
      langCd: i18n.language,
      [`lodCstFvrCn_${inf.langCd}`]: inf.lodCstFvrCn,
    };
    oprCn = {
      ...oprCn,
      langCd: i18n.language,
      [`lodOprCn_${inf.langCd}`]: inf.lodOprCn,
    };

    langOptions.forEach((lang) => {
      if (lang.value === inf.langCd) {
        listLngOption.push(lang);
        return;
      }
    });
  });

  newLngOption = langOptions.filter((lang) => {
    return !adList.some((li: dftAdInf) => li.langCd === lang.value);
  });

  const [isOpen, setIsOpen] = useState("");

  const [inf, setInf] = useState({
    langCd: "",
    lodNotiCn: "",
    lodOprCn: "",
    lodCstFvrCn: "",
    lodAddFacCn: "",
    lodBrkfCn: "",
    lodCokCn: "",
    lodCstAdCn: "",
  });

  const handleChange = (name: string, value: string) => {
    const adNm = name.split("_")[0];
    setInf({
      ...inf,
      [adNm]: value,
    });
  };

  const handleSelect = (target: EventTarget & HTMLLIElement) => {
    const { name, value } = target.dataset;
    if (name && value) {
      setInf({
        ...inf,
        [name]: value === "A" ? "" : value,
      });
    }
  };

  const handleClose = () => {
    setIsOpen("");
    setInf({
      langCd: "",
      lodNotiCn: "",
      lodOprCn: "",
      lodCstFvrCn: "",
      lodAddFacCn: "",
      lodBrkfCn: "",
      lodCokCn: "",
      lodCstAdCn: "",
    });
  };

  const adInfMutation = useMutation(lodAdDftModify, {
    onSuccess(data) {
      if (data) {
        const {
          header: { code, message },
          body,
        } = data;
        if (code === 0) {
          dispatch(
            setMessage({
              message: "숙소기본정보 추가정보를 수정하였습니다.",
              isConfirm: true,
              handleClick() {
                dispatch(setMessage({ message: null }));
                handleClose();
                queryClient.invalidateQueries(["lodAdDftList", lodNo]);
              },
            })
          );
        }
        if (code === -1) {
          dispatch(setMessage({ message }));
        }
      }
    },
  });

  const handleModify = () => {
    if (!inf.langCd) {
      return alert("언어를 선택하여 주세요.");
    }
    if (
      !inf.lodAddFacCn &&
      !inf.lodBrkfCn &&
      !inf.lodCokCn &&
      !inf.lodCstAdCn &&
      !inf.lodCstFvrCn &&
      !inf.lodNotiCn &&
      !inf.lodNotiCn &&
      !inf.lodOprCn
    ) {
      return alert("수정할 항목을 입력하여 주세요.");
    }
    dispatch(
      setMessage({
        message: "숙소기본정보를 수정 하시겠습니까?",
        isCancel: true,
        isConfirm: true,
        handleClick() {
          dispatch(setMessage({ message: null }));
          adInfMutation.mutate({
            ...inf,
            lodNo,
          });
        },
      })
    );
  };

  let langOp =
    isOpen === "add"
      ? [{ kr: "선택하세요", value: "A", us: "select" }, ...newLngOption]
      : [{ kr: "선택하세요", value: "A", us: "select" }, ...listLngOption];

  return (
    <>
      <div className="table-form" data-dir-col>
        <div className="sb-title">
          <h4>추가정보</h4>
          <div className="flex items-center gap-4">
            <button
              className={
                langOptions.length !== listLngOption.length
                  ? "btn-mdfy"
                  : "hidden"
              }
              onClick={() => {
                setIsOpen("add");
              }}
            >
              언어추가
            </button>
            <button
              className="btn-mdfy"
              onClick={() => {
                setIsOpen("modify");
              }}
            >
              수정
            </button>
          </div>
        </div>
        <fieldset>
          <table>
            <tbody>
              <tr>
                <th>공지사항</th>
                <td colSpan={3} className="full">
                  <RadioEditor
                    name={"lodNotiCn"}
                    info={notiCn}
                    readOnly
                    onChange={(target) => {}}
                  />
                </td>
              </tr>
              <tr>
                <th>운영정보</th>
                <td colSpan={3}>
                  <RadioEditor
                    name={"lodOprCn"}
                    info={oprCn}
                    readOnly
                    onChange={(target) => {}}
                  />
                </td>
              </tr>
              <tr>
                <th>인원추가정보</th>
                <td colSpan={3}>
                  <RadioEditor
                    name={"lodCstAdCn"}
                    info={cstAdCn}
                    readOnly
                    onChange={(target) => {}}
                  />
                </td>
              </tr>
              <tr>
                <th>투숙고객혜택정보</th>
                <td colSpan={3}>
                  <RadioEditor
                    name={"lodCstFvrCn"}
                    info={cstFvrCn}
                    readOnly
                    onChange={(target) => {}}
                  />
                </td>
              </tr>
              <tr>
                <th>부대시설정보</th>
                <td colSpan={3}>
                  <RadioEditor
                    name={"lodAddFacCn"}
                    info={facCn}
                    readOnly
                    onChange={(target) => {}}
                  />
                </td>
              </tr>
              <tr>
                <th>조식정보</th>
                <td colSpan={3}>
                  <RadioEditor
                    name={"lodBrkfCn"}
                    info={brkfCn}
                    readOnly
                    onChange={(target) => {}}
                  />
                </td>
              </tr>
              <tr>
                <th>취사시설정보</th>
                <td colSpan={3}>
                  <RadioEditor
                    name={"lodCokCn"}
                    info={cokCn}
                    readOnly
                    onChange={(target) => {}}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </fieldset>
      </div>
      <Modal
        title={`추가정보 ${isOpen === "add" ? "등록" : "수정"}`}
        isOpen={isOpen !== ""}
        styles="w-9/10 overflow-auto"
        btnText={isOpen === "add" ? "등록" : "수정"}
        handleCloseModal={handleClose}
        onClick={handleModify}
      >
        <div className="table-form">
          <table>
            <tbody>
              <tr data-t-brd>
                <th>언어 선택</th>
                <td>
                  <Select
                    id={"langCd"}
                    name={"langCd"}
                    option={inf.langCd}
                    options={langOp}
                    onSelect={(target) => handleSelect(target)}
                  />
                </td>
                <th></th>
              </tr>
              <tr>
                <th>공지사항</th>
                <td colSpan={3} className="full">
                  <RadioEditor
                    name={"lodNotiCn"}
                    info={{}}
                    onChange={(name, value) => handleChange(name, value)}
                  />
                </td>
              </tr>
              <tr>
                <th>운영정보</th>
                <td colSpan={3}>
                  <RadioEditor
                    name={"lodOprCn"}
                    info={{}}
                    onChange={(name, value) => handleChange(name, value)}
                  />
                </td>
              </tr>
              <tr>
                <th>인원추가정보</th>
                <td colSpan={3}>
                  <RadioEditor
                    name={"lodCstAdCn"}
                    info={{}}
                    onChange={(name, value) => handleChange(name, value)}
                  />
                </td>
              </tr>
              <tr>
                <th>투숙고객혜택정보</th>
                <td colSpan={3}>
                  <RadioEditor
                    name={"lodCstFvrCn"}
                    info={{}}
                    onChange={(name, value) => handleChange(name, value)}
                  />
                </td>
              </tr>
              <tr>
                <th>부대시설정보</th>
                <td colSpan={3}>
                  <RadioEditor
                    name={"lodAddFacCn"}
                    info={{}}
                    onChange={(name, value) => handleChange(name, value)}
                  />
                </td>
              </tr>
              <tr>
                <th>조식정보</th>
                <td colSpan={3}>
                  <RadioEditor
                    name={"lodBrkfCn"}
                    info={{}}
                    onChange={(name, value) => handleChange(name, value)}
                  />
                </td>
              </tr>
              <tr>
                <th>취사시설정보</th>
                <td colSpan={3}>
                  <RadioEditor
                    name={"lodCokCn"}
                    info={{}}
                    onChange={(name, value) => handleChange(name, value)}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </Modal>
    </>
  );
};

export default AdInfModifyView;
